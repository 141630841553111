/*Start of Login Header*/
.c-header#Header {
    .c-header__logo {
        max-height: 44px;
        min-width: 148px;
        .c-header__logo-link{
        &:focus {
        outline: auto var(--theme-color-neutrals-1000);
        }
        }
    }
    .c-header__nav {
        .c-header__nav-list-item{
            position: relative;
            .c-header__nav-list-item-link {
                border: 2px solid var(--theme-color-500);
                border-radius: 30px;
                padding: calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 4)
                    calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 13);
                font-size: 16px;
                text-decoration: none;
                &:before {
                    content: url(../../assets/default/images/download.svg);
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    left: 20px;
                    top: 8px;
                    @media (max-width: map-get($context--breakpoints, sm-max)) {
                        left: 10px;
                        top: 10px;
                    }
                }
                &:hover {                   
                    color: var(--theme-color-neutrals-1000);
                    border-color: var(--theme-color-neutrals-1000);
                }
                &:focus {
                    background-color: var(--theme-color-neutrals-0);
                    border-color: var(--theme-color-500);
                    color: var(--theme-color-neutrals-1000);
                    outline: var(--theme-color-500) solid 1px;                 
                }
                @media (max-width: map-get($context--breakpoints, sm-max)) {
                    font-size: 0;
                    padding: 8px 20px 12px;
                    padding: calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 5)
                        calc(var(--theme-global-spacing) * 3);
                    height: 44px;
                }
            }
        }
    }
}
/*End of Login Header*/
.c-footer .c-footer__logo {
    max-width: 170px !important;
    flex: 0 0 170px !important;
    @media (max-width: map-get($context--breakpoints, sm-max)) {
        margin-left: auto !important;
        margin-right: auto !important;
    }
}
.u-divider{
    background-color: var(--theme-color-neutrals-150);
}
// css for filter panel in ipad device
@media (min-width: map-get($context--breakpoints, lg)) and (orientation: landscape){
    .page .page__container .main-layout .main-layout__content .main-layout__content-data.main-layout__content-data--status .c-filter-toolbar .c-filter-toolbar__sort-filter-group .c-filter-toolbar__sort-filter-group-list .c-filter-toolbar__sort-filter-group-list-item .c-floating-panel{
      min-height: fit-content;
    }
}
@media (max-width: map-get($context--breakpoints, md-max)) and (orientation: portrait){
    .page .page__container .main-layout .main-layout__content .main-layout__content-data.main-layout__content-data--status .c-filter-toolbar .c-filter-toolbar__sort-filter-group .c-filter-toolbar__sort-filter-group-list .c-filter-toolbar__sort-filter-group-list-item .c-floating-panel[data-popper-placement="top-end"] .c-floating-panel__content{
        min-height: 196px;
    }
}