.c-chip {
  @include context--base-sm;
  font-weight: var(--font-weight-bold);
  color: var(--theme-color-neutrals-1000);
  border-color: var(--theme-transparent-background);
  border-radius: var(--radius-200);
  padding: calc(var(--theme-global-spacing) * 0.75) calc(var(--theme-global-spacing) * 1) calc(var(--theme-global-spacing) * 0.75) calc(var(--theme-global-spacing) * 2);
  .c-chip__input-element {
    border-radius: var(--radius);
    &:focus {
      outline-color: var(--theme-color-950);
    }
  }
  .c-chip__button {
    padding: calc(var(--theme-global-spacing) * 1);
    background-color: var(--theme-transparent-background);
    border-radius: var(--radius);
    margin-right: calc(var(--theme-global-spacing) * -1.25);
    margin-left: calc(var(--theme-global-spacing) * 2);
    color:var(--theme-color-neutrals-1000);
    .c-chip__button-icon {
      font-size: var(--text-base-size);
    }
    &:hover {
      background-color: var(--theme-color-neutrals-1000);
      color: var(--theme-color-neutrals-0);
    }
    &:focus {
      outline-color: var(--theme-color-950);
    }
  }
  &:focus {
    outline-color: var(--theme-color-950);
    border-radius: var(--radius-200);
  }
  &:hover{
    border-color: var(--theme-color-neutrals-950);
  }
  &.c-chip--is-active {
    @include chip-active;
    .c-chip__status-badge {
      @include context--base-sm;
      background-color: var(--theme-color-neutrals-1000);
      padding: calc(var(--theme-global-spacing) * 1);
    }
    .c-chip__button {
      color: var(--theme-color-neutrals-1000);
      &:hover {
        background-color: var(--theme-color-neutrals-1000);
        color: var(--theme-color-neutrals-0);
      }
    }
  }
  .c-chip__status {
    margin-right: calc(var(--theme-global-spacing));
    em.u-icon{
      font-size: var(--text-base-size);
    }
  }
  .c-chip__status-badge {
    border-radius: var(--radius-800);
    padding: calc(var(--theme-global-spacing)* 1);
    border-color: var(--theme-transparent-background);
  }
  &.c-chip--default {
    @include chip-default;
    .c-chip__status-badge {
      background-color: var(--theme-color-neutrals-500);
    }
    &:hover{
      border-color: var(--theme-color-neutrals-950);
    }
  }
  &.c-chip--primary {
    @include chip-primary;
    .c-chip__status-badge {
      background-color: var(--theme-color-500);
    }
  }
  &.c-chip--success {
    @include chip-success;
    .c-chip__status-badge {
      background-color: var(--theme-success);
    }
  }
  &.c-chip--warning {
    @include chip-warning;
    .c-chip__status-badge {
      background-color: var(--theme-warning);
    }
  }
  &.c-chip--error {
    @include chip-error;
    .c-chip__status-badge {
      background-color: var(--theme-error);
    }
  }
  &.c-chip--normal {
    @include chip-normal;
    .c-chip__status-badge {
      background-color: var(--theme-color-neutrals-500);
    }
    &:hover{
      border-color: var(--theme-color-neutrals-950);
    }
  }
  &.c-chip--outline-primary {
    .c-chip__status-badge {
      background-color: var(--theme-color-500);
    }
  }
  &.c-chip--outline-success {
    .c-chip__status-badge {
      background-color: var(--theme-success);
    }
  }
  &.c-chip--outline-warning {
    .c-chip__status-badge {
      background-color: var(--theme-warning);
    }
  }
  &.c-chip--outline-error {
    .c-chip__status-badge {
      background-color: var(--theme-error);
    }
  }
  &.c-chip--outline-normal {
    .c-chip__status-badge {
      background-color: var(--theme-color-neutrals-500);
    }
  }
  &.c-chip--is-disabled {
    border-color: var(--theme-color-neutrals-200);
    color: var(--theme-color-neutrals-400);
    .c-chip__button {
      color: var(--theme-color-neutrals-400);
      &:hover {
        background-color: var(--theme-transparent-background);
        color: var(--theme-color-neutrals-40);
      }
    }
    .c-chip__status-badge{
      border-color: var(--theme-color-neutrals-200);
      color: var(--theme-color-neutrals-400);
      background-color: var(--theme-color-neutrals-200);
    }
  }
  &.c-chip--large {
    padding-top: calc(var(--theme-global-spacing) * 2.25);
    padding-bottom: calc(var(--theme-global-spacing) * 2.25);
    padding-right:calc(var(--theme-global-spacing) * 2);
    -webkit-tap-highlight-color: var(--theme-transparent-background);
    .c-chip__button {
      padding: calc(var(--theme-global-spacing) * 1.75);
      margin: calc(var(--theme-global-spacing) * -2);
      margin-left: calc(var(--theme-global-spacing) * 2);
      margin-right: calc(var(--theme-global-spacing) * -1.5);
    }
    .c-chip__status-badge {
      @include context--base;
      border-color: var(--theme-color-neutrals-1000);
    }
    .c-chip__button-icon {
      font-size: var(--text-xxs);
    }
  }
  &.c-chip--small {
    .c-chip__button {
      padding: calc(var(--theme-global-spacing) * 1);
      margin-right: 0;
      -webkit-tap-highlight-color: var(--theme-transparent-background);
    }
    .c-chip__status-badge {
      @include context--base-sm;
      border-color: var(--theme-color-neutrals-1000);
    }
  }
}
/*Override all the hover styles only for touch devices*/
@media (hover:none),
(hover:on-demand) {
  .c-chip .c-chip__button:hover {
    background-color: var(--theme-transparent-background);
    color: var(--theme-color-neutrals-1000);
  }
}