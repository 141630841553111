.page--login{
    background: var(--theme-color-neutrals-0);
    .c-header__logo-link{
     display: block !important;
    }
    .c-header {
        padding: calc(var(--theme-global-spacing) * 5) calc(var(--theme-global-spacing) * 6);
    }
    .login-container .login-block .login-block__card#prelogin {
        padding: calc(var(--theme-global-spacing) * 6);
    }
    .c-footer {
        padding: calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 11);
        .c-footer__nav .c-footer__nav-list .c-footer__nav-list-item{
            padding: calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 5) calc(var(--theme-global-spacing) * 3) calc(var(--theme-global-spacing) * 0);
            .c-link{
                padding-right: calc(var(--theme-global-spacing) * 6);
                position: relative;
                &::after{
                    content: "\e959";
                    font-family: mee-platform, sans-serif;
                    @include font-style(var(--text-base-size), var(--text-xs));
                    font-weight: var(--font-weight-bold);
                    @include position(absolute, $top: 50%, $right: 0);
                    transform: translateY(-50%);
                }
            }
        }
    }
    .login-container .login-block .login-block__card#prelogin .login-block__card-title{
        font-size: var(--text-xs);
        line-height: var(--text-md);
        @media (max-width: map-get($context--breakpoints, sm-max)) {
            font-size: var(--text-xxs);
            line-height: var(--text-sm);
        }
    }
 }
 .login-container {
     padding: calc(var(--theme-global-spacing) * 0) 0;
     background-image: url(../../assets/default/images/Path.svg);
     background-color: var(--theme-color-500);
     
     justify-content: center;
     align-items: center;
     @media (max-width: map-get($context--breakpoints, sm-max)) {
         height: auto;
         padding: calc(var(--theme-global-spacing) * 12) calc(var(--theme-global-spacing) * 4);
     }
     .c-card {
         background: var(--theme-color-500);
         border: none !important;
         &:focus {
             outline: none;
         }
         &:active {
             border: none;
             box-shadow: none;
         }
         &:hover {
             border: none;
             outline: none;
             color: var(--theme-color-neutrals-1000);
             .u-button--primary {
                 background-color: var(--theme-color-500);
                 color: var(--theme-color-neutrals-0);
                 border-color: var(--theme-color-500);
             }
 
             .u-button.u-button--default {
                 background-color: var(--theme-color-neutrals-0);
                 color: var(--theme-color-neutrals-1000);
                 border-color: var(--theme-color-500);
             }
         }
         .u-button--primary {
             &:hover {
                 background-color: var(--theme-color-neutrals-1000);
                 border-color: var(--theme-color-neutrals-1000);
                 color: var(--theme-color-neutrals-0);
             }
             &:focus {
                 border-width: 2px;
                 border-style: solid;
                 outline-width: 1px;
                 outline-style: solid;
                 outline-offset: 1px;
                 background-color: var(--theme-color-500);
                 border-color: var(--theme-color-500);
                 color: var(--theme-color-neutrals-0);
                 outline: var(--theme-color-500) solid 1px;
             }
         }
         .u-button.u-button--default {
             &:hover {
                 border-color: var(--theme-color-neutrals-1000);
             }
             &:focus {
                 border-width: 2px;
                 border-style: solid;
                 outline-width: 1px;
                 outline-style: solid;
                 outline-offset: 1px;
                 background-color: var(--theme-color-neutrals-0);
                 border-color: var(--theme-color-500);
                 color: var(--theme-color-neutrals-1000);
                 outline: var(--theme-color-500) solid 1px;
             }
         }
         .c-card__body {
             padding: 0px;
             @media (max-width: map-get($context--breakpoints, sm-max)) {
                 padding: 0px;
             }
         }
         @media (max-width: map-get($context--breakpoints, sm-max)) {
             width: 100%;
         }
     }
     .login-block {
         text-align: center;
         @media (max-width: map-get($context--breakpoints, sm-max)) {
             margin: 0 auto;
         }
         .login-block__card#prelogin {
             padding: calc(var(--theme-global-spacing) * 3) calc(var(--theme-global-spacing) * 10)
                 calc(var(--theme-global-spacing) * 5);
             background-color: var(--theme-color-neutrals-0);
             @media (max-width: map-get($context--breakpoints, sm-max)) {
                 padding: calc(var(--theme-global-spacing) * 8) calc(var(--theme-global-spacing) * 8);
             }
             .u-button {
                 min-width: 100%;
                 justify-content: center;
                 @media (max-width: map-get($context--breakpoints, sm-max)) {
                     min-width: 100%;
                 }
             }
             a {
                 //@include context--base-lg;
                 @include context--base;
                 font-weight: 600;
                 &:focus {
                     color: var(--theme-color-neutrals-1000);
                     text-decoration: none;
                 }
                 &.u-button.u-button--primary{
                    color: var(--theme-color-neutrals-0);
                    &:focus,
                    &:hover {
                        color: var(--theme-color-neutrals-0);
                    }
                 }
             }
             .login-block__card-details {
                max-width: 100%;
                margin: calc(var(--theme-global-spacing) * 4) auto;
                 padding: 0;
                 &:before {
                     background-color: var(--theme-color-neutrals-150);
                 }
                 .account-instruction {
                     background-color: var(--theme-color-neutrals-0);
                     padding: 0px calc(var(--theme-global-spacing) * 4);
                     font-size: var(--text-xxxs);
                 }
             }
             .login-block__card-subtitle {
                 @include context--base-sm;
                 margin-bottom: calc(var(--theme-global-spacing) * 9);
                 color: var(--theme-loging-subtitle);
                 padding: 0;
                 margin-top: 0;
             }
             .login-block__card-title {
                padding: 0;
                padding-bottom: calc(var(--theme-global-spacing) * 5);
                margin: 0;
                @include context--font-size-h3;
                color: var(--theme-color-neutrals-1000);
             }
         }
     }
 }