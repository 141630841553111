.main-content-wrapper, .main-body{
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    .main-content-wrapper__nav, .main-body__aside{
        display: flex;
        height: calc(100vh - 92px);
        border-right: 0;
        @media (max-width: map-get($context--breakpoints, md-max)) {
            height: calc(100vh - 82px);
            border: 0;
            .c-navigation-list{
                bottom: 0px;
                left: 0px;
                height: 84px !important;
                top: inherit !important;
                padding: 0;
                width: 100% !important;
                position: fixed !important;
                .c-menu .c-menu__list {
                    display: flex;
                    .c-menu__list-item{
                        flex: auto;
                        .c-menu__list-item-link .c-menu__list-item-link-icon{
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
    }
    .main-content-wrapper__content, .main-body__content{
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        height: calc(100vh - 92px);
        overflow-y: auto;
        padding: calc(var(--theme-global-spacing) * 8);
    }
    @media (max-width: map-get($context--breakpoints, md-max)) {
        padding-left: 0;
    }
    .practice-page-body{
        background-color: var(--theme-color-neutrals-0);
    }
}
.assessment-questions.assessment-questions--ready-made .assessment-questions__content-sidebar-filter-list .c-accordion .c-accordion__title-text{
      color: var(--theme-color-1000);
      text-decoration: none;
      font-weight: var(--font-weight-bold);
}
.assessment-questions.assessment-questions--ready-made .assessment-questions__content-sidebar-filter-list .c-accordion__action-icon{
      color: var(--theme-color-1000);
}
.page.page--assessment .assessment-questions .c-accordion .c-accordion__header .c-accordion__title .c-accordion__title-text{
    color: var(--theme-color-1000);
    font-weight: var(--font-weight-bold);
}
.page.page--assessment .page__container-wrapper .assessment-questions.assessment-questions--label-form .label-list__form-note-link {
    padding-right: calc(var(--theme-global-spacing) * 6);
    position: relative;

    &::after {
        content: "\e959";
        font-family: mee-platform, sans-serif;
        @include font-style(var(--text-base-size), var(--text-xs));
        font-weight: var(--font-weight-bold);
        @include position(absolute, $top: 50%, $right: 0);
        transform: translateY(-50%);
    }
}
.c-pagination .c-pagination__list-outer{
    @media (max-width: map-get($context--breakpoints, sm-max)) {
        margin: 0;
        width: 100%;
    }
}
.page.page--notification-list, .page.page--assessment{
    @media (max-width: map-get($context--breakpoints, md-max)) {
        .c-pagination .c-pagination__list-outer {
            margin: 0;
            width: 100%;
        }        
    }
}
// grading and progress tracker fixed table cell width (SA-8922)
.page.page--grading,
.page.page--progress-courses {
    .c-table-outer.c-table-outer--scrolable .c-table{
        @media (min-width: map-get($context--breakpoints, md-max)) {
           // width: 100% !important; // remove this width if require table internal horizontal scroll
            //   Commented code is required for future.
        }
        .c-table__header .c-table__header-coulmn{
            @media (min-width: map-get($context--breakpoints, md-max)) {
                min-width: 120px !important;
                &#student_name{width: 169px !important;}
                &#question_no{width: 131px !important;}
                &#score, &#total_score{min-width: 150px !important; width: 154px !important;}
                &#coursework_average, &#student_average, &#selected_data_average{width: 215px !important;}
                &#time_spent{width: 150px !important;}
                &#attempts{width: 110px !important;}
                &#feedback {width: 110px !important; white-space: nowrap;}
                &#actions, &#action{width: 90px !important; white-space: nowrap;}
                &#max_score{width: 112px !important;}
                &#class_average{width: 161px !important;}
                &#activities{width: 125px !important;}
                &#challenges{width: 125px !important;}
                &#selected_student_average{width: 160px !important}
            }
            .u-icon{
                &:not([class^="icon-"]):empty{
                    display: none;
                }
                &:before{
                    @media (min-width: map-get($context--breakpoints, md-max)) {
                        margin: 0 calc(var(--theme-global-spacing) * 1); // this will be remove after extra space omit from table thead component
                    }
                }
            }
            .c-table__header-coulmn-totalscore:empty{display: none;}
            .c-tooltip[data-show]{
                @media (min-width: map-get($context--breakpoints, md-max)){
                    @include position(absolute !important, $top: -6px !important, $left: 4px !important);/*to override the thirdparty style we used important*/
                    min-width: 250px !important;/*to override the thirdparty style we used important*/
                    min-height: 56px !important;/*to override the thirdparty style we used important*/
                }
            }
        }  
    }
    // page head metadata list info-icon aligned into middle
    .c-page-header .c-page-header__top-panel .c-page-header-meta-data .c-page-header__list .c-page-header__list-item .c-page-header__list-item-value {
        display: inline-flex;
        align-items: center;
    }
    // filter component toolbar fixed for 400% zoom
    @media (max-width: map-get($context--breakpoints, sm-max)) {
        .c-filter-toolbar .c-filter-toolbar__sort-filter-group .c-filter-toolbar__sort-filter-group-list .c-filter-toolbar__sort-filter-group-list-item .c-floating-panel{
            overflow-y: auto;
            .c-floating-panel__content {
                height: auto;
                overflow-y: initial;
                max-height: inherit;
                min-height: calc(100% - 73px);
                .c-floating-panel__body {
                    height: auto;
                    width: 100%;
                    padding-top: 0;
                    .c-floating-panel__body-header{
                        position: relative;
                    }
                    .c-floating-panel__body-block{
                        max-height: 100%;
                        height: auto;
                        overflow-y: initial;
                        overflow-x: initial;
                        position: relative;
                    }
                }
                .c-multiselect {
                    display: block;
                    max-height: inherit;
                    border: none;
                    position: relative;
                    .c-multiselect__input{
                        border: 1px solid var(--theme-color-neutrals-950);
                        border-radius: var(--radius);
                    }
                    .c-multiselect__action{
                        display: block;
                    }
                    .c-multiselect__checbox-label{
                        @include position(absolute, $top: 0, $right: 0);
                    }
                    .c-multiselect__list {
                        position: relative;
                    }
                }
            }
            .c-floating-panel__footer {
                position: relative;
            }
        }
    }
    // filter component toolbar fixed for 400% zoom
    // collapse accordion text color changes into blue
    .c-grading-card .c-card__body .c-grading-card__header, 
    .c-grading-card .c-card__body .c-grading-card__body,
    .c-progress-card .c-card__body .c-progress-card__body{
        .c-accordion .c-accordion__panel .c-accordion__header{
            .c-accordion__title .c-accordion__title-text {
                color: var(--theme-color-500);
                text-decoration: underline;
            }
            .c-accordion__action-icon{
                color: var(--theme-color-500);
            }
        }
    }
    // collapse accordion text color changes into blue
}
// grading and progress tracker fixed table cell width (SA-8922)
.page__content .c-page-header .c-tabs.c-tabs--no-wrap{
    .c-tabs__inner{
        &:before{
           bottom: -1px;
        }
        .c-tabs__nav{
            padding: 0;
            overflow: auto;
            .c-tabs__item{
                padding: 0;
                &.c-tabs__item--active{
                    &:after{
                        width: 100%;
                        left: 0;
                        transform: none;
                    }
                }
                .c-tabs__link{
                    @media (min-width: map-get($context--breakpoints, xxl)) {
                        @include context--base-sm;
                        padding: calc(var(--theme-global-spacing) * 2);
                    }
                }
            }
        }
    }
}
// grading card hover effect for touch device only. It should removed after implement in grading card or dropdown component
.page.page--grading .c-grading-card .c-card__body .c-grading-card__button-group .c-grading-card__button-group-content .c-dropdown .c-dropdown__content .c-dropdown__button:hover{
    @media (hover: none), (hover: on-demand) {
        background-color: var(--theme-color-neutrals-100);
        color: var(--theme-color-neutrals-1000);
        border-color: var(--theme-transparent-background);
    }
}
// grading card hover effect for touch device only. It should removed after implement in grading card or dropdown component
.c-pagination .c-pagination__wrapper .c-pagination__list .c-pagination__item .c-pagination__link.c-pagination__link--disabled:focus{
    outline: none!important; //Added !important to overwrite css from style.scss which has !important
}

//only for bring-back-dialog footer button alignment
.bring-back-dialog .c-dialog .c-dialog__inner .c-dialog-footer{
    @media (max-width: map-get($context--breakpoints, xs-max)) {
        flex-direction: column;
        maced-button{
            .u-button{
                width: 100%;
                display: block;
                text-align: center;
            }
            &+ maced-button{
                margin: calc(var(--theme-global-spacing) * 4) 0 0;
            }
        }
    }
}
.page{
    .c-modal-header .c-modal__title {
        width: 90%;
        @media (max-width: map-get($context--breakpoints, md-max)) {
            width: 67%;
        }
      h1 {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        white-space: nowrap;
        .c-heading-sub-title {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
    .c-headers {
        .c-headers__wrapper {
            .c-headers__branding{
                    width: calc(100% - 90px);
                    .c-headers__branding-content{
                        width: 90px;
                        .c-headers-title__sub-title, .c-headers-title__text{
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: block;
                            width: 100%;
                        }
                    }
                
            }
        }
    }
    .c-toast .c-toast__content{
        word-break: break-word;
    }
    #page__content .c-search-form .c-search-form__search-icon{
        z-index: 2;
    }
} 
.assessment-questions .homework-activity .homework-activity__body .activity-list .activity-list__data-heading .activity-list__data-heading-wrapper .activity-list__data-heading-wrapper-button-group .c-page-header .c-page-header__top-panel .c-page-header-button-group .c-page-header__button-list .c-page-header__button-list-item--modified:last-child{
    @media (max-width: map-get($context--breakpoints, md-max)) {
        width: auto;
    }
}
.openframe{
    background-color: var(--theme-color-neutrals-0);
  }

.paper-preview .show_printQuestions{
    padding-left: calc(var(--theme-global-spacing) * 10);
    margin: calc(var(--theme-global-spacing) * 4) 0;
    >li {
        list-style: decimal;
    }
}
.page--assessment .page__container-wrapper .assessment-questions--schedule-setting .assessment-questions__container{
    position: relative;
    z-index: 0;
  }
