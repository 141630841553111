.c-multiselect {
  border-color: var(--theme-color-neutrals-500);
  border-radius: var(--radius);
  background-color: var(--theme-color-neutrals-0);  
  &:hover{
    border-color: var(--theme-color-neutrals-950);
  }
  .c-multiselect__input {
    padding: calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 4);
    .c-multiselect__input-icon {
      margin-right: calc(var(--theme-global-spacing) * 2);
    }
    .c-multiselect__input-text {
      background-color: var(--theme-transparent-background);
      @include context--base;
      height: calc(var(--text-sm) + var(--form-input-base-padding) * 1);
    }
    .c-multiselect__selected-values {
      .c-multiselect__selected-chip {
        margin: calc(var(--theme-global-spacing) * 0.5) calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 0.5) 0;
        .c-chip .c-chip__button{
          margin-left: calc(var(--theme-global-spacing) * 1);
          color: var(--theme-color-neutrals-1000);
          &:hover {
            color:var(--theme-color-neutrals-0);
          }
        }
      }
    }    
  }
  .c-multiselect__action {
    .c-multiselect__checbox-label {
      -webkit-tap-highlight-color: var(--theme-transparent-background);
      border-radius: var(--radius);
      padding: calc(var(--theme-global-spacing) * 2.1) 0;
      margin:calc(var(--theme-global-spacing) * 0.5) calc(var(--theme-global-spacing) * 0.5) calc(var(--theme-global-spacing) * 0.5) calc(var(--theme-global-spacing) * 1) ;
      .c-multiselect__checkbox-label-text {
        color: var(--theme-color-neutrals-800);
        font-weight: var(--font-weight-bold);
        @include context--base;
      }
    }
    .c-multiselect__checkbox:focus+.c-multiselect__checbox-label {
      outline-color: var(--theme-color-950);
      border-radius: calc(var(--radius) * 0.25);
    }
    .c-multiselect__checkbox:disabled+.c-multiselect__checbox-label {
      background-color: var(--theme-transparent-background);
      color: var(--theme-color-neutrals-400);
    }
  }
  &.c-multiselect--is-selected{
    .c-multiselect__input{
      padding: calc(var(--theme-global-spacing) * 1.5) calc(var(--theme-global-spacing) * 4);
    }
    .c-multiselect__input-icon{
      margin-top: calc(var(--theme-global-spacing) * 2);
    }
    .c-multiselect__checbox-label{
      margin-top: calc(var(--theme-global-spacing) * 0.5);
    }
  }
  &.c-multiselect--disabled {
    border-color: var(--theme-color-neutrals-200);
    background-color: var(--theme-color-neutrals-50);
    color: var(--theme-color-neutrals-400);   
    .c-multiselect__input-button {
      color: var(--theme-color-neutrals-400);
    }
    + .c-multiselect__helper-text{
      color: var(--theme-color-neutrals-400);
    }
  }
  &.c-multiselect--error {
    border-color: var(--theme-error);
    + .c-multiselect__helper-text{
      color: var(--theme-error);
    }
  }
  &.c-multiselect--focus {
    outline-color: var(--theme-color-950);
  }
  .c-multiselect__list {
    box-shadow: map-get($shadow, diffuse-4) map-get(map-get($palette, neutrals), 200);
    border-color: var(--theme-color-neutrals-150);
    margin: calc(var(--theme-global-spacing) * 1) calc(var(--theme-global-spacing) * 0);
    border-radius: var(--radius-200);
    padding: calc(var(--theme-global-spacing) * 2);
    background-color: var(--theme-color-neutrals-0);
    .c-multiselect__list-item {
      padding: calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 2);
      margin-bottom: calc(var(--theme-global-spacing) * 1);
      border-radius: var(--radius);
      .c-multiselect__list-label {
        font-weight: var(--font-weight-bold);
        color: var(--theme-color-neutrals-1000);
        &:hover {
          background-color: var(--theme-color-200);
        }
      }
      &:hover {
        background-color: var(--theme-color-200);
      }
      &.c-multiselect__list-item--modified {
        padding: 0;
        /*checkbox component is used to overwrite css as per multiselect adding below styling*/
        .c-input-group {
          margin-bottom: calc(var(--theme-global-spacing) * 0);
          display: flex;  
        }
        .c-dropdown__list-item {
          border-color: var(--theme-color-neutrals-1000);
          margin-bottom: calc(var(--theme-global-spacing) * 1);
          padding: calc(var(--theme-global-spacing) * 0);
          &:last-child {
            margin-bottom: calc(var(--theme-global-spacing) * 0);
          }
          .c-dropdown__list-icon-place-holder {
            margin-right: calc(var(--theme-global-spacing) * 2);
          }
          &.c-dropdown__list-item--is-active{
            .c-dropdown__list-item-link{
              background-color: var(--theme-color-200);
              &:hover{
                color: var(--theme-color-neutrals-1000);
              }
            }
            .c-input-group.c-input-group--check-box{
              background-color: var(--theme-color-200);
              &:hover{
                color: var(--theme-color-neutrals-1000);
              }
            }
          }
        }
        // dropdown list items link
        .c-dropdown__list-item-link {
          padding: calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 2);
          color: var(--theme-color-neutrals-1000);
          border-radius: calc(var(--theme-global-spacing) * 1);
          @include font-style(var(--text-base-size), var(--text-xs));
          font-weight: var(--font-weight-bold);
          background-color: var(--theme-color-neutrals-0);
          // dropdown list items link hover state
          &:hover {
            background-color: var(--theme-color-950);
            color: var(--theme-color-neutrals-0);
            svg {
              fill: var(--theme-color-neutrals-0);
            }
          }
          &:focus {
            outline-color: var(--theme-color-950);
            outline-width: 2px;
            outline-style: solid;
            outline-offset: 1px;
          }
          // dropdown list items link disabled state
          &[disabled],
          &.disabled {
            background-color: var(--theme-color-neutrals-200);
            color: var(--theme-color-neutrals-400);
            &:hover,
            &:focus {
              background-color: var(--theme-color-neutrals-200);
              color: var(--theme-color-neutrals-400);
            }
          }
          // dropdown list items link focus state
          &.active {
            background-color: var(--theme-color-200);
            color: var(--theme-color-neutrals-1000);
            &:hover {
              color: var(--theme-color-neutrals-1000);
            }
          }
          width: 100%;
        }
        .c-input-group.c-input-group--check-box {
          padding: calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 0);
          color: var(--theme-color-neutrals-1000);
          border-radius: calc(var(--theme-global-spacing) * 1);
          @include context--font-size-h4;
          font-weight: var(--font-weight-bold);
          background-color: var(--theme-color-neutrals-0);
          display: block;
          .c-input-group__control.c-input-group__control--check {
            height: 100%;
            + .c-input-group__label {
              font-weight: var(--font-weight-bold);
              padding: calc(var(--theme-global-spacing) * 4) 0 calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 10);
              &::before,&::after{
                left: 8px;
                z-index: 0;
              }
            }
            &:hover{
              + .c-input-group__label {
                &::before{
                  background-color: var(--theme-color-neutrals-0);
                  border-color: var(--theme-color-neutrals-500);
                }
              }
            }
            &:checked{
              &:hover{
                + .c-input-group__label {
                  &::after{
                    filter: none;
                    color: var(--theme-color-500);
                  }
                }
              }
            }
          }
          // dropdown list items link hover state
          &:hover {
            background-color: var(--theme-color-neutrals-1000);
            color: var(--theme-color-neutrals-0);
            svg {
              fill: var(--theme-color-neutrals-0);
            }
          }
          &:focus {
            outline-color: var(--theme-color-950);
          }
          // dropdown list items link disabled state
          &[disabled],
          &.disabled {
            background-color: var(--theme-color-neutrals-200);
            color: var(--theme-color-neutrals-400);
            &:hover,
            &:focus {
              background-color: var(--theme-color-neutrals-200);
              color: var(--theme-color-neutrals-400);
            }
          }
          // dropdown list items link focus state
          &.active {
            background-color: var(--theme-color-200);
            color: var(--theme-color-neutrals-1000);
            &:hover {
              color: var(--theme-color-neutrals-1000);
            }
          }
        }
        &.c-multiselect__list-item--selected .c-input-group.c-input-group--check-box {
            background-color: var(--theme-color-200);
            &:hover{
              color: var(--theme-color-neutrals-1000);
            }
        }
      }
    }
  }
  &.c-multiselect--is-active{
    border-color: var(--theme-color-neutrals-950);
  }
  &.c-multiselect--inline {
    width: 100%;
    padding: calc(var(--theme-global-spacing) * 0);
    .c-multiselect__action {
      .c-multiselect__checbox-label {
        border-radius: calc(var(--radius) * 1.5);
        padding: calc(var(--theme-global-spacing) * 1) calc(var(--theme-global-spacing) * 2);
        margin-right: calc(var(--theme-global-spacing) * 0);
      }
      .c-multiselect__input-checkbox-icon {
        @include context--base;
        margin-left: calc(var(--theme-global-spacing) * 2);
      }
      .c-multiselect__checkbox:focus+.c-multiselect__checbox-label {
        border-radius: var(--radius);
      }
    }
    &.c-multiselect--disabled {
      background-color: var(--theme-transparent-background);
      color: var(--theme-color-neutrals-400);
      .c-multiselect__checkbox-label-text {
        color: var(--theme-color-neutrals-400);
      }
    }
    .c-multiselect__checkbox:checked + .c-multiselect__checbox-label,
    .c-multiselect__checkbox:checked:hover + .c-multiselect__checbox-label{
      background-color: var(--theme-color-200);
      &:hover{
        color: var(--theme-color-neutrals-800);
      }
      .c-multiselect__checkbox-label-text {
        color: var(--theme-color-neutrals-800);
      }
    }
    .c-multiselect__checkbox:disabled + .c-multiselect__checbox-label,
    .c-multiselect__checkbox:disabled:hover + .c-multiselect__checbox-label{
      background-color: var(--theme-transparent-background);
      &:hover{
        color: var(--theme-color-neutrals-400);
      }
      .c-multiselect__checkbox-label-text {
        color: var(--theme-color-neutrals-400);
      }
    }
    .c-multiselect__list{
      min-width: 280px;
    }
  }
}
.c-multiselect__outer-label{
  color: var(--theme-color-neutrals-1000);
  margin-bottom: calc(var(--theme-global-spacing) * 2);
  .c-multiselect__label-required{
    color: var(--theme-error);    
  }
  &.c-multiselect__outer-label--disabled{
    color: var(--theme-color-neutrals-400);    
  }
  @media (min-width: map-get($context--breakpoints, xxl)) {
    @include context--base-sm;
  }
}
.c-multiselect__helper-text{
  display: block;
  color: var(--theme-color-neutrals-1000);
  margin-top: calc(var(--theme-global-spacing) * 2);
  @media (min-width: map-get($context--breakpoints, xxl)) {
    @include context--base-sm;
  }
}
/*Multiselect*/
.c-multiselect--truncate.c-multiselect {
  .c-multiselect__input .c-multiselect__selected-values {
    .c-multiselect__selected-chip {
      margin: 0;
      .c-chip {
        margin-right: calc(var(--theme-global-spacing) * 2);
        .c-chip__input-element {
          display: none;
          }
        .c-chip__button {
          color: var(--theme-color-neutrals-1000);
          &:hover {
            color: var(--theme-color-neutrals-0);
          }
        }
        /*To override the chip component style add css here*/
        @media (max-width: map-get($context--breakpoints, sm-max)) {
          .c-chip__text {
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 116px;
            display: block !important;
            /*To override the chip component style*/
          }
        }
      }
    }
  }
  .c-multiselect__list-item.c-multiselect__list-item--category-name {
    @include font-style(var(--text-base-size), var(--text-xs));
    padding: calc(var(--theme-global-spacing) * 1) calc(var(--theme-global-spacing) * 2);
    color: var(--theme-color-neutrals-800);
    font-weight: var(--font-weight-normal);
    &:hover {
      background-color: var(--theme-transparent-background);
    }
  }
  .c-multiselect__input .c-multiselect__values .c-multiselect__selected-values .c-chip.c-chip--small {
    margin-right: calc(var(--theme-global-spacing) * 2);
  }
}
.c-multiselect--truncate.c-multiselect.c-multiselect--is-selected .c-multiselect__input {
  padding: calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 4);
}
.c-floating-panel__body-block-list-item {
  @media (max-width: map-get($context--breakpoints, sm-max)) { 
      .c-multiselect,.c-multiselect__input{
          width: 100%;
      }
  }
} 
/*Override all the hover styles only for touch devices*/
@media (hover:none), (hover:on-demand) {
  .c-multiselect .c-multiselect__list .c-multiselect__list-item.c-multiselect__list-item--modified .c-input-group.c-input-group--check-box:hover {
    background-color: var(--theme-color-neutrals-0);
    color: var(--theme-color-neutrals-1000);
  }
  .c-multiselect .c-multiselect__list .c-multiselect__list-item.c-multiselect__list-item--modified.c-multiselect__list-item--selected .c-input-group.c-input-group--check-box {
    background-color: var(--theme-color-200);
  }
}
