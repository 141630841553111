// dropdown component styles
.c-dropdown {
	@include font-style(var(--text-base-size), var(--text-xs));
	font-family: var(--font-primary);
	.c-dropdown__label{
		margin-bottom: calc(var(--theme-global-spacing) * 2);
	}
	.c-dropdown__checkbox {
		&:focus ~ .c-dropdown__button {
			outline: var(--theme-color-950);
		}
		&:checked + .c-dropdown__button {
			background-color: var(--theme-color-neutrals-0);
		}
	}
	// ** dropdown button **
	.c-dropdown__button {
		color: var(--theme-color-neutrals-1000);
		background-color: var(--theme-color-neutrals-0);
		border-color: var(--theme-color-neutrals-500);
		border-radius: var(--radius);
		margin-bottom: calc(var(--theme-global-spacing) * 1);
		padding: calc(var(--theme-global-spacing) * 3) calc(var(--theme-global-spacing) * 3) calc(var(--theme-global-spacing) * 3) calc(var(--theme-global-spacing) * 4);
		-webkit-tap-highlight-color: var(--theme-transparent-background);
		@media (min-width: map-get($context--breakpoints, xxl)) {
			padding: calc(var(--theme-global-spacing) * 3);
		}
		@media (max-width: map-get($context--breakpoints, sm-max)) {
			padding: calc(var(--theme-global-spacing) * 3);
		}
		// dropdown button focus state
		&:focus {
			outline-color: var(--theme-color-950);
		}
		// dropdown button disabled state
		&:disabled,
		&:disabled:hover,
		&:disabled:focus,
		&.disabled,
		&.disabled:hover,
		&.disabled:focus {
			border-color: var(--theme-color-neutrals-200);
			color: var(--theme-color-neutrals-400);
			background-color: var(--theme-color-neutrals-50);
			.c-dropdown__button-icon {
				opacity: 0.4;
			}
		}
		// dropdown icon modifier - left
		.c-dropdown__button-icon--left {
			margin-right: calc(var(--theme-global-spacing) * 2);
		}
		// dropdown icon modifier - right
		.c-dropdown__button-icon--right {
			margin-left: calc(var(--theme-global-spacing) * 2);
		}
		// dropdown icon modifier font size
		.c-dropdown__button-icon {
			@include font-style(var(--text-xs), var(--text-xs));
		}
		.c-dropdown__button-text {
			margin-right: calc(var(--theme-global-spacing) * 4);
		}
	}
	.c-dropdown--error-text {
		@include font-style(var(--text-xxxs), var(--text-xxs));
		color: var(--theme-error);
	}
	// ** dropdown list **
	.c-dropdown__list-outer {
		border-color: var(--theme-color-neutrals-150);
		background-color: var(--theme-color-neutrals-0);
		border-radius: calc(var(--radius) * 2);
		padding: calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 0);
		box-shadow: map-get($shadow, diffuse-4) map-get(map-get($palette, neutrals), 300);
		// dropdown list items
		.c-dropdown__list{
			.c-input-group {
				margin-bottom: calc(var(--theme-global-spacing) * 0);
			}
			.c-dropdown__list-item {
				border-color: var(--theme-color-neutrals-1000);
				margin: calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 1);
				padding: calc(var(--theme-global-spacing) * 0);
				&:last-child {
					margin-bottom: calc(var(--theme-global-spacing) * 2);
				}
				&:first-child {
					margin-top: calc(var(--theme-global-spacing) * 2);
				}
				.c-dropdown__list-icon-place-holder {
					margin-right: calc(var(--theme-global-spacing) * 2);
				}
				.c-dropdown__item-content{
					&.c-dropdown__item-content--is-selected{
						border: none;
						.c-dropdown__list-item-link{						
							font-family: var(--font-primary);
							background-color: var(--theme-color-200);
							&:hover{
								color: var(--theme-color-neutrals-1000);
							}
						}
						.c-input-group.c-input-group--check-box{
							background-color: var(--theme-color-200);
							&:hover{
								color: var(--theme-color-neutrals-1000);
							}
						}
					}
				}
			}
			.c-dropdown__list-item.c-dropdown__list-item--category-name{
				padding: calc(var(--theme-global-spacing) * 1) calc(var(--theme-global-spacing) * 2);
				margin: calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 2);
				color: var(--theme-color-neutrals-800);
				&:first-child {
					margin-top: calc(var(--theme-global-spacing) * 2);
				}
			}
			// dropdown list items link
			.c-dropdown__list-item-link {			
				font-family: var(--font-primary);
				padding: calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 2);
				color: var(--theme-color-neutrals-1000);
				border-radius: calc(var(--theme-global-spacing) * 1);
				@include font-style(var(--text-base-size), var(--text-xs));		
				font-weight: var(--font-weight-bold);
				background-color: var(--theme-color-neutrals-0);
				-webkit-tap-highlight-color: var(--theme-transparent-background);
				// dropdown list items link hover state
				&:hover {
					background-color: var(--theme-color-950);
					color: var(--theme-color-neutrals-0);
					svg {
						fill: var(--theme-color-neutrals-0);
					}
				}
				&:focus {
					outline-color: var(--theme-color-950);
					outline-width: 2px;
					outline-style: solid;
					outline-offset: 1px;
				}
				// dropdown list items link disabled state
				&[disabled],
				&.disabled {
					background-color: var(--theme-color-neutrals-200);
					color: var(--theme-color-neutrals-400);
					&:hover,
					&:focus {
						background-color: var(--theme-color-neutrals-200);
						color: var(--theme-color-neutrals-400);
					}
				}
				// dropdown list items link focus state
				&.active {
					background-color: var(--theme-color-200);
					color: var(--theme-color-neutrals-1000);
					&:hover {
						color: var(--theme-color-neutrals-1000);
					}
				}
				width: 100%;
			}
			.c-input-group.c-input-group--check-box {
				padding: calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 0);
				color: var(--theme-color-neutrals-1000);
				border-radius: calc(var(--theme-global-spacing) * 1);
				@include context--font-size-h4;
				font-weight: var(--font-weight-bold);
				background-color: var(--theme-color-neutrals-0);
				display: block;
				.c-input-group__control.c-input-group__control--check {
					height: 100%;
					+ .c-input-group__label {
						font-weight: var(--font-weight-bold);
						padding: calc(var(--theme-global-spacing) * 4) 0 calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 10);
						&::before,&::after{
							left: 8px;
						}
					}
					&:hover{
						+ .c-input-group__label {
							&::before{
								background-color: var(--theme-color-neutrals-0);
								border-color: var(--theme-color-neutrals-500);
							}
						}
					}
					&:checked{
						&:hover{
							+ .c-input-group__label {
								&::after{
									filter: none;
									color: var(--theme-color-500);
								}
							}
						}
					}
				}
				// dropdown list items link hover state
				&:hover {
					background-color: var(--theme-color-950);
					color: var(--theme-color-neutrals-0);
					svg {
						fill: var(--theme-color-neutrals-0);
					}
				}
				&:focus {
					outline-color: var(--theme-color-950);
				}
				// dropdown list items link disabled state
				&[disabled],
				&.disabled {
					background-color: var(--theme-color-neutrals-200);
					color: var(--theme-color-neutrals-400);
					&:hover,
					&:focus {
						background-color: var(--theme-color-neutrals-200);
						color: var(--theme-color-neutrals-400);
					}
				}
				// dropdown list items link focus state
				&.active {
					background-color: var(--theme-color-200);
					color: var(--theme-color-neutrals-1000);
					&:hover {
						color: var(--theme-color-neutrals-1000);
					}
				}
			}
		}
		.c-dropdown__list-button{
			padding: calc(var(--theme-global-spacing) * 4);
			border-color: var(--theme-color-neutrals-150);
			.u-button.u-button--link {
				color: var(--theme-color-500);
				text-decoration: underline;
				border-color: var(--theme-transparent-background);
				&:hover {
					color: var(--theme-color-950);
				}
				&:disabled {
					background-color: var(--theme-transparent-background);
					color: var(--theme-color-neutrals-400);
					text-decoration: underline;
				}
			}
			.u-button.u-button--link.u-button--disabled {
				background-color: var(--theme-transparent-background);
				color: var(--theme-color-neutrals-400);
				text-decoration: underline;
			}
		  }
	}
	&.c-dropdown--fixed-icon {
		.c-dropdown__button-text {
			display: none;
		}
		.c-dropdown__button {
			padding: 0;
		}
	}
	.c-dropdown__button.c-dropdown__button--error {
		border-color: var(--theme-error);
		box-shadow: none;
	}
	&.c-dropdown--is-inactive {
		.c-dropdown__button {
			border-color: var(--theme-color-neutrals-200);
			box-shadow: none;
		}
		.c-dropdown__button.c-dropdown__button--error {
			border-color: var(--theme-error);
			box-shadow: none;
		}
	}
	.c-dropdown__helper-text{
		display: block;
		margin-top: calc(var(--theme-global-spacing) * 2);
	}
	&.c-dropdown--is-disabled{
		.c-dropdown__label, .c-dropdown__helper-text{
			color: var(--theme-color-neutrals-400);
		}
	}
	&.c-dropdown--is-error{
		.c-dropdown__helper-text{
			color: var(--theme-error);
		}
	}
}
.c-dropdown__error-text {
	color: var(--theme-error);
}
/*Override all the hover styles only for touch devices*/
@media (hover:none),
(hover:on-demand) {
	.c-dropdown__list-item-link:hover,
	.c-dropdown .c-dropdown__list-outer .c-dropdown__list .c-dropdown__list-item-link:hover {
		background-color: transparent;
		color: var(--theme-color-neutrals-1000);
	}
	.c-dropdown .c-dropdown__list-outer .c-dropdown__list .c-input-group.c-input-group--check-box:hover{
		background-color: var(--theme-color-neutrals-0);
		color: var(--theme-color-neutrals-1000);
	}
}
