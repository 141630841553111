//Drop Down
select.c-input-group__control {
  background-color: var(--theme-color-neutrals-0);
  color: var(--theme-color-neutrals-1000);
  border-color: var(--theme-color-neutrals-150);
  &:focus {
    background-color: var(--theme-color-neutrals-0);
  }
  &:hover {
    box-shadow: map-get($shadow, diffuse-4) map-get(map-get($palette, neutrals), 200);
  }
  &:active {
    background-color: theme-var(--theme-dropdown-active);
  }
  &:disabled,
  &.disabled {
    background-color: var(--theme-color-neutrals-50);
    color: var(--theme-color-neutrals-400);
  }
}
//Forms
.c-input-group {
  margin-bottom: calc(var(--theme-global-spacing) * 4);
  .c-input-group__label {
    margin-bottom: calc(var(--theme-global-spacing) * 2);
    @media (min-width: map-get($context--breakpoints, xxl)) {
      @include context--base-sm;
    }
  }
  .c-input-group__control {
    height: calc(var(--text-sm) + calc(var(--form-input-padding-y) * 2));
    padding: calc(var(--theme-global-spacing) * 3) calc(var(--theme-global-spacing) * 4);
    margin-bottom: calc(var(--theme-global-spacing) * 2);
    &.c-input-group__error:focus {
      border-color: var(--theme-error);
    }
    /* c-input-group - textarea */
    &.c-input-group__control--textarea {
      margin-bottom: calc(var(--theme-global-spacing) * 2);
      &:hover,
      &:active {
        border-color: var(--theme-color-neutrals-950);
      }
      &.c-input-group__error:focus,
      &.c-input-group__error:active,
      &.c-input-group__error:hover {
        border-color: var(--theme-error);
      }
      &:disabled,
      &.disabled {
        background-color: var(--theme-color-neutrals-50);
        &:hover,
        &:active {
          border-color: var(--theme-color-neutrals-200);
        }
        & + .c-input-group__resize-icon {
          border-left-color: var(--theme-transparent-background);
          border-right-color: var(--theme-transparent-background);
          border-top-color:var(--theme-color-neutrals-50);
        }
        & + .c-input-group__resize-icon + .c-input-group__character-count {
          color: var(--theme-color-neutrals-400);
        }
      }
      /* textarea - Scrollbar Style*/
      &::-webkit-scrollbar-track {
        border-radius: var(--radius);
        background-color: var(-theme-transparent-background);
        margin: calc(var(--theme-global-spacing) * 3) calc(var(--theme-global-spacing) * 1);
      }
      &::-webkit-scrollbar {
        background-color: var(-theme-transparent-background);
      }
      &::-webkit-scrollbar-thumb {
        border-radius: var(--radius);
        background-color: var(--theme-color-neutrals-200);
      }
    }
  }
  .c-input-group__label-required,
  .c-input-group__error {
    color: var(--theme-error);
    border-color: var(--theme-error);
  }
  .c-input-group__button-trailing-icon--disabled {
    color: var(--theme-color-neutrals-400);
  }
  .c-input-group__control + .c-input-group__button-show-hide {
    right: 0;
  }
  .c-input-group__control + .c-input-group__button-trailing-icon--error {
    right: 48px;
  }
  .c-input-group__control + .c-input-group__button-trailing-icon {
    right: 0px;
  }
  .c-input-group__error[type="password"],
  .c-input-group__error[type="text"] {
    padding-right: calc(var(--theme-global-spacing) * 22);
  }
  .c-input-group__error[type="password"].c-input-group__control + .c-input-group__button-trailing-icon--error,
  .c-input-group__error[type="text"].c-input-group__control + .c-input-group__button-trailing-icon--error {
    right: 48px;
  }
  &.c-input-group--textarea .c-input-group__error,
  &.c-input-group--textarea .c-input-group__help-text{
    position: absolute;
  }
  &.c-input-group--is-disabled{
    .c-input-group__label{
      color: var(--theme-color-neutrals-400);
    }
  }
}
.c-input-group__control {
  background-color: var(--theme-color-neutrals-0);
  color: var(--theme-color-neutrals-1000);
  border-color: var(--theme-color-neutrals-150);
  border-radius: var(--radius);
  @include font-style(var(--text-base-size), var(--text-xs));
  font-weight: var(--font-weight-normal);
  font-family: var(--font-primary);
  &::placeholder {
    font-size: var(--text-base-size);
  }
  &:hover,
  &:focus {
    border-color: var(--theme-color-neutrals-1000);
    outline-color: var(--theme-color-950);
  }
  &.error {
    border-color: var(--theme-error);
  }
  &:disabled,
  &.disabled {
    border-color: var(--theme-color-neutrals-200);
  }
  &:focus::placeholder {
    color: var(--theme-transparent-background);
  }
}
.c-input-group__button-show-hide,
.c-input-group__button-trailing-icon {
  padding: calc(var(--theme-global-spacing) * 3);
  background-color: var(--theme-transparent-background);
  margin: 0;
  &:disabled {
    color: var(--theme-color-neutrals-400);
  }
}
//Forms / Radio-Checkbox / Primary Filled
input[type="radio"].c-input-group__control,
input[type="checkbox"].c-input-group__control {
  background-color: var(--theme-color-neutrals-0);
  border-color: var(--theme-color-neutrals-1000);
  &:disabled,
  &.disabled {
    background-color: var(--theme-color-neutrals-200);
    border-color: var(--theme-color-neutrals-200);
  }
}
input.c-input-group__control {
  padding-right: calc(var(--theme-global-spacing) * 12);
}
//checkbox
// custom checkbox input
.c-input-group__control--check {
  margin: calc(var(--theme-global-spacing) * 0);
  // custom checkbox input hover state
  &:hover ~ .c-input-group__label::before {
    background-color: var(--theme-color-neutrals-1000);
  }
  // custom checkbox input checked hover state
  &:checked:hover ~ .c-input-group__label::before {
    background-color: var(--theme-color-neutrals-1000);
  }
}
// custom checkbox label
.c-input-group__control--check + .c-input-group__label {
  margin-bottom: 0;
  @include font-style(var(--text-base-size), var(--text-xs));
  font-weight: var(--font-weight-normal);
  font-family: var(--font-primary);
  padding: calc(var(--theme-global-spacing) * 3) 0 calc(var(--theme-global-spacing) * 3)
    calc(var(--theme-global-spacing) * 10);
  @media (min-width: map-get($context--breakpoints, xxl)) {
    padding: calc(var(--theme-global-spacing) * 3) 0 calc(var(--theme-global-spacing) * 3)
      calc(var(--theme-global-spacing) * 10);
  }
  &::before {
    background-color: var(--theme-color-neutrals-0);
    border-color: var(--theme-color-neutrals-1000);
  }
  &:disabled,
  &.disabled {
    &.c-input-group__label::before {
      border-color: var(--theme-color-neutrals-200);
    }
  }
}
/*Left Aligned Icon*/
.c-input-group--left-aligned .c-input-group__control {
  padding-right: calc(var(--theme-global-spacing) * 3);
  padding-left: calc(var(--theme-global-spacing) * 12);
}
/* character-count */
.c-input-group__character-count {
  @include font-style(var(--text-xxxs), var(--text-xxs));
  color: var(--theme-color-neutrals-1000);
}
/* textarea - resize icon */
.c-input-group--textarea {
  .c-input-group__resize-icon {
    border-left-color: var(--theme-transparent-background);
    border-right-color: var(--theme-transparent-background);
    border-top-color: var(--theme-color-neutrals-0);
    &::before {
      border-color: var(--theme-color-neutrals-500);
    }
    &::after {
      border-color: var(--theme-color-neutrals-500);
      background-color: var(--theme-transparent-background);
    }
  }
  .c-input-group__help-text {
    @media (min-width: map-get($context--breakpoints, xxl)) {
      @include context--base-sm;
    }
  }
  /*Textare Error*/
  .c-input-group__control--textarea.c-input-group__control--is-error,
  .c-input-group__control--textarea.c-input-group__control--is-error:hover,
  .c-input-group__control--textarea.c-input-group__control--is-error:active,
  .c-input-group__control--textarea.c-input-group__control--is-error:focus {
    border-color: var(--theme-error);
  }
  .c-input-group__error-message,
  .c-input-group__character-count--is-error {
    color: var(--theme-error);
  }
}
.c-input-group--textarea.c-input-group--textarea-is-error {
  margin-bottom:calc(var(--theme-global-spacing) * 8);
}
