.c-input-group--radio-button {
  .c-input-group__control--radio {
    &:checked {
      + {
        .c-input-group__label {
          padding-left: calc(var(--theme-global-spacing) * 8);
          color: var(--theme-color-neutrals-1000);
          -webkit-tap-highlight-color:var(--theme-transparent-background);
          &:before {
            width: calc(var(--theme-global-spacing) * 6);
            height: calc(var(--theme-global-spacing) * 6);
            border-color: var(--theme-color-neutrals-500);
            border-radius: var(--radius-500);
            background-color: var(--theme-color-neutrals-0);
          }
          &:after {
            width: calc(var(--theme-global-spacing) * 3);
            height: calc(var(--theme-global-spacing) * 3);
            background-color: var(--theme-color-500);
            border-radius: var(--radius-500);
          }
        }
      }
      &:focus {
        ~ {
          .c-input-group__label {
            &::before {
              outline-color: var(--theme-color-950);
            }
            &::after {
              background-color: var(--theme-color-500);
            }
          }
        }
      }
      &:hover {
        ~ {
          .c-input-group__label {
            &::before {
              background-color: var(--theme-color-850);
              border-color: var(--theme-color-850);
            }
            &::after {
              background-color: var(--theme-color-neutrals-0);
            }
          }
        }
      }
      &:disabled {
        ~ {
          .c-input-group__label {
            &::before {
              background-color: var(--theme-color-neutrals-50);
              border-color: var(--theme-color-neutrals-200);
            }
            &::after {
              background-color: var(--theme-color-200);
            }
          }
        }
      }
    }
    &:not(:checked) {
      + {
        .c-input-group__label {
          padding-left: calc(var(--theme-global-spacing) * 8);
          color: var(--theme-color-neutrals-1000);
          -webkit-tap-highlight-color:var(--theme-transparent-background);
          &:before {
            width: calc(var(--theme-global-spacing) * 6);
            height: calc(var(--theme-global-spacing) * 6);
            border-color: var(--theme-color-neutrals-500);
            border-radius: var(--radius-500);
            background-color: var(--theme-color-neutrals-0);
          }
          &:after {
            width: calc(var(--theme-global-spacing) * 3);
            height: calc(var(--theme-global-spacing) * 3);
            background-color: var(--theme-color-neutrals-0);
            border-radius: var(--radius-500);
          }
        }
      }
      &:hover {
        ~ {
          .c-input-group__label {
            &::before {
              background-color: var(--theme-color-850);
              border-color: var(--theme-color-850);
            }
            &::after {
              background-color: var(--theme-color-850);
            }
          }
        }
      }
      &:focus {
        ~ {
          .c-input-group__label {
            &::before {
              outline-color: var(--theme-color-950);
            }
            &::after {
              background-color: var(--theme-color-850);
              background-color: var(--theme-color-neutrals-0);
            }
          }
        }
      }
      &:disabled {
        ~ {
          .c-input-group__label {
            &::before {
              background-color: var(--theme-color-neutrals-50);
              border-color: var(--theme-color-neutrals-200);
            }
            &::after {
              background-color: var(--theme-color-neutrals-50);
            }
          }
        }
      }
    }
    &.c-input-group__control--error {
      &:checked {
        + {
          .c-input-group__label {
            color: var(--theme-color-neutrals-1000);
            &:before {
              border-color: var(--theme-error);
              background-color: var(--theme-color-neutrals-0);
            }
            &:after {
              background-color: var(--theme-error);
            }
          }
        }
        &:focus {
          ~ {
            .c-input-group__label {
              &::before {
                outline-color: var(--theme-color-950);
              }
              &::after {
                background-color: var(--theme-error);
              }
            }
          }
        }
        &:hover {
          ~ {
            .c-input-group__label {
              &::before {
                background-color: var(--theme-error);
                border-color: var(--theme-error);
              }
              &::after {
                background-color: var(--theme-color-neutrals-0);
              }
            }
          }
        }
      }
      &:not(:checked) {
        + {
          .c-input-group__label {
            color: var(--theme-color-neutrals-1000);
            &:before {
              border-color: var(--theme-error);
              background-color: var(--theme-color-neutrals-0);
            }
            &:after {
              background-color: var(--theme-color-neutrals-0);
            }
          }
        }
        &:hover {
          ~ {
            .c-input-group__label {
              &::before {
                background-color: var(--theme-error);
                border-color: var(--theme-error);
              }
              &::after {
                background-color: var(--theme-error);
              }
            }
          }
        }
        &:focus {
          ~ {
            .c-input-group__label {
              &::before {
                outline-color: var(--theme-color-950);
              }
              &::after {
                background-color: var(--theme-color-neutrals-0);
              }
            }
          }
        }
      }
    }
    &.c-input-group__control--disabled {
      &:checked {
        + {
          .c-input-group__label {
            color: var(--theme-color-neutrals-400);
          }
        }
      }
      &:not(:checked) {
        + {
          .c-input-group__label {
            color: var(--theme-color-neutrals-400);
          }
        }
      }
    }
  }
  .c-input-group__label{
    margin-bottom: calc(var(--theme-global-spacing) * 0);
  }
}
.c-input-group__error {
  color: var(--theme-error);
}
.c-input-outer {
  .c-input-outer__label {
    color: var(--theme-color-neutrals-1000);
    margin-bottom: calc(var(--theme-global-spacing) * 2);
    &:empty {
      margin: 0;
    }
    .c-input-outer__label-required {
      color: var(--theme-error);
    }
  }
  .c-input-group {
    margin-bottom: 0;
  }
  .c-input-outer__multiple-item{
    margin-bottom: calc(var(--theme-global-spacing) * 2);
  }
  &.c-input-outer--inline .c-input-outer__multiple {
    .c-input-outer__multiple-item {
      margin-right: calc(var(--theme-global-spacing) * 6);
    }
  }
  .c-input-outer__helper-text{
    color: var(--theme-color-neutrals-1000);
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  &.c-input-outer--disabled{
    .c-input-outer__label{
      color: var(--theme-color-neutrals-400);
      .c-input-outer__label-required {
        color: var(--theme-color-neutrals-400);
      }
    }
    .c-input-outer__helper-text{
      color: var(--theme-color-neutrals-400);
    }
  }
  &.c-input-outer--error{
    .c-input-outer__helper-text{
      color: var(--theme-error);
    }
  }
  // Below class from test harness
  .c-input-outer__multiple-error {
    color: var(--theme-error);
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
/*Override all the hover styles only for touch devices*/
@media (hover:none),
(hover:on-demand){
  .c-input-group--radio-button .c-input-group__control--radio:checked:hover ~ .c-input-group__label::before{
    border-color: var(--theme-color-neutrals-500);
    background-color: var(--theme-color-neutrals-0);
    }
    .c-input-group--radio-button .c-input-group__control--radio:checked:hover ~ .c-input-group__label::after{
      background-color: var(--theme-color-500);
    }
    .c-input-group--radio-button .c-input-group__control--radio:checked:hover~.c-input-group__label:before {
      border-color: var(--theme-color-neutrals-500);
      background-color: var(--theme-color-neutrals-0);
    }
    .c-input-group--radio-button .c-input-group__control--radio:checked:hover~.c-input-group__label:after {
      background-color: var(--theme-color-500);
    }
    .c-input-group--radio-button .c-input-group__control--radio:not(:checked):hover~.c-input-group__label:before {
      border-color: var(--theme-color-neutrals-500);
      background-color: var(--theme-color-neutrals-0);
    }
    .c-input-group--radio-button .c-input-group__control--radio:not(:checked):hover~.c-input-group__label:after {
      background-color: var(--theme-color-neutrals-0);
    }
    .c-input-group--radio-button .c-input-group__control--radio:not(:checked):disabled~.c-input-group__label:before {
      background-color: var(--theme-color-neutrals-50);
      border-color: var(--theme-color-neutrals-200);
    }
  .c-input-group--radio-button .c-input-group__control--radio:not(:checked):disabled~.c-input-group__label:after {
    background-color: var(--theme-color-neutrals-50);
  }
  .c-input-group--radio-button .c-input-group__control--radio:checked:disabled~.c-input-group__label:after {
    background-color: var(--theme-color-200);
    }
  .c-input-group--radio-button .c-input-group__control--radio:checked:disabled ~ .c-input-group__label::before{
    background-color: var(--theme-color-neutrals-50);
    border-color: var(--theme-color-neutrals-200);
  }
  .c-input-group--radio-button .c-input-group__control--radio.c-input-group__control--error:checked:hover~.c-input-group__label:before {
    border-color: var(--theme-error);
    background-color: var(--theme-color-neutrals-0);
  }
  .c-input-group--radio-button .c-input-group__control--radio.c-input-group__control--error:checked:hover~.c-input-group__label:after {
    background-color: var(--theme-error);
  }
}
