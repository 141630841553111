// This is wrapper css so we have updated the custom css here
.c-navigation {
    display: flex;
    height: calc(100% - 92px);
    overflow: hidden;
    position: relative;
    @media (max-width: map-get($context--breakpoints, "sm-max")) {
        height: calc(100% - 82px); 
    }
    .c-navigation__cascade {
        position: absolute;
        display: flex;
        left: 304px;
        top: 0;
        height: 100%;
        overflow-y: auto;
        @media (max-width: map-get($context--breakpoints, "md-max")) {
            position: initial;
        }
    }
    .c-navigation__cascade--without-menubar {
        left: 0;
    }
    .c-navigation-tools {
        height: 100% !important; //added important to overide component css
        border-right: 1px solid var(--theme-color-neutrals-150);
    }
    .c-navigation-list {
        border-right: 1px solid var(--theme-color-neutrals-150);
        width:304px;
        height: 100%;
    }
    .c-navigation-cascading {
        height: 100%;
        border-right: 1px solid var(--theme-color-neutrals-150);
        width: 304px;
        overflow-x: auto;
        .u-divider.u-divider--trim {
            margin: 0 calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 2);
            @media (max-width: map-get($context--breakpoints, "md-max")) {
                margin: calc(var(--theme-global-spacing) * 2) 0;
            }
        }
        .c-menu__list .c-menu__list{
            padding-left: calc(var(--theme-global-spacing) * 2);
        }
        @media (max-width: map-get($context--breakpoints, "md-max")) {
            border-right: none;
            width: 220px;
        }
        .c-navigation-cascading__breadcrumbs{
            padding: calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 0);
            border-bottom-color: var(--theme-color-neutrals-600);
            margin-bottom: calc(var(--theme-global-spacing) * 2);
            @media (max-width: map-get($context--breakpoints, md-max)) {
                padding:calc(var(--theme-global-spacing) * 4) 0 calc(var(--theme-global-spacing) * 6) 0;
                margin: 0 calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 2);
                .c-breadcrumbs__item {
                    padding-left: calc(var(--theme-global-spacing) * 2);
                }
            }
        }
        .c-menu__list-item-badge-icon:after {
            border-radius: calc(var(--theme-global-spacing) * 2.5);
            background-color: var(--theme-error);
        }
        .c-menu__list-item-badge{
            margin-left: auto;
            .c-badge.c-badge--medium{
                padding: calc(var(--theme-global-spacing) * 1) calc(var(--theme-global-spacing) * 2);
                @include font-style(var(--text-avrage), var(--text-base-size));
            }
        }
        .c-menu {
            .c-menu__list{
                padding: calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 1) 0;
                @media (max-width: map-get($context--breakpoints, md-max)) {
                    padding: calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 0) 0;
                }
            }
        }
    }    
    .c-menu__list-item {
        padding: calc(var(--theme-global-spacing) * 0);
        margin:calc(var(--theme-global-spacing) * 1) 0;
        @media (max-width: map-get($context--breakpoints, "md-max")) {
            margin: 0;
        }
    }
    @media (max-width: map-get($context--breakpoints, "sm-max")) {
        display: flex;
    }
    .c-navigation__overlay {
        background-color: var(--theme-dialog-overlay);
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 1;
        @media (max-width: map-get($context--breakpoints, "md-max")) {
            display: none;
        }
    }
    .c-navigation__cascade,
    maced-menubar,
    maced-cascade-menubar{
        z-index: 2;
        @media (max-width: map-get($context--breakpoints, "md-max")) {
            z-index: inherit;
        }
    }
    @media (min-width: map-get($context--breakpoints, "lg")) {
        maced-menubar{
            position: relative;
            .c-navigation-list{
                position: relative;
                z-index: 1;
            }
        }
    }
}
.c-navigation.c-navigation--toolbar {
    height: 100%;
}
// This is wrapper css so we have updated the custom end css here
.c-navigation-list__back-button,
.c-navigation-list__close-button,
.c-navigation-cascading__close-button,
.c-navigation-cascading__back-button {
    background-color: var(--theme-color-neutrals-0);
    border-radius: var(--radius);
    -webkit-tap-highlight-color: var(--theme-transparent-background);
    &:hover {
        background-color: var(--theme-color-neutrals-1000);
        color: var(--theme-color-neutrals-0);
    }
}
.c-navigation-tools,
.c-navigation-list,
.c-navigation-cascading {
    background-color: var(--theme-color-neutrals-0);
    -webkit-tap-highlight-color: var(--theme-transparent-background);
    .c-menu {
        .u-divider {
            margin: calc(var(--theme-global-spacing) * 2) 0;
        }
        .c-menu__list .c-menu__list-item {
            position: relative;
            @media (max-width: map-get($context--breakpoints, "md-max")) {
                padding: calc(var(--theme-global-spacing) * 0.5) calc(var(--theme-global-spacing) * 2);
            }
        }
    }
    .c-menu__list-item-link,
    .c-menu__expand-collapse-button,
    .c-navigation-tools__burger-button {
        background-color: transparent;
        padding: calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 3.25);
        color: var(--theme-color-neutrals-1000);
        border-radius: var(--radius);
        @include context--base;
        -webkit-tap-highlight-color: var(--theme-transparent-background);
        &:focus,
        &.focus {
            box-shadow: none;
            background-color: var(--theme-color-neutrals-0);
            color: var(--theme-color-neutrals-1000);
            outline-color: var(--theme-color-950);
        }
        &:hover,
        &.hover {
            color: var(--theme-color-neutrals-0);
            background-color: var(--theme-color-950);
            border-bottom-color: var(--theme-color-neutrals-400);
        }
        &:disabled,
        &.disabled {
            color: var(--theme-color-neutrals-400);
            background-color: var(--theme-color-neutrals-0);
            &:focus,
            &.focus,
            &:hover,
            &.hover {
                color: var(--theme-color-neutrals-400);
                background-color: var(--theme-color-neutrals-0);
            }
            .u-icon{
                color: var(--theme-color-neutrals-400);
                .c-menu__list-item-badge-icon{
                    &::after{
                        background-color: var(--theme-color-neutrals-400);
                    }
                }
            }
            .c-badge{
                background-color: var(--theme-color-neutrals-400);
            }
        }
        &.active {
            background-color: var(--theme-color-200);
            color: var(--theme-color-neutrals-1000);
            &:focus,
            &.focus,
            &:hover,
            &.hover {
                color: var(--theme-color-neutrals-1000);
            }
        }
        &.c-menu__list-item-link--is-selected {
            background-color: var(--theme-color-100);
            color: var(--theme-color-neutrals-1000);
            &:hover {
                color: var(--theme-color-neutrals-0);
                background-color: var(--theme-color-950);
            }
          }
    }
    .c-menu__expand-collapse-button[aria-label="Collapse"]{
        .c-menu__list-item-link-title{
            padding: 0;
        }
    }
    .c-menu__list-item-link{
        padding: calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 2);
        .c-menu__list-item-icon {
            &::before{
                margin-right: calc(var(--theme-global-spacing) * 2);
            }
          }
    }
    .c-navigation-cascading__back-button,
    .c-navigation-cascading__close-button,
    a {
        &:focus {
            outline-color: var(--theme-color-950);
        }
        &.active {
            &:focus {
                outline-color: var(--theme-color-950);
            }
            &:hover{
                .u-icon{
                    color: var(--theme-color-neutrals-1000);
                }
            }
        }
        .u-icon{
            color: var(--theme-color-neutrals-1000);
        }
        &:hover{
            .u-icon{
                color: var(--theme-color-neutrals-0);
            }
        }
    }
    .c-menu__list--sub-menu {
        padding: calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 2);
    }
    .c-menu__expand-collapse-button {
        border-radius: calc(var(--theme-global-spacing));
    }
}
.c-navigation-tools {
    padding: calc(var(--theme-global-spacing) * 6) calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 4);
    .c-menu{
        padding: 0 !important; //to overide the library css
    }
    .c-menu__list{
        height: 100%;
    }
    .c-menu__list-item{
        margin: calc(var(--theme-global-spacing) * 0);
        padding-left: calc(var(--theme-global-spacing) * 0);
        padding-right: calc(var(--theme-global-spacing) * 0);
    }
    .c-menu__list-item-badge-icon:after {
        border-radius: calc(var(--theme-global-spacing) * 2.5);
        background-color: var(--theme-error);
    }
    .c-menu__list-item-badge{
        .c-badge.c-badge--medium{
            padding: calc(var(--theme-global-spacing) * 1) calc(var(--theme-global-spacing) * 2);
            @include font-style(var(--text-avrage), var(--text-base-size));      
        }
    }
    .c-menu .c-menu__list .c-menu__list-item-link:disabled .c-menu__list-item-badge, 
    .c-menu .c-menu__list .c-menu__list-item-link.disabled .c-menu__list-item-badge {
        margin-left: auto;
    }
}
.c-navigation-tools.c-navigation-tools--modifier {
    .c-menu__list-item-link{
        padding: calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 3.25);        
    }
    .c-menu__expand-collapse-button {
        margin: 0 auto calc(var(--theme-global-spacing) * 0);
        padding: calc(var(--theme-global-spacing) * 2);
    }
    .c-menu__list-item-badge-icon:after {
        border-radius: calc(var(--theme-global-spacing) * 2.5);
        background-color: var(--theme-error);
    }
}
.c-navigation-list.c-navigation-list--modifier{
    padding-top: calc(var(--theme-global-spacing) * 6);
    .c-menu__list-item-link-title{
        padding-top: calc(var(--theme-global-spacing) * 2);
        @include context--base-sm;
    }
    .c-navigation-list__title{
        padding: calc(var(--theme-global-spacing) * 0)  calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 2);
    }
    .c-navigation-list__close-button{
        padding: calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 3.25);
    }
    .c-menu__list-item-indicator {
        margin: 0;
    }
}
.c-navigation-list,
.c-navigation-cascading {
    padding: calc(var(--theme-global-spacing) * 8) 0 calc(var(--theme-global-spacing) * 4);
    @media (max-width: map-get($context--breakpoints, md-max)) {
        padding: calc(var(--theme-global-spacing) * 6) 0 calc(var(--theme-global-spacing) * 2); 
    }
    .c-navigation-list__title,
    .c-navigation-cascading__title {
        @include font-style(var(--text-base-size), var(--text-xs));
        font-weight: var(--font-weight-bold);
        font-family: var(--font-primary);
        color: var(--theme-color-neutrals-800);
        padding: calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 4);
        @media (max-width: map-get($context--breakpoints, md-max)) {
            padding-left: calc(var(--theme-global-spacing) * 2);
        }
    }
    .c-navigation-cascading__title {
        padding-left: calc(var(--theme-global-spacing) * 2);
    }
    .c-navigation-list__back-button,
    .c-navigation-list__close-button,
    .c-navigation-cascading__close-button,
    .c-navigation-cascading__back-button {
        padding:calc(var(--theme-global-spacing) * 1) calc(var(--theme-global-spacing) * 1);
    }
    .c-navigation-list__back-button,
    .c-navigation-cascading__back-button {
        margin-right: calc(var(--theme-global-spacing) * 2);
        color: var(--theme-color-neutrals-800);
    }
    .c-menu__list-item-indicator {
        @media (max-width: map-get($context--breakpoints, "xs-max")) {
            margin: 0;
        }
        color: var(--theme-color-neutrals-1000);
    }
    &.c-navigation-list--is-more{
        .c-menu__list{
            .c-menu__list-item{
                @media (max-width: map-get($context--breakpoints, "md-max")) {
                    padding: calc(var(--theme-global-spacing) * 0.5) calc(var(--theme-global-spacing) * 2);
                }
            }
        }
    }
    .c-menu__list-item-indicator {
        margin-left: auto;
    }
}
.c-navigation-list.c-navigation-list--is-more.c-navigation-list--modifier .c-menu .c-menu__list .c-menu__list.c-menu__list--sub-menu{
    padding: calc(var(--theme-global-spacing) * 2);
}
.c-navigation-list.c-navigation-list--is-more.c-navigation-list--multiple .c-menu__list.c-menu__list--sub-menu .c-menu__list-item  {
    @media (max-width: map-get($context--breakpoints, "md-max")) {
        padding: calc(var(--theme-global-spacing) * 0.5) 0;
    }
}
.c-navigation-list.c-navigation-list--is-more.c-navigation-list--multiple .c-menu__list .c-menu__list-item  {
    @media (max-width: map-get($context--breakpoints, "md-max")) {
        padding:0;
    }
}
@media (max-width: map-get($context--breakpoints, "md-max")) {
    .c-navigation-list{
            padding:calc(var(--theme-global-spacing) * 6) 0;
            border-top-color: var(--theme-color-neutrals-1000);
        .c-menu{
            .c-menu__list{
                .c-menu__list-item-link{
                    font-size: var(--text-xxxs);
                    @media (max-width: map-get($context--breakpoints, "sm-max")) {
                        font-size: var(--text-base-size);
                    }
                    padding: calc(var(--theme-global-spacing) *  1.5) calc(var(--theme-global-spacing) * 1);
                    @media (max-width: map-get($context--breakpoints, "md-max")) {
                        padding: calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 2);
                    }
                    .c-menu__list-item-link-icon{
                        margin-right: 0;
                        margin-bottom: 0;
                    }
                }
            }
        }
    } 
    .c-navigation-list.c-navigation-list--multiple {
        padding-top: 0;
    }
    .c-navigation-list.c-navigation-list--multiple.c-navigation-cascading .c-navigation-cascading__close-button {
        margin-right: calc(var(--theme-global-spacing) * 2);
    }
}
.u-divider{
    margin: calc(var(--theme-global-spacing) * 2) 0;
}
// CSS part for temporary purpose
.c-navigation__cards{
    padding: calc(var(--theme-global-spacing) * 3);
    width: 100%;
    height: 100%;
    overflow: auto;
}
.c-navigation__content {
    flex-grow: 1;
    padding: calc(var(--theme-global-spacing) * 3);
    width: 100%;
    height: 100%;
    overflow: auto;
    @media (max-width: map-get($context--breakpoints, "md-max")) {
        padding:0;
    }
}
.c-navigation__cards--hide,
.c-navigation__content--hide {
    display: none;
}
.c-navigation__content--with-margin {
    margin-left: 304px;
}
/*Navigation List Multiple*/
.c-navigation.c-navigation--multiple-option {
    height: 100%;
    .c-navigation-list.c-navigation-list--multiple {
        .c-menu .c-menu__list .c-menu__list-item {
            margin-top: calc(var(--theme-global-spacing) * 1);
            @media (max-width: map-get($context--breakpoints, md-max)) {
                margin-top: 0;
            }
        }
    }
    .c-navigation-tools{
        .c-menu__list{
            padding-bottom: calc(var(--theme-global-spacing) * 1);
            padding-top: calc(var(--theme-global-spacing) * 1);
            @media (max-width: map-get($context--breakpoints, md-max)) {
                padding-bottom: 0;
                padding-top: 0;
            }
        }
    }
}
.c-navigation-list.c-navigation-list--multiple {
    .c-menu .c-menu__list .c-menu__list-item {
        margin-top: 0;
    }  
    .c-menu__list.c-menu__list--sub-menu .c-menu__list-item-link{
        @include font-style(var(--text-base-size), var(--text-xs));
    }    
}
.c-navigation-cascading.c-navigation-cascading--multiple {
    .c-navigation-cascading__title {
        padding-right: calc(var(--theme-global-spacing) * 2);
    }
    .u-divider.u-divider--trim {
        margin: 0 calc(var(--theme-global-spacing)* 2) calc(var(--theme-global-spacing)* 2);
        @media (max-width: map-get($context--breakpoints, md-max)) {
            margin:calc(var(--theme-global-spacing) * 2) 0;
        }
    }
}
.u-divider.u-divider--trim {
    margin: 0 calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 2);
    @media (max-width: map-get($context--breakpoints, md-max)) {
        margin:calc(var(--theme-global-spacing) * 2) 0;
    }
}
/*Buttton pressed state*/
@media (hover: none), (hover: on-demand) {
    .c-header__action-menu-single{
         .u-button.u-button--secondary:hover{
             border-color: var(--theme-color-500);
         }
     }
  }
/*Override all the hover styles only for touch devices*/
@media (hover:none),
(hover:on-demand) {
    .c-menu__list-item-link:hover,
    .c-navigation-cascading .c-menu__list-item-link:hover,
    .c-menu__expand-collapse-button:hover,
    .c-navigation-tools__burger-button:hover {
        background-color: var(--theme-transparent-background);
        color: var(--theme-color-neutrals-1000);
    }
}
// theme css start
.c-navigation {
    .c-navigation-list.c-navigation-list--theme-color{
        height: 100% !important; // to override main navigation css
        @media (max-width: map-get($context--breakpoints, md-max)) {
            padding-top: 0;
            padding-bottom:calc(var(--theme-global-spacing) * 2);
        }
        .c-menu {
            height: 100%;
            position: relative;
            padding-bottom: 92px;
            .c-menu__list {
                height: calc(100% - 92px);
                @media screen and (orientation: landscape){
                    @media (min-width: map-get($context--breakpoints, md-max)) {
                        height: 100% !important;
                    }
                }
                @media (max-width: map-get($context--breakpoints, md-max)) {
                    position: static !important; // to override main navigation css
                    height: calc(100% - 92px) !important; // to override main navigation css
                    overflow-y: auto !important; // to override main navigation css
                    padding-top: calc(var(--theme-global-spacing)* 6);
                    @media screen and (orientation: portrait){
                        position: relative !important; // to override main navigation css
                        padding-bottom: 92px;
                    }
                    @media screen and (orientation: landscape){
                        position: relative !important; // to override main navigation css
                        padding-bottom: 92px;
                    }
                }
                .c-menu__list-item {
                    &.c-menu__list-item--theme-color-selected {
                        position: absolute !important; // to override main navigation css
                        bottom: 0;
                        width: calc(100% - 32px);
                        @media (max-width: map-get($context--breakpoints, md-max)) {
                            @media screen and (orientation: portrait){
                                position: fixed !important; // to override main navigation css
                                bottom: 18px;
                                background-color: #fff;
                            }
                        }
                        @media screen and (orientation: landscape){
                            position: fixed !important; // to override main navigation css
                            bottom: 18px;
                            background-color: #fff;
                            @media (min-width: map-get($context--breakpoints, md-max)) {
                                width: 233px !important;
                                bottom: 8px;
                            }
                            @media (min-width: map-get($context--breakpoints, xxl)) {
                                width: 283px !important;
                            }
                        }
                        @media (max-width: map-get($context--breakpoints, md-max)) {
                            width: 100%;
                        }
                        .c-menu__list-item-link {
                            border-width: 1px !important; // to override main navigation css
                            border-style: solid !important; // to override main navigation css
                            border-radius: calc(var(--theme-global-spacing) * 3);
                            padding: calc(var(--theme-global-spacing) * 2);
                            position: relative;
                            &::before{
                                position: absolute;
                                left: 0;
                                top: 0;
                                width: 100%;
                                height: 100%;
                                content: "";
                                border-radius: calc(var(--theme-global-spacing) * 3);
                            }
                            .u-icon{
                                border-width: 1px;
                                border-style: solid;
                                padding: calc(var(--theme-global-spacing) * 2.5);
                                border-radius: calc(var(--theme-global-spacing) * 2);
                                width: 44px;
                                height: 44px; 
                            }
                            .c-menu__list-item-link-title{
                                z-index: 9;
                            }
                        }
                        &.c-menu__list-item--theme-color-blue, &.c-menu__list-item--theme-color-fantasy-blue{
                            .c-menu__list-item-link{
                                &::before{content: none;}
                            } 
                        }
                        &.c-menu__list-item--theme-color-lovely-purple{
                            .c-menu__list-item-link{
                                &::before{content: none;}
                            } 
                        }
                        &.c-menu__list-item--theme-color-grassy-green{
                            .c-menu__list-item-link{
                                &::before{content: none;}
                            } 
                        }
                        .c-menu__list-item-title{
                            font-weight: var(--font-weight-bold);
                            margin-bottom: calc(var(--theme-global-spacing) * 2);
                        }
                    }
                }
            }

        }
    }
    //cascader css for theme
    .c-navigation__cascade {
        .c-navigation-cascading.c-navigation-cascading--theme{
            @media (max-width: map-get($context--breakpoints, md-max)) {
                height: 100%;
            }
            .c-menu {
                @media (max-width: map-get($context--breakpoints, md-max)) {
                    height: calc(100% - 84px);
                    padding-top: calc(var(--theme-global-spacing)* 4)
                }
                .c-menu__list {
                    .c-menu__list-item {
                        margin: calc(var(--theme-global-spacing)* 2) 0;
                        &.c-menu__list-item--is-part{
                            padding: calc(var(--theme-global-spacing)* 1) calc(var(--theme-global-spacing)* 2);
                            @media (max-width: map-get($context--breakpoints, md-max)) {
                                padding: calc(var(--theme-global-spacing)* 1) calc(var(--theme-global-spacing)* 4);
                            }
                            &:first-child{
                                margin-top: 0;
                            }
                        }
                        .c-menu__list-item-link{
                            border-width: 1px !important; // to override main navigation css
                            border-style: solid !important; // to override main navigation css
                            border-radius: calc(var(--theme-global-spacing) * 3);
                            padding: calc(var(--theme-global-spacing) * 2);
                            min-height: 60px;
                            position: relative;
                            &::before{
                                position: absolute;
                                left: 0;
                                top: 0;
                                width: 100%;
                                height: 100%;
                                content: "";
                                border-radius: calc(var(--theme-global-spacing) * 3);
                            }
                            .u-icon{
                                border-width: 1px;
                                border-style: solid;
                                padding: calc(var(--theme-global-spacing) * 2.5);
                                border-radius: calc(var(--theme-global-spacing) * 2);
                                width: 44px;
                                height: 44px;
                                &.c-menu__list-item-icon{
                                    display: none;
                                }
                            }
                            .c-menu__list-item-link-title{
                                z-index: 9;
                            }
                        }
                        &.c-menu__list-item--theme-color-blue, &.c-menu__list-item--theme-color-fantasy-blue{
                            .c-menu__list-item-link{
                                &::before{content: none;}
                            } 
                        }
                        &.c-menu__list-item--theme-color-lovely-purple{
                            .c-menu__list-item-link{
                                &::before{content: none;}
                            } 
                        }
                        &.c-menu__list-item--theme-color-grassy-green{
                            .c-menu__list-item-link{
                                &::before{content: none;}
                            } 
                        }
                    }
                }
            }
            .c-navigation-cascading__title-theme-text--small{
                font-size: var(--text-base-size);
                line-height: var(--text-xs);
                font-weight: var(--font-weight-bold);
            }
        }
    }
}
//mobile cacade menu css for theme selection start
.cascade-without-header{
    .c-navigation{
        @media (max-width: map-get($context--breakpoints, md-max)) {
            height: 100%;
            .c-navigation-cascading {
                padding-top: 0;
                padding-bottom: 0;
                .c-navigation-cascading__title{
                    font-size: var(--text-small);
                    line-height: var(--text-medium);
                    padding: calc(var(--theme-global-spacing)* 4);
                    height: 82px;
                    .c-navigation-cascading__back-button{
                        width: 40px;
                        height: 40px;
                        border-color: var(--theme-color-500);
                        color: var(--theme-color-neutrals-1000);
                        border-radius: var(--radius-300);
                        border-width: 2px;
                        border-style: solid;
                        margin-right: calc(var(--theme-global-spacing) * 4);
                        .u-icon{
                            font-size: var(--text-xs);
                        }
                        &::before{
                            content: none;
                        }
                    }
                    .c-navigation-cascading__title-theme-text {
                        display: -webkit-box; 
                        -webkit-box-orient: vertical; 
                        -webkit-line-clamp: 2; 
                        overflow: hidden; 
                        text-overflow: ellipsis;
                    }
                }
                .u-divider.u-divider--trim {
                    background-color: var(--theme-color-500);
                    margin: 0;
                    height: 2px;
                }
            }
        }
    }
}
//mobile cacade menu css for theme selection end
/*Override all the hover styles only for touch devices*/
@media (hover:none),
(hover:on-demand){
    .c-navigation .c-navigation-cascading .c-menu__more-menu-button:hover,
    .c-navigation .c-menu__list-item-link:hover {
        background-color:var(--theme-transparent-background);
        color: var(--theme-color-neutrals-1000);
        .u-icon{
            color: var(--theme-color-neutrals-1000);
        }
    }
    .c-menu__more-menu-button:hover,
    .c-menu__more-menu-button.active:hover {
        background-color: var(--theme-color-200);
        color: var(--theme-color-neutrals-1000);
        .u-icon{
            color: var(--theme-color-neutrals-1000);
        }
    }
    .c-navigation .c-menu__list-item-link.c-menu__list-item-link--is-selected:hover{ 
        background-color: var(--theme-color-100);
        color: var(--theme-color-neutrals-1000);
    }
    .c-navigation .c-navigation-cascading .c-menu__more-menu-button.active,
    .c-navigation .c-menu__list-item-link.active{
        background-color: var(--theme-color-200);
    }
    .c-navigation-list__back-button:hover, 
    .c-navigation-list__close-button:hover, 
    .c-navigation-cascading .c-navigation-cascading__close-button:hover, 
    .c-navigation-cascading .c-navigation-cascading__back-button:hover {
        background-color: var(--theme-color-neutrals-0);
        color: var(--theme-color-neutrals-1000);
        .u-icon {
            color: var(--theme-color-neutrals-1000);
        }
    }
}


@media (min-width: map-get($context--breakpoints, sm-max)) {
    .one.nav-cascade-without-header .page.page--course-list .page__container .page__container-sidebar .c-navigation {
        height: calc(100vh - 0px);
    }
}

@media (max-width: map-get($context--breakpoints, md-max)) {
    @media screen and (orientation: portrait){
        #Menubar.c-navigation-list .c-menu .c-menu__list{
            height: 100% !important;
        }
    }
    @media screen and (orientation: landscape){
        #Menubar.c-navigation-list .c-menu .c-menu__list{
            height: 100% !important;
        }
    }
}