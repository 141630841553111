.c-multi-level-progress {
  .c-multi-level-progress__title{
    font-weight: var(--font-weight-bold);
    margin-bottom: calc(var(--theme-global-spacing) * 4);
  }
  .c-multi-level-progress__track{
    background-color: var(--theme-color-neutrals-50);
    border-radius: var(--radius-200);
    .c-multi-level-progress__track-thumb{
      &:first-child{
        border-radius: var(--radius-200) 0 0 var(--radius-200);
      }
      &:last-child{
        border-radius: 0 var(--radius-200) var(--radius-200) 0;
      }
    }
  }
  // progress achivement row
  .c-multi-level-progress__achievement {
    margin-top: calc(var(--theme-global-spacing) * 4);
    .c-multi-level-progress__achievement-status {
      margin: 0 calc(var(--theme-global-spacing) * 4) 0 0;
      padding: calc(var(--theme-global-spacing) * 1);
      @media (max-width: map-get($context--breakpoints, sm-max)) {
        margin: calc(var(--theme-global-spacing) * 0);
      }
    }
    .c-multi-level-progress__status-icon {
      margin-right: calc(var(--theme-global-spacing) * 4);
      @include font-style(var(--text-md), var(--text-md));
    }
    .c-multi-level-progress__status-label {
      @include font-style(var(--text-base-size), var(--text-sm));
      margin: 0;
      .c-multi-level-progress__status-milestone {
        @include context--font-size-h3;
        font-weight: var(--font-weight-bold);
        margin-right: calc(var(--theme-global-spacing) * 2);
      }
    }
  }
}