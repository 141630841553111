//Forms
.c-input-group {
  margin-bottom: calc(var(--theme-global-spacing) * 4);
  font-family: var(--font-primary);
  .c-input-group__control {
    background-color: var(--theme-color-neutrals-0);
    color: var(--theme-color-neutrals-1000);
    border-color: var(--theme-color-neutrals-500);
    &:hover,
    &:focus {
      border-color: var(--theme-color-950);
      outline-color: var(--theme-color-950);
    }
    &.error {
      border-color: var(--theme-error);
    }
    &:disabled,
    &.disabled {
      border-color: var(--theme-color-neutrals-200);
      background-color: var(--theme-color-neutrals-50);
      color: var(--theme-color-neutrals-400);
    }
    .c-input-group__button-show-hide {
      padding: calc(var(--theme-global-spacing) * 3);
    }
  }
  .c-input-group__error,
  .c-input-group__error:hover {
    border-color: var(--theme-error);
  }
  //Forms / Radio-Checkbox / Primary Filled
  input[type="radio"].c-input-group__control,
  input[type="checkbox"].c-input-group__control {
    background-color: var(--theme-color-850);
    border-color: var(--theme-color-850);
    &:disabled,
    &.disabled {
      background-color: var(--theme-color-neutrals-50);
      border-color: var(--theme-color-neutrals-200);
    }
  }
  input.c-input-group__control {
    padding-right: calc(var(--theme-global-spacing) * 12);
    @media (hover:none), (hover:on-demand) {
      &:hover~.c-input-group__label:before {
        background-color: var(--theme-color-neutrals-0);
        border-color: var(--theme-color-neutrals-500);
      }
    }
  }
  // custom checkbox input
  .c-input-group__control--check {
    margin: calc(var(--theme-global-spacing) * 0);
    // custom checkbox input hover state
    &:hover ~ .c-input-group__label::before {
      background-color: var(--theme-color-850);
      border-color: var(--theme-color-850);
    }
    // custom checkbox input checked hover state
    &:checked:hover ~ .c-input-group__label::before {
      background-color: var(--theme-color-850);
      border-color: var(--theme-color-850);
    }
  }
  // custom checkbox label
  .c-input-group__control--check + .c-input-group__label {
    margin-bottom: 0;
    padding: calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 0)
      calc(var(--theme-global-spacing) * 8);
    &:before {
      border-radius: var(--radius);
      background-color: var(--theme-color-neutrals-0);
      border-color: var(--theme-color-neutrals-500);
    }
    &:disabled,
    &.disabled {
      &.c-input-group__label::before {
        border-color: var(--theme-color-neutrals-200);
      }
    }
  }
  .c-input-group__control--error + .c-input-group__label::before {
    border-color: var(--theme-error);
  }
  .c-input-group__control--error:hover + .c-input-group__label::before,
  .c-input-group__control--error:checked:hover + .c-input-group__label::before {
    background-color: var(--theme-error);
  }
  .c-input-group__control--error:focus:hover + .c-input-group__label::before,
  .c-input-group__control--check.c-input-group__control--error:focus + .c-input-group__label::before {
    outline-color: var(--theme-color-950);
  }
  .c-input-group__control--check:focus + .c-input-group__label::before {
    outline-color: var(--theme-color-950);
  }
  .c-input-group__control--check:disabled + .c-input-group__label::before,
  .c-input-group__control--check.disabled + .c-input-group__label::before {
    outline-color: var(--theme-transparent-background);
  }
  .c-input-group__control--check:disabled + .c-input-group__label::before,
  .c-input-group__control--check.disabled + .c-input-group__label::before {
    border-color: var(--theme-color-neutrals-150);
  }
  .c-input-group__control--check:disabled + .c-input-group__label,
  .c-input-group__control--check.disabled + .c-input-group__label {
    color: var(--theme-color-neutrals-400);
  }
  .c-input-group__control--check:disabled:hover + .c-input-group__label::before,
  .c-input-group__control--check.disabled:hover + .c-input-group__label::before {
    background-color: var(--theme-color-neutrals-0);
  }
  .c-input-group__control--check:disabled:hover + .c-input-group__label::before,
  .c-input-group__control--check.disabled:hover + .c-input-group__label::before {
    border-color: var(--theme-color-neutrals-150);
  }
  .c-input-group__control--check:disabled:checked + .c-input-group__label::before,
  .c-input-group__control--check.disabled:checked + .c-input-group__label::before {
    background-color: var(--theme-color-neutrals-150);
  }
}
.c-input-group--check-box .c-input-group__error {
  padding-left: calc(var(--theme-global-spacing) * 3);
}
.c-input-group .c-input-group__control--check.c-input-group__control--error:checked ~ .c-input-group__label:after {
  filter: invert(33%) sepia(100%) saturate(6230%) hue-rotate(331deg) brightness(100%) contrast(60%);
}
.c-input-group
  .c-input-group__control--check.c-input-group__control--error:checked:hover
  ~ .c-input-group__label::before,
.c-input-group .c-input-group__control--check.c-input-group__control--error:hover ~ .c-input-group__label::before {
  border-color: var(--theme-error);
}
.c-input-group__label-required {
  font-size: var(--text-base-size);
}
.c-input-outer {
  .c-input-outer__label {
    color: var(--theme-color-neutrals-1000);
    margin-bottom: calc(var(--theme-global-spacing) * 2);
    &:empty {
      margin: 0;
    }
    .c-input-outer__label-required {
      color: var(--theme-error);
    }
  }
  .c-input-group {
    margin-bottom: 0;
  }
  .c-input-outer__multiple-item{
    margin-bottom: calc(var(--theme-global-spacing) * 2);
  }
  &.c-input-outer--inline .c-input-outer__multiple {
    .c-input-outer__multiple-item {
      margin-right: calc(var(--theme-global-spacing) * 6);
    }
  }
  .c-input-outer__helper-text{
    color: var(--theme-color-neutrals-1000);
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  &.c-input-outer--disabled{
    .c-input-outer__label{
      color: var(--theme-color-neutrals-400);
      .c-input-outer__label-required {
        color: var(--theme-color-neutrals-400);
      }
    }
    .c-input-outer__helper-text{
      color: var(--theme-color-neutrals-400);
    }
  }
  &.c-input-outer--error{
    .c-input-outer__helper-text{
      color: var(--theme-error);
    }
  }
  // Below class from test harness
  .c-input-outer__multiple-error {
    color: var(--theme-error);
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
/*Button styled custom checkbox*/
.c-input-group .c-input-group__control--check.c-input-group__control--check-box-button + .c-input-group__label {
  @include button-style-checkbox-default; 
  background-color: var(--theme-color-500);
  border-radius: var(--radius);
  color: var(--theme-color-neutrals-0);
  &::after {
    @include context--base;
    @media (min-width: map-get($context--breakpoints, xxl)) {
      margin-top: calc(var(--theme-global-spacing) * -3.5);
    }
  }
  &::before {
    @include context--base;
    content: "";
  }
}
.c-input-group.c-input-group--check-box .c-input-group__control--check.c-input-group__control--check-box-button {
  &:hover + .c-input-group__label {
    background-color: var(--theme-color-neutrals-1000);
  }
  &:active + .c-input-group__label {
    background-color: var(--theme-color-700);
  }
  &:focus ~ .c-input-group__label {
    outline-color: var(--theme-color-950);
  }
  &:checked + .c-input-group__label {
    background-color: var(--theme-color-neutrals-0);
    color: var(--theme-color-neutrals-1000);
    &::after {
      color: var(--theme-color-1000);
      margin-top: calc(var(--theme-global-spacing) * -3) ;
      @media (min-width: map-get($context--breakpoints, xxl)) {
        margin-top: calc(var(--theme-global-spacing) * -4);
      }
    }
  }
  &:checked:hover ~ .c-input-group__label{
    &::before {
      background-color: var(--theme-transparent-background);
    }
  }
}
/*Override all the hover styles only for touch devices*/
@media (max-width: map-get($context--breakpoints, md-max)) {
  .c-input-group .c-input-group__control--check:checked:hover~.c-input-group__label:before{
    background-color: var(--theme-color-neutrals-0);
    border-color: var(--theme-color-neutrals-500);
  }
  .c-input-group .c-input-group__control--check:hover~.c-input-group__label:before {
    background-color: var(--theme-color-neutrals-0);
    border-color: var(--theme-color-neutrals-500);
  }
  .c-input-group.c-input-group--check-box .c-input-group__control--check.c-input-group__control--check-box-button:hover+.c-input-group__label {
    background-color: var(--theme-color-500);
    color: var(--theme-color-neutrals-0);
  }
}
/*To remove the blue highlight of checkbox on mobile*/
.c-input-group.c-input-group--check-box {
  -webkit-tap-highlight-color: transparent;
}

.c-input-group .c-input-group__control--check.c-input-group__control--check-box-button + .c-input-group__label:before {
  background: linear-gradient(var(--theme-color-neutrals-0) 0 0),linear-gradient(var(--theme-color-neutrals-0) 0 0);
}
.c-input-group .c-input-group__control--check.c-input-group__control--check-box-button:checked + .c-input-group__label:before {
  background: linear-gradient(var(--theme-color-neutrals-1000) 0 0);
  @media (max-width: map-get($context--breakpoints, md-max)) {
    background: linear-gradient(var(--theme-color-neutrals-0) 0 0);
  }
}