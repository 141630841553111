.c-navbar__header {
    background-color: var(--theme-color-neutrals-0);
    padding: calc(var(--theme-global-spacing) * 3);
    &:before {
        background-color: var(--theme-color-500);
    }
    .c-heading-title__sub-title {
        @include context--font-size-h4;
    }
    @media (min-width: map-get($context--breakpoints, lg)) {
        padding: calc(var(--theme-global-spacing) * 8) calc(var(--theme-global-spacing) * 8);
    }
    .c-navbar__list {
        margin: 0;
        padding: 0;
        @media (max-width: map-get($context--breakpoints, sm-max)) {
            background-color:var(--theme-color-neutrals-0);
        }
        &__item {
            padding: 0 calc(var(--theme-global-spacing));
            @media (max-width: map-get($context--breakpoints, sm-max)) {
                padding: 0px;
                border-bottom-color: var(--theme-color-neutrals-150);
                .u-button.u-button--primary {
                    color: var(--theme-color-neutrals-1000);
                    background-color: transparent;
                    padding: calc(var(--theme-global-spacing) * 6) 0;
                }
            }
        }
    }
    .c-navbar__header-actions {
        .c-navbar__menu-icon {
            padding: calc(var(--theme-global-spacing) * 7) calc(var(--theme-global-spacing) * 5);
            &:hover,
            &:focus {
                background-color: var(--theme-color-neutrals-1000);
                box-shadow: 0 0 0 0.3em var(--theme-color-neutrals-0), 0 0 0 0.36em var(--theme-color-500);
            }
            @media (max-width: map-get($context--breakpoints, sm-max)) {
                padding: calc(var(--theme-global-spacing));
                background-color:var(--theme-color-500);
                color: var(--theme-color-neutrals-0);
            }
        }
    }
    &.c-navbar__open {
        .c-navbar__list {
            padding: calc(var(--theme-global-spacing)) calc(var(--theme-global-spacing) * 4) 0;
            background-color: var(--theme-color-neutrals-0);
        }
    }
}
@media (max-width: map-get($context--breakpoints, sm-max)) {
    .c-navbar .c-navbar__header .c-navbar__header-title-content{
        padding-right: calc(var(--theme-global-spacing) * 11);
    }   
}

.c-navbar__header-actions .u-button{
    margin-left:calc(var(--theme-global-spacing) * 3);
  }