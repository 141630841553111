// Link
.c-link {
    @include font-style(var(--text-base-size), var(--text-xs));
    font-weight: var(--font-weight-bold);
    font-family: var(--font-primary);
    padding: calc(var(--theme-global-spacing) * 0)
      calc(var(--theme-global-spacing) * 1)
      calc(var(--theme-global-spacing) * 0)
      calc(var(--theme-global-spacing) * 2);
    color: var(--theme-color-500);
    @media (min-width: map-get($context--breakpoints, "xxl")) {
      padding: calc(var(--theme-global-spacing) * 0)
      calc(var(--theme-global-spacing) * 1)
      calc(var(--theme-global-spacing) * 0)
      calc(var(--theme-global-spacing) * 3);
    }
    &:hover {
      color: var(--theme-color-950);
    }
    &:focus {
      color: var(--theme-color-neutrals-800);
      outline-color: var(--theme-color-950);
      border-radius: var(--radius);
    }
    &:active {
      color: var(--theme-color-700);
    }
    .icon-chevron-left {
      margin-right: calc(var(--theme-global-spacing) * 2);
      @media (min-width: map-get($context--breakpoints, "xxl")) {
          margin-right: calc(var(--theme-global-spacing) * 2);
      }
    }
    .icon-chevron-right {
      margin-left: calc(var(--theme-global-spacing) * 2);
      @media (min-width: map-get($context--breakpoints, "xxl")) {
          margin-left: calc(var(--theme-global-spacing) * 2);
      }
    }
    .u-icon{
      font-size: var(--text-base-size);
    }
  }

  a {
    color: var(--theme-color-500);
    &:hover {
      color: var(--theme-color-neutrals-950);
    }
    &:active {
      color: var(--theme-color-neutrals-700);
    }
  }
  .c-link.c-link--is-disabled {
    color: var(--theme-color-neutrals-400);
  }
  .c-link.c-link--xsmall {
    .u-icon {
      font-size: var(--text-base-size);
    }
  }
  .c-link.c-link--small {
    .u-icon {
      font-size:var(--text-xxs);
    }
  }
  .c-link.c-link--medium {
    .u-icon {
      font-size: var(--text-xs);
    }
  }
  .c-link.c-link--large {
    .u-icon {
      font-size:var(--text-md);
    }
  }
  .c-link--primary {
    color: var(--theme-color-500);
  }
  .c-link--secondary{
    color: var(--theme-color-neutrals-800);
    &:hover {
      color: var(--theme-color-500);
    }
    &:focus {
      color: var(--theme-color-neutrals-800);
      outline-color: var(--theme-color-950);
      border-radius: var(--radius);
    }
    &:active {
      color: var(--theme-color-700);
    }
  }
  .c-link--tertiary{
   color: var(--theme-color-neutrals-0);
    &:hover {
      color: var(--theme-color-neutrals-0);
    }
    &:focus {
      color: var(--theme-color-neutrals-0);
      outline-color: var(--theme-color-950);
      border-radius: var(--radius);
    }
    &:active {
      color: var(--theme-color-neutrals-0);
    }
  }