@mixin font-style($font-size, $line-height) {
	font-size: $font-size;
	line-height: $line-height;
}

// Headings
@mixin context--font-size-h1 {
	@include font-style(var(--text-sm), var(--text-lg));
	@media (max-width: map-get($context--breakpoints, sm-max)) {
		@include font-style(var(--text-small), var(--text-medium));
	}
}

@mixin context--font-size-h2 {
	@include font-style(var(--text-xs), var(--text-md));
	@media (max-width: map-get($context--breakpoints, sm-max)) {
		@include font-style(var(--text-xxs), var(--text-sm));
	}
}

@mixin context--font-size-h3 {
	@include font-style(var(--text-base-size), var(--text-xs));
}

@mixin context--font-size-h4 {
	@include font-style(var(--text-base-size), var(--text-xs));
	@media (min-width: map-get($context--breakpoints, xxl)) {
		@include font-style(var(--text-sm), var(--text-lg));
	}
}
// body
@mixin context--base {
	@include font-style(var(--text-base-size), var(--text-xs));
	@media (min-width: map-get($context--breakpoints, xxl)) {
		@include font-style(var(--text-xxs), var(--text-sm));
	}
}
@mixin context--base-sm {
	@include font-style(var(--text-xxxs), var(--text-xxs));
	@media (min-width: map-get($context--breakpoints, xxl)) {
		@include font-style(var(--text-base-size), var(--text-xs));
	}
}

@mixin context--smallest {
	@include font-style(var(--text-smallest), var(--text-xxxs));
}

@mixin context--overline {
	@include font-style(var(--text-xxxs), var(--text-xs));
}

@mixin context--avrage {
	@include font-style(var(--text-avrage), var(--text-xxxs));
}

@mixin context--small {
	@include font-style(var(--text-xxs), var(--text-xxxs));
}

@mixin context--medium {
	@include font-style(var(--text-md), var(--text-xxxs));
}

@mixin u-word-wrap {
	word-wrap: break-word;
	overflow-wrap: break-word;
	word-break: break-word;
}

@mixin u-double-underline {
	text-decoration: underline;
	-webkit-text-decoration-skip: skip;
	text-decoration-skip-ink: auto;
	border-bottom-width: 1px;
	border-bottom-style: solid;
}
