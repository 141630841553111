.c-global-navigation-header {
    position: relative;
    height: 100vh;
    .c-header__action-menu {
        display: flex;
        align-items: center;
    }
    .c-headers__list-item {
        display: inline-block;
        padding: calc(var(--theme-global-spacing) * 0);
        margin: calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 0);
    }
    .c-headers__actions-menu {
        @media (max-width: map-get($context--breakpoints, sm-max)) {
            display: none;
        }
    }
    .c-headers .c-headers__wrapper .c-headers__list {
        @media (max-width: map-get($context--breakpoints, sm-max)) {
            padding: 0;
            .c-headers__list-item {
                margin: 0;
            }
        }
    }
    .c-headers .c-headers__wrapper .c-headers__actions .c-header__action-menu-single {
        display: none;
        @media (max-width: map-get($context--breakpoints, sm-max)) {
            display: block;
            .u-button::before{
                content: url("../../assets/default/images/vertical-dot.svg");
            }
            .u-button .icon-x-close {
                background: url("../../assets/default/images/close-icon.svg") var(--theme-color-neutrals-0) 9px;
                background-repeat: no-repeat;
                width: 34px;
                height: 34px;
                position: absolute;
                left: 1px;
                top: 1px;
            }
        }
    }
    .c-navigation-cascading{
        top: 82px !important;
        height: calc(100% - 82px) !important;
        .c-menu .c-menu__list .c-menu__list-item {
            margin: calc(var(--theme-global-spacing) * 1) calc(var(--theme-global-spacing) * 2);
        }
        .c-navigation-cascading__title {
            &:empty{
                display: none;
            }
        }
    }
}
