// c-toast types start
.c-toast {
  @include font-style(var(--text-base-size), var(--text-xs));
  font-weight: var(--font-weight-bold);
  border-radius: var(--radius-200);
  padding: calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 4)
    calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 6);
  @media (max-width: map-get($context--breakpoints, sm-max)) {
    padding: 0 calc(var(--theme-global-spacing) * 2) 0 calc(var(--theme-global-spacing) * 4);
    @include font-style(var(--text-base-size), var(--text-xs));
  }
  @include toast-info;
  .c-toast__icon {
    @include font-style(var(--text-xxs), var(--text-xs));
    margin-right: calc(var(--theme-global-spacing) * 4);
    @media (max-width: map-get($context--breakpoints, sm-max)) {
      margin-right: calc(var(--theme-global-spacing) * 2);
    }
  }
  .c-toast__content {
    margin-top: calc(var(--theme-global-spacing) * 3);
    margin-bottom: calc(var(--theme-global-spacing) * 3);
    margin-right: calc(var(--theme-global-spacing) * 4);
    @media (max-width: map-get($context--breakpoints, sm-max)) {
      margin-right: calc(var(--theme-global-spacing) * 2);
    }
  }
  .c-toast__action-button {
    margin-right: calc(var(--theme-global-spacing) * 4);
    @media (max-width: map-get($context--breakpoints, sm-max)) {
      margin-right: calc(var(--theme-global-spacing) * 2);
    }
    //below class from test harness. so update in theme css for matching with the UI
    .c-link{
      text-decoration: none;
      .c-link__text{
        text-decoration: underline;
        color: var(--theme-color-neutrals-1000);
      }
      &:hover {
        .c-link__text{
          text-decoration: underline;
        }
      }
    }
  }
  .c-toast__close-button {
    .u-button.u-button--fixed-size {
      border-color: var(--theme-transparent-background);
      background-color: var(--theme-transparent-background);
      color: var(--theme-color-neutrals-1000);
      &:hover,
      &.hover {
        color: var(--theme-color-neutrals-0);
        background-color: var(--theme-color-neutrals-1000);
        border-color: var(--theme-color-neutrals-1000);
      }
    }
    .u-button--large {
      @media (max-width: map-get($context--breakpoints, sm-max)) {
        padding: calc(var(--theme-global-spacing) * 0);
        top: 8px;
        width: 32px !important; /*To override component style*/
        height: 32px !important; /*To override component style*/
      }
      .icon-x-close {
        @include font-style(var(--text-xs), var(--text-xs));
        @media (max-width: map-get($context--breakpoints, sm-max)) {
          @include font-style(var(--text-base-size), var(--text-base-size));
        }
      }
    }
  }
}
.c-toast--info {
  @include toast-info;
}
.c-toast--success {
  @include toast-success;
}
.c-toast--danger {
  @include toast-danger;
}
.c-toast--warning {
  @include toast-warning;
}
