.c-accordion{
.c-accordion__panel {
	border-bottom-color: var(--theme-color-neutrals-200);
	.c-accordion__header {
		padding: calc(var(--theme-global-spacing) * 4);
		.c-accordion__title-text {
			@include context--base;
			margin: calc(var(--theme-global-spacing) * 0);
			padding-right: calc(var(--theme-global-spacing) * 8);
			@media (max-width: map-get($context--breakpoints, sm-max)) {
				padding-right: calc(var(--theme-global-spacing) * 7);
			}
		}
		&:focus .c-accordion__title-text .c-accordion__expand-label,
        &:focus .c-accordion__title-text .c-accordion__collapse-label{
			outline-color: var(--theme-color-950);
			border-radius: var(--radius);
		}
		.c-accordion__header-content{
			color: var(--theme-color-neutrals-600);
			margin-bottom: calc(var(--theme-global-spacing) * 0);
			margin-top: calc(var(--theme-global-spacing) * 4);
		}
	}
	.c-accordion__action-icon{
		color: var(--theme-color-1000);
		margin: calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 0) auto;	
	}
	.c-accordion__input-text {
		border-radius: var(--radius);
	}
	.c-accordion__content{
		padding: calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 8) calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 8);
		.c-accordion__body{
			margin: calc(var(--theme-global-spacing) * 0);
		}
	}
	&.c-accordion__panel[open] {
		&.c-accordion--open-top {
			padding-bottom: calc(var(--theme-global-spacing) * 14);
		}
	}
}
&.c-accordion--title-left-icon {
	.c-accordion__action-icon {
		margin-left: calc(var(--theme-global-spacing) * 0);
	}
	.c-accordion__title-text {
		padding-right: calc(var(--theme-global-spacing) * 0);
		padding-left: calc(var(--theme-global-spacing) * 8);
		@media (max-width: map-get($context--breakpoints, sm-max)) {
			padding-right: calc(var(--theme-global-spacing) * 0);
			padding-left: calc(var(--theme-global-spacing) * 7);
		}
	}
}
&.c-accordion--open-top {
	.c-accordion__panel{
		padding-bottom: calc(var(--theme-global-spacing) * 30);
		&.c-accordion__panel[open] {
			padding-bottom: calc(var(--theme-global-spacing) * 14);
			.c-accordion__content{
				padding-bottom: 0;
			}
		}
	}	
	.c-accordion__content{
		padding: calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 8) calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 4);
	}
	.c-accordion__header{
		.c-accordion__header-content{
			margin-top: calc(var(--theme-global-spacing) * 0);
			margin-bottom: calc(var(--theme-global-spacing) * 4);
		}
	}
	&.c-accordion--default{
		.c-accordion__panel{
			padding-bottom: calc(var(--theme-global-spacing) * 14);
		}	
	}
}
&.c-accordion--modified {
	.c-accordion__header{
		padding: calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 4);
		.c-accordion__title-text{
			color: var(--theme-color-1000);
			text-decoration: none;
			font-weight:600;
		}
	}
	.c-accordion__content{
		color: var(--theme-color-neutrals-600);
		padding: calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 4);
	}
}
.c-accordion__action {
	margin: calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 0) auto;	
	@media (max-width: map-get($context--breakpoints, sm-max)) {
		margin-left: calc(var(--theme-global-spacing) * 0);
	}
	button, html [type=button], [type=reset], [type=submit]{
		-webkit-appearance: none;
		-moz-appearance: none;
	}
	.u-button {
		margin-left: calc(var(--theme-global-spacing) * 4);
	}		
}
.c-accordion__action + .c-accordion__action-icon{
	margin-left: calc(var(--theme-global-spacing) * 2);
}
}
