//Global Navigation / label 
.c-list-item {
    margin-bottom: calc(var(--theme-global-spacing) * 1);
    padding: calc(var(--theme-global-spacing) * 0);
    padding-left: calc(var(--theme-global-spacing) * 2);
    padding-right: calc(var(--theme-global-spacing) * 2);
}
.c-list-item__link {
    padding: calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 2);
    color: var(--theme-color-neutrals-1000);
    border-radius: calc(var(--theme-global-spacing) * 1);
    font-weight: var(--font-weight-bold);
    .u-badge {
        border-radius: calc(var(--theme-global-spacing) * 1);
        @include context--smallest;
    }
    &:focus,
    &.focus {
        box-shadow: none;
        outline-color: var(--theme-color-950);
    }
    &:hover,
    &.hover {
        box-shadow: none;
        background-color: var(--theme-color-neutrals-1000);
        color: var(--theme-color-neutrals-0);
    }
    &:hover,
    &.hover {
        color: var(--theme-color-neutrals-0);
    }

    &:disabled,
    &.disabled {
        color: var(--theme-color-neutrals-400);
        background-color: var(--theme-color-neutrals-0);
        &:focus,
        &.focus {
            outline-color: var(--theme-color-950);
        }
        &:hover,
        &.hover {
            color: var(--theme-color-neutrals-150);
            background-color: var(--theme-color-neutrals-0);
            border-color: var(--theme-color-200);
            outline-color: var(--theme-color-950);
        }
    }
    &.active {
        background-color: var(--theme-color-200);
        color: var(--theme-color-neutrals-1000);
        &:focus,
        &.focus{
            color: var(--theme-color-neutrals-1000);
            outline-color: var(--theme-color-950);
        }
        &:hover,
        &.hover {
            color: var(--theme-color-neutrals-1000);
        }
    }
}
.c-list-item__link-icon{
    margin-right: calc(var(--theme-global-spacing) * 2.75);
}
.c-list {
    height: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none;  
}
.c-list .c-badge.c-badge--medium{
    @include font-style(var(--text-smallest), var(--text-xxxs));
    width: 24px;
    height: 24px;
    padding: 0;
    display: flex;
}
.c-list.c-list--horizontal{
    width: 120px;
    .c-list-item .c-list-item__link{
        flex-wrap: wrap;
        padding: calc(var(--theme-global-spacing) * 2);
        word-break: break-word;
        .c-badge{
            position: absolute;
            top: 8px;
            left: 50%;
            transform: translateX(-50%);
            width: 8px;
            height: 8px;
            text-indent: -999em;
        }
    }
    .c-list-item__link-icon{
        width: 100%;
        text-align: center;
        margin-right: calc(var(--theme-global-spacing) * 0);
        margin-bottom: calc(var(--theme-global-spacing) * 2);
    }
    .c-list-item__link-title{
        width: 100%;
        text-align: center;
    }
    .c-list-item__link-indicator.icon-chevron-right{
        display: none;
    }
}
/*Override all the hover styles only for touch devices*/
@media (hover:none),
(hover:on-demand) {
    .c-list-item__link:hover, 
    .c-list-item__link.hover {
        background-color: var(--theme-transparent-background);
        color: var(--theme-color-neutrals-1000);
    }
    .c-list-item__link.active,
    .c-list-item__link.active:hover {
        background-color: var(--theme-color-200);
        color: var(--theme-color-neutrals-1000);
    }
}