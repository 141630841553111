.c-function-card {
  border-color: var(--theme-color-neutrals-150);
  border-radius: var(--radius-200);
  margin-bottom: calc(var(--theme-global-spacing) * 4);
  background-color: var(--theme-color-neutrals-0);
  &.c-function-card--is-selected{
    border-color: var(--theme-color-200);
  }
  .c-function-card__header {
    padding: calc(var(--theme-global-spacing) * 6) calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 6) calc(var(--theme-global-spacing) * 6);
    @media (max-width: map-get($context--breakpoints, md)) {
      padding: calc(var(--theme-global-spacing) * 4);
    }
    .c-funtion-card__icon-drag{
      padding: calc(var(--theme-global-spacing) * 1);
    }
    .c-funtion-card__icon-list {
      .c-funtion-card__icon-check {
        @include font-style(var(--text-xl), var(--text-xl));
        color: var(--theme-success);
        margin-right: calc(var(--theme-global-spacing) * 4);
        @media (max-width: map-get($context--breakpoints, md)) {
          @include font-style(var(--text-xs), var(--text-xs));
          margin-bottom: calc(var(--theme-global-spacing) * 2);
          margin-right: calc(var(--theme-global-spacing) * 0);
        }
      }
      .c-funtion-card__check-box {
        margin-left: calc(var(--theme-global-spacing) * 2);
        margin-right: calc(var(--theme-global-spacing) * 4);
        .c-input-group--check-box{
          margin: calc(var(--theme-global-spacing) * 0);
          .c-input-group__label-text{display: none;}
          .c-input-group__control--check + .c-input-group__label{
            padding-left: calc(var(--theme-global-spacing) * 6);
            text-indent: -999em;
            &::before{
              left: 0;
            }
            &::after{
              left: 0;
              text-indent: 0;
            }
          }
        }
        @media (max-width: map-get($context--breakpoints, md)) {
          margin: calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 0);
        }     
      }
      .c-funtion-card__badge-container{
        @media (max-width: map-get($context--breakpoints, md)) {
          flex-grow: 1;
          text-align: right;
          margin-bottom: calc(var(--theme-global-spacing) * 2);
          padding-left: calc(var(--theme-global-spacing) * 2);
        }
      }
    }
    .c-function-card__title {
      font-weight: var(--font-weight-bold);
      font-size: var(--text-base-size);
      line-height: var(--text-xs);
      margin: 0;
      @media (min-width: map-get($context--breakpoints, xxl)) {
        @include context--base-sm;
      }
    }
    .c-function-card__list,
    .c-function-card__list-item {
      padding: calc(var(--theme-global-spacing) * 0);
      margin: calc(var(--theme-global-spacing) * 0);
    }
    .c-function-card__list {
      @media (max-width: map-get($context--breakpoints, md)) {
        margin-top: calc(var(--theme-global-spacing) * 2);
        display: flex;
        flex-direction: column;
      }
    }
    .c-function-card__list-item {
      @include context--base-sm;
      margin-top: calc(var(--theme-global-spacing) * 2);
      padding: calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 0);
      margin-right: calc(var(--theme-global-spacing) * 4);
      border-right: 1px solid var(--theme-color-neutrals-150);
      @media (min-width: map-get($context--breakpoints, xxl)) {
        @include font-style(var(--text-xxxs), var(--text-xxs))
      }
      .c-function-card-icon {
        @include context--base;
        color: var(--theme-color-500);
        position: relative;
        top: 2px;
        margin-left: calc(var(--theme-global-spacing) * 1);
        @media (min-width: map-get($context--breakpoints, xxl)) {
          @include context--base-sm;
        }
        @media (max-width: map-get($context--breakpoints, md)) {
          @include font-style(var(--text-base-size), var(--text-xxxs));
        }
      }
      &:last-child {
        border-right: none;
      }
      @media (max-width: map-get($context--breakpoints, md)) {
        padding: calc(var(--theme-global-spacing) * 0);
        margin-top: calc(var(--theme-global-spacing) * 2);
        border-right: none;
        margin-right: calc(var(--theme-global-spacing) * 0);
      }
      .c-function-card__list-item-label {
        color: var(--theme-color-neutrals-600);
        @media (max-width: map-get($context--breakpoints, md)) {
          padding-right: calc(var(--theme-global-spacing) * 4);
        }
      }
    }
  }
  .c-function-card__button-group {
    margin: calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 0) auto;
    padding: calc(var(--theme-global-spacing) * 6);
    &:empty{
      padding: calc(var(--theme-global-spacing) * 0);
    }
    .u-button--square{
      @media (min-width: map-get($context--breakpoints, xxl)) {
        padding: calc(var(--theme-global-spacing) * 1.5) calc(var(--theme-global-spacing) * 4);
      }
    }
    .c-dropdown.c-dropdown--fixed-icon {
      margin-left: calc(var(--theme-global-spacing) * 4);
      .c-dropdown__checkbox{
        + .c-dropdown__button {
          padding: calc(var(--theme-global-spacing) * 3);
          background-color: var(--theme-color-neutrals-100);
          border-color: var(--theme-color-neutrals-100);
          margin-bottom: 0;
          height: 40px;
          width: 40px;
          .c-dropdown__button-toggle-icon{
            right: 11px;
            @media (max-width: map-get($context--breakpoints, md)) {
              width: 100%;
              right: 0;
              position: relative;
              &::before{
                content: "\e936";
              }
            }
          }
          + .c-dropdown__list-outer{
            left: inherit;
            right: 0;
            min-width: 312px;
            top: calc(100% + 4px);
            @media (max-width: map-get($context--breakpoints, md)) {
              min-width: 100%;
              max-width: 100%;
            }
          }
          @media (max-width: map-get($context--breakpoints, md)) {
            width: 100%;
            justify-content: center;
          }
        }
        &:checked{
          + .c-dropdown__button.c-drodown__is-active{
            @media (max-width: map-get($context--breakpoints, md)) {
              .c-dropdown__button-text + .c-dropdown__button-toggle-icon{
                transform: rotate(90deg);
              }
            }
          }
        }
      }      
      @media (max-width: map-get($context--breakpoints, md)) {
        margin: calc(var(--theme-global-spacing) * 0);
      }
    }
    @media (max-width: map-get($context--breakpoints, md)) {
      margin: calc(var(--theme-global-spacing) * 0);
      padding: calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 4);
    }
    .u-button {
      @media (max-width: map-get($context--breakpoints, md)) {
        width: 100%;
        margin-bottom: calc(var(--theme-global-spacing) * 4);
        justify-content: center;
      }
    }
  }
  .c-function-card__accordion {
    border-top-color: var(--theme-color-neutrals-200);
    .c-accordion-outer{
      .c-accordion.c-accordion--open-top.c-accordion--modified{
        .c-accordion__panel {
          border: none;
          padding-bottom: calc(var(--theme-global-spacing) * 34);
          @media (max-width: map-get($context--breakpoints, md)) {
            padding-bottom: calc(var(--theme-global-spacing) * 28);
            height: 121px;
          }
          .c-accordion__content {
            padding:  calc(var(--theme-global-spacing) * 6) calc(var(--theme-global-spacing) * 6) calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 6);
            .c-accordion__content-body{
              margin: calc(var(--theme-global-spacing) * 0);
              @include context--base-sm;
            }
            @media (max-width: map-get($context--breakpoints, md)) {
              padding:  calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 4);
            }
          }
          .c-accordion__header{
            padding:  calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 6) calc(var(--theme-global-spacing) * 6) calc(var(--theme-global-spacing) * 6);
            border-radius: 0 0 var(--radius-200) var(--radius-200);
            @media (max-width: map-get($context--breakpoints, md)) {
              padding:  calc(var(--theme-global-spacing) * 4);
              border-radius: 0;
            }
            .c-accordion__title-text{
              padding: calc(var(--theme-global-spacing) * 1) calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 1) calc(var(--theme-global-spacing) * 6);
              @media (max-width: map-get($context--breakpoints, md)) {
                padding: calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 6);
              }
              @media (min-width: map-get($context--breakpoints, xxl)) {
                @include context--base-sm;
              } 
            }
            .c-accordion__action-icon{
              @include context--base;
              left: 24px;
              bottom: 28px;
              color: var(--theme-color-neutrals-800);
              @media (max-width: map-get($context--breakpoints, md)) {
                left: 16px;
                bottom: 16px;
              }
              @media (min-width: map-get($context--breakpoints, xxl)) {
                @include context--base-sm;
              } 
            }
            .c-accordion__header-content{
              @include context--base-sm;
              @media (min-width: map-get($context--breakpoints, xxl)) {
                @include font-style(var(--text-xxxs), var(--text-xxs))
              }
              &> *{
                margin: 0;
              }
            }  
            &:focus{
              outline: none;
              .c-accordion__title-text{
                outline-style: solid;
                outline-offset: 1px;
                outline-width: 2px;
                outline-color: var(--theme-color-950);
                border-radius: var(--radius);
                align-self: flex-start;
                padding-right: calc(var(--theme-global-spacing) * 1);
              }
            }
            &:visited,
            &:active{
              .c-accordion__title-text{
                color: var(--theme-color-700);
                outline: none;
              }
              .c-accordion__action-icon{
                color: var(--theme-color-neutrals-1000);
              }
            }          
          }
        }
        .c-accordion__action{
          bottom: 24px;
          @media (max-width: map-get($context--breakpoints, md)) {
            display: none;
          }
        }
        .c-accordion__panel[open]{
          padding-bottom: calc(var(--theme-global-spacing) * 18);
          @media (max-width: map-get($context--breakpoints, md)) {
            padding-bottom: calc(var(--theme-global-spacing) * 14);
            height: auto;
          }
        }
      }
    }
    &:empty{
      border-color: var(--theme-transparent-background);
    }
  }
  &.c-function-card--modified{
    @media (max-width: map-get($context--breakpoints, md)) {
      .c-function-card__list{
        display: none;
      }
    }
  }
}
// ios ui fixes
@media (max-width: map-get($context--breakpoints, md)) {
  .c-function-card {
    .c-function-card__accordion {
      .c-accordion-outer {
        .c-accordion.c-accordion--open-top.c-accordion--modified {
          .c-accordion__panel {
            height: 121px;
            padding-bottom: calc(var(--theme-global-spacing) * 14);
            .c-accordion__header {
              position: relative;
            }
            .c-accordion__header-content{
              height: 50px;
            }
            .c-accordion__header-content>*{
              display: inline;
            }
          }
          .c-accordion__panel[open]{
            height: auto;
            .c-accordion__header {
              position: absolute;
            }
            .c-accordion__header-content{
              height: auto;
            }
          }
        }
      }  
    }
  }
  }
