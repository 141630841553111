.c-stepper__header {
	border-color: var(--theme-color-neutrals-200);
	color: var(--theme-color-neutrals-1000);
	padding: calc(var(--theme-global-spacing) * 3) calc(var(--theme-global-spacing) * 4);
}
.c-stepper__body {
	padding: calc(var(--theme-global-spacing) * 16) calc(var(--theme-global-spacing) * 4)
		calc(var(--theme-global-spacing) * 3) calc(var(--theme-global-spacing) * 4);
}
.c-stepper__body-progress .c-stepper__body-progress-list {
	margin: 0 0 calc(var(--theme-global-spacing) * 5) 0;
	.c-stepper__body-progress-list-item {
		padding: 0 calc(var(--theme-global-spacing) * 25);
		.c-stepper__body-progress-list-item-link {
			&:before {
				background-color: var(--theme-color-neutrals-0);
				border-color: var(--theme-color-500);
				margin-left: calc(var(--theme-global-spacing) * -5);
				@media (max-width: map-get($context--breakpoints, sm)) {
					margin-left: calc(var(--theme-global-spacing) * -4);
				}
			}
			&:hover {
				outline-color: none;
				&:before {
					background-color: var(--theme-color-neutrals-1000);
					border-color: var(--theme-color-neutrals-1000);
				}
			}
		}
		@media (max-width: map-get($context--breakpoints, sm)) {
			padding: 0 calc(var(--theme-global-spacing) * 8);
		}
		&:after {
			background-color: var(--theme-color-neutrals-0);
		}
		&:last-child.c-stepper__body-progress-list-item--disabled {
			&:after {
				background-color: var(--theme-color-neutrals-200);
			}
			&:before {
				background-color: var(--theme-color-neutrals-200);
				border-color: var(--theme-color-neutrals-200);
			}
		}
		&.c-stepper__body-progress-list-item--disabled {
			&:after {
				background-color: var(--theme-color-neutrals-200);
			}
			.c-stepper__body-progress-list-item-link:before {
				background-color: var(--theme-color-neutrals-200);
				border-color: var(--theme-color-neutrals-200);
			}
			.c-stepper__body-progress-list-item-link {
				&:hover {
					outline: none;
					&:before {
						background-color: var(--theme-color-neutrals-200);
						border-color: var(--theme-color-neutrals-200);
					}
				}
				&:focus {
					outline-color: var(--theme-color-950);
				}
			}
		}

		&.c-stepper__body-progress-list-item--active {
			&:before {
				border-color: var(--theme-color-neutrals-0);
			}
			.c-stepper__body-progress-list-item-link:after {
				margin: auto;
				background-color: var(--theme-color-500);
			}
			.c-stepper__body-progress-list-item-link {
				&:hover {
					outline: none;
					&:before {
						background-color: var(--theme-color-neutrals-0);
						border-color: var(--theme-color-500);
					}
				}
				&:focus{
					outline-color: var(--theme-color-950);
				}
			}
		}
		

		&.c-stepper__body-progress-list-item--complete {
			&:after {
				background-color: var(--theme-success);
			}
			.c-stepper__body-progress-list-item-link:before {
				background-color: var(--theme-success);
				border-color: var(--theme-success);
			}
			.c-stepper__body-progress-list-item-link:focus {
				outline-color: var(--theme-color-950);
			}
		}		
		.c-stepper__body-progress-list-item-text {
			margin-left: calc(var(--theme-global-spacing) * -25);
			font-weight: var(--font-weight-bold);
		}
	}
	.c-stepper__body-progress-list-item--active + .c-stepper__body-progress-list-item.c-stepper__body-progress-list-item--disabled{
		&:after{
			background-color: var(--theme-color-500);
		}
	}
	.c-stepper__body-progress-list-item--stepper {
		margin-left: calc(var(--theme-global-spacing) * -5);
		padding: calc(var(--theme-global-spacing) * 1);
	}
	@media (max-width: map-get($context--breakpoints, "lg")) {
		margin: 0 0 calc(var(--theme-global-spacing) * 1) 0;
	}
}
.c-stepper__body-progress-steps{
	padding-top: calc(var(--theme-global-spacing) * 4);
}
@media (max-width: map-get($context--breakpoints, "lg")) {
	.c-stepper__body-progress-overview {
		color: var(--theme-color-neutrals-1000);
	}
}
