//list card
.c-list-card {
  padding: calc(var(--theme-global-spacing) * 6);
  margin-bottom: calc(var(--theme-global-spacing) * 4);
  border-radius: var(--radius-200);
  border-color: var(--theme-color-neutrals-150);
  background-color: var(--theme-color-neutrals-0);
  @media (max-width: map-get($context--breakpoints, sm-max)) {
    padding: calc(var(--theme-global-spacing) * 4);
    margin-bottom: calc(var(--theme-global-spacing) * 2);
  }
  // list card sequence type
  .c-list-card__type {
    margin-right: calc(var(--theme-global-spacing) * 4);
    @media (max-width: map-get($context--breakpoints, sm-max)) {
      margin-right: calc(var(--theme-global-spacing) * 0);
      margin-bottom: calc(var(--theme-global-spacing) * 3);
    }
    .c-list-card__type-selected {
      color: var(--theme-success);
      @include font-style(var(--text-xl), var(--text-xl));
      @media (max-width: map-get($context--breakpoints, sm-max)) {
        @include font-style(var(--text-xs), var(--text-xs));
      }
    }
    .c-list-card__type-draggable {
      .c-input-group.c-input-group--check-box {
        margin: 0 0 0 calc(var(--theme-global-spacing) * 2);
        @media (max-width: map-get($context--breakpoints, sm-max)) {
          margin: 0;
        }
        .c-input-group__control--check + .c-input-group__label {
          padding-left: calc(var(--theme-global-spacing) * 6);
          &:before {
            left: 0;
          }
          &:after {
            left: 0;
            text-indent: 0;
          }
        }
        .c-input-group__label {
          padding-top: calc(var(--theme-global-spacing) * 0);
          padding-bottom: calc(var(--theme-global-spacing) * 0);
        }
      }
    }
  }
  // list card content
  .c-list-card__content {
    margin-right: calc(var(--theme-global-spacing) * 4);
    @media (max-width: map-get($context--breakpoints, md)) {
      margin-bottom: calc(var(--theme-global-spacing) * 4);
      margin-right: calc(var(--theme-global-spacing) * 0);
    }
    .c-list-card__content-title {
      margin: calc(var(--theme-global-spacing) * 0);
      margin-bottom: calc(var(--theme-global-spacing) * 2);
      @media (max-width: map-get($context--breakpoints, sm-max)) {
        margin-left: calc(var(--theme-global-spacing) * 0);
      }
    }
    .c-list-card__content-list {
      padding: calc(var(--theme-global-spacing) * 0);
      margin: calc(var(--theme-global-spacing) * 0);
      .c-list-card__content-list-item {
        @include context--base-sm;
        margin: calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 4) 0 0;
        padding-right: calc(var(--theme-global-spacing) * 4);
        border-color: var(--theme-color-neutrals-150);
        color: var(--theme-color-neutrals-1000);
        @media (max-width: map-get($context--breakpoints, sm-max)) {
          padding: calc(var(--theme-global-spacing) * 0);
          margin: 0 0 calc(var(--theme-global-spacing) * 1) 0;
        }
        &:last-child {
          margin-right: calc(var(--theme-global-spacing) * 0);
          padding-right: calc(var(--theme-global-spacing) * 0);
          @media (max-width: map-get($context--breakpoints, sm-max)) {
            margin: 0;
          }
        }
      }
      .c-list-card__content-list-label {
        color: var(--theme-color-neutrals-600);
        margin-right: calc(var(--theme-global-spacing) * 1); 
      }
      .c-list-card__content-list-icon {
        @include font-style(var(--text-base-size), var(--text-base-size));
        margin-left: calc(var(--theme-global-spacing) * 1);
        color: var(--theme-color-500);
      }
    }
  }
  // type draggable checked
  &.c-list-card--is-checked{
    border-color: var(--theme-color-200);
  }
  // type selected activate
  &.c-list-card--selected{
    border-color: var(--theme-color-200);
    .c-list-card__content .c-list-card__content-title {
      @media (max-width: map-get($context--breakpoints, sm-max)) {
        margin-left: calc(var(--theme-global-spacing) * 8);
      }
    }
  }

  // Below css for support other component UI which is matching with List Card UI. Thease other component and supperted classes is coming from test-harness.
  // list card checkbox mute label
  .c-list-card__type .c-list-card__type-draggable .c-input-group .c-input-group__label {
    text-indent: -999em;
  }
  // list card item details
  .c-list-card__content-list {
    display: flex;
    flex-wrap: wrap;
    .c-list-card__content-list-item {
      font-weight: 400;
      word-break: break-all;
      position: relative;
      list-style: none;
      border-right-width: 1px;
      border-right-style: solid;
      display: inline-flex;
      align-items: center;
      &:last-child {
        border-right-width: 0;
      }
      @media (max-width: map-get($context--breakpoints, sm-max)) {
        border: none;
        width: 100%;
      }
    }
    .c-list-card__content-list-icon {
      position: relative;
      display: inline-flex;
    }
  }
  // list card button group
  .c-list-card__buttons {
    -webkit-tap-highlight-color: var(--theme-transparent-background);
    .c-list-card__buttons-item + .c-list-card__buttons-item {
      margin-left: calc(var(--theme-global-spacing) * 4);
      @media (max-width: map-get($context--breakpoints, sm-max)) {
        margin-left: calc(var(--theme-global-spacing) * 0);
        margin-top: calc(var(--theme-global-spacing) * 4);
      }
    }
    .c-list-card__buttons-item {
      @media (max-width: map-get($context--breakpoints, sm-max)) {
        width: 100%;
      }
      .u-button {
        -webkit-tap-highlight-color: var(--theme-transparent-background);
        @media (max-width: map-get($context--breakpoints, sm-max)) {
          width: 100%;
          justify-content: center;
        }
      }
      .c-dropdown .c-dropdown__checkbox {
        + .c-dropdown__button {
          background-color: var(--theme-color-neutrals-100);
          border-color: var(--theme-color-neutrals-100);
          height: 40px;
          -webkit-tap-highlight-color: var(--theme-transparent-background);
          @media (min-width: map-get($context--breakpoints, md)) {
            width: 40px;
          }
          @media (min-width: map-get($context--breakpoints, xxl)) {
            width: 48px;
            height: 48px;
          }
          @media (max-width: map-get($context--breakpoints, sm-max)) {
            width: 100%;
            justify-content: center;
          }
          .c-dropdown__button-text {
            flex: 0 0 auto;
            width: auto;
            max-width: 100%;
            flex-basis: auto;
            flex-grow: 0;
            @media (min-width: map-get($context--breakpoints, md)) {
              margin: 0;
              opacity: 0;
              visibility: hidden;
              width: 0;
            }
          }
          + .c-dropdown__list-outer {
            left: calc(100% - 225px);
            width: 225px;
            @media (max-width: map-get($context--breakpoints, sm-max)) {
              left: 0;
              width: 100%;
              max-width: 100%;
            }
          }
        }
        &:checked + .c-dropdown__button.c-drodown__is-active .icon-dots-vertical {
          transform: rotate(0);
        }
      }
    }
  }
}
