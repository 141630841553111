//Global Navigation / label
.c-menu__expand-collapse-button,
.c-navigation-tools__burger-button {
    border: calc(var(--theme-global-spacing) * 0);
    border-bottom-color: var(--theme-dividers);
    margin: calc(var(--theme-global-spacing) * 1) calc(var(--theme-global-spacing) * 0);
    background-color: var(--theme-color-neutrals-0);
}
.c-menu__more-menu {
    background-color: var(--theme-color-neutrals-0);
    padding-top:calc(var(--theme-global-spacing) * 2);
    @media (max-width: map-get($context--breakpoints, "md-max")) {
        padding-top: 0;
    }
}
.c-menu__more-menu-button {
    border: calc(var(--theme-global-spacing) * 0);
    border-top-color: var(--theme-dividers);
    margin: calc(var(--theme-global-spacing) * 1) calc(var(--theme-global-spacing) * 0);
    background-color: var(--theme-color-neutrals-0);
    &.active {
        background-color: var(--theme-color-200);
        color: var(--theme-color-neutrals-1000);
    }
}
.c-menu {
    background-color: var(--theme-color-neutrals-0);
    border-right-color: var(--theme-dividers);
    padding: 0  calc(var(--theme-global-spacing) * 3);
    @media (max-width: map-get($context--breakpoints, "md-max")) {
        padding: 0
    }
}
.c-navigation-cascading .c-menu {
    padding: 0  calc(var(--theme-global-spacing) * 1);
    @media (max-width: map-get($context--breakpoints, "md-max")) {
        padding: 0
    }
}
.c-navigation .c-navigation-tools .c-menu {
    padding: 0  calc(var(--theme-global-spacing) * 2);
}
.c-menu__list-item-link-icon,
.c-menu__expand-collapse-button .u-icon,
.c-menu__more-menu-button .u-icon,
.c-navigation-tools__burger-button .u-icon {
    margin-right: calc(var(--theme-global-spacing) * 2);
}
@media (max-width: map-get($context--breakpoints, "md-max")) {
    .c-menu__more-menu-button .u-icon {
        margin-right: calc(var(--theme-global-spacing) * 0);
        margin-bottom: calc(var(--theme-global-spacing) * 2);
    }
    .c-menu__list.c-menu__list--sub-menu{
        padding: calc(var(--theme-global-spacing) * 2) 0;
        .c-menu__list-item-link-icon,
        .c-menu__expand-collapse-button .u-icon,
        .c-menu__more-menu-button .u-icon,
        .c-navigation-tools__burger-button .u-icon {
            margin-right: calc(var(--theme-global-spacing) * 2);
            margin-bottom: calc(var(--theme-global-spacing) * 0);
        }
    }
    
}
.c-menu__list {
    background-color: var(--theme-color-neutrals-0);
    padding: calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 1) 0;
    margin: calc(var(--theme-global-spacing) * 0);
    @media (max-width: map-get($context--breakpoints, "md-max")) {
        padding: calc(var(--theme-global-spacing) * 0);
        .u-badge--danger {
            margin-left: calc(var(--theme-global-spacing) * 3);
        }
    }
}
.c-menu__list-item {
    margin-bottom: calc(var(--theme-global-spacing) * 0);
    padding: calc(var(--theme-global-spacing) * 0);
    padding-left: calc(var(--theme-global-spacing) * 2);
    padding-right: calc(var(--theme-global-spacing) * 2);
    @media (max-width: map-get($context--breakpoints, "md-max")) {
        margin-bottom: calc(var(--theme-global-spacing) * 0);
    }
}
.c-menu__list-item-link,
.c-menu__expand-collapse-button,
.c-menu__more-menu-button,
.c-navigation-tools__burger-button {
    background-color: transparent;
    padding: calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 3);
    color: var(--theme-color-neutrals-1000);
    border-radius: var(--radius);
    font-weight: var(--font-weight-bold);
    font-family: var(--font-primary);
    @include font-style(var(--text-base-size), var(--text-xs));
    &:focus,
    &.focus,
    &:hover,
    &.hover {
        box-shadow: none;
        background-color: var(--theme-color-neutrals-1000);
        color: var(--theme-color-neutrals-0);
    }
    &:hover,
    &.hover {
        color: var(--theme-color-neutrals-0);
        border-bottom-color: var(--theme-dividers);
    }

    &:disabled,
    &.disabled {
        color: var(--theme-dividers);
        background-color: var(--theme-color-neutrals-0);
        &:focus,
        &.focus,
        &:hover,
        &.hover {
            color: var(--theme-dividers);
            background-color: var(--theme-color-neutrals-0);
        }
    }
    &.active {
        background-color: var(--theme-color-200);
        color: var(--theme-color-neutrals-1000);
        &:focus,
        &.focus,
        &:hover,
        &.hover {
            color: var(--theme-color-neutrals-1000);
        }
    }
} 
.u-badge, .u-badge--pill {
    @include context--smallest;
}
.c-menu--modifier {
    .c-menu__list-item-link-icon,
    .c-menu__more-menu-icon {
        margin: calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 2);
    }
    .c-menu__list-item-link-subtitle{
        @include context--base-sm;
    }
}
@media (max-width: map-get($context--breakpoints, "sm-max")) {
    .c-menu__list--sub-menu{
        padding: calc(var(--theme-global-spacing) * 2) 0;
        .c-menu__list-item-link-icon {
            margin-right: calc(var(--theme-global-spacing) * 2);
            margin-bottom: calc(var(--theme-global-spacing) * 0);
        }
    }
}