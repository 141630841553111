.c-table-outer {
    border: 1px solid var(--theme-color-neutrals-200);
    border-radius: var(--radius-200);
    @media (max-width: map-get($context--breakpoints, md-max)) {
        border: none
    }
    .c-table {
        min-width: 100%;
        border-radius: var(--radius-200);
        background-color: var(--theme-transparent-background);
        td,
        th {
            border-bottom: 1px solid var(--theme-color-neutrals-200);
            @media (max-width: map-get($context--breakpoints, md-max)) {
                border: none;
            }
        }
        .c-table__header {
            background-color: var(--theme-color-neutrals-50);
            @media (max-width: map-get($context--breakpoints, md-max)) {
                background-color: var(--theme-transparent-background);
                padding: 0;
            }
            .c-table__header-row {
                .c-table__header-coulmn {
                    padding: calc(var(--theme-global-spacing) * 2.85) calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 2.85) calc(var(--theme-global-spacing) * 4);
                    font-weight: var(--font-weight-bold);
                    .c-input-group .c-input-group__control--check+.c-input-group__label {
                        &::after {
                            text-indent: initial;
                        }
                    }
                    &:first-child {
                        border-left-color: var(--theme-color-neutrals-200);
                        padding: calc(var(--theme-global-spacing) * 2.85) calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 2.85) calc(var(--theme-global-spacing) * 4);
                        border-top-left-radius: var(--radius-200);
                        @media (max-width: map-get($context--breakpoints, md-max)) {
                            box-shadow: none;
                            background-color: var(--theme-transparent-background);
                            padding-bottom: calc(var(--theme-global-spacing) * 6);
                            .c-input-group .c-input-group__control--check+.c-input-group__label {
                                text-indent: initial;
                            }
                        }
                        .c-table__coulmn-text {
                            @media (max-width: map-get($context--breakpoints, md-max)) {
                                margin-left: calc(var(--theme-global-spacing) * 2);
                            }
                        }
                    }
                    &:last-child {
                        border-top-right-radius: var(--radius-200);
                    }
                    .c-table__header-sorting-icon {
                        margin-left: calc(var(--theme-global-spacing) * 2);
                        line-height: calc(var(--theme-global-spacing) * 4);
                        color: var(--theme-color-neutrals-1000);
                        &:before {
                            font-size: var(--text-base-size);
                        }
                    }
                    .c-input-group {
                        margin-bottom: calc(var(--theme-global-spacing) * 0);
                        width: 24px;
                        line-height: 0;
                        display: flex;
                        @media (max-width: map-get($context--breakpoints, md-max)) {
                            width: auto
                        }
                    }
                    &:focus {
                        outline-color: var(--theme-color-neutrals-200);
                    }
                    .c-tooltip{
                        max-width: 250px;
                        text-align: left;
                    }
                }
            }
        }
        .c-table__body {
            border-right-color: var(--theme-color-neutrals-200);
            border-bottom-left-radius: var(--radius-200);
            border-bottom-right-radius: var(--radius-200);
            border-bottom-color: var(--theme-color-neutrals-200);
            .c-table__row {
                @media (max-width: map-get($context--breakpoints, md-max)) {
                    margin-bottom: calc(var(--theme-global-spacing) * 2);
                    border: 1px solid var(--theme-color-neutrals-200);
                    border-radius: var(--radius-200);
                }
                .c-table__checkbox {
                    padding: calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 4);
                    border-left-color: var(--theme-color-neutrals-200);
                    @media (max-width: map-get($context--breakpoints, md-max)) {
                        padding: calc(var(--theme-global-spacing) * 4);
                        margin-bottom: calc(var(--theme-global-spacing) * 2);
                        &::before {
                            border-bottom-color: var(--theme-color-neutrals-150);
                            border-width: 1px;
                        }
                    }
                    .c-input-group .c-input-group__control--check+.c-input-group__label {
                        &::after {
                            text-indent: initial;
                        }
                    }
                    /*To overwrite the checkbox component style*/
                    .c-input-group.c-input-group--check-box {
                        height: 24px;
                        width: 23px;
                        margin-bottom: 0;
                        flex-direction: inherit;
                        display: flex;
                    }
                }
                .c-table__row-column {
                    border-right-color: var(--theme-transparent-background);
                    padding: calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 4);
                    @media (max-width: map-get($context--breakpoints, md-max)) {
                        box-shadow: none;
                        font-family: var(--font-primary);
                        border-left: 0;
                        border-right: 0;
                        position: relative;
                        &:before {
                            @include font-style(var(--text-xxxs), var(--text-xxs));
                            padding: calc(var(--theme-global-spacing) * 1) calc(var(--theme-global-spacing) * 4) 0;
                            color: var(--theme-color-neutrals-600);
                        }
                    }
                    &:first-child {
                        border-left-color: var(--theme-color-neutrals-200);
                        @media (max-width: map-get($context--breakpoints, md-max)) {
                            padding: calc(var(--theme-global-spacing) * 4) 0 0;
                            border-top-left-radius: var(--radius-200);
                            border-top-right-radius: var(--radius-200);
                            border-left-color: var(--theme-color-neutrals-200);
                            border-right-color: var(--theme-color-neutrals-200);
                            box-shadow: inset 0 1px 0 var(--theme-color-neutrals-200);
                        }
                        .c-table__row-column-place-holder {
                            @media (max-width: map-get($context--breakpoints, md-max)) {
                                border-bottom-color: var(--theme-color-neutrals-200);
                                color: var(--theme-transparent-background);
                            }
                        }
                    }
                    &:last-child {
                        border-right-color: var(--theme-color-neutrals-200);
                        @media (max-width: map-get($context--breakpoints, md-max)) {
                            border-bottom-color: var(--theme-color-neutrals-200);
                            border-bottom-left-radius: var(--radius-200);
                            border-bottom-right-radius: var(--radius-200);
                            padding-bottom: calc(var(--theme-global-spacing) * 2);
                        }
                    }
                    @media (max-width: map-get($context--breakpoints, md-max)) {
                        border-color: var(--theme-transparent-background) var(--theme-color-neutrals-200) var(--theme-transparent-background) var(--theme-color-neutrals-200);
                        padding: 0;
                    }
                    .c-table__row-column-place-holder {
                        @media (max-width: map-get($context--breakpoints, md-max)) {
                            @include font-style(var(--text-xxxs), var(--text-xxs));
                            color: var(--theme-color-neutrals-600);
                            padding: calc(var(--theme-global-spacing) * 1) calc(var(--theme-global-spacing) * 4);
                        }
                    }
                    .c-input-group {
                        margin-bottom: calc(var(--theme-global-spacing) * 0);
                    }
                    &:focus {
                        outline-color: var(--theme-color-neutrals-200);
                    }
                    .c-table__row-column-data {
                        font-weight: var(--font-weight-normal);
                        @include font-style(var(--text-base-size), var(--text-xs));
                        @media (max-width: map-get($context--breakpoints, md-max)) {
                            @include font-style(var(--text-xxxs), var(--text-xxs));
                            padding: calc(var(--theme-global-spacing) * 1) calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 4);
                        }
                    }
                    .c-input-outer__multiple-item {
                        @media (max-width: map-get($context--breakpoints, md-max)) {
                            padding: calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 4);
                        }
                    }
                    .c-input-group .c-input-group--radio-button {
                        display: flex;
                        flex-direction: inherit;
                        max-width: 23px;
                        @media (max-width: map-get($context--breakpoints, md-max)) {
                            flex-direction: inherit;
                            height: 24px;
                            max-width: inherit;
                        }
                    }
                    .c-table__row-column--mobile-tooltip {
                        position: absolute;
                        right: 16px;
                        top: 6px;
                        .u-icon {
                            font-size: var(--text-base-size);
                            line-height: var(--text-xs);
                            color: var(--theme-color-neutrals-600);
                        }
                    }
                }
                &:last-child {
                    @media (max-width: map-get($context--breakpoints, md-max)) {
                        margin-bottom: 0;
                    }
                    td {
                        &:first-child {
                            border-bottom-left-radius: var(--radius-200);
                        }
                        &:last-child {
                            border-bottom-right-radius: var(--radius-200);
                        }
                    }
                }
                &:hover {
                    background-color: var(--theme-color-100);
                    @media (max-width: map-get($context--breakpoints, md-max)) {
                        background-color: var(--theme-transparent-background);
                    }
                }
                &.c-table__row--is-selected {
                    background-color: var(--theme-color-50);
                }
            }
            .c-table__row:last-child td,
            .c-table__row:last-child th {
                border-bottom: 0;
            }
            .c-input-group--radio-button .c-input-group__control--radio {
                width: 100%;
                opacity: 0;
            }
        }
        /*Table with Static Data*/
        &.c-table--bordered {
            td, th {
                border: 1px solid var(--theme-color-neutrals-200);
            }
            .c-table__header {
                .c-table__header-row .c-table__header-coulmn {
                    border-top: 0;
                    word-break: break-word;
                    :first-child {
                        border-left: 0;
                    }
                    &:last-child {
                        border-right: 0;
                    }
                    .c-table__header-coulmn-totalscore {
                        font-size: var(--text-xxxs);
                        line-height: var(--text-xxs);
                        color: var(--theme-color-neutrals-600);
                        font-weight: var(--font-weight-normal);
                        word-break: break-word;
                        display: block;
                        margin-top: calc(var(--theme-global-spacing) * 2);
                    }
                }
                .c-table__header-row--2 .c-table__header-coulmn {
                    &:first-child {
                        border-top-left-radius: 0;
                    }
                    &:last-child {
                        border-top-right-radius: 0;
                    }
                }
                .u-icon {
                    @include font-style(var(--text-base-size), var(--text-xs));
                    &:before {
                        margin: 0 calc(var(--theme-global-spacing) * 2);
                    }
                    @media (min-width: map-get($context--breakpoints, xxl)){
                        line-height: 30px;
                    }
                }
            }
            .c-table__header .c-table__header-row .c-table__header-coulmn:first-child,
            .c-table__body .c-table__row .c-table__checkbox {
                border-right-color: var(--theme-color-neutrals-200);
                border-left: none;
                @media (max-width: map-get($context--breakpoints, md-max)) {
                    border: 0;
                }
            }
            .c-table__body .c-table__row {
                .c-table__row-column {
                    border-right-color: var(--theme-color-neutrals-200);
                    padding: calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 4);
                    @media (max-width: map-get($context--breakpoints, md-max)) {
                        padding: calc(var(--theme-global-spacing) * 1) calc(var(--theme-global-spacing) * 4);
                    }
                    &::before {
                        padding: calc(var(--theme-global-spacing) * 1) calc(var(--theme-global-spacing) * 4) 0 0;
                        color: var(--theme-color-neutrals-600);
                        @include font-style(var(--text-xxxs), var(--text-xxs));
                    }
                    .c-dropdown {
                        position: inherit;
                        @media (max-width: map-get($context--breakpoints, md-max)) {
                            margin-top: calc(var(--theme-global-spacing) * 2);
                        }
                        .c-dropdown__checkbox[aria-expanded="true"] + .c-dropdown__button{
                            background-color: var(--theme-color-neutrals-200);
                            color: var(--theme-color-neutrals-1000);
                            .c-dropdown__button-toggle-icon {
                                @media (max-width: map-get($context--breakpoints, md-max)) {
                                    transform: rotate(90deg);
                                }
                            }
                        }
                        .c-dropdown__button {
                            border: 0;
                            background-color: var(--theme-color-neutrals-100);
                            width: 32px;
                            height: 32px;
                            padding: 0;
                            margin-bottom: 0;
                            @media (max-width: map-get($context--breakpoints, md-max)) {
                                width: 100%;
                            }
                            .c-dropdown__button-text{
                                position: absolute;
                                text-indent: -9999em;
                            }
                            .c-dropdown__button-toggle-icon{
                                font-size: var(--text-base-size);
                                width: 32px;
                                height: 32px;
                                @media (max-width: map-get($context--breakpoints, md-max)) {
                                    transform: rotate(90deg);
                                }
                            }
                            &:active {
                                background-color: var(--theme-color-neutrals-200);
                                color: var(--theme-color-neutrals-1000);
                            }
                            &:hover {
                                background-color: var(--theme-color-neutrals-1000);
                                color: var(--theme-color-neutrals-0);
                            }
                        }
                        .c-dropdown__content {
                            position: relative;
                            width: max-content;
                            margin: 0 auto;
                            @media (max-width: map-get($context--breakpoints, md-max)) {
                                position: relative;
                                width: 100%;
                            }
                            .c-dropdown__list-outer {
                                width: 182px;
                                right: 0;
                                left: inherit;
                                top: 36px;
                                @media (max-width: map-get($context--breakpoints, md-max)) {
                                    top: 44px;
                                    left: 0;
                                    width: 100%;
                                }
                            }
                        }
                    }
                    &.text-aligned--right {
                        .c-table-text-label--score{
                            &:first-child .u-icon{
                                padding-right: calc(var(--theme-global-spacing) * 2);
                            }
                        }
                        .c-table-text-label {
                            .u-icon{
                                padding-right: 0;
                            }
                            .u-icon{
                                &:empty{
                                    display: none;
                                }
                                &[class*="icon-"] {
                                    display: block;
                                    @media (max-width: map-get($context--breakpoints, md-max)) {
                                        margin-right:calc(var(--theme-global-spacing) * 1);
                                    }
                                }
                            }
                        }
                    }
                    &.c-table__row-column--check-box.c-table__header-coulmn--selection-check-list {
                        @media (max-width: map-get($context--breakpoints, md-max)) {
                            padding: calc(var(--theme-global-spacing) * 4);
                            margin-bottom: calc(var(--theme-global-spacing) * 2);
                        }
                    }
                }
                td:first-child {
                    border-left: 0;
                    padding: calc(var(--theme-global-spacing) * 5) calc(var(--theme-global-spacing) * 4);
                    @media (max-width: map-get($context--breakpoints, md-max)) {
                        padding: calc(var(--theme-global-spacing) * 1) calc(var(--theme-global-spacing) * 4);
                        margin-bottom: 0;
                    }
                }
                td:last-child {
                    border-right: 0;
                    @media (max-width: map-get($context--breakpoints, md-max)) {
                       padding-bottom: calc(var(--theme-global-spacing) * 4); 
                    }
                }
                .c-table-text-label {
                    @media (max-width: map-get($context--breakpoints, md-max)) {
                        @include font-style(var(--text-xxxs), var(--text-xxs));
                    }
                    @media (min-width: map-get($context--breakpoints, xxl)) {
                        @include font-style(var(--text-base-size), var(--text-xs));
                    }
                }
                .c-table-text-status {
                    @include font-style(var(--text-xxxs), var(--text-xxs));
                    font-weight: var(--font-weight-normal);
                    color: var(--theme-color-neutrals-600);
                    word-break: normal;
                    @media (max-width: map-get($context--breakpoints, md-max)) {
                        color: var(--theme-color-neutrals-1000);
                    }
                }
                .c-table-text-link {
                    .c-link {
                        padding: 0;
                        .u-icon {
                            font-size: var(--text-base-size);
                            &::before {
                                padding-right: calc(var(--theme-global-spacing) * 2);
                            }
                        }
                        .u-icon:not([class^=icon-]) {
                            display: none;
                        }
                    }
                    .c-link__text {
                        @include font-style(var(--text-xxxs), var(--text-xxs));
                    }
                }
                .c-table-text-label {
                    display: inline-flex;
                    align-items: center;
                    @media (max-width: map-get($context--breakpoints, md-max)) {
                        padding-top:calc(var(--theme-global-spacing) * 1); 
                    }
                    .u-icon {
                        @include font-style(var(--text-base-size), var(--text-xs));
                        padding-right: calc(var(--theme-global-spacing) * 2);
                    }
                    .u-icon.icon-check-circle{
                        color: var(--theme-success);
                    }
                    .c-table-text-label-img {
                        padding-right: calc(var(--theme-global-spacing) * 2);
                    }
                    &.c-table-text-label--score{
                        .c-table-text-label--score-icon {
                            display: flex;
                            .c-table-text-label-img {
                                padding-right: calc(var(--theme-global-spacing) * 1);
                            }
                        }
                        .c-table-text-label-data{
                            flex: 1;
                            margin-left: calc(var(--theme-global-spacing) * 2);
                            @media (max-width: map-get($context--breakpoints, md-max)) {
                                flex: none;
                                margin-left: calc(var(--theme-global-spacing) * 0);
                            }
                        }
                    }
                }
            }
            .c-input-group.c-input-group--check-box .c-input-group__label {
                text-indent: -9999px;
                font-size: 0;
                line-height: 0;
                height: 24px;
            }
        }
        .c-input-group .c-input-group__control--check:checked~.c-input-group__label {
            text-indent: inherit;
            font-size: 0;
            line-height: 0;
            height: 24px;
            &::after {
                @include font-style(var(--text-base-size), var(--text-xs));
            }
        }
        .c-table__row-column--check-box {
            @media (max-width: map-get($context--breakpoints, md-max)) {
                &::before {
                    border-bottom-color: var(--theme-color-neutrals-150);
                }
            }
            .c-input-group.c-input-group--check-box {
                margin-bottom: 0;
                width: 24px;
                height: 24px;
                font-size: 0;
                line-height: 0;
            }
            .c-table__row-column-title {
                display: none;
                @media (max-width: map-get($context--breakpoints, md-max)) {
                    display: block;
                    @include font-style(var(--text-base-size), var(--text-xs));
                    font-weight: var(--font-weight-bold);
                    color: var(--theme-color-neutrals-1000);
                    padding-top:calc(var(--theme-global-spacing) * 2);
                }
            }
        }
    }
}
.c-table .c-table__body .c-table__row:hover .c-table__row-column .c-dropdown .c-dropdown__button {
    background-color: transparent;
}
/*Pagination after table*/
body .c-table-pagination {
    .c-pagination {
        /*To avoid "!important! tag ID has been used*/
        border: none;
        padding: calc(var(--theme-global-spacing) * 4) 0;
    }
}
/*Select Result coming through test-harnes*/
.c-table-result {
    display: flex;
    align-items: center;
    background-color: var(--theme-color-neutrals-100);
    border-radius: var(--radius-200);
    padding: calc(var(--theme-global-spacing) * 4);
    margin-bottom: calc(var(--theme-global-spacing) * 4);
    @include font-style(var(--text-xxxs), var(--text-xxs));
    @media (max-width: map-get($context--breakpoints, md-max)) {
        padding: calc(var(--theme-global-spacing) * 3) calc(var(--theme-global-spacing) * 4);
    }
    .c-table-result__buttons {
        margin-left: auto;
        min-width: 320px;
        display: flex;
        justify-content: end;

        @media (max-width: map-get($context--breakpoints, md-max)) {
            min-width: inherit;
        }
        .u-button {
            margin-left: calc(var(--theme-global-spacing) * 2);
        }
    }
    .c-table-result__buttons--dropdown {
        position: fixed;
        left: -9999px;
        @media (max-width: map-get($context--breakpoints, md-max)) {
            position: relative;
            left: auto;

            .c-dropdown .c-dropdown__checkbox+.c-dropdown__button+.c-dropdown__list {
                left: inherit;
                right: 0;
            }
        }
        .c-dropdown .c-dropdown__button {
            background-color: var(--theme-color-neutrals-100);
            color: var(--theme-color-neutrals-1000);
            border-color: var(--theme-color-neutrals-100);
            margin-bottom: 0;
        }
        .c-dropdown .c-dropdown__list-outer{
            @media (max-width: map-get($context--breakpoints, md-max)) {
              min-width: 197px;
              left: auto !important;
              right:0;
            }
            .c-dropdown__list{
              @media (max-width: map-get($context--breakpoints, md-max)) {
                max-height: 318px;
                overflow-y: auto;
              }
            }
        }
    }
}
/*This style is used to hide the label which are added for the accessibility*/
.c-table__checkbox label,
.c-table__header-coulmn--label label,
.c-table__body .c-input-group__label {
    text-indent: -9999em;
}
.c-table__checkbox .c-dropdown .c-dropdown__button {
    text-indent: inherit;
}
.c-table__row td:nth-child(2) {
    .c-table-text-link,
    .c-table-text-label {
        text-align: left;
        @include font-style(var(--text-xxxs), var(--text-xxs));
        color: var(--theme-color-neutrals-600);
        .c-link__text {
            @include font-style(var(--text-xxxs), var(--text-xxs));
        }
    }
    .c-table-text-link {
        .c-link__text {
            @include font-style(var(--text-xxxs), var(--text-xxs));
        }
    }
}

/*Override the hover styles only for touch devices*/
@media (hover:none),
(hover:on-demand){
    .c-table-outer .c-table.c-table--bordered .c-table__body .c-table__row .c-table__row-column .c-dropdown .c-dropdown__button:hover{
        background-color: var(--theme-color-neutrals-100);
        color: var(--theme-color-neutrals-1000);
    }
}

/* fixed & scrolable table style css*/
@media (max-width: map-get($context--breakpoints, md-max)) {
    .c-table-outer.c-table-outer--scrolable .c-table.c-table--fixed-column {
        width: 100% !important; // to overide the dynamic width css of table for mobile
    }
    .c-table__row {
        .c-table__row-column--fixed-width-left{
            width: 100% !important; // to overide the dynamic width css for left fixed col in mobile
        }
        .c-table__row-column--fixed-width-right {
            width: 100% !important; // to overide the dynamic width css for right fixed col in mobile
        }
    }
    .c-table-outer.c-table-outer--scrolable{
        .c-tooltip {
            max-width: 260px;
            text-align: left;
            font-weight: var(--font-weight-bold);
        } 
    }
}
@media (min-width: map-get($context--breakpoints, md-max)) {
    .c-table-outer.c-table-outer--scrolable {
        overflow: auto;
        width: 100%;
        height: 100%;
        max-height: 350px;
        background-color:var(--theme-color-neutrals-0);
        .c-table.c-table--fixed-column {
            table-layout: fixed;
            border-collapse: separate !important; // for fixed column border to be sticky when scrolling
            border-spacing: 0;
            .c-table__header {
                top: 0;
                position: sticky;
                left: 0;
                z-index: 99;
                width: auto;
                tr{
                    th {
                        &:first-child{
                            border-left: 1px solid var(--theme-color-neutrals-200) !important; // for border color overiding non scrolling table coloumn
                        }
                        &:nth-child(2){
                            width: auto !important; // for scrolling column to adjust width for overiding non scrolling table coloumn
                        }
                    }
                    td {
                        &:nth-child(2){
                            width: auto;
                        }
                    }
                }
                .c-table__header-coulmn.c-table__header-coulmn--fixed-width-left {
                    position: sticky;
                    top: 0;
                    z-index: 9999;
                    left: 0;
                    box-shadow: 1px 0px 2px 0px rgba(0, 0, 0, 0.20);
                    border-right: 1px solid var(--theme-color-neutrals-300) !important;
                    & + .c-table__header-coulmn {
                        border-left: 0;
                    }
                    &:nth-child(2){
                        left: 56px;
                    }
                }
                .c-table__header-coulmn.c-table__header-coulmn--fixed-width-right {
                    position: sticky;
                    top: 0;
                    z-index: 9999;
                    right: 0;
                    box-shadow: -1px 0px 2px 0px rgba(0, 0, 0, 0.20);
                    border-left-color: var(--theme-color-neutrals-300);
                }
            }
            td {
                width: auto;
                border: 0;
                border-bottom: 1px solid var(--theme-color-neutrals-200);
                border-left: 1px solid var(--theme-color-neutrals-200);
            }
            th {
                width: auto;
                border: 0;
                border-bottom: 1px solid var(--theme-color-neutrals-200);
                border-left: 1px solid var(--theme-color-neutrals-200);
            }
            .c-table__body {
                .c-table__row {
                    .c-table__row-column--fixed-width-left {
                        background-color:var(--theme-color-neutrals-0);
                        & + .c-table__row-column {
                            border-left: 0;
                        }
                        &:nth-child(2){
                            left: 56px;
                        }
                    }
                    .c-table__row-column--fixed-width-right {
                        background-color:var(--theme-color-neutrals-0);   
                    }
                    .c-dropdown .c-dropdown__list-outer {
                        z-index: 9;
                     }
                &:last-child{
                    td{
                        border-bottom: 1px solid var(--theme-color-neutrals-200);
                     }
                }
                &:hover{
                    .c-table__row-column--fixed-width-right {
                        background-color: var(--theme-color-100);   
                    }
                    .c-table__row-column--fixed-width-left {
                        background-color: var(--theme-color-100);
                    }
                } 
                &.c-table__row--is-selected {
                    background-color: var(--theme-color-50);
                    .c-table__row-column--fixed-width-right {
                        background-color: var(--theme-color-50);   
                    }
                    .c-table__row-column--fixed-width-left {
                        background-color: var(--theme-color-50);
                    }
                }   
                }
            }
            .c-table__header-coulmn.c-table__header-coulmn--fixed-width-left {
                background-color: var(--theme-color-neutrals-50);
                box-shadow: 1px 0px 2px 0px rgba(0, 0, 0, 0.20);
                border-right: 1px solid var(--theme-color-neutrals-300);
                & + .c-table__header-coulmn {
                    border-left: 0;
                }
            }
            .c-table__header {
                .c-table__header-coulmn.c-table__header-coulmn--fixed-width-left {
                    background-color: var(--theme-color-neutrals-50);
                    border-right: 1px solid var(--theme-color-neutrals-300);
                    & + .c-table__header-coulmn {
                        border-left: 0;
                    }
                }
                .c-table__header-coulmn.c-table__header-coulmn--fixed-width-right {
                    background-color: var(--theme-color-neutrals-50);
                }
            }
            .c-table__row-column.c-table__row-column--fixed-width-left {
                position: sticky !important; // for sticky column to overide other column position
                left: 0;
                z-index: 1 !important;// to overide dynamic z-indexing on element
                width: auto;
                box-shadow: 1px 0px 2px 0px rgba(0, 0, 0, 0.20);
                border-right: 1px solid var(--theme-color-neutrals-300);
                & + .c-table__row-column {
                    border-left: 0;
                }
            }
            .c-table__header-coulmn.c-table__header-coulmn--fixed-width-right {
                background-color: var(--theme-color-neutrals-50);
                box-shadow: -1px 0px 2px 0px rgba(0, 0, 0, 0.20);
                border-left-color: var(--theme-color-neutrals-300);
            }
            .c-table__row-column.c-table__row-column--fixed-width-right {
                position: sticky !important; // for sticky column to overide other column position
                right: 0;
                z-index: 1;
                width: auto;
                box-shadow: -1px 0px 2px 0px rgba(0, 0, 0, 0.20);
                border-left-color: var(--theme-color-neutrals-300);
            }
        }
        .c-tooltip {
            z-index: 99999;
            max-width: 250px;
            text-align: left;
            font-weight: var(--font-weight-bold);
        }
    }
    .c-table-outer.c-table-outer--scrolable.c-table-outer--no-scrollbar{
        scrollbar-width: none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar { 
            width: 0;
            display: none; 
            visibility: hidden;
            background: transparent;
        }
    }
}
@media (max-width: map-get($context--breakpoints, md-max)) {
    .c-table-outer.c-table-outer--scrolable .c-table.c-table--fixed-column {
        width: 100% !important; // to overide the dynamic width css of table for mobile
    }
    .c-table__row {
        .c-table__row-column--fixed-width-left{
            width: 100% !important; // to overide the dynamic width css for left fixed col in mobile
        }
        .c-table__row-column--fixed-width-right {
            width: 100% !important; // to overide the dynamic width css for right fixed col in mobile
        }
    }
    .c-table-outer.c-table-outer--scrolable{
        .c-tooltip {
            max-width: 260px;
            text-align: left;
            font-weight: var(--font-weight-bold);
        } 
    }
}
.c-filter-toolbar .c-filter-toolbar__sort-filter-group .c-filter-toolbar__sort-filter-group-list .c-filter-toolbar__sort-filter-group-list-item .c-floating-panel {
    z-index: 999;
}
.table-score--alignment{
    .c-table-text-label--score{
        text-align: right;
    }
    .c-table-text-label--score-icon-data:not(:empty) {
        @media (max-width: map-get($context--breakpoints, md-max)) {
        margin-right: 16px;
        border-right: 1px solid var(--theme-color-neutrals-150);
        padding-right: 16px;
        border-color: --theme-color-neutrals-150; 
        } 
    }
}
// editable form elements in table start
.c-table-outer{
    .c-table-editable {
        &.c-table {
            .c-table__header {
                .c-table__header-row {
                    .c-table__header-coulmn {
                        &:first-child {
                            @media (max-width: map-get($context--breakpoints, md-max)) {
                                .c-input-group .c-input-group__control--check+.c-input-group__label {
                                    text-indent: initial;
                                    font-size: var(--text-base-size);
                                    line-height: var(--text-xs);
                                    .sr-only{
                                        position: inherit;
                                        margin: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .c-table__body{
                .c-table__row{
                    .c-table__row-column{
                        &:first-child{
                            padding: calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 4) !important; //to overiding other usecases css
                            @media (max-width: map-get($context--breakpoints, md-max)) {
                                padding: calc(var(--theme-global-spacing) * 4) !important; //to overiding other usecases css
                                margin-bottom: calc(var(--theme-global-spacing) * 2);
                            }
                        }
                        &.c-table__row-column--check-box.c-table__header-coulmn--selection-check-list{
                            @media (max-width: map-get($context--breakpoints, md-max)) {
                                padding-bottom: 0 !important; // overriding css of other usecase
                                margin-bottom: 0;
                            }
                            .c-table__row-column-title{
                               @media (max-width: map-get($context--breakpoints, md-max)) {
                                    display: none;
                                }
                            }
                            &::before{
                                content: none !important; // overriding css of other usecase
                            }
                        }
                        &:nth-child(2n){
                            @media (max-width: map-get($context--breakpoints, md-max)) {
                                padding-bottom: calc(var(--theme-global-spacing) * 4);
                                margin-bottom: calc(var(--theme-global-spacing) * 2);   
                            }
                            &::before{
                                @media (max-width: map-get($context--breakpoints, md-max)) {
                                    content: ""  !important; // overriding css of other usecase
                                    position: absolute;
                                    bottom: 0;  
                                    left: 16px;
                                    width: calc(100% - 32px) !important; // overriding css of other usecase
                                    border-bottom-style: solid;
                                    border-bottom-width: 1px;
                                    border-bottom-color: var(--theme-color-neutrals-150);
                                }
                            }
                            .c-table-text-link{
                                text-align: right;
                                @media (max-width: map-get($context--breakpoints, md-max)) {
                                    display: inline-block !important; // overriding css of other usecase
                                    .c-link__text{
                                        font-size: var(--text-base-size);
                                        line-height: var(--text-xs);
                                        padding-right:calc(var(--theme-global-spacing) * 1);
                                    }
                                }  
                            }
                            .c-table-text-link.c-table-text-link--value .c-link__text{
                                @media (max-width: map-get($context--breakpoints, md-max)) {
                                    padding-right: 0;
                                }
                            }
                        }
                        &.c-table__row-column-editable {
                            padding: 0 !important; // overriding css of other usecase
                            @media (max-width: map-get($context--breakpoints, md-max)) {
                                padding: calc(var(--theme-global-spacing) * 1) calc(var(--theme-global-spacing) * 4) !important; //to overiding other usecases css
                            }
                            .c-table__row-column-form {
                                &.c-table__row-column-form--numaricInput, &.c-table__row-column-form--formElement{
                                    .c-input-group {
                                        margin-bottom: calc(var(--theme-global-spacing) * 0);
                                        .c-input-group__label{
                                            display: none;
                                        }
                                        .c-input-group__control {
                                            padding: calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 4);
                                            border: 1px solid var(--theme-transparent-background);
                                            margin-bottom: 0;
                                            border-radius: 0;
                                            text-align: right;
                                            background: var(--theme-transparent-background);
                                            &:focus{
                                                outline: 0 !important; //to overiding other usecases css
                                                background-color: var(--theme-color-50);
                                                border: 1px solid var(--theme-color-50);
                                            }
                                            @media (max-width: map-get($context--breakpoints, md-max)) {
                                                margin-top: calc(var(--theme-global-spacing) * 1);
                                                border-radius: var(--radius); 
                                                border: 1px solid var(--theme-color-neutrals-500);
                                                padding: calc(var(--theme-global-spacing) * 3) calc(var(--theme-global-spacing) * 4);
                                                max-width: 108px;
                                                text-align: left;
                                            }
                                        }
                                        /* Firefox */
                                        input[type=number] {
                                            -moz-appearance: textfield;
                                        }
                                        .c-number-input {
                                            display: none;
                                        }
                                    }
                                    input::-webkit-outer-spin-button,input::-webkit-inner-spin-button {
                                                display: none;
                                            }
                                    @media (max-width: map-get($context--breakpoints, md-max)) {
                                        width: 108px;
                                        display: inline-block;
                                    }
                                }
                                &.c-table__row-column-modified{
                                    .c-input-group{
                                        .c-input-group__control{
                                        background-color: var(--theme-color-50);
                                        border: 1px solid var(--theme-color-50);
                                        color: var(--theme-color-neutrals-1000);
                                        }                                        
                                    }
                                }
                                &.has-error{
                                    .c-input-group{
                                        .c-input-group__control{
                                        background-color: var(--theme-error-light-100);
                                        border: 1px solid var(--theme-error);
                                        @media (max-width: map-get($context--breakpoints, md-max)) {
                                            background-color: var(--theme-color-neutrals-0);
                                        }
                                        }
                                    }
                                }                       
                            }
                            .c-table__row-column-form--maxscore-data{
                                display: none;
                                @media (max-width: map-get($context--breakpoints, md-max)) {
                                    display: inline-block;
                                    color: var(--theme-color-neutrals-600);
                                }
                            }
                        }
                        .c-table__row-column-form--textArea{
                            margin-bottom: calc(var(--theme-global-spacing) * 4) ;
                            .c-input-group__control--textarea{
                                min-width: 100%;
                                margin-bottom: calc(var(--theme-global-spacing) * 1) ;
                            }
                        }
                        &.c-table__row-column--maxscore{
                            color: var(--theme-color-neutrals-600);
                            @media (max-width: map-get($context--breakpoints, md-max)) {
                                display: none !important; //to overiding other scrollable table css
                                }
                        }
                        .c-table__row-column-form--maxscore-data{
                            .c-table__row-column-form--maxscore-data-text {
                                margin-left:calc(var(--theme-global-spacing) * 2);
                                margin-right: calc(var(--theme-global-spacing) * 1);
                            }
                        }
                    }
                }
            } 
        }
    }
}
.editable-table-result {
    .c-dropdown .c-dropdown__button .c-dropdown__button-toggle-icon {
        color:var(--theme-color-neutrals-400);
    }
}
// editable form elements in table End
/*Override all the hover styles only for touch devices*/
@media (hover:none),
(hover:on-demand) {
    .c-table-outer .c-table .c-table__body .c-table__row:hover,
    .c-table-outer.c-table-outer--scrolable .c-table.c-table--fixed-column .c-table__body .c-table__row:hover .c-table__row-column--fixed-width-right, /*Touch device tapping color disabled at first and last column of each row :: please do not delete as it is not exist in maced component*/
	.c-table-outer.c-table-outer--scrolable .c-table.c-table--fixed-column .c-table__body .c-table__row:hover .c-table__row-column--fixed-width-left {
        background-color: var(--theme-color-neutrals-0);
    }
    .c-table-outer .c-table .c-table__body .c-table__row.c-table__row--is-selected {
        background-color: var(--theme-color-50);
    }
}