.c-summary-bar-outer {
    .c-summary-bar {
        .c-accordion-outer{
            .c-accordion {
                padding: calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 6);
                margin-bottom: calc(var(--theme-global-spacing) * 4);
                border-radius: var(--radius-200);
                border-color: var(--theme-color-neutrals-150);
                background-color: var(--theme-color-neutrals-0);
                width: 100%;
                border-width: 1px;
                border-style: solid;
                @media (max-width: map-get($context--breakpoints, sm-max)) {
                    padding: calc(var(--theme-global-spacing) * 4);
                    height:100%;
                }
                .c-accordion__panel {
                    border-bottom-width: 0;
                    .c-accordion__header {
                        display: none !important; //used important to override accordion header css
                        @media (max-width: map-get($context--breakpoints, sm-max)) {
                            display: flex !important; //used important to override accordion header css
                            align-items: center !important; //used important to override accordion header css
                            padding: calc(var(--theme-global-spacing) * 0);
                            .c-accordion__title {
                                font-weight: var(--font-weight-bold);
                            }
                        }
                        .c-accordion__action-icon {
                            @media (max-width: map-get($context--breakpoints, sm-max)) {
                                color: var(--theme-color-neutrals-1000);
                                right: 0 !important; //used important to override accordion header icon css
                                font-size: var(--text-base-size);
                            }
                        }
                    }
                    .c-accordion__content {
                        overflow-y: visible !important; //used important to override accordion header icon css
                        padding: calc(var(--theme-global-spacing) * 0);
                    }
                }
            }
        }
        .c-summary-bar__content-outer{
            padding: calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 6);
            margin-bottom: calc(var(--theme-global-spacing) * 4);
            border-radius: var(--radius-200);
            border-color: var(--theme-color-neutrals-150);
            background-color: var(--theme-color-neutrals-0);
            width: 100%;
            border-width: 1px;
            border-style: solid;
            @media (max-width: map-get($context--breakpoints, sm-max)) {
                padding: calc(var(--theme-global-spacing) * 4);
                height:100%;
            }
        }
        .c-summary-bar__content{
            min-height: calc(var(--theme-global-spacing) * 6.7);
            display: flex;
            align-items: center;
            @media (max-width: map-get($context--breakpoints, sm-max)) {
                min-height: 100%;
                display: block;
            }
            @media (min-width: map-get($context--breakpoints, xxl)) {
                min-height: 26px;
            }
            .c-summary-bar__list {
                display: flex;
                flex-wrap: wrap;
                @media (max-width: map-get($context--breakpoints, sm-max)) {
                    display: block;
                    padding-top: calc(var(--theme-global-spacing) * 4);
                }
                .c-summary-bar__list-item {
                    word-break: normal;
                    display: inline-flex;
                    align-items: center;
                    font-size: var(--text-xxxs);
                    line-height: var(--text-xxs);
                    margin: calc(var(--theme-global-spacing) * 0.5) calc(var(--theme-global-spacing) * 6)  calc(var(--theme-global-spacing) * 0.5) 0;
                    padding-right: calc(var(--theme-global-spacing) * 6);
                    color: var(--theme-color-neutrals-1000);
                    position: relative;
                    border: 0;
                    border-right-width: 1px;
                    border-style: solid;
                    border-color: var(--theme-color-neutrals-150);
                    @media (max-width: map-get($context--breakpoints, sm-max)) {
                        display: block;
                        margin: calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 0);
                        padding-right: 0;
                        border-right-width: 0;
                    }
                    @media (min-width: map-get($context--breakpoints, xxl)) {
                        font-size: var(--text-base-size);
                        line-height: normal;
                    }
                    &:last-child {
                        border-right-width: 0;
                        @media (max-width: map-get($context--breakpoints, sm-max)) {
                            margin: calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 0);
                        }
                    }
                    .c-summary-bar__list-item-label {
                        color: var(--theme-color-neutrals-600);
                        padding-right: calc(var(--theme-global-spacing) * 1);
                        @media (max-width: map-get($context--breakpoints, sm-max)) {
                            display: block;
                            margin-bottom: calc(var(--theme-global-spacing) * 1);
                            padding-right: calc(var(--theme-global-spacing) * 0);
                        }
                    }
                    .c-summary-bar__list-item-value{
                        display: flex;
                        align-items: center;
                    }
                    .c-summary-bar__list-item-icon {
                        font-size: var(--text-base-size);
                        line-height: var(--text-base-size);
                        margin-left: calc(var(--theme-global-spacing) * 1);
                        color: var(--theme-color-500);
                        vertical-align: middle;
                    }
                    .icon-info-circle:before {
                        content: "\e952";
                    }
                    .c-link {
                        font-size: var(--text-xxxs);
                        line-height: var(--text-xxs);
                        font-weight: var(--font-weight-bold);
                        padding: calc(var(--theme-global-spacing) * 0);
                        @media (min-width: map-get($context--breakpoints, xxl)) {
                            font-size: var(--text-base-size);
                        }
                        .u-icon {
                            font-size: var(--text-xxxs);
                            margin-left: calc(var(--theme-global-spacing) * 2);
                            font-weight: var(--font-weight-bold);
                        }
                    }
                    .c-summary-bar__list-item-toggle {
                        position: absolute;
                        @media (max-width: map-get($context--breakpoints, sm-max)) {
                            position: relative;
                            -webkit-tap-highlight-color: transparent;
                        }
                        .c-toggle {
                            padding-left: calc(var(--theme-global-spacing) * 0);
                            .c-toggle__label {
                                display: flex;
                                .c-toggle__button {
                                    margin-left: calc(var(--theme-global-spacing) * 0);
                                    border-radius: calc(var(--radius)* 3.5);
                                    @media (min-width: map-get($context--breakpoints, xxl)) {
                                        border-radius: calc(var(--radius)* 8);
                                    }
                                    .c-toggle__button-label--on {
                                        margin-top: calc(var(--theme-global-spacing) * -2.5);
                                        left: calc(var(--theme-global-spacing) * 13.7);
                                        min-width: 70px;
                                        @media (min-width: map-get($context--breakpoints, xxl)) {
                                            margin-top: calc(var(--theme-global-spacing) * -3);
                                            font-size: var(--text-base-size);
                                            left: calc(var(--theme-global-spacing) * 16.75);
                                        }
                                        .u-icon {
                                            font-size: var(--text-xxxs);
                                            margin-left: calc(var(--theme-global-spacing) * 2);
                                            font-weight: var(--font-weight-bold);
                                        }
                                    }
                                    .c-summary-bar__list-item-toggle {
                                        position: absolute;
                                        @media (max-width: map-get($context--breakpoints, sm-max)) {
                                            position: relative;
                                        }
                                        .c-toggle {
                                            padding-left: calc(var(--theme-global-spacing) * 0);
                                            .c-toggle__label {
                                                display: flex;
                                                .c-toggle__button {
                                                    margin-left: calc(var(--theme-global-spacing) * 0);
                                                    .c-toggle__button-label--on {
                                                        margin-top: calc(var(--theme-global-spacing) * -2.5);
                                                        left: calc(var(--theme-global-spacing) * 13.7);
                                                        min-width: 70px;
                                                        @media (min-width: map-get($context--breakpoints, xxl)) {
                                                            left: calc(var(--theme-global-spacing) * 16);
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        
                    }
                }
            }
        }
    }
}