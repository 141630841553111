.c-modal-header{
    background-color: var(--theme-color-neutrals-0);
    padding: calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 8);
    &:before {
        background-color: var(--theme-color-500);
    }
    .c-heading-sub-title {
        @include context--font-size-h4;
        font-weight: var(--font-weight-normal);
    }
    @media (max-width: map-get($context--breakpoints, lg)) {
        padding: calc(var(--theme-global-spacing) * 5) calc(var(--theme-global-spacing) * 8);
    }
    @media (max-width: map-get($context--breakpoints, sm-max)) {
        .u-button--back.u-button--link.u-button{
            margin-right: calc(var(--theme-global-spacing) * 2);
        }
    }
}
.c-modal__buttons-group .u-button{
    margin-left: calc(var(--theme-global-spacing) * 4);
    min-width: auto;
}