.c-grading-card {
    position: relative;
    .c-card__body {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        border-color: var(--theme-color-neutrals-150);
        @media (max-width: map-get($context--breakpoints, sm-max)) {
            padding: calc(var(--theme-global-spacing) * 4);
        }
        .c-grading-card__header {
            display: flex;
            align-items: start;
            position: relative;
            flex: 1;
            padding: calc(var(--theme-global-spacing) * 6) calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 6) calc(var(--theme-global-spacing) * 6);
            @media (max-width: map-get($context--breakpoints, sm-max)) {
                padding: 0;
            }
            .c-grading-card__header-block-tag {
                padding-bottom: calc(var(--theme-global-spacing) * 4);
                .c-chip {
                    border: none;
                    height: 100%;
                    padding: calc(var(--theme-global-spacing) * 0.5) calc(var(--theme-global-spacing) * 2);
                    .c-chip__status {
                        display: none;
                    }
                    &.c-chip--error {
                        background-color: var(--theme-color-orange-50);
                    }
                    &.c-chip--normal {
                        background-color: var(--theme-color-purple-50);
                    }
                    &.c-chip--active {
                        background-color: var(--theme-color-magenta-50);
                    }
                    .c-chip__input-element {
                        display: none;
                    }
                }
            }
            .c-grading-card__header-block-heading {
                .c-grading-card__header-block-heading-title {
                    @include font-style(var(--text-base-size), var(--text-xs));
                    font-weight: var(--font-weight-bold);
                    padding-right: calc(var(--theme-global-spacing) * 4);
                    display: inline-block;
                    word-break: break-word;
                    @media (max-width: map-get($context--breakpoints, sm-max)) {
                        display: block;
                        padding-right: 0;
                    }
                }
                .c-grading-card__header-block-heading-date {
                    @include font-style(var(--text-xxxs), var(--text-xxs));
                    font-weight: var(--font-weight-normal);
                    display: inline-block;
                    @media (max-width: map-get($context--breakpoints, sm-max)) {
                        margin-top: calc(var(--theme-global-spacing) * 1);
                    }
                    @media (min-width: map-get($context--breakpoints, xxl)) {
                        @include font-style(var(--text-base-size), var(--text-xs));
                    }
                    .c-grading-card__header-block-heading-date-label {
                        color: var(--theme-color-neutrals-600);
                        margin-right: calc(var(--theme-global-spacing) * 1);
                    }
                }
                .c-grading-card__header-block-heading-sub-text{
                    display: block;
                }
            }
            /*List*/
            .c-grading-card__header-block-metadata {
                margin-top: calc(var(--theme-global-spacing) * 4);
                .c-accordion{
                    .c-accordion__panel {
                        border-bottom-width: 0;
                        .c-accordion__header{
                            display: none;
                            padding: 0;
                            @media (max-width: map-get($context--breakpoints, sm-max)) {
                                display: flex;
                                padding: 0;
                            }
                            .c-accordion__title .c-accordion__title-text {
                                padding-left: calc(var(--theme-global-spacing) * 6);
                            }
                            .c-accordion__action-icon {
                                left: calc(var(--theme-global-spacing) * 0);
                                top: calc(var(--theme-global-spacing) * 1);
                                font-size: var(--text-base-size);
                            }
                        }
                        .c-accordion__content{
                            padding: 0;
                        }
                    }
                    .c-accordion__panel[open]{
                        .c-accordion__header{
                            @media (max-width: map-get($context--breakpoints, sm-max)) {
                                padding: 0 0 calc(var(--theme-global-spacing) * 2) 0;
                            }
                        }
                    }
                }
                .c-grading-card__header-block-metadata-list {
                    padding: calc(var(--theme-global-spacing) * 0);
                    margin: calc(var(--theme-global-spacing) * 0);
                    .c-grading-card__header-block-metadata-list-item {
                        font-size: var(--text-xxxs);
                        line-height: var(--text-xxs);
                        font-weight: var(--font-weight-normal);
                        color: var(--theme-color-neutrals-1000);
                        display: inline-block;
                        padding: calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 0);
                        margin-right: calc(var(--theme-global-spacing) * 4);
                        border-right: 1px solid var(--theme-color-neutrals-150);
                        @media (max-width: map-get($context--breakpoints, sm-max)) {
                            margin: 0 0 calc(var(--theme-global-spacing) * 2) 0;
                            border-right: 0;
                            padding: 0;
                            width: 100%;
                        }
                        @media (min-width: map-get($context--breakpoints, xxl)) {
                            @include font-style(var(--text-base-size), var(--text-xs));
                        }
                        &:last-child {
                            border: none;
                            @media (max-width: map-get($context--breakpoints, sm-max)) {
                                margin-bottom: 0;
                            }
                        }
                        .c-grading-card__header-block-metadata-list-item-label {
                            color: var(--theme-color-neutrals-600);
                            margin-right: calc(var(--theme-global-spacing) * 1);
                            @media (max-width: map-get($context--breakpoints, sm-max)) {
                                display: block;
                                margin: 0 0 calc(var(--theme-global-spacing) * 1) 0;
                            }
                        }
                        .c-grading-card__header-block-metadata-list-item-icon{
                            font-size: var(--text-base-size);
                            line-height: var(--text-xs);
                            color: var(--theme-color-500);
                            position: relative;
                            margin-left: calc(var(--theme-global-spacing) * 1);
                            top: calc(var(--theme-global-spacing) * 0.5);
                        }
                        .icon-clipboard-check:before {
                            content: "\e92a";
                            margin-left: calc(var(--theme-global-spacing) * 0.75);
                        }
                    }
                } 
            }
        }
        /*Body Content*/
        .c-grading-card__body {
            padding: calc(var(--theme-global-spacing) * 6);
            border-top-width: 1px;
            border-top-style: solid;
            border-color: var(--theme-color-neutrals-150);
            width: 100%;
            @media (max-width: map-get($context--breakpoints, sm-max)) {
                padding: calc(var(--theme-global-spacing) * 4) 0 0 0;
                border-top-width: 0;
            }
            .c-accordion .c-accordion__panel {
                border: none;
            }
            .c-accordion.c-accordion--modified {
                .c-accordion__header {
                    padding: 0;
                    .c-accordion__title {
                        .c-accordion__title-text{
                            padding-left: calc(var(--theme-global-spacing) * 6);
                            color: var(--theme-color-500);
                            @media (max-width: map-get($context--breakpoints, sm-max)) {
                                color: var(--theme-color-1000);
                            }
                        }  
                    }
                }
                .c-accordion__content {
                    padding: calc(var(--theme-global-spacing) * 6) 0 0 0;
                    @media (max-width: map-get($context--breakpoints, sm-max)) {
                        padding: calc(var(--theme-global-spacing) * 4) 0 0 0;
                    }
                    .c-grading-card__attempt-cards-list {
                        .c-grading-card__attempt-cards-list-item{
                            margin-bottom: calc(var(--theme-global-spacing) * 2);
                            .c-card__body{
                                padding: calc(var(--theme-global-spacing) * 4);
                                .c-grading-card__attempt-block{
                                   flex: 1;
                                   align-items: center;
                                   display: flex;
                                   .c-grading-card__attempt-block-list {
                                        display: flex;
                                        flex-wrap: wrap;
                                        border-color: var(--theme-color-neutrals-150);
                                        line-height: calc(var(--theme-global-spacing) * 4);
                                        @media (max-width: map-get($context--breakpoints, sm-max)) {
                                            line-height: normal;
                                        }
                                        .c-grading-card__attempt-block-list-item {
                                            padding: calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 0);
                                            margin: calc(var(--theme-global-spacing) * 1) calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 1) calc(var(--theme-global-spacing) * 0);
                                            border-right-width: 1px;
                                            border-color: var(--theme-color-neutrals-150);
                                            border-right-style: solid;
                                            @media (max-width: map-get($context--breakpoints, sm-max)) {
                                                margin: 0 0 calc(var(--theme-global-spacing) * 4) 0;
                                                border-right-width: 0;
                                                padding: 0;
                                                width: 100%;
                                            }
                                            &:last-child{
                                                border-right-width: 0;
                                                @media (max-width: map-get($context--breakpoints, sm-max)) {
                                                    margin: 0;
                                                }
                                            }
                                            .c-grading-card__attempt-block-list-item-label {
                                                color: var(--theme-color-neutrals-600);
                                                margin-right: calc(var(--theme-global-spacing) * 1);
                                                @media (max-width: map-get($context--breakpoints, sm-max)) {
                                                    display: block;
                                                    margin: 0 0 calc(var(--theme-global-spacing) * 1) 0;
                                                }
                                            }
                                        }
                                    }
                                }
                                .c-grading-card__attempt-cards-list-item-buttons {
                                    @media (max-width: map-get($context--breakpoints, sm-max)) {
                                        width: 100%;
                                        .u-button{
                                            width: 100%;
                                            justify-content: center;
                                            margin: calc(var(--theme-global-spacing) * 5) 0 0 0;
                                        }
                                    }
                                }
                            }
                            &:last-child{
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
            .c-accordion.c-accordion--title-left-icon .c-accordion__action-icon {
                left: calc(var(--theme-global-spacing) * 0);
                top: calc(var(--theme-global-spacing) * 1);
                font-size: var(--text-base-size);
                color: var(--theme-color-500);
                @media (max-width: map-get($context--breakpoints, sm-max)) {
                    color: var(--theme-color-1000);
                }
            }
        }
        /*Button Group*/
        .c-grading-card__button-group {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: calc(var(--theme-global-spacing) * 6) calc(var(--theme-global-spacing) * 6) calc(var(--theme-global-spacing) * 6) calc(var(--theme-global-spacing) * 0);
            @media (max-width: map-get($context--breakpoints, sm-max)) {
                padding: calc(var(--theme-global-spacing) * 4) 0 0 0;
                width: 100%;
            }
            .c-grading-card__button-group-content {
                display: flex;
                justify-content: flex-end;
                @media (max-width: map-get($context--breakpoints, sm-max)) {
                    display: block;
                    .u-button{
                        width: 100%;
                        justify-content: center;
                    }
                }
                .c-dropdown .c-dropdown__content {
                    .c-dropdown__button {
                        margin: 0 0 0 calc(var(--theme-global-spacing) * 4);
                        padding: calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 4);
                        font-weight: var(--font-weight-bold);
                        background-color: var(--theme-color-neutrals-100);
                        border-color: var(--theme-transparent-background);
                        height: calc(var(--theme-global-spacing) * 10);
                        width: auto;
                        &:hover{
                            background-color: var(--theme-color-neutrals-1000);
                            color: var(--theme-color-neutrals-0);
                            border-color: var(--theme-color-neutrals-1000);
                        }
                        @media (max-width: map-get($context--breakpoints, sm-max)) {
                            margin: calc(var(--theme-global-spacing) * 4) 0 0 0;
                            width: 100%;
                            display: block;
                            text-align: center;
                        }
                        @media (min-width: map-get($context--breakpoints, xxl)) {
                            padding: calc(var(--theme-global-spacing) * 2.5) calc(var(--theme-global-spacing) * 4);
                            height: calc(var(--theme-global-spacing) * 12);
                        }
                        .c-dropdown__button-text{
                            margin-right: calc(var(--theme-global-spacing) * 2);
                            @media (max-width: map-get($context--breakpoints, sm-max)) {
                                text-align: center;
                                display: inline;
                            }
                        }
                        .c-dropdown__button-toggle-icon {
                            transform: rotate(0deg);
                            @media (max-width: map-get($context--breakpoints, sm-max)) {
                                display: inline-block;
                            }
                        }
                    }
                    .c-dropdown__list-outer{
                        top: calc(100% + 4px);
                        @media (max-width: map-get($context--breakpoints, sm-max)) {
                            max-width: 100%;
                        }
                    }
                    .c-dropdown__checkbox:checked + .c-dropdown__button .c-dropdown__button-toggle-icon{
                        transform: rotate(0deg);
                    }
                }
            }
            .c-grading-card__button-group-grading-count{
                display: flex;
                align-items: center;
                justify-content: flex-end;
                font-style: normal;
                font-weight: 600;
                margin-top: calc(var(--theme-global-spacing) * 4);
                @media (max-width: map-get($context--breakpoints, sm-max)) {
                    justify-content: flex-start;
                }
                .c-grading-card__button-group-grading-count-icon{
                    font-size: var(--text-base-size);
                    line-height: var(--text-base-size);
                    margin-right: calc(var(--theme-global-spacing) * 1);
                    vertical-align: middle;
                    &.c-grading-count--primary {
                        color: var(--theme-color-500);  
                    }
                    &.c-grading-count--success {
                        color: var(--theme-color-green-500);  
                    }
                }
            }
            &.c-grading-card__button-group--is-mobile{
                display: none;
                @media (max-width: map-get($context--breakpoints, sm-max)) {
                    display: block;
                }
            }
            &.c-grading-card__button-group--is-desktop{
                @media (max-width: map-get($context--breakpoints, sm-max)) {
                    display: none;
                }
            }
            
        }
    }
    .icon-info-circle:before {
        content: "\e952";
    }
    .icon-check-circle:before {
        content: "\e988";
    } 
    .c-grading-card__header-block-heading-date + .c-grading-card__header-block-heading-sub-text {
        @media (max-width: map-get($context--breakpoints, sm-max)) {
            margin-top: calc(var(--theme-global-spacing) * 1);
        }  
    }
}
/*Override all the hover styles only for touch devices*/
@media (hover:none),
(hover:on-demand) {
    .c-grading-card .c-card__body .c-grading-card__button-group .c-grading-card__button-group-content .c-dropdown .c-dropdown__content .c-dropdown__button:hover{
        background-color: var(--theme-color-neutrals-100);
        border-color: var(--theme-transparent-background);
        color: var(--theme-color-neutrals-1000);
    }
}