// indicator themes
@mixin indicator-not-started-yet {
	@if variable-exists('indicator-not-started-yet') {
		@include indicator-theme($indicator-not-started-yet);
	}
}
@mixin indicator-not-completed {
	@if variable-exists('indicator-not-completed') {
		@include indicator-theme($indicator-not-completed);
	}
}
@mixin indicator-awaiting-grading {
	@if variable-exists('indicator-awaiting-grading') {
		@include indicator-theme($indicator-awaiting-grading);
	}
}
@mixin indicator-complete {
	@if variable-exists('indicator-complete') {
		@include indicator-theme($indicator-complete);
	}
}
// Add indicator theme
@mixin indicator-theme($theme) {
	background-color: map-get($theme, 'backgroundColor');
	border-color: map-get($theme, 'borderColor');
}