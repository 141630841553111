.c-course-level-content-card {
    .c-course-level-content-card__book{
        padding: calc(var(--theme-global-spacing) * 6);
        border-color: var(--theme-color-neutrals-200);
        background-color: var(--theme-color-neutrals-0);
        font-family: var(--font-primary);
    }
    .c-course-level-card__aside {
        margin-right: calc(var(--theme-global-spacing) * 6);
        @media (max-width: map-get($context--breakpoints, sm-max)) {
             padding: calc(var(--theme-global-spacing) * 6);
             border-bottom: 1px solid var(--theme-color-neutrals-200);
        }
    }
    .u-divider{
        margin: calc(var(--theme-global-spacing) * 5) 0;
    }

    .c-course-level-card__list {
        @include font-style(var(--text-xxxs), var(--text-xxs));
        font-weight: var(--font-weight-bold);
        margin-top: calc(var(--theme-global-spacing) * 0);

        dt {
            padding-right: calc(var(--theme-global-spacing) * 1);
            padding-left: calc(var(--theme-global-spacing) * 6);

            @media (max-width: map-get($context--breakpoints, sm-max)) {
                padding-bottom: calc(var(--theme-global-spacing) * 3);
            }
        }
    }
    .c-course-level-content-card__similar{
        margin: calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * -2);
    }
    .c-course-level-content-card__similar-book {
        margin: 0 calc(var(--theme-global-spacing) * 2);
        @media (min-width: map-get($context--breakpoints, md)) {
            margin-bottom: calc(var(--theme-global-spacing) * 4);
        }
        .c-card--is-course-level {
            .c-card__title {
                @include font-style(var(--text-base-size), var(--text-xs));
                font-weight: var(--font-weight-bold);
                padding-bottom: calc(var(--theme-global-spacing) * 0);
            }
        }

        .c-card.c-card--is-course-level .c-card__header {
            padding: calc(var(--theme-global-spacing) * 6);
        }
        @media (max-width: map-get($context--breakpoints, md-max)) {
            .c-card.c-card--is-course-level .c-card__header {
                padding: calc(var(--theme-global-spacing) * 6);
            }
        }

        @media (max-width: map-get($context--breakpoints, sm-max)) {
            margin: calc(var(--theme-global-spacing) * 0);
            margin-bottom: calc(var(--theme-global-spacing) * 4);
        }
    }
    .c-course-level-card__body {
        @include font-style(var( --text-xxxs), var( --text-xxs));
        font-weight: var(--font-weight-normal);
        /*To override the default p tag style using "p"*/
        .c-course-level-card__body-content{
            @include font-style(var( --text-xxxs), var( --text-xxs));
            font-weight: var(--font-weight-normal);
            margin-top: 0;
            margin-bottom: 0;
        }
    }
    .c-course-level-card__header{
        margin-bottom: calc(var(--theme-global-spacing) * 2);
    }
    @media (max-width: map-get($context--breakpoints, sm-max)) {
        .c-course-level-card__header {
            padding: calc(var(--theme-global-spacing) * 6) calc(var(--theme-global-spacing) * 6) 0;
        }

        .c-course-level-card__body {
            padding: 0 calc(var(--theme-global-spacing) * 6) calc(var(--theme-global-spacing) * 6);
        }

        .c-course-level-content-card__similar {
            padding:calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 2);
        }
    }
}

.c-course-level-card__header .c-course-level-card__title{
    @include font-style(var(--text-xxs), var(--text-md));
    font-weight: var(--font-weight-bold);
    margin: calc(var(--theme-global-spacing) * 0);
  }
  .c-course-level-card__header .c-course-level-card__subtitle{
    @include font-style(var(--text-base-size), var(--text-xs));
    font-weight: var(--font-weight-bold);
    margin: calc(var(--theme-global-spacing) * 0);
  }

  /*Course Level Card for Builder*/
.c-course-card {
    /*Course Book*/
    .c-course-card__header {
        max-width: 100%;
        display: flex;
        padding: 0;
        @media (max-width: map-get($context--breakpoints, sm-max)) {
            display: block;
        }
        .c-course-card__header-left {
            width: 180px;
            height: 180px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid var(--theme-color-neutrals-200);
            border-radius: var(--radius-200);
            margin-right: calc(var(--theme-global-spacing)* 5);
            @media (max-width: map-get($context--breakpoints, sm-max)) {
                margin-right: 0;
                width: 100%;
            }
            .c-course-card__header-left-image {
                margin: 0;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                .c-course-card__header-left-image-placeholder {
                    max-height: 100%;
                }
            }
        }
        .c-course-card__header-right {
            display: flex;
            flex-direction: column;
            width: calc(100% - 180px);
            padding: calc(var(--theme-global-spacing)* 7) 0 0 calc(var(--theme-global-spacing)* 6);
            @media (max-width: map-get($context--breakpoints, sm-max)) {
                width: 100%;
                padding: 0;
            }
            .c-course-card__header-right-top {
                padding: 0;
                .c-course-card__header-right__title {
                    @include font-style( var(--text-sm), var(--text-lg));
                    margin: 0 0 calc(var(--theme-global-spacing)* 2);
                    @media (max-width: map-get($context--breakpoints, sm-max)) {
                        margin:calc(var(--theme-global-spacing)* 2) 0;
                    }
                }
                .c-course-card__header-right__subtitle {
                    @include font-style( var(--text-xxxs), var(--text-xxs));
                    font-weight: var(--font-weight-normal);
                    color: var(--theme-color-neutrals-600);
                    margin:calc(var(--theme-global-spacing)* 2) 0;
                }
            }
            .c-course-card__header-right-content {
                display: flex;
                flex-wrap: wrap;
                .c-course-card__header-right-content-details {
                    position: relative;
                    @include font-style( var(--text-xxxs), var(--text-xxs));
                    padding-right: calc(var(--theme-global-spacing)* 4);
                    margin: 0 calc(var(--theme-global-spacing)* 4) 0 0;
                    &:before {
                        content: '';
                        position: absolute;
                        right: 0;
                        top: 2px;
                        width: 1px;
                        height: 16px;
                        background-color: var(--theme-color-neutrals-150);
                    }
                    &:last-child {
                        &::before {
                            display: none;
                        }
                    }
                    .c-course-card__header-right-content-details-label {
                        color: var(--theme-color-neutrals-600);
                        padding-right: calc(var(--theme-global-spacing)* 1);
                    }
                    .c-course-card__header-right-content-details-input {
                        color: var(--theme-color-neutrals-1000);
                    }
                }
            }

        }
    }
    /*Course List*/
    .c-course-level-content-card__similar {
        margin: calc(var(--theme-global-spacing) * 5.5) 0 0 0;
        @media (max-width: map-get($context--breakpoints, sm-max)) {
            padding: 0;
        }
        .c-card__body {
            padding: calc(var(--theme-global-spacing) * 6) calc(var(--theme-global-spacing) * 6) calc(var(--theme-global-spacing) * 1);
            .u-button.u-button--back.u-button--link.u-button-icon-left.c-course-card__content-back-button {
                padding: 0;
                margin-bottom: calc(var(--theme-global-spacing) * 6);
                color: var(--theme-color-500);
                .u-icon.icon-chevron-left {
                    font-size: var(--text-base-size);
                }
                .u-button-text {
                    position: relative;
                    display: block;
                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 19px;
                        height: 1px;
                        width: 100%;
                        background-color:var(--theme-color-500);
                    }
                }
                &:hover .u-button-text {
                    color: var(--theme-color-neutrals-1000);
                    &::before {
                        background-color:var(--theme-color-neutrals-1000);
                    }
                }
            }
            .c-course-card__content-list {
                display: block;
                margin: calc(var(--theme-global-spacing)* 0) calc(var(--theme-global-spacing)* -2);
                @media (max-width: map-get($context--breakpoints, sm-max)) {
                    margin: 0;
                }
                .c-course-card__content-list-item {
                    width: calc(25% - 20px);
                    display: inline-block;
                    margin: 0 calc(var(--theme-global-spacing) * 2.5) calc(var(--theme-global-spacing) * 5);
                    @media (min-width: map-get($context--breakpoints, xl)) {
                        width: calc(20% - 20px);
                    }
                    @media (max-width: map-get($context--breakpoints, sm-max)) {
                        display: block;
                        width: 100%;
                        margin:0 0 calc(var(--theme-global-spacing)* 4);
                    }
                    .c-card {
                        display: flex;
                        flex-direction: column;
                        min-width: 0;
                        width: 100%;
                        padding: 0;
                        position: relative;
                        border: 1px solid var(--theme-color-neutrals-200);
                        border-radius: var(--radius-200);
                        height: 100%;
                        .c-card__body {
                            padding: 0;
                            .c-course-card__content-list-item-header {
                                position: relative;
                                .c-course-card__content-list-item-header-image {
                                    height: 165px;
                                    border-bottom: 1px solid var(--theme-color-neutrals-200);
                                    margin: 0;
                                    display: flex;
                                    align-items: end;
                                    justify-content: center;
                                    padding-bottom: calc(var(--theme-global-spacing)* 4);
                                }
                                .c-course-card__content-list-item-header-tag {
                                    position: absolute;
                                    right: 16px;
                                    top: 16px;
                                    .c-chip.c-chip--small {
                                        height: 24px;
                                        padding: calc(var(--theme-global-spacing) * 1) calc(var(--theme-global-spacing) * 2);
                                    }
                                    .c-chip.c-chip--is-disabled {
                                        background-color: var(--theme-color-neutrals-50);
                                        border-color: var(--theme-color-neutrals-500);
                                        padding: calc(var(--theme-global-spacing) * 1) calc(var(--theme-global-spacing) * 2);
                                        .c-chip__status-badge {
                                            background-color: var(--theme-color-neutrals-500);
                                        }
                                        .c-chip__text {
                                            color: var(--theme-color-neutrals-1000);
                                        }
                                    }
                                }
                            }
                            .c-course-card__content-list-item-details {
                                .c-course-card__content-list-item-title {
                                    @include font-style(var(--text-base-size), var(--text-xs));
                                    color: var(--theme-color-neutrals-1000);
                                    font-weight: var(--font-weight-bold);
                                    padding: calc(var(--theme-global-spacing) * 4) 0 0 calc(var(--theme-global-spacing) * 7.5);
                                    margin: 0;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    width: 100%;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 1;
                                    -webkit-box-orient: vertical;
                                }
                                .c-accordion.c-accordion--modified .c-accordion__header .c-accordion__title-text {
                                    color: var(--theme-color-500);
                                    font-size: var(--text-base-size);
                                    top: 20px;
                                    right: 4px;
                                    padding-left: calc(var(--theme-global-spacing) * 6);
                                    .c-accordion__expand-label,
                                    .c-accordion__collapse-label{
                                        position: relative;
                                        &::before {
                                            content: '';
                                            position: absolute;
                                            left: 0;
                                            top: 19px;
                                            height: 1px;
                                            width: 100%;
                                            background-color:var(--theme-color-500);
                                        }
                                    }
                                }
                                .c-accordion .c-accordion__panel .c-accordion__action-icon {
                                    color: var(--theme-color-500);
                                    font-size: var(--text-base-size);
                                    top: 20px;
                                    right: 4px;
                                }
                            }
                            .c-course-card__content-list-item-footer {
                                padding: calc(var(--theme-global-spacing) * 4);
                                .u-button {
                                    width: 100%;
                                    justify-content: center;
                                }
                            }
                        }
    
                    }
                }
            }
        }
    }
}