// Default chip
@mixin chip-default {
	@include chip($chip-default);
}
// chip themes
@mixin chip-primary {
	@if variable-exists('chip-primary') {
		@include chip-theme($chip-primary);
	}
}
@mixin chip-success {
	@if variable-exists('chip-success') {
		@include chip-theme($chip-success);
	}
}
@mixin chip-warning {
	@if variable-exists('chip-warning') {
		@include chip-theme($chip-warning);
	}
}
@mixin chip-error {
	@if variable-exists('chip-error') {
		@include chip-theme($chip-error);
	}
}
@mixin chip-normal {
	@if variable-exists('chip-normal') {
		@include chip-theme($chip-normal);
	}
}
@mixin chip-active {
	@if variable-exists('chip-active') {
		@include chip-theme($chip-active);
	}
}
@mixin chip-disabled {
	@if variable-exists('chip-disabled') {
		@include chip-theme($chip-normal);
	}
}
// Base chip styles
@mixin chip ($theme) {
	@include chip-theme($theme);
}
// Add chip theme
@mixin chip-theme($theme) {
	border-color: map-get($theme, 'borderColor');
	background-color: map-get($theme, 'backgroundColor');
	color: map-get($theme, 'color');
}