//Spinner
.c-spinner {
	background-color: var(--theme-transparent-background);
	margin: calc(var(--theme-global-spacing) * 0) auto;
	.c-spinner__block {
		width:calc(var(--theme-global-spacing) * 4);
		height:calc(var(--theme-global-spacing) * 4);
		border-radius: var(--radius-800);
		background: conic-gradient(var(--theme-color-neutrals-0) 20%, var(--theme-color-500) 80%);
		-webkit-mask: radial-gradient(farthest-side, var(--theme-transparent-background) calc(100% - 2px), var(--theme-color-neutrals-1000) 0);
		&.c-spinner__block--large {
			width:calc(var(--theme-global-spacing) * 16);
			height:calc(var(--theme-global-spacing) * 16);
			-webkit-mask: radial-gradient(farthest-side, var(--theme-transparent-background) calc(100% - calc(var(--theme-global-spacing) * 2)), var(--theme-color-neutrals-1000) 0);
		}
		&.c-spinner__block--medium {
			width:calc(var(--theme-global-spacing) * 8);
			height:calc(var(--theme-global-spacing) * 8);
			-webkit-mask: radial-gradient(farthest-side, var(--theme-transparent-background) calc(100% - calc(var(--theme-global-spacing) * 1)), var(--theme-color-neutrals-1000) 0);
		}
		&.c-spinner__block--small {
			width:calc(var(--theme-global-spacing) * 6);
			height:calc(var(--theme-global-spacing) * 6);
			-webkit-mask: radial-gradient(farthest-side, var(--theme-transparent-background) calc(100% - calc(var(--theme-global-spacing) * 1)), var(--theme-color-neutrals-1000) 0);
		}
	}
} 
