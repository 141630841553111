// border radius base size
:root{
    --radius: var(--theme-global-spacing);
    --radius-200: calc(var(--radius)*2);
    --radius-300: calc(var(--radius)*3);
    --radius-400: calc(var(--radius)*4);
    --radius-500: calc(var(--radius)*5);
    --radius-600: calc(var(--radius)*6);
    --radius-700: calc(var(--radius)*8);
    --radius-800: calc(var(--radius)*12);
}