:root {
    --spacing: var(--theme-global-spacing);
    --spacing-0: calc(var(--spacing) * 0);
    --spacing-2: calc(var(--spacing) * 2);
    --spacing-4: calc(var(--spacing) * 4);
    --spacing-6: calc(var(--spacing) * 6);
    --spacing-8: calc(var(--spacing) * 8);
    --spacing-10: calc(var(--spacing) * 10);
    --spacing-12: calc(var(--spacing) * 12);
    --spacing-16: calc(var(--spacing) * 16);
    --spacing-20: calc(var(--spacing) * 20);

    --global-spacing-normal: var(--spacing);
    @media (min-width: map-get($context--breakpoints, xxl)) {
        --global-spacing-normal: var(--spacing-2);
    }
    @media (max-width: map-get($context--breakpoints, sm-max)) {
        --global-spacing-normal: var(--spacing);
    }

    --global-spacing: var(--spacing-2);
    @media (min-width: map-get($context--breakpoints, xxl)) {
        --global-spacing: var(--spacing-4);
    }
    @media (max-width: map-get($context--breakpoints, sm-max)) {
        --global-spacing: var(--spacing-2);
    }

    --global-spacing-x: var(--spacing-4);
    @media (min-width: map-get($context--breakpoints, xxl)) {
        --global-spacing-x: var(--spacing-6);
    }
    @media (max-width: map-get($context--breakpoints, sm-max)) {
        --global-spacing-x: var(--spacing-4);
    }
}
