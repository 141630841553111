.c-badge {
	background: var(--theme-error);
	color: var(--theme-color-neutrals-0);
	border-radius: var(--radius-800);
	padding: calc(var(--theme-global-spacing) * 1);
	&.c-badge--medium {
		@include font-style(var(--text-xxxs), var(--text-xxs));
		font-weight: var(--font-weight-bold);
		padding:calc(var(--theme-global-spacing) * 1.25) calc(var(--theme-global-spacing) * 1.75);
		min-height: 32px;
		min-width: 32px
	}
	&.c-badge--small {
		@include font-style(var(--text-avrage), var(--text-base-size));
		font-weight: var(--font-weight-bold);
		padding:calc(var(--theme-global-spacing) * 0.75) calc(var(--theme-global-spacing) * 2);
		min-height: 24px;
		min-width: 37px;
	}
	&.c-badge--dot {
		background: var(--theme-error);
		line-height: 0;
		text-indent: -9999em;
		padding:calc(var(--theme-global-spacing) * 0.75);
		border: solid 1px var(--theme-color-neutrals-0);
		min-width: 8px;
		min-height: 8px;
	}
}
/*Gray Badge*/
.c-badge--gray {
	background: var(--theme-color-neutrals-0);
	color: var(--theme-color-neutrals-1000);
	border: solid 1px var(--theme-color-neutrals-150);
}
.c-badge--red {
	background: var(--theme-error);
    color: var(--theme-color-neutrals-0);
	border: solid 1px var(--theme-color-neutrals-0);
}