.c-filter-toolbar {
    display: flex;
    justify-content: space-between;
    min-height: calc(var(--theme-global-spacing) * 29);
    @media (max-width: map-get($context--breakpoints, sm-max)) {
        display: block;
    }
    /*Searchbox*/
    .c-filter-toolbar__search-wrap {
        float: left;
        @media (max-width: map-get($context--breakpoints, sm-max)) {
            float: none;
        }
        .c-search-form {
            width: 294px;
            @media (max-width: map-get($context--breakpoints, sm-max)) {
                width: 100%;
                margin-bottom: calc(var(--theme-global-spacing) * 2);
            }
            .c-search-form__input {
                padding-top: calc(var(--theme-global-spacing) * 3);
                padding-bottom: calc(var(--theme-global-spacing) * 3);
                padding-right: calc(var(--theme-global-spacing) * 10);
                height: calc(var(--text-sm) + var(--form-input-padding-y) * 2);
                border-color: var(--theme-color-neutrals-500);
                @media (max-width: map-get($context--breakpoints, sm-max)) {
                    height: calc(var(--text-sm) + var(--form-input-padding-y) * 2.5);
                }
                ::-webkit-input-placeholder,
                ::-moz-placeholder,
                input:-moz-placeholder,
                :-ms-input-placeholder {
                    color: var(--theme-color-neutrals-500);
                }
            }
        }
        .c-search-form {
            margin-bottom:calc(var(--theme-global-spacing) * 3);
            @media (max-width: map-get($context--breakpoints, sm-max)) {
                margin-bottom:calc(var(--theme-global-spacing) * 2);
            }
        }
    }
    /*Sort and Filter*/
    .c-filter-toolbar__sort-filter-group {
        .c-filter-toolbar__sort-filter-group-list {
            display: flex;
            justify-content: flex-end;
            flex-flow: wrap-reverse;
            @media (max-width: map-get($context--breakpoints, sm-max)) {
                display: block;
            }
            .c-filter-toolbar__sort-filter-group-list-item {
                margin-left: calc(var(--theme-global-spacing) * 4);
                padding: 0;
                align-items: center;
                display: flex;
                &:first-child {
                    margin-left: 0;
                }
                @media (max-width: map-get($context--breakpoints, sm-max)) {
                    display: block;
                    margin-left: 0;
                    .u-display-inline-block {
                        display: block;
                        text-align: center;
                        maced-button, /*To overwride*/
                        .u-button.u-button--square {
                            width: 100%;
                            justify-content: center;
                        }
                    }
                }
                .c-filter-button {
                    position: relative;
                    .c-badge.c-badge--dot {
                        position: absolute;
                        top: 11px;
                        left: 32px;
                        @media (max-width: map-get($context--breakpoints, sm-max)) {
                            left: -9999px;
                        }
                    }
                    .icon-filter-funnel {
                        position: relative;
                        font-size: var(--text-xs);
                        margin-right: calc(var(--theme-global-spacing) * 2);
                        &:before {
                            content: "\e948";
                        }
                    }
                    @media (max-width: map-get($context--breakpoints, sm-max)) {
                        width: 100%;
                        justify-content: center;
                    }
                }
                .c-floating-panel {
                    border-color: var(--theme-color-neutrals-150);
                    position: absolute !important; //To override the js style using importang tag
                    top: -13px !important; //To override the js style using importang tag
                    @media (max-width: map-get($context--breakpoints, sm-max)) {
                        position: fixed!important; //To override the js style using importang tag
                        transform: inherit !important; //To override the js style using importang tag
                        z-index: 99999;
                        width: 100%;
                        height: 100%;
                        border: none;
                        border-radius: 0;
                        top: 0!important; //To override the js style using importang tag
                        .c-floting-panel__close {
                            top: calc(var(--theme-global-spacing) * 4);
                            right: calc(var(--theme-global-spacing) * 4);
                            border: none;
                            width: 32px;
                            height: 32px;
                            z-index: 4;
                            color: var(--theme-color-neutrals-800);
                            &:hover, 
                            &:focus {
                                background-color: var(--theme-transparent-background);
                            }
                        }
                    }
                    .c-floating-panel__content {
                        width: 360px;
                        min-height: 200px;
                        max-height: 552px;
                        display: block;
                        overflow-y: auto;
                        @media (max-width: map-get($context--breakpoints, sm-max)) {
                            width: 100%;
                            max-height: 100%;
                            height: calc(100% - 72px);
                        }
                        .c-floating-panel__body-block {
                            padding: 0 calc(var(--theme-global-spacing) * 4);
                            @media (max-width: map-get($context--breakpoints, sm-max)) { 
                                max-height: 100%;
                                height: calc(100% - 139px);
                                overflow-y: auto;
                                overflow-x: hidden;
                                z-index: 1;
                                position: fixed;
                                width: 100%;
                            }
                            .c-floating-panel__body-block-list-item {
                                padding-bottom: calc(var(--theme-global-spacing) * 6);
                                width: 100%;
                                @media (max-width: map-get($context--breakpoints, sm-max)) {
                                    padding-bottom: calc(var(--theme-global-spacing) * 4);
                                }
                                .c-dropdown {
                                    @media (max-width: map-get($context--breakpoints, sm-max)) {
                                        padding-bottom: calc(var(--theme-global-spacing) * 2); 
                                    }
                                    .c-dropdown__button {
                                        margin-bottom: 0;
                                        padding: calc(var(--theme-global-spacing) * 3) calc(var(--theme-global-spacing) * 4);
                                        @media (max-width: map-get($context--breakpoints, sm-max)) {
                                            padding: calc(var(--theme-global-spacing) * 3) calc(var(--theme-global-spacing) * 4);
                                        }
                                        .c-dropdown__button-text {
                                            color:var(--theme-color-neutrals-500);
                                        }
                                        .c-dropdown__button-text.c-dropdown__button-text--is-select {
                                            color:var(--theme-color-neutrals-1000);
                                        }
                                    }
                                }
                                .c-input-outer.c-input-outer--inline .c-input-outer__multiple {
                                    display: block;
                                    @media (max-width: map-get($context--breakpoints, sm-max)) {
                                        .c-input-outer__multiple-item {
                                            &:last-child {
                                                margin-bottom: 0;
                                            }
                                        }
                                    }
                                }
                                .c-input-group--radio-button .c-input-group__control--radio {
                                    width: 100%;
                                    opacity: 0;
                                }
                                .c-input-outer .c-input-outer__label {
                                    font-weight: var(--font-weight-bold);
                                }
                                @media (max-width: map-get($context--breakpoints, sm-max)) {
                                    &:first-child {
                                        padding-top:calc(var(--theme-global-spacing) * 4);
                                    }
                                }
                            }
                            .c-multiselect--truncate{
                                max-width: 328px;
                                width: 100%;
                                @media (max-width: map-get($context--breakpoints, sm-max)) { 
                                    max-width: inherit;
                                }
                                .c-multiselect__input {
                                    max-width: 280px;
                                    width: 100%;
                                    @media (max-width: map-get($context--breakpoints, sm-max)) { 
                                        max-width: inherit;
                                    }
                                }
                                .c-multiselect__selected-chip .c-chip {
                                    max-width: 150px;
                                    .c-chip__text {
                                        max-width: 100px;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }
                                }
                            } 
                            .c-multiselect.c-multiselect--truncate .c-multiselect__list {
                                z-index: inherit;
                            }
                        }
                        .c-floating-panel__body.c-floating-panel__body--medium {
                            background-color: var(--theme-transparent-background);
                            margin: calc(var(--theme-global-spacing) * 4) 0 0;
                            @media (max-width: map-get($context--breakpoints, sm-max)) {
                                padding-top: calc(var(--theme-global-spacing) * 16);
                                margin: 0;
                                height: 100%;
                                .c-floating-panel__body-header {
                                    position: fixed;
                                    top: 0;
                                    height:calc(var(--theme-global-spacing) * 16);
                                    width:calc(100% - 32px);
                                    background-color: var(--theme-color-neutrals-0);
                                    z-index: 2;
                                    margin: 0 calc(var(--theme-global-spacing) * 4);
                                    padding: calc(var(--theme-global-spacing) * 5) 0;
                                    border-bottom: 1px solid var(--theme-color-neutrals-150);
                                    font-weight: var(--font-weight-bold);
                                }
                            }
                            .c-floating-panel__footer {
                                display: flex;
                                width: 100%;
                                justify-content: space-between;
                                border-top-width: 1px;
                                border-top-style: solid;
                                position: fixed;
                                bottom: 0;
                                padding: calc(var(--theme-global-spacing) * 4);
                                border-color: var(--theme-color-neutrals-150);
                                z-index: 4;
                                .u-button.u-button--link {
                                    color: var(--theme-color-500);
                                    text-decoration: underline;
                                    &:hover {
                                        color: var(--theme-color-neutrals-950);
                                    }
                                    &:disabled {
                                        background-color: var(--theme-transparent-background);
                                        color: var(--theme-color-neutrals-400);
                                    }
                                }
                            }
                        }
                    }
                    .c-floating-panel__footer {
                        border-color: var(--theme-color-neutrals-200);
                        .c-display-none {
                            position: fixed;
                            text-indent: -999999em;
                          }
                    }
                    @media (max-width: map-get($context--breakpoints, sm-max)) {
                        .c-floating-panel__footer {
                            z-index: 999;
                            background-color: var(--theme-color-neutrals-0);
                            position: fixed;
                            width: 100%;
                            bottom: 0;
                        }
                    }
                }
            }
            /*Dropdown*/
            .c-filter-toolbar__sort-filter-group-list-item.c-filter-toolbar__sort-filter-group-list-item--dropdown .c-dropdown {
                display: flex;
                align-items: center;
                .c-dropdown__content{
                    position: initial;
                }
                .c-dropdown__label {
                    margin-bottom: 0;
                    font-weight: var(--font-weight-bold);
                    padding: calc(var(--theme-global-spacing) * 1) 0 calc(var(--theme-global-spacing) * 1) calc(var(--theme-global-spacing) * 2);
                }
                .c-dropdown__button {
                    border: none;
                    padding-left: calc(var(--theme-global-spacing) * 2);
                    padding-right: calc(var(--theme-global-spacing) * 1);
                    margin-bottom: 0;
                    padding: calc(var(--theme-global-spacing) * 1) calc(var(--theme-global-spacing) * 1) calc(var(--theme-global-spacing) * 1) calc(var(--theme-global-spacing) * 2);
                    height: calc(var(--theme-global-spacing) * 8);
                    position: initial;
                    .c-dropdown__button-text {
                        margin-right: calc(var(--theme-global-spacing) * 2);
                        color:var(--theme-color-neutrals-800);
                        text-decoration: underline;
                        font-weight: var(--font-weight-bold);
                        &:hover {
                            color: var(--theme-color-950);
                        }
                    }
                    .c-dropdown__button-toggle-icon {
                        font-size: var(--text-base-size);
                        height: calc(var(--theme-global-spacing) * 4);
                        width: calc(var(--theme-global-spacing) * 4);
                    }
                    &:before{
                        position: absolute;
                        left: 0;
                        top: 0;
                        content: "";
                        width: 100%;
                        height: 100%;
                    }
                }
                &:hover{
                    background-color: var(--theme-color-neutrals-1000);
                    color: var(--theme-color-neutrals-0);
                    border-radius: var(--radius);
                    .c-dropdown__label,
                    .c-dropdown__button,
                    .c-dropdown__button .c-dropdown__button-text{
                        background-color: var(--theme-color-neutrals-1000);
                        color: var(--theme-color-neutrals-0);
                        border-radius: var(--radius);
                        .c-dropdown__button-toggle-icon{
                            color: var(--theme-color-neutrals-0);
                        }
                    }
                    &.c-dropdown--is-open{
                        background-color: var(--theme-color-200);
                        color: var(--theme-color-neutrals-1000);
                        border-radius: var(--radius);
                        .c-dropdown__label,
                        .c-dropdown__button,
                        .c-dropdown__button .c-dropdown__button-text{
                            background-color: var(--theme-color-200);
                            color: var(--theme-color-neutrals-1000);
                            border-radius: var(--radius);
                                .c-dropdown__button-toggle-icon{
                                    color: var(--theme-color-neutrals-1000);
                                }
                        }
                    }
                }
                .c-dropdown__list-outer {
                    min-width: 265px;
                    left: inherit;
                    right: 0;
                    top: calc(100% + 4px);
                }
            }
            .c-filter-toolbar__sort-filter-group-list-item.c-filter-toolbar__sort-filter-group-list-item--dropdown .c-dropdown.c-dropdown--is-open,
            .c-filter-toolbar__sort-filter-group-list-item.c-filter-toolbar__sort-filter-group-list-item--dropdown .c-dropdown.c-dropdown--is-open .c-dropdown__button {
                background-color: var(--theme-color-200);
                color: var(--theme-color-neutrals-1000);
                border-radius: var(--radius);
            }
            .c-filter-toolbar__sort-filter-group-list-item--is-active {
                .c-dropdown,
                .c-dropdown .c-dropdown__content,
                .c-dropdown .c-dropdown__button,
                .c-dropdown .c-dropdown__button .c-dropdown__button-text {
                    background-color: var(--theme-color-200);
                    color: var(--theme-color-neutrals-1000);
                    border-radius: var(--radius);
                    .c-dropdown__button:hover,
                    .c-dropdown__button-text:hover {
                        background-color: var(--theme-color-200);
                        color: var(--theme-color-neutrals-1000);
                    }
                }
            }
        }
    }
}
/*Error Message*/
.c-error-state {
    clear: both;
}
/*For Mobile view*/
@media (max-width: map-get($context--breakpoints, sm-max)) {
    .c-input-outer.c-input-outer--inline {
        border-bottom: 1px solid var(--theme-color-neutrals-150);
        padding-bottom: calc(var(--theme-global-spacing) * 4);
    }
    .c-input-group--radio-button .c-input-group__control--radio {
        right: -99999px;
    }
    .u-button--badge .u-button.u-button--square .u-icon {
        &::after {
            content: '';  
            position: absolute;
            right: 0;
            top: 0;
            width:8px;
            height:8px;
            background-color: var(--theme-error);
            border-radius:var(--radius);
            border: 1px solid var(--theme-color-neutrals-0);
        }
    }
}    
/*For No JS Only*/
.c-filter-toolbar.c-filter-toolbar--no-js {
    display: block;
    .c-filter-toolbar__sort-filter-group {
        .c-filter-toolbar__sort-filter-group-sortby {
            float: right;
            @media (max-width: map-get($context--breakpoints, sm-max)) {
                float: none;
            }
            .c-filter-toolbar__sort-filter-group-list {
                display: flex;
                justify-content: flex-end;
                align-items: end;
                @media (max-width: map-get($context--breakpoints, sm-max)) {
                    display: block;
                    }
                }
        }
        .c-filter-toolbar__sort-filter-group-filter {
            float: left;
            width: 100%;
            clear: both;
            @media (max-width: map-get($context--breakpoints, sm-max)) {
                float: none;
            }
            .c-filter-toolbar__sort-filter-group-list {
                display: block;
                    .c-filter-toolbar__sort-filter-group-list-item {
                        display: inline-block;
                        margin-right: calc(var(--theme-global-spacing) * 4);
                        margin-left: 0;
                        margin-bottom: calc(var(--theme-global-spacing) * 2);
                        min-height: 80px;
                        .c-dropdown {
                            display: block;
                            .c-dropdown__label {
                                margin-bottom: calc(var(--theme-global-spacing) * 2);
                                padding: 0;
                            }
                            .c-dropdown__button {
                                border: 1px solid var(--theme-color-neutrals-500);
                                border-radius: var(--radius);
                                height: calc(var(--theme-global-spacing) * 12);
                                min-width: 264px;
                                .c-dropdown__button-text {
                                    text-decoration: none;
                                    color: var(--theme-color-neutrals-500);
                                    font-weight: var(--font-weight-normal);
                                }
                                .c-dropdown__button-text.c-dropdown__button-text--is-select {
                                    color: var(--theme-color-neutrals-1000);
                                }
                            }
                            &:hover, 
                            &:hover .c-dropdown__label,
                            &:hover .c-dropdown__button,
                            &:hover .c-dropdown__button .c-dropdown__button-text,
                            &:hover .c-dropdown__button .c-dropdown__button-toggle-icon {
                                background-color: var(--theme-transparent-background);
                                color: inherit;
                            }
                        }
                        .u-button {
                            margin-right: calc(var(--theme-global-spacing) * 2);
                            @media (max-width: map-get($context--breakpoints, sm-max)) {
                                width: 100%;
                                margin: 0 0 calc(var(--theme-global-spacing) * 2);
                                text-align: center;
                                justify-content: center;
                            }
                        }
                        @media (max-width: map-get($context--breakpoints, sm-max)) {
                            float: none;
                            margin-right: 0;
                        }
                        &:first-child {
                            .c-dropdown .c-dropdown__list-outer {
                                left: 0;
                            }
                        }
                        .c-dropdown .c-dropdown__list-outer {
                            @media (max-width: map-get($context--breakpoints, sm-max)) {
                                left: 0;
                            }
                        }
                        &:last-child {
                            float: inherit;
                            display: inline-flex;
                            min-height: 80px;
                            align-items: self-end;
                            @media (max-width: map-get($context--breakpoints, sm-max)) {
                                display: block;
                                min-height: auto;
                            }
                        }
                        .c-multi-select__height--modifier {
                            display: inline-block;
                            margin-left: calc(var(--theme-global-spacing) * 2);
                        }
                    }
                }
                .u-button.u-button--link {
                    text-decoration: underline;
                }
                .u-button.u-button--link.u-button--disabled {
                    background-color: var(--theme-transparent-background);
                    border-color: var(--theme-transparent-background);
                    color: var(--theme-color-neutrals-400);
                    text-decoration: underline;
                }
        }
    }
    .c-filter-toolbar__sort-filter-group-list .c-filter-toolbar__sort-filter-group-list-item.c-filter-toolbar__sort-filter-group-list-item--dropdown .c-dropdown:hover {
        background-color: inherit ;
        color: inherit;
        .c-dropdown__label,
                    .c-dropdown__button,
                    .c-dropdown__button .c-dropdown__button-text{
                        background-color: inherit;
                        color: inherit;
                        .c-dropdown__button-toggle-icon{
                            color: inherit;
                        }
                    }
    }
}
// !important tag are required for below CSS
.using-keyboard .c-filter-toolbar__sort-filter-group .c-dropdown .c-dropdown__checkbox:focus~.c-dropdown__button:focus,
.using-keyboard .c-filter-toolbar__sort-filter-group .c-dropdown .c-dropdown__button:focus{
    outline: none!important;
    outline-offset: 0!important;
    text-decoration: none;
    &::before{
        outline: 2px solid #000!important;
        outline-offset: 2px!important;
        text-decoration: none;
        border-radius: 4px;
    }
}
