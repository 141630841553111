.c-page-header {
    padding: calc(var(--theme-global-spacing) * 8);
    @media (max-width: map-get($context--breakpoints, sm-max)) {
        padding: calc(var(--theme-global-spacing) * 4);
    }
    .c-page-header__top-panel {
        display: block;
        position: relative;
        .c-link {
            padding-left: 0;
            margin-bottom: calc(var(--theme-global-spacing) * 2);
        }
        .c-page-header-link,
        .c-page-header-content:first-child{
            padding-top: calc(var(--theme-global-spacing) * 2);
        }
        .c-page-header-content .c-page-header-content__title {
            padding: 0;
            margin: 0;
            .c-page-header-content__title-sub-text {
                @include font-style(var(--text-base-size), var(--text-xs));
                display: block;
                margin: 0;
                font-weight: var(--font-weight-normal);
                @media (max-width: map-get($context--breakpoints, sm-max)) {
                    @include font-style(var(--text-xxxs), var(--text-xxs));
                }
            }
            .c-page-header-content__title-text {
                display: block;
                margin: 0;
                padding: 0;
            }
        }
        /*Header Meta Data*/
        .c-page-header-meta-data .c-page-header__list {
            padding: calc(var(--theme-global-spacing) * 0);
            margin: calc(var(--theme-global-spacing) * 2) 0 0 0;
            @media (max-width: map-get($context--breakpoints, sm-max)) {
                margin-bottom: calc(var(--theme-global-spacing) * 4);
            }
            .c-page-header__list-item {
                @include font-style(var(--text-xxxs), var(--text-xxs));
                font-weight: var(--font-weight-normal);
                display: inline-block;
                margin-top: calc(var(--theme-global-spacing) * 2);
                padding: calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 6) calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 0);
                margin-right: calc(var(--theme-global-spacing) * 6);
                border-right: 1px solid var(--theme-color-neutrals-150);
                @media (max-width: map-get($context--breakpoints, sm-max)) {
                    display: block;
                    border-right: none;
                }
                .c-page-header__list-item-label {
                    color: var(--theme-color-neutrals-600);
                    margin-right: calc(var(--theme-global-spacing) * 1);
                    @media (max-width: map-get($context--breakpoints, sm-max)) {
                        display: block;
                        margin-bottom: calc(var(--theme-global-spacing) * 1);
                        margin-right: 0;
                    }
                }
                &:last-child{
                    border-right: 0;
                }
            }
        }
        /*Header Button Group*/
        .c-page-header-button-group {
            position: absolute;
            right: 0;
            top: 0;
            @media (max-width: map-get($context--breakpoints, sm-max)) {
                position: inherit;
            }
            .c-page-header__button-list {
                display: flex;
                @media (max-width: map-get($context--breakpoints, sm-max)) {
                    display: block;
                }
                .c-page-header__button-list-item {
                    margin-left: calc(var(--theme-global-spacing) * 4);
                    @media (max-width: map-get($context--breakpoints, sm-max)) {
                        margin-left: 0;
                        .u-button {
                            width: 100%;
                            justify-content: center;
                        }
                        .c-dropdown__button{
                            margin-bottom: 0;
                            height: 48px;
                        }
                    }
                    &:first-child {
                        margin-left: 0;

                        @media (max-width: map-get($context--breakpoints, sm-max)) {
                            margin-left: 0;
                            margin-bottom: calc(var(--theme-global-spacing) * 4);
                        }
                    }
                    .c-dropdown {
                        min-width: 220px;
                        .c-dropdown__button{
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
        @media (max-width: map-get($context--breakpoints, sm-max)) {
            .c-page-header-button-group:nth-child(2),
            .c-page-header-button-group:nth-child(3){
                padding-top: calc(var(--theme-global-spacing) * 4);
            }
            .c-page-header-meta-data:nth-child(2) .c-page-header__list{
                margin-bottom: 0;
            }
        }
    }
    /*Tab Panel*/
    .c-page-header__tab-group {
        margin-top: calc(var(--theme-global-spacing) * 4);
        @media (max-width: map-get($context--breakpoints, sm-max)) {
            margin-top: calc(var(--theme-global-spacing) * 6);
        }
    }
    /*Progress Buttons group*/
    .c-page-header__dropdown-group {
        .c-page-header__dropdown-title{
            margin-bottom: calc(var(--theme-global-spacing) * 2);
        }
        .c-page-header__dropdown-list {
            display: flex;
            flex-wrap: wrap;
            @media (max-width: map-get($context--breakpoints, sm-max)) {
                display: block;
            }
            .c-page-header__dropdown-list-item {
                margin-left: calc(var(--theme-global-spacing) * 4);
                margin-bottom: calc(var(--theme-global-spacing) * 2);
                width: 26%;
                .c-dropdown .c-dropdown__label{
                  display: none;
                }
                @media (max-width: map-get($context--breakpoints, sm-max)) {
                    display: block;
                    width: 100%;
                    margin-left: 0;
                    margin-bottom: calc(var(--theme-global-spacing) * 4);

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                &:first-child {
                    margin-left: calc(var(--theme-global-spacing) * 0);
                }
                &:nth-child(3n+1){
                    margin-left: calc(var(--theme-global-spacing) * 0);
                }
                .c-dropdown .c-dropdown__content {
                    width: 100%;
                }
                .c-dropdown__button{
                    margin-bottom: 0;
                    height: 48px;
                }
                @media (min-width: map-get($context--breakpoints, md)) {
                    margin-bottom: 0;
                }
            }
        }
    }
}