// info notification-inline
@mixin notification-inline-info {
	@include notification-inline($notification-inline-info);
}
@mixin notification-inline-info {
	@if variable-exists('notification-inline-info') {
		@include notification-inline-theme($notification-inline-info);
	}
}
// notification-inline themes
@mixin notification-inline-success {
	@if variable-exists('notification-inline-success') {
		@include notification-inline-theme($notification-inline-success);
	}
}
@mixin notification-inline-danger {
	@if variable-exists('notification-inline-danger') {
		@include notification-inline-theme($notification-inline-danger);
	}
}
@mixin notification-inline-warning {
	@if variable-exists('notification-inline-warning') {
		@include notification-inline-theme($notification-inline-warning);
	}
}
// Base notification-inline styles
@mixin notification-inline ($theme) {
	@include notification-inline-theme($theme);
}
// Add notification-inline theme
@mixin notification-inline-theme($theme) {
	color: map-get($theme, 'color');
	background-color: map-get($theme, 'backgroundColor');
	background-image: map-get($theme, 'backgroundImage');
	box-shadow:inset 0 0 0 2px map-get($theme, 'box-shadowColor');
}