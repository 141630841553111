@mixin floating-panel-body-none {
	@if variable-exists('floating-panel-body-none') {
		@include floating-panel-size($floating-panel-body-none);
	}
}
@mixin floating-panel-body-medium {
	@if variable-exists('floating-panel-body-medium') {
		@include floating-panel-size($floating-panel-body-medium);
	}
}
@mixin floating-panel-body-large {
	@if variable-exists('floating-panel-body-large') {
		@include floating-panel-size($floating-panel-body-large);
	}
}
@mixin floating-panel-size($theme) {
	margin: map-get($theme, 'margin');
	background-color: map-get($theme, 'backgroundColor');
}