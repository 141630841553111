// c-notification inline default style
.c-notification-inline {
  @include font-style(var(--text-base-size), var(--text-xs));
  font-weight: var(--font-weight-bold);
  border-radius: var(--radius-200);
  padding: calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 4)
    calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 6);
  @media (max-width: map-get($context--breakpoints, sm-max)) {
    padding: calc(var(--theme-global-spacing) * 3) calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 3) calc(var(--theme-global-spacing) * 4);
    @include font-style(var(--text-xxxs), var(--text-xxs));
  }
  @include notification-inline-info;
  .c-notification-inline__content {
    margin-right: calc(var(--theme-global-spacing) * 4);
    @media (max-width: map-get($context--breakpoints, sm-max)) {
      margin-right: calc(var(--theme-global-spacing) * 2);
    }
    .c-notification-inline__content-text {
      margin-top: calc(var(--theme-global-spacing) * 3);
      margin-bottom: calc(var(--theme-global-spacing) * 3);
      margin-right: calc(var(--theme-global-spacing) * 4);
      a:focus {
        border-radius: var(--radius);
      }
      .c-link{
        text-decoration: none;
        color: var(--theme-color-neutrals-1000);
        .c-link__text{
          text-decoration: underline;
        }
      }
      @media (max-width: map-get($context--breakpoints, sm-max)) {
        margin-top: 0;
        @include font-style(var(--text-base-size), var(--text-xs));
      }
    }
    .c-notification-inline__tab-content + .c-notification-inline__content-text {
      margin-right: calc(var(--theme-global-spacing) * 4);
      @media (max-width: map-get($context--breakpoints, sm-max)) {
        margin-right: calc(var(--theme-global-spacing) * 2);
      }
    }
  }
  .c-notification-inline__button {
    margin-right: calc(var(--theme-global-spacing) * 4);
    @media (max-width: map-get($context--breakpoints, sm-max)) {
      margin-right: calc(var(--theme-global-spacing) * 2);
    }
    //below class from test harness. so update in theme css for matching with the UI
    .c-link{
      text-decoration: none;
      color: var(--theme-color-neutrals-1000);
      .c-link__text{
        text-decoration: underline;
      }
    }
  }
  .c-notification-inline__content + .c-notification-inline__button .c-link:hover .c-link__text{
    text-decoration: none;
  }
  .c-notification-inline__close-button {
    .u-button.u-button--fixed-size.u-button--large {
      border-color: var(--theme-transparent-background);
      background-color: var(--theme-transparent-background);
      color: var(--theme-color-neutrals-1000);
      &:hover,
      &.hover {
        color: var(--theme-color-neutrals-0);
        background-color: var(--theme-color-neutrals-1000);
        border-color: var(--theme-color-neutrals-1000);
      }
      @media (max-width: map-get($context--breakpoints, sm-max)) {
        height: 32px;
        width: 32px;
        margin-top: calc(var(--theme-global-spacing) * -1);
      }
    }
    .u-button--large {
      @media (max-width: map-get($context--breakpoints, sm-max)) {
        padding: calc(var(--theme-global-spacing) * 0);
        top: 2px;
      }
      .icon-x-close {
        @include font-style(var(--text-xs), var(--text-xs));
        @media (max-width: map-get($context--breakpoints, sm-max)) {
          @include font-style(var(--text-base-size), var(--text-base-size));
        }
      }
    }
  }
  .c-notification-inline__icon.u-icon {
    margin-right: calc(var(--theme-global-spacing) * 4);
    @media (max-width: map-get($context--breakpoints, sm-max)) {
      margin-right: calc(var(--theme-global-spacing) * 2);
      @include font-style(var(--text-base-size), var(--text-base-size));
    }
  }
}
.c-notification-inline--success {
  @include notification-inline-success;
  .c-notification-inline__label {
    border-color: var(--theme-success);
  }
}
.c-notification-inline--danger {
  @include notification-inline-danger;
  .c-notification-inline__label {
    border-color: var(--theme-error);
  }
}
.c-notification-inline--warning {
  @include notification-inline-warning;
  .c-notification-inline__label {
    border-color: var(--theme-warning);
  }
}
.c-notification-inline--info {
  @include notification-inline-info;
  .c-notification-inline__label {
    border-color: var(--theme-color-neutrals-300);
  }
  .c-notification-inline__content {
    font-weight: normal;
  }
}
.c-notification-inline__check:focus + .c-notification-inline__label {
  outline-color: var(--theme-color-950);
}
// c-notification inline types end
.c-notification-inline__title {
  @media (max-width: map-get($context--breakpoints, sm-max)) {
    margin-bottom: calc(var(--theme-global-spacing) * 2);
  }
}
.c-notification-inline__label {
  padding: calc(var(--theme-global-spacing) * 2.5) calc(var(--theme-global-spacing) * 4);
  border-radius: var(--radius);
  @media (max-width: map-get($context--breakpoints, lg-max)) {
    margin-top: 0;
  }
  @media (max-width: map-get($context--breakpoints, sm-max)) {
    padding: calc(var(--theme-global-spacing) * 1.5) calc(var(--theme-global-spacing) * 4);
    margin-left: 0;
  }
  background: var(--theme-color-neutrals-0);
  color: var(--theme-color-neutrals-1000);
}
.c-notification-inline__check {
  margin-left: calc(var(--theme-global-spacing) * 6);
}