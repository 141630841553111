.using-mouse *:focus,
.using-mouse *:focus ~ label,
.using-mouse *a:focus,.using-mouse *button:focus,.using-mouse *select:focus,
.using-mouse .owl-dt-popup-container .owl-dt-calendar-main .owl-dt-calendar-view .owl-dt-calendar-table .owl-dt-calendar-cell:focus .owl-dt-calendar-cell-content,
.using-mouse .owl-dt-popup-container .owl-dt-calendar-main .owl-dt-calendar-view .owl-dt-calendar-table .owl-dt-calendar-cell.owl-dt-calendar-cell-active:focus > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected),
.using-mouse .c-multiselect.c-multiselect--focus,
.using-mouse .ql-snow.ql-toolbar .ql-formats .ql-picker-label:focus,
.using-mouse .ql-snow.ql-toolbar .ql-formats .ql-picker-item:focus,
.using-mouse .c-chip .c-chip__input-element:focus,
.using-mouse .c-hot-corner a:focus,
.using-mouse .c-time-form:focus-within,
.using-mouse .c-input-group-numeric .c-input-group-wrap:focus-within  .c-input-group__control,
.using-mouse .c-headers .c-headers__wrapper .c-headers__logo-link:focus .c-headers__logo-img,
.using-mouse .c-tree .c-tree__list .c-tree__list-item .c-tree__input:focus + .c-tree__label .c-tree__label-check-mark:before,
.using-mouse .c-accordion .c-accordion__panel .c-accordion__header:focus .c-accordion__title-text .c-accordion__expand-label,
.using-mouse .c-accordion .c-accordion__panel .c-accordion__header:focus .c-accordion__title-text .c-accordion__collapse-label,
.using-mouse .c-accordion .c-accordion__panel .c-accordion__header:focus .c-accordion__title-text,
.using-mouse .c-input-group .c-input-group__hide:focus ~ .c-input-group__control--datepicker,
.using-mouse .c-input-group .c-input-group__control--check:focus + .c-input-group__label:before,
.using-mouse .c-input-group .c-input-group__control--radio:focus + .c-input-group__label:before,
.using-mouse .c-function-card .c-function-card__accordion .c-accordion-outer .c-accordion.c-accordion--open-top.c-accordion--modified .c-accordion__panel .c-accordion__header:focus .c-accordion__title-text .c-accordion__expand-label,
.using-mouse .c-function-card .c-function-card__accordion .c-accordion-outer .c-accordion.c-accordion--open-top.c-accordion--modified .c-accordion__panel .c-accordion__header:focus .c-accordion__title-text .c-accordion__collapse-label,
.using-mouse .c-toggle .c-toggle__label .c-toggle__input:focus + .c-toggle__button,
.using-mouse .c-player__timer-range-input:focus + .c-player__timer-range-track .c-player__timer-range-track-thumb {
    outline: none !important;
}
.using-mouse .owl-dt-popup-container .owl-dt-calendar-main .owl-dt-calendar-view .owl-dt-calendar-table .owl-dt-calendar-cell:focus .owl-dt-calendar-cell-content.owl-dt-calendar-cell-selected {
    box-shadow: none;
}
.using-keyboard *a:focus,.using-keyboard *button:focus,.using-keyboard *select:focus,
*a:focus, *button:focus, *select:focus,
.using-keyboard .c-card .c-card__input-element:focus,
.using-keyboard .c-input-group__control:focus,
.using-keyboard .c-input-group .c-input-group__hide:focus~.c-input-group__control--datepicker,
.using-keyboard .owl-dt-popup-container .owl-dt-calendar-main .owl-dt-calendar-view .owl-dt-calendar-table .owl-dt-calendar-cell:focus .owl-dt-calendar-cell-content,
.using-keyboard .owl-dt-popup-container .owl-dt-calendar-main .owl-dt-calendar-view .owl-dt-calendar-table .owl-dt-calendar-cell.owl-dt-calendar-cell-active:focus > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected),
.using-keyboard .c-input-group--radio-button .c-input-group__control--radio:not(:checked):focus~.c-input-group__label:before,
.using-keyboard .c-input-group .c-input-group__control.c-input-group__error:focus,
.using-keyboard .c-tree .c-tree__list .c-tree__list-item .c-tree__input:focus+.c-tree__label .c-tree__label-check-mark:before,
.using-keyboard .c-input-group .c-input-group__control--check:focus + .c-input-group__label:before,
.using-keyboard .c-input-group .c-input-group__control--radio:focus + .c-input-group__label:before,
.using-keyboard .c-input-group .c-input-group__control--check.c-input-group__control--check-box-button:focus + .c-input-group__label,
.using-keyboard .c-accordion__header:focus .c-accordion__title .c-accordion__title-text .c-accordion__expand-label,
.using-keyboard .c-accordion__header:focus .c-accordion__title .c-accordion__title-text .c-accordion__collapse-label,
.using-keyboard .c-dropdown .c-dropdown__checkbox:focus~.c-dropdown__button,
.using-keyboard .c-player__timer-range-input:focus+.c-player__timer-range-track .c-player__timer-range-track-thumb,
.using-keyboard .c-toggle .c-toggle__input:focus+.c-toggle__button,
.using-keyboard .c-number-input__button[type=button]:focus:not(.c-input-group__control), 
.c-number-input__button[type=button]:focus:focus-visible, 
.c-number-input__button[type=button]:focus-visible,
.using-keyboard .c-tree.c-tree__list .c-tree__list-item .c-tree__input-expand:focus + .c-tree__expand-label,
.using-keyboard .c-multiselect__checkbox:focus+.c-multiselect__checbox-label,
.using-keyboard .c-search-form .c-search-form__submit-button:focus,
.using-keyboard .c-search-form .c-search-form__input:focus,
.using-keyboard .c-pen__color .c-pen__color-form-control input[type=radio]:focus,
.using-keyboard .c-highlight__color .c-highlight__color-form-control input[type=radio]:focus,
.using-keyboard .c-type .c-type__color-form-control input[type=radio]:focus,
.using-keyboard .c-highlight__color .c-highlight__color-form-control input[type=radio]:focus,
.using-keyboard .c-headers .c-headers__wrapper .c-headers__logo-link:focus .c-headers__logo-img,
.using-keyboard .c-tree .c-tree__list .c-tree__list-item .c-tree__input-expand:focus + .c-tree__expand-label,
.using-keyboard .c-multiselect.c-multiselect--focus,
.using-keyboard .ql-snow.ql-toolbar .ql-formats .ql-picker-label:focus,
.using-keyboard .ql-snow.ql-toolbar .ql-formats .ql-picker-item:focus,
.using-keyboard .c-chip .c-chip__input-element:focus,
.using-keyboard .c-hot-corner a:focus,
.using-keyboard .c-time-input-group__control:focus,
.using-keyboard .c-input-group-numeric .c-input-group__control:focus,
.using-keyboard .c-tree .c-tree__list .c-tree__list-item .c-tree__input:focus + .c-tree__label .c-tree__label-check-mark:before,
audio:focus-within,
audio:focus-visible,
.using-keyboard .c-toggle .c-toggle__label .c-toggle__input:focus + .c-toggle__button,
.using-keyboard .c-player__timer-range-input:focus + .c-player__timer-range-track .c-player__timer-range-track-thumb,
.using-keyboard .c-highlight__style .c-highlight__style-form-control input[type=radio]:checked:focus,
.using-keyboard .c-pen__style .c-pen__style-form-control input[type=radio]:focus,
.using-keyboard .c-type .c-type__style .c-type__style-form-control input[type=radio]:checked:focus,
.using-keyboard a.c-link:focus,
.using-keyboard .c-link--is-disabled:focus,
.using-keyboard .c-footer .c-footer__nav .c-footer__nav-list .c-footer__nav-list-item .c-footer__nav-list-item-link:focus,
.using-keyboard .c-notification-inline .c-notification-inline__content-text a:focus,
.using-keyboard .c-notification-inline .c-notification-inline__button a.c-link:focus,
.using-keyboard .c-filter-toolbar .c-filter-toolbar__sort-filter-group .c-filter-toolbar__sort-filter-group-list .c-filter-toolbar__sort-filter-group-list-item .c-floating-panel .c-floating-panel__content .c-floating-panel__body-block .c-floating-panel__body-block-list-item .c-dropdown .c-dropdown__checkbox:focus~.c-dropdown__button,
.using-keyboard .c-filter-toolbar .c-filter-toolbar__sort-filter-group .c-filter-toolbar__sort-filter-group-list .c-filter-toolbar__sort-filter-group-list-item .c-floating-panel .c-floating-panel__content .c-floating-panel__body-block .c-floating-panel__body-block-list-item .c-dropdown .c-dropdown__button:focus,
.using-keyboard .c-multiselect .c-multiselect__action .c-multiselect__checbox-label:focus:focus,
.using-keyboard .c-multiselect.c-multiselect--inline .c-multiselect__action .c-multiselect__checbox-label:focus,
.using-keyboard .c-tree .c-tree__list .c-tree__list-item .c-tree__expand-label:focus-visible,
.using-keyboard  .c-card .c-card__input-element:focus,
.using-keyboard .c-card .c-card__link:focus,
.using-keyboard  .c-card.c-card--with-link:focus {
    outline: 2px solid var(--theme-color-neutrals-1000) !important; 
    outline-offset: 2px !important;
    text-decoration: none;
}
.using-keyboard .c-tree .c-tree__list .c-tree__list-item .c-tree__expand-label:focus-visible,
.using-keyboard .owl-dt-control-button,
.using-keyboard .ql-snow.ql-toolbar .ql-formats .ql-picker-label:focus,
.using-keyboard .ql-snow.ql-toolbar .ql-formats .ql-picker-item:focus{
    border-radius: 4px;
    &:focus > .owl-dt-control-content {
        outline: none;
    }
}
.using-keyboard .owl-dt-popup-container .owl-dt-calendar-main .owl-dt-calendar-view .owl-dt-calendar-table .owl-dt-calendar-cell:focus .owl-dt-calendar-cell-content, .using-keyboard .owl-dt-popup-container .owl-dt-calendar-main .owl-dt-calendar-view .owl-dt-calendar-table .owl-dt-calendar-cell.owl-dt-calendar-cell-active:focus > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
    z-index: 2;
}
.using-keyboard .c-input-group__control--radio,
.using-keyboard .owl-dt-popup-container .owl-dt-calendar-main .owl-dt-calendar-view .owl-dt-calendar-table .owl-dt-calendar-cell,
.using-keyboard .c-input-group--radio-button .c-input-group__label,
.using-keyboard .c-input-group .c-input-group__control--check + .c-input-group__label,
.using-keyboard .c-input-group .c-input-group__control--check.c-input-group__control--check-box-button + .c-input-group__label:before,
.using-keyboard .c-input-group .c-input-group__label + .c-input-group__hide,
.using-keyboard .c-dropdown .c-dropdown__checkbox,
.using-keyboard .c-input-check-inline .c-input-check-inline__label,
.using-keyboard .c-accordion-outer .c-accordion.c-accordion--open-top.c-accordion--modified .c-accordion__panel,
.using-keyboard .c-function-card .c-function-card__accordion .c-accordion-outer .c-accordion.c-accordion--open-top.c-accordion--modified .c-accordion__panel,
.using-keyboard .c-function-card .c-function-card__accordion .c-accordion-outer .c-accordion.c-accordion--open-top.c-accordion--modified .c-accordion__panel .c-accordion__header,
.using-keyboard .c-accordion.c-accordion--modified .c-accordion__header,
.c-headers .c-headers__wrapper .c-headers__logo-link:focus,
.using-keyboard .c-accordion-outer .c-accordion.c-accordion--open-top.c-accordion--modified .c-accordion__panel .c-accordion__header:focus .c-accordion__title-text .c-accordion__expand-label,
.using-keyboard .c-accordion-outer .c-accordion.c-accordion--open-top.c-accordion--modified .c-accordion__panel .c-accordion__header:focus .c-accordion__title-text .c-accordion__collapse-label,
.using-keyboard .c-function-card .c-function-card__accordion .c-accordion.c-accordion--open-top.c-accordion--modified .c-accordion__panel .c-accordion__header:focus .c-accordion__title-text .c-accordion__expand-label,
.using-keyboard .c-function-card .c-function-card__accordion .c-accordion.c-accordion--open-top.c-accordion--modified .c-accordion__panel .c-accordion__header:focus .c-accordion__title-text .c-accordion__collapse-label,
.using-keyboard .c-multiselect .c-multiselect__action .c-multiselect__checkbox:disabled+.c-multiselect__checbox-label:focus  {
    outline: none !important;
}
.using-keyboard .c-number-input__button[type=button]:focus,
.using-keyboard .c-time-form .c-time-form__input-button .c-time-form__down-button:focus,
.using-keyboard .c-time-form .c-time-form__input-button .c-time-form__up-button:focus,
.using-keyboard .c-tabs .c-tabs__link:focus {
    border-radius:var(--radius) !important;
}
.using-keyboard [tabindex]:focus,.using-keyboard [tabindex]:focus-visible{
    outline-color: var(--theme-color-neutrals-1000) !important;
    outline-style: solid !important;
    outline-width: 2px !important;
    outline-offset: 2px !important; 
    text-decoration: none;
}