.c-player {
	margin-bottom: calc(var(--theme-global-spacing) * 27);
	.c-player__button {
		&:focus,
		&:active {
			outline-color: var(--theme-color-950);
		}
		&:disabled {
			background-color: var(--theme-color-neutrals-200);
			border-color: var(--theme-color-neutrals-200);
			color: var(--theme-color-neutrals-200);
		}
	}
	.c-player__button--play {
		margin-right: calc(var(--theme-global-spacing) * 3);
		background-color: var(--theme-color-500);
		border-color: var(--theme-color-500);
		&:hover {
			background-color: var(--theme-color-neutrals-1000);
			border-color: var(--theme-color-neutrals-1000);
			@media (max-width: map-get($context--breakpoints, sm-max)) {
				background-color: var(--theme-color-500);
				border-color: var(--theme-color-500);
			}
		}
		&:disabled {
			background-color: var(--theme-color-neutrals-200);
			border-color: var(--theme-color-neutrals-200);
			color: var(--theme-color-neutrals-200);
		}
	}
	.c-player__button--pause {
		margin-right: calc(var(--theme-global-spacing) * 3);
		background-color: var(--theme-color-500);
		border-color: var(--theme-color-500);
		&:hover {
			background-color: var(--theme-color-neutrals-1000);
			border-color: var(--theme-color-neutrals-1000);
			@media (max-width: map-get($context--breakpoints, sm-max)) {
				background-color: var(--theme-color-500);
				border-color: var(--theme-color-500);
			}
		}
		&:disabled {
			background-color: var(--theme-color-neutrals-200);
			border-color: var(--theme-color-neutrals-200);
			color: var(--theme-color-neutrals-200);
		}
	}
	.c-player__timer-range {
		.c-player__timer-range-label {
			padding-top: calc(var(--theme-global-spacing) * 2);
			@include font-style(var(--text-xxxs), var(--text-xxs));
			font-weight: var(--font-weight-normal);
			font-family: var(--font-primary);
		}
	}
	.c-player__volume-range {
		margin-left: calc(var(--theme-global-spacing) * 3);
		.c-player__button--volume {
			background: var(--theme-color-neutrals-400);
			border-color: var(--theme-color-neutrals-400);
			border-color: var(--theme-transparent-background);
			&:hover {
				border-color: var(--theme-color-neutrals-1000);
			}
			&:focus {
				outline-color: var(--theme-color-950);
			}
		}
		.c-player__button--volume[disabled]:hover {
			border-color: var(--theme-color-neutrals-400);
		}
		.c-player__volume-progress {
			border-color: var(--theme-color-neutrals-500);
			background-color: var(--theme-color-neutrals-0);
		}
		.c-player__volume-progress--top {
			padding-bottom: calc(var(--theme-global-spacing) * 12);
		}
		.c-player__volume-progress--bottom {
			padding-top: calc(var(--theme-global-spacing) * 12);
		}
	}
	.c-player__volume-range--is-open {
		.c-player__button--volume {
			background-color: var(--theme-transparent-background);
		}
		.c-player__button--volume[disabled]:hover {
			border-color: var(--theme-color-neutrals-400);
		}
	}
}
.c-player__timer-range-input {
	margin: calc(var(--theme-global-spacing) * 0);
	padding: calc(var(--theme-global-spacing) * 0);
	background: var(--theme-transparent-background);
	color: var(--theme-transparent-background);
	&::-ms-track,
	&::-moz-range-track {
		margin: calc(var(--theme-global-spacing) * 0);
		padding: calc(var(--theme-global-spacing) * 0);
		background: var(--theme-transparent-background);
		color: var(--theme-transparent-background);
	}
	&:focus::-webkit-slider-runnable-track {
		background: var(--theme-transparent-background);
		border-color: var(--theme-transparent-background);
	}
	&:focus {
		.c-player__timer-range-track {
			.c-player__timer-range-track-thumb {
				box-shadow: calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 1) var(--theme-dropdown-shadow);
			}
		}
	}
	&::-ms-thumb,
	&::-moz-range-thumb,
	&::-webkit-slider-thumb,
	&::-ms-fill-lower,
	&::-ms-fill-upper {
		background: var(--theme-transparent-background);
	}
}
.c-player__timer-range-track {
	background-color:  var(--theme-color-neutrals-150);
}
.c-player__timer-range-track-fill {
	background-color: var(--theme-color-500);
	margin: auto calc(var(--theme-global-spacing) * 0);
}
.c-player__timer-range-track-thumb {
	background-color: var(--theme-color-500);
	box-shadow: map-get($shadow, diffuse-4) map-get(map-get($palette, neutrals), 200);
	border-color: var(--theme-color-neutrals-0);
	margin: auto calc(var(--theme-global-spacing) * 0) auto calc(var(--theme-global-spacing) * -1);
}
.c-player__timer-range-input:focus + .c-player__timer-range-track .c-player__timer-range-track-thumb{
	outline-color: var(--theme-color-950);
}
.c-player__timer-range-input::-moz-range-thumb {
	-moz-appearance: none;
	background: var(--theme-transparent-background);
	border: var(--theme-transparent-background);
  }
