.c-toggle {
  padding: 0 calc(var(--theme-global-spacing) * 12);
  .c-toggle__input {
    &:checked + .c-toggle__button {
      background-color: var(--theme-color-500);
    }
    &:disabled:checked + .c-toggle__button {
      background-color: var(--theme-color-200);
    }
    &:disabled {
      & + .c-toggle__button {
        background-color: var(--theme-color-neutrals-200);
        .c-toggle__button-label {
          color: var(--theme-color-neutrals-200);
        }
      }
      &:hover,
      &:focus {
        background-color: var(--theme-color-neutrals-150);
        box-shadow: none;
      }
    }
    &:focus + .c-toggle__button {
      outline-color: var(--theme-color-950);
      box-shadow: none;
    }
  }
  .c-toggle__button {
    background-color: var(--theme-color-neutrals-500);
    padding: calc(var(--theme-global-spacing) * 1);
    margin: 0 calc(var(--theme-global-spacing) * 7);
    @media (min-width: map-get($context--breakpoints, xxl)) {
      margin: 0 calc(var(--theme-global-spacing) * 11);
    }
    &:active {
      box-shadow: none;
    }
  }
  .c-toggle__button--label {
    color: var(--theme-color-neutrals-1000);
  }
  .c-toggle__button--modified::after {
    background-color: var(--theme-color-neutrals-0);
  }
}
