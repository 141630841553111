/*Sidepanel*/
.c-side-panel {
    border-color: var(--theme-color-neutrals-150);
    padding-top: calc(var(--theme-global-spacing) * 8);
    .c-side-panel__title-text{
        font-size: var(--text-base-size);
        line-height: var(--text-lg);
        font-weight: var(--font-weight-bold); 
        margin:calc(var(--theme-global-spacing) * 2.5) 0;
    }
}
/*Sidepanel title*/
.c-side-panel__title {
    font-size: var(--text-base-size);
    font-weight: var(--font-weight-bold);
    margin: 0 calc(var(--theme-global-spacing) * 2);
    border-bottom-color: var(--theme-color-neutrals-150);
    padding: calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 6);
    @media (max-width: map-get($context--breakpoints, sm-max)) {
        margin: 0;
        padding: calc(var(--theme-global-spacing) * 4);
        border-bottom-color: var(--theme-color-500);
    }
    .c-side-panel__title-button {
        background-color: var(--theme-color-neutrals-0);
        color: var(--theme-color-neutrals-1000);
        border-color: transparent;
        @media (max-width: map-get($context--breakpoints, sm-max)) {
            border-color: var(--theme-color-500);
        }
        &:hover,
        &:focus {
            background-color: var(--theme-color-neutrals-1000);
            border-color: var(--theme-color-neutrals-1000);
            outline: 0;
            color: var(--theme-color-neutrals-0);
        }
    }
    .c-side-panel__title-button--left {
        margin-right: calc(var(--theme-global-spacing) * 4);
    }
}
/*Sidepanel content*/
.c-side-panel__content {
    margin: calc(var(--theme-global-spacing) * 2);
}
// "content-block" class use here only for matching with the ux and demo purpose, It will removed after the actual data will come
.content-block-aside{
    @include context--font-size-h4;
    background-color: var(--theme-color-50);
    padding: calc(var(--theme-global-spacing) * 4);
}
