/**
 *  toast settings
 */
$toast-info: (
	'color': var(--theme-color-neutrals-1000),
	'backgroundColor': var(--theme-color-100),
	'box-shadowColor' : var(--theme-color-500),
);
$toast-success: (
	'color': var(--theme-color-neutrals-1000),
	'backgroundColor': var(--theme-success-light-100),
	'box-shadowColor' : var(--theme-success),
);
$toast-danger: (
	'color': var(--theme-color-neutrals-1000),
	'backgroundColor':var(--theme-error-light-100),
	'box-shadowColor' : var(--theme-error),
);
$toast-warning: (
	'color':var(--theme-color-neutrals-1000),
	'backgroundColor':var(--theme-warning-light-100),
	'box-shadowColor' : var(--theme-warning),
);