.c-search-form {
  margin-bottom: calc(var(--theme-global-spacing) * 8);
  .c-search-form__group {
    border-radius: var(--radius);
    background-color: var(--theme-color-neutrals-0);
  }
  .c-search-form__input {
    @include font-style(var(--text-base-size), var(--text-xs));
    font-family: var(--theme-global-font-familly);
    border-color: var(--theme-color-neutrals-500);
    height: calc(var(--text-sm) + calc(var(--form-input-padding-y) * 2));
    padding: calc(var(--theme-global-spacing) * 3) calc(var(--theme-global-spacing) * 12);
    color: var(--theme-color-neutrals-1000);
    background-color: var(--theme-transparent-background);
    border-radius: var(--radius);
    @media (max-width: map-get($context--breakpoints, sm-max)) {
      height: calc(var(--text-md) + calc(var(--form-input-padding-y) * 2));
    }
    &.c-search-form__input--no-icon{
      padding-left: var(--form-input-padding-y);
    }
    &::placeholder {
      color: var(--theme-color-neutrals-500);
    }
    &:focus {
      border-color: var(--theme-color-neutrals-1000);
      outline-color: var(--theme-color-950);
    }
    &:hover {
      border-color: var(--theme-color-neutrals-950);
    }
    &:active {
      border-color: var(--theme-color-neutrals-950);
    }
    &:disabled,
    &.disabled {
      background-color: var(--theme-color-neutrals-50);
      border-color: var(--theme-color-neutrals-200);
      color: var(--theme-color-neutrals-400);
      &::placeholder {
        color: var(--theme-color-neutrals-400);
      }
      &::before {
        color: var(--theme-color-neutrals-400);
        border-color: var(--theme-color-neutrals-200);
      }
    }
  }
  .c-search-form__clear-button {
    background-color: var(--theme-transparent-background);
    border-radius: var(--radius);
    color: var(--theme-color-neutrals-1000);
    &:focus {
      outline-color: var(--theme-color-950);
    }
  }
  .c-search-form__submit-button {
    background-color: var(--theme-transparent-background);
    border-radius: var(--radius);
    color: var(--theme-color-neutrals-1000);
    &:focus {
      outline-color: var(--theme-color-950);
    }
  }
  .c-search-form__search-icon {
    padding-left: calc(var(--theme-global-spacing) * 4);
    padding-right: calc(var(--theme-global-spacing) * 2);
  }
  .c-search-form__search-icon--is-disabled .icon-search {
    color: var(--theme-color-neutrals-400);
  }
  .c-input-group__error {
    color: var(--theme-error);
    margin-top: calc(var(--theme-global-spacing) * 3);
  }
  .u-button.u-button--large {
    margin-left:calc(var(--theme-global-spacing) * 2);
    padding-left:calc(var(--theme-global-spacing) * 2.5);
    padding-right:calc(var(--theme-global-spacing) * 2.5);
    font-size: 0;
    .u-icon {
      font-size: var(--text-xs);
    }
  }
}
/*For Left position*/
.c-search-form--right {
  .c-search-form__clear-button .icon-x-close::after {
    background-color: var(--theme-color-neutrals-1000);
  }
  .c-search-form__input {
    padding-left: calc(var(--theme-global-spacing) * 3);
  }
}