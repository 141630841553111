// Floating panel Highlight css start here 
.c-highlight {
	// Common wrapper for highlight style
	.c-highlight__style {
		padding: calc(var(--theme-global-spacing) * 3) calc(var(--theme-global-spacing) * 3);
		/* highlight form control */
		.c-highlight__style-form-control {
			margin: 0 calc(var(--theme-global-spacing) * 1);

			/* highlight form control radio style */
			input[type="radio"] {
				width: 44px;
				height: 44px;
				margin: 0;
			}
			/* highlight form control radio button hover style */
			input[type="radio"]:hover {
				border:1px solid var(--theme-color-500);
			}
			/* highlight form control radio button focus style */
			input[type="radio"]:focus {
				outline: none;
			}

			/* highlight form control radio button custom checked*/
			input[type="radio"]:checked::before {
				background-color: var(--theme-color-500);
			}
			input[type="radio"].disabled:checked::before {
				background-color: var(--theme-color-neutrals-0);
			}
		}

	}
	.c-highlight__divider {
		background-color: var(--theme-color-neutrals-150);
	}
	// Common wrapper for highlight color
	.c-highlight__color {
		padding: calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 4);
		/* highlight form control */
		.c-highlight__color-form-control {
			margin: 0 calc(var(--theme-global-spacing) * 2);
			/* highlight form control radio style */
			input[type="radio"] {
				border: 1px solid #7F7F7F;
				width: 20px;
				height: 20px;
				/* highlight form control radio button custom */
				&::before {
					background-color: transparent;
				}
				/* highlight form control radio button focus style*/
				&:focus {
					outline: max(2px, 2px) solid #f69ba4;
				}
				&:hover {
					box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
				}
			}
		}
	}
}

// Floating panel IWB css start here 
@media (min-width: map-get($context--breakpoints, xxl)) {
	.c-highlight {
		.c-highlight__style {
			padding: calc(var(--theme-global-spacing) * 5);
			/* highlight form control radio style */
			.c-highlight__style-form-control input[type="radio"] {
				width: 48px;
				height: 48px;
			}
		}
		.c-highlight__color {
			max-width: 272px;
			flex-wrap: wrap;
			padding: calc(var(--theme-global-spacing) * 8);
			/* highlight form control */
			.c-highlight__color-form-control {
				margin: calc(var(--theme-global-spacing) * 3);
				/* highlight form control radio style */
				input[type="radio"] {
					width: 28px;
					height: 28px;
				}
			}
		}
	}
}

// Floating panel Mobile css start here 
@media (max-width: map-get($context--breakpoints, sm-max)) {
	.c-highlight .c-highlight__color {
		justify-content: center;
		padding: calc(var(--theme-global-spacing) * 6) calc(var(--theme-global-spacing) * 6);
		.c-highlight__color-form-control {
			margin: calc(var(--theme-global-spacing) * 3);
		}
	}
	.c-highlight .c-highlight__style .c-highlight__style-form-control input[type=radio]{
		width: 71px;
		height: 48px;
	}
	.c-highlight .c-highlight__color .c-highlight__color-form-control input[type=radio] {
		width: 22px;
		height: 22px;
	}
}