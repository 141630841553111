.c-floating-panel {
	border-color: var(--theme-color-neutrals-400);
	background-color: var(--theme-color-neutrals-0);
	border-radius: var(--radius-200);
	box-shadow: map-get($shadow, diffuse-4) map-get(map-get($palette, neutrals), 300);
	.c-floting-panel__close {
		color: var(--theme-color-neutrals-1000);
		padding: calc(var(--theme-global-spacing) * 1);
		background-color: var(--theme-color-neutrals-0);
		border-color: var(--theme-color-500);
		border-radius: var(--radius-800);
		&:hover,
		&:focus {
			background-color: var(--theme-color-neutrals-1000);
			border-color: var(--theme-color-neutrals-1000);
			color: var(--theme-color-neutrals-0);
		}
	}
	&.c-floating-panel--caret-bottom-end .c-floating-panel__caret,
	&.c-floating-panel--caret-top-end .c-floating-panel__caret{
		margin-left: calc(var(--theme-global-spacing) * 6);
	}
	&.c-floating-panel--caret-bottom-start .c-floating-panel__caret,
	&.c-floating-panel--caret-top-start .c-floating-panel__caret{
		margin-left: calc(var(--theme-global-spacing) * -6);
	}
}
.c-floating-panel__content {
	margin: calc(var(--theme-global-spacing) * 0);
	border-radius: var(--radius);
	.c-floating-panel__body{
		width: 100%;
		@media (max-width: map-get($context--breakpoints, sm-max)) {
			width: calc(100% - 32px);
		}
		&.c-floating-panel__body--none{
			@include floating-panel-body-none;
		}
		&.c-floating-panel__body--medium{
			@include floating-panel-body-medium;
		}
		&.c-floating-panel__body--large{
			@include floating-panel-body-large;
		}
	}
	.c-floating-panel__list{
		background-color: var(--theme-color-neutrals-0);
		min-width: 273px;
		max-height: 312px;
		overflow-y: auto;
		border-radius: var(--radius-200);
		position: relative;
		z-index: 1;
		.c-list-item{
			&:first-child{
				margin-top: calc(var(--theme-global-spacing) * 2);
			}
			&:last-child{
				margin-bottom: calc(var(--theme-global-spacing) * 2);
			}
			.c-list-item__link{
				.c-list-item__link-icon{
					margin-right: calc(var(--theme-global-spacing) * 2);
				}
			}
		}
		@media (max-width: map-get($context--breakpoints, sm-max)) {
			min-width: 100%;
		}
	}
}
.c-floating-panel__footer {
	border-color: var(--theme-color-neutrals-400);
	padding: calc(var(--theme-global-spacing) * 4);
	.c-floating-panel__footer-button-group {
		.u-button.u-button--link {
			color: var(--theme-color-500);
			text-decoration: underline;
			padding-left: 0;
			padding-right: 0;
			&:hover {
				color: var(--theme-color-950);
			}
			&:disabled {
				background-color:var(--theme-transparent-background);
				border-color:var(--theme-transparent-background);
				color:var(--theme-color-neutrals-400);
			}
		}
	}
}

// caret
.c-floating-panel__caret,
.c-floating-panel__caret::before {
	background: var(--theme-color-neutrals-0);
}
.c-floating-panel[data-popper-placement^="top"] > .c-floating-panel__caret:before {
	border-bottom-color: var(--theme-color-neutrals-400);
	border-right-color: var(--theme-color-neutrals-400);
}
.c-floating-panel[data-popper-placement^="bottom"] > .c-floating-panel__caret:before {
	border-top-color: var(--theme-color-neutrals-400);
	border-left-color: var(--theme-color-neutrals-400);
}
.c-floating-panel[data-popper-placement^="left"] > .c-floating-panel__caret:before {
	border-top-color: var(--theme-color-neutrals-400);
	border-right-color: var(--theme-color-neutrals-400);
}
.c-floating-panel[data-popper-placement^="right"] > .c-floating-panel__caret:before {
	border-bottom-color: var(--theme-color-neutrals-400);
	border-left-color: var(--theme-color-neutrals-400);
}
/*to match with figma design added this CSS*/
.c-floating-panel__button{
	.u-button--square{
		flex-direction: row-reverse;
		padding: calc(var(--theme-global-spacing) * 2.5) calc(var(--theme-global-spacing) * 3.6);
		.u-icon.icon-dots-vertical{
			margin-left: calc(var(--theme-global-spacing) * 0);
			margin-right: calc(var(--theme-global-spacing) * 2);
		}
	}
}