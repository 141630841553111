$shadow: (
    diffuse-0: 0 0 0 0,
    diffuse-2: 0 0 2px 0,
    diffuse-4: 0 2px 4px 0,
    diffuse-8: 0 4px 8px 0,
    diffuse-12: 0 6px 12px 0,
    diffuse-16: 0 8px 16px 0,
    diffuse-24: 0 12px 24px 0,
);

:root {
    @media (min-width: map-get($context--breakpoints, xxl)) {
        --box-shadow: 0 12px 24px 0
    }
    @media (max-width: map-get($context--breakpoints, xl-max)) {
        --box-shadow: 0 8px 16px 0
    }
    @media (max-width: map-get($context--breakpoints, lg-max)) {
        --box-shadow: 0 6px 12px 0
    }
    @media (max-width: map-get($context--breakpoints, md-max)) {
        --box-shadow: 0 4px 8px 0
    }
    @media (max-width: map-get($context--breakpoints, sm-max)) {
        --box-shadow: 0 2px 4px 0
    }
    @media (max-width: map-get($context--breakpoints, xs-max)) {
        --box-shadow: 0 0 2px 0
    }   
}
