.c-loader {
	background-color: var(--theme-color-neutrals-0);
	margin: calc(var(--theme-global-spacing) * 0) auto;
	.c-loader__block {
		background: conic-gradient(var(--theme-color-neutrals-0) 20%, var(--theme-color-500));
		-webkit-mask: radial-gradient(farthest-side, var(--theme-transparent-background) calc(100% - 8px), var(--theme-color-neutrals-1000) 0);
	}
	.c-loader__title {
		color: var(--theme-color-neutrals-1000);
		padding-top: calc(var(--theme-global-spacing) * 4);
		font-weight: var(--font-weight-bold);
	}
}