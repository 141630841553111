//breadcrumbs
.c-breadcrumbs {
    margin: calc(var(--theme-global-spacing) * 0);
    padding: calc(var(--theme-global-spacing) * 0);
    background-color: var(--theme-color-neutrals-0);
    // breadcrumbs item
    .c-breadcrumbs__item {
        color: var(--theme-color-neutrals-1000);
        @include context--base-sm;
        .c-breadcrumbs__icon,
        .icon-chevron-slash {
            @include context--base;
        }
        .c-breadcrumbs__icon.icon-chevron-right,
        .c-breadcrumbs__icon.icon-chevron-slash {
            padding-left: calc(var(--theme-global-spacing) * 1);
            @media (min-width: map-get($context--breakpoints, xxl)) {
                padding-left: calc(var(--theme-global-spacing) * 1);
            }
            @media (max-width: map-get($context--breakpoints, sm-max)) {
                padding-left: calc(var(--theme-global-spacing) * 1);
            }
        }
    }
    // breadcrumbs link
    .c-breadcrumbs__link {
        color: var(--theme-color-neutrals-1000);
        &:hover,
        &:focus {
            color: var(--theme-color-neutrals-1000);
            border-radius: var(--radius);
        }
    }
}
.c-breadcrumbs__item{
    padding-left: calc(var(--theme-global-spacing) * 1);
}
