.c-number-input {
    top: calc(var(--theme-global-spacing) * 0);
    right: calc(var(--theme-global-spacing) * 0);
    width: calc(var(--theme-global-spacing) * 8);
    height: calc(100% - calc(var(--theme-global-spacing) * 2));
    border-radius: 0 var(--radius) var(--radius) 0;
}
  
.c-number-input__button {
    height: calc(var(--theme-global-spacing) * 6);
    padding: 0;
    margin: 0;
    background-color: var(--theme-transparent-background);
    border: 0;
    border-radius: calc(var(--radius) * 0);
    cursor: pointer;
    color: var(--theme-color-neutrals-1000);
    -webkit-tap-highlight-color: var(--theme-transparent-background);
    &:hover{
        background-color: var(--theme-color-neutrals-1000);
        color: var(--theme-color-neutrals-0);
    }
    &:focus,
    &:focus:focus-visible,
    &:focus-visible{
        outline-color: var(--theme-color-950);
    }
    &[type="button"]{
        &:focus,
        &:focus:focus-visible,
        &:focus-visible{
            outline-color: var(--theme-color-950);
        }
    }
    &:disabled,  &.disabled{
        background-color: var(--theme-transparent-background);
        color: var(--theme-color-neutrals-400);
        cursor: not-allowed;
    }
}

.c-number-input__button .u-icon[class*=" icon-"] {
    font-size: var(--text-base-size);
}
input[type=number].c-input-group__control {
    padding-right: calc(var(--theme-global-spacing) * 10);
}
/*Override all the hover styles only for touch devices*/
@media (hover:none),
(hover:on-demand) {
    .c-number-input__button:hover {
        background-color: var(--theme-transparent-background);
        color: var(--theme-color-neutrals-1000);
    }
    .c-number-input__button:disabled:hover, 
    .c-number-input__button.disabled:hover{
        color: var(--theme-color-neutrals-400);
    }
}