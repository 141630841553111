.c-action-card {
    border-color: var(--theme-color-neutrals-200);
    background-color: var(--theme-color-neutrals-0);
    border-radius: var(--radius-200);
    padding: calc(var(--theme-global-spacing) * 6);
    @media (max-width: map-get($context--breakpoints, md-max)) {
        padding: calc(var(--theme-global-spacing) * 4);
    }
    .c-action-card__header {
        padding-right: calc(var(--theme-global-spacing) * 4);
        .c-action-card__header-icon {
            margin-right: calc(var(--theme-global-spacing) * 4);
            @include font-style(var(--text-xl), var(--text-xl));
            color: var(--theme-success);
            @media (max-width: map-get($context--breakpoints, md-max)) {
                @include font-style(var(--text-xs), var(--text-xs));
            }
        }
        .c-action-card__title {
            margin: calc(var(--theme-global-spacing) * 0);
            @include context--font-size-h2;
            @media (min-width: map-get($context--breakpoints, xxl)) {
                @include font-style(var(--text-xs), var(--text-md));
            }
        }
        .c-action-card__header-icon+.c-action-card__header-content .c-action-card__title {
            @media (max-width: map-get($context--breakpoints, md-max)) {
                padding-left: calc(var(--theme-global-spacing) * 8);
            }
        }
        /*Added  this css because conetent is getting added from test harness*/
        .c-action-card__date {
            display: flex;
            flex-wrap: wrap;
            margin: calc(var(--theme-global-spacing) * 0);
            padding-top: calc(var(--theme-global-spacing) * 1);
            .c-action-card__date-details {
                @include context--base-sm;
                padding-right: calc(var(--theme-global-spacing) * 4);
                margin: calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 0);
                border-right: 1px solid var(--theme-color-neutrals-150);
                &:last-child {
                    margin-right: calc(var(--theme-global-spacing) * 0);
                    border: none;
                }
                @media (max-width: map-get($context--breakpoints, md-max)) {
                    padding-right: calc(var(--theme-global-spacing) * 0);
                    margin: calc(var(--theme-global-spacing) * 0);
                    border: none;
                    padding-top: calc(var(--theme-global-spacing) * 1);
                }
            }
            .c-action-card__date-details-label {
                color: var(--theme-color-neutrals-600);
                padding-right: calc(var(--theme-global-spacing) * 1);
            }
            @media (max-width: map-get($context--breakpoints, md-max)) {
                flex-direction: column;
            }
            @media (max-width: map-get($context--breakpoints, sm-max)) {
                padding-top: calc(var(--theme-global-spacing) * 2);
            }
        }
    }
    /*Added  this css because conetent is getting added from test harness*/
    .c-action-card__button-group {
        .c-action-card__button-group-item {
            margin-left: calc(var(--theme-global-spacing) * 4);
            @media (max-width: map-get($context--breakpoints, md-max)) {
                padding-top: calc(var(--theme-global-spacing) * 6);
                margin-left: calc(var(--theme-global-spacing) * 0);
                width: 100%;
                &:last-child{
                    padding-top: calc(var(--theme-global-spacing) * 4);
                }
                .u-button {
                    width: 100%;
                    justify-content: center;
                }
            }
            .c-dropdown .c-dropdown__checkbox {
                +.c-dropdown__button {
                    margin-bottom: calc(var(--theme-global-spacing) * 0);
                    padding: calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 12) calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 4);
                    font-weight: var(--font-weight-bold);
                    background-color: var(--theme-color-neutrals-100);
                    border-color: var(--theme-transparent-background);
                    height: 40px;
                    width: 100%;
                    .c-dropdown__button-text{
                        margin-right: calc(var(--theme-global-spacing) * 0);
                    }
                    .c-dropdown__button-toggle-icon{
                        position: absolute;
                        right: 16px;
                    }
                    @media (max-width: map-get($context--breakpoints, md-max)) {
                        padding: calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 4);
                        justify-content: center;
                        .c-dropdown__button-text {
                            order: 2;
                            padding-left: calc(var(--theme-global-spacing) * 2);
                            flex-basis: auto;
                            flex-grow: inherit;
                        }
                        .c-dropdown__button-toggle-icon {
                            order: 1;
                            right: 0;
                            position: relative;
                        }
                    }
                    +.c-dropdown__list-outer {
                        min-width: 312px;
                        right: 0;
                        border-color: var(--theme-color-neutrals-150);
                        top: calc(100% + 4px);
                        left: inherit;
                        @media (max-width: map-get($context--breakpoints, md-max)) {
                            min-width: 100%;
                        }
                    }
                }
            }
            .u-button--square{
                @media (min-width: map-get($context--breakpoints, xxl)) {
                  padding: calc(var(--theme-global-spacing) * 1.5) calc(var(--theme-global-spacing) * 4);
                }
            }
        }
    }
    .c-action-card__body {
        border-top-color: var(--theme-color-neutrals-150);
        margin-top: calc(var(--theme-global-spacing) * 6);
        @media (max-width: map-get($context--breakpoints, md-max)) {
            margin-top: calc(var(--theme-global-spacing) * 0);
        }
        .c-action-card__input-label {
            color: var(--theme-color-neutrals-800);
            .c-action-card__label-icon {
                @include context--base-sm;
                margin-right: calc(var(--theme-global-spacing) * 2);
            }
        }
        .c-action-card__input:focus+.c-action-card__input-label {
            outline-color: var(--theme-color-950);
            border-radius: var(--radius);
        }
        .c-action-card__body-content {
            padding-top: calc(var(--theme-global-spacing) * 2);
            @media (max-width: map-get($context--breakpoints, md-max)) {
                flex-wrap: wrap;
                padding-top: calc(var(--theme-global-spacing) * 4);
            }
            /*Added  this css because conetent is getting added from test harness*/
            .c-action-card__data {
                float: left;
                width: 50%;
                padding-top: calc(var(--theme-global-spacing) * 4);
                .c-action-card__data-list {
                    list-style: none;
                    @include context--base-sm;
                    margin: calc(var(--theme-global-spacing) * 0);
                    padding: calc(var(--theme-global-spacing) * 0);
                    .c-action-card__data-list-item {
                        display: flex;
                        margin-bottom: calc(var(--theme-global-spacing) * 4);
                        align-items: flex-start;
                        @media (max-width: map-get($context--breakpoints, md-max)) {
                            margin-bottom: calc(var(--theme-global-spacing) * 2);
                        }
                        .c-action-card__label {
                            flex: 33%;
                            color: var(--theme-color-neutrals-600);
                        }
                        .c-action-card__sub-text {
                            flex: 60%;
                            margin-left: calc(var(--theme-global-spacing) * 4);
                            .c-link {
                                margin-left: calc(var(--theme-global-spacing) * 2);
                                padding-right: calc(var(--theme-global-spacing) * 2);
                                @include context--base-sm;
                                text-decoration: underline;
                            }
                            @media (max-width: map-get($context--breakpoints, md-max)) {
                                display: flex;
                                flex-direction: column;
                                .c-link {
                                    margin-left: calc(var(--theme-global-spacing) * 0);
                                }
                                .c-action-card__sub-text-value {
                                    padding-left: calc(var(--theme-global-spacing) * 2);
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2;
                                    -webkit-box-orient: vertical;
                                }
                            }
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
                @media (max-width: map-get($context--breakpoints, xl)) {
                    width: 50%;
                }
                @media (max-width: map-get($context--breakpoints, lg-max)) {
                    width: 30%;
                }
                @media (max-width: map-get($context--breakpoints, md-max)) {
                    width: 100%;
                }
            }
            /*Added  this css because conetent is getting added from test harness*/
            .c-action-card__indicators {
                float: right;
                width: 50%;
                padding-top: calc(var(--theme-global-spacing) * 4);
                .c-action-card__indicators-list {
                    list-style: none;
                    display: flex;
                    justify-content: flex-end;
                    padding: calc(var(--theme-global-spacing) * 0);
                    .c-action-card__indicators-list-item {
                        margin: calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 4);
                        .c-action-card__indicators-list-icon {
                            @include context--medium;
                            display: inline-block;
                            margin-top: calc(var(--theme-global-spacing) * 5);
                        }
                        .c-indicator {
                            max-width: 77px;
                            @media (min-width: map-get($context--breakpoints, xxl)) {
                                max-width: 88px;
                            }
                            @media (max-width: map-get($context--breakpoints, md-max)) {
                                flex-direction: row;
                                .c-indicator__count{
                                    width: 40px;
                                    height: 40px;
                                    flex-shrink: 0;
                                }
                                .c-indicator__link, .c-indicator__label{
                                    margin-left: calc(var(--theme-global-spacing) * 2);
                                    margin-top: calc(var(--theme-global-spacing) * 0);
                                }
                            }
                        }
                        &:last-child{
                            @media (max-width: map-get($context--breakpoints, md-max)) {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
                @media (max-width: map-get($context--breakpoints, xl)) {
                    width: 50%;
                }
                @media (max-width: map-get($context--breakpoints, lg-max)) {
                    width: 70%;
                }
                @media (max-width: map-get($context--breakpoints, md-max)) {
                    margin-top: calc(var(--theme-global-spacing) * 0);
                    width: 100%;
                    padding-top: calc(var(--theme-global-spacing) * 6);
                    .c-action-card__indicators-list {
                        flex-wrap: wrap;
                        margin: calc(var(--theme-global-spacing) * 0);
                        .c-action-card__indicators-list-item {
                            width: 100%;
                            margin: calc(var(--theme-global-spacing) * 1) calc(var(--theme-global-spacing) * 0);
                            &:first-child {
                                margin-top: calc(var(--theme-global-spacing) * 0)
                            }
                            //test-harness HTML so updated the below css in theme file
                            .c-action-card__indicators-list-icon {
                                display: none;
                            }
                            .c-indicator {
                                max-width: 100%;
                            }
                        }
                    }
                }
            }
            .c-accordion-outer {
                .c-accordion.c-accordion--title-left-icon {
                    .c-accordion__panel {
                        border-color: var(--theme-transparent-background);
                        .c-accordion__header {
                            padding: calc(var(--theme-global-spacing) * 1) calc(var(--theme-global-spacing) * 0);
                            font-weight: var(--font-weight-bold);
                            @media (max-width: map-get($context--breakpoints, md-max)) {
                                padding: calc(var(--theme-global-spacing) * 0);
                            }
                            .c-accordion__title-text {
                                padding-left: calc(var(--theme-global-spacing) * 6);
                            }
                            .c-accordion__title {
                                text-decoration: underline;
                                color: var(--theme-color-neutrals-800);
                            }
                            .c-accordion__action-icon {
                                @include context--base-sm;
                                color: var(--theme-color-neutrals-800);
                                font-size: var(--text-base-size);
                                @media (max-width: map-get($context--breakpoints, md-max)) {
                                    top: 3px;
                                    left: 0;
                                }
                            }
                        }
                        .c-accordion__content {
                            padding: calc(var(--theme-global-spacing) * 0);
                            @media (max-width: map-get($context--breakpoints, md-max)) {
                                flex-wrap: wrap;
                                padding-top: calc(var(--theme-global-spacing) * 4);
                                .c-action-card__data {
                                    padding: calc(var(--theme-global-spacing) * 0);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}