body {
  .u-button {
    @include font-style(var(--text-base-size), var(--text-xs));
    font-weight: var(--font-weight-bold);
    font-family: var(--font-primary);
    border-radius: var(--radius);
    padding: calc(var(--theme-global-spacing) * 1.5)
      calc(var(--theme-global-spacing) * 4);
    @media (min-width: map-get($context--breakpoints, xxl)) {
      padding: calc(var(--theme-global-spacing) * 2.5)
        calc(var(--theme-global-spacing) * 4);
    }
    @media (max-width: map-get($context--breakpoints, sm-max)) {
      padding: calc(var(--theme-global-spacing) * 1.5)
        calc(var(--theme-global-spacing) * 4);
    }
    .c-spinner {
      overflow: hidden;
      margin: calc(var(--theme-global-spacing) * 0) ;
      .c-spinner__block{
        width: calc(var(--theme-global-spacing) * 6);
        height: calc(var(--theme-global-spacing) * 6);
        background: conic-gradient(transparent 20%, var(--theme-color-500) 80%);
      }
    }
    //Primary Button
    &.u-button--primary {
      background-color: var(--theme-color-500);
      color: var(--theme-color-neutrals-0);
      border-color: var(--theme-color-500);
      -webkit-tap-highlight-color: var(--theme-transparent-background);
      &:hover {
        background-color: var(--theme-color-950);
        border-color: var(--theme-color-950);
      }
      &:focus {
        outline-color: var(--theme-color-950);
      }
      &:active {
        background-color: var(--theme-color-700);
        border-color: var(--theme-color-700);
      }
      &:disabled,
      &.disabled,
      &.u-button--disabled {
        background-color: var(--theme-color-200);
        border-color: var(--theme-color-200);
        color: var(--theme-color-neutrals-0);
      }
      .c-spinner {
        .c-spinner__block{
          background: conic-gradient(transparent 20%, var(--theme-color-neutrals-0) 80%);
        }
      }
    }
    //Secondary Button
    &.u-button--secondary {
      background-color: var(--theme-color-neutrals-0);
      color: var(--theme-color-neutrals-1000);
      border-color: var(--theme-color-500);
      -webkit-tap-highlight-color: var(--theme-transparent-background);
      &:hover {
        background-color: var(--theme-color-neutrals-0);
        color: var(--theme-color-neutrals-1000);
        border-color: var(--theme-color-950);
      }
      &:focus {
        outline-color: var(--theme-color-950);
      }
      &:active {
        background-color: var(--theme-color-neutrals-0);
        border-color: var(--theme-color-700);
      }
      &:disabled,
      &.disabled,
      &.u-button--disabled {
        background-color: var(--theme-color-neutrals-0);
        border-color: var(--theme-color-200);
        color: var(--theme-color-neutrals-400);
      }
    }
    //Default Button
    &.u-button--default {
      background-color: var(--theme-color-neutrals-100);
      color: var(--theme-color-neutrals-1000);
      border-color: var(--theme-color-neutrals-100);
      &:hover {
        background-color: var(--theme-color-neutrals-950);
        color: var(--theme-color-neutrals-0);
        border-color: var(--theme-color-neutrals-950);
      }
      &:focus {
        outline-color: var(--theme-color-950);
      }
      &:active {
        background-color: var(--theme-color-neutrals-200);
        border-color: var(--theme-color-neutrals-200);
        color: var(--theme-color-neutrals-1000);
      }
      &:disabled,
      &.disabled,
      &.u-button--disabled {
        background-color: var(--theme-color-neutrals-50);
        border-color: var(--theme-color-neutrals-50);
        color: var(--theme-color-neutrals-400);
      }
    }
    //Success Button
    &.u-button--success {
      background-color: var(--theme-success-light);
      color: var(--theme-color-neutrals-1000);
      border-color: var(--theme-success);
      &:hover {
        background-color: var(--theme-success);
        border-color: var(--theme-success);
        color: var(--theme-color-neutrals-0);
      }
      &:focus {
        outline-color: var(--theme-color-950);
      }
      &:active {
        background-color: var(--theme-success);
        border-color: var(--theme-success);
        color: var(--theme-color-neutrals-0);
      }
      &:disabled,
      &.disabled,
      &.u-button--disabled {
        background-color: var(--theme-success-light);
        border-color: var(--theme-color-neutrals-200);
        color: var(--theme-color-neutrals-400);
      }
      .c-spinner {
        .c-spinner__block{
          background: conic-gradient(transparent 20%, var(--theme-success) 80%);
        }
      }
    }
    //Danger Button
    &.u-button--danger {
      background-color: var(--theme-error-light);
      color: var(--theme-color-neutrals-1000);
      border-color: var(--theme-error);
      &:hover {
        background-color: var(--theme-error);
        border-color: var(--theme-error);
        color: var(--theme-color-neutrals-0);
      }
      &:focus {
        outline-color: var(--theme-color-950);
      }
      &:active {
        background-color: var(--theme-error);
        border-color: var(--theme-error);
        color: var(--theme-color-neutrals-0);
      }
      &:disabled,
      &.disabled,
      &.u-button--disabled {
        background-color: var(--theme-error-light);
        border-color: var(--theme-color-neutrals-200);
        color: var(--theme-color-neutrals-400);
      }
      .c-spinner {
        .c-spinner__block{
          background: conic-gradient(transparent 20%, var(--theme-error) 80%);
        }
      }
    }
    //Warning Button
    &.u-button--warning {
      background-color: var(--theme-warning-light);
      color: var(--theme-color-neutrals-1000);
      border-color: var(--theme-warning);
      &:hover {
        background-color: var(--theme-warning);
        border-color: var(--theme-warning);
        color: var(--theme-color-neutrals-0);
      }
      &:focus {
        outline-color: var(--theme-color-950);
      }
      &:active {
        background-color: var(--theme-warning);
        border-color: var(--theme-warning);
        color: var(--theme-color-neutrals-0);
      }
      &:disabled,
      &.disabled,
      &.u-button--disabled {
        background-color: var(--theme-warning-light);
        border-color: var(--theme-color-neutrals-200);
        color: var(--theme-color-neutrals-400);
      }
      .c-spinner {
        .c-spinner__block{
          background: conic-gradient(transparent 20%, var(--theme-warning) 80%);
        }
      }
    }
    //Info Button
    &.u-button--info {
      background-color: var(--theme-color-50);
      color: var(--theme-color-neutrals-1000);
      border-color: var(--theme-color-200);
      &:hover {
        background-color: var(--theme-color-100);
        border-color: var(--theme-color-100);
        color: var(--theme-color-500);
      }
      &:focus {
        outline-color: var(--theme-color-950);
      }
      &:active {
        background-color: var(--theme-color-50);
        border-color: var(--theme-color-500);
        color: var(--theme-color-neutrals-1000);
      }
      &:disabled,
      &.disabled,
      &.u-button--disabled {
        background-color: var(--theme-color-50);
        border-color: var(--theme-color-neutrals-200);
        color: var(--theme-color-neutrals-400);
      }
      .c-spinner {
        .c-spinner__block{
          background: conic-gradient(transparent 20%, var(--theme-color-200) 80%);
        }
      }
    }
    //Link Button
    &.u-button--link {
      background-color: var(--theme-color-neutrals-0);
      color: var(--theme-color-neutrals-1000);
      border-color: var(--theme-color-neutrals-0);
      -webkit-tap-highlight-color: var(--theme-transparent-background);
      &:hover {
        background-color: var(--theme-color-neutrals-0);
        color: var(--theme-color-neutrals-1000);
        border-color: var(--theme-color-neutrals-0);
      }
      &:focus {
        outline-color: var(--theme-color-950);
      }
      &:active {
        background-color: var(--theme-color-neutrals-0);
        color: var(--theme-color-neutrals-1000);
        border-color: var(--theme-color-neutrals-0);
      }
      &:disabled,
      &.disabled,
      &.u-button--disabled {
        background-color: var(--theme-color-neutrals-0);
        border-color: var(--theme-color-neutrals-0);
        color: var(--theme-color-neutrals-400);
      }
    }
    //Tartiary Button
    &.u-button--tertiary {
      background-color: var(--theme-color-neutrals-100);
      color: var(--theme-color-neutrals-1000);
      border-color: var(--theme-color-neutrals-100);
      &:hover {
        background-color: var(--theme-color-neutrals-1000);
        color: var(--theme-color-neutrals-0);
        border-color: var(--theme-color-neutrals-1000);
      }
      &:focus {
        outline-color: var(--theme-color-950);
      }
      &:active {
        background-color: var(--theme-color-neutrals-200);
        color: var(--theme-color-neutrals-1000);
        border-color: var(--theme-color-neutrals-200);
      }
      &:disabled,
      &.disabled,
      &.u-button--disabled {
        background-color: var(--theme-color-neutrals-50);
        border-color: var(--theme-color-neutrals-50);
        color: var(--theme-color-neutrals-400);
      }
    }
    //Right Icon Button
    &.u-button-icon-right .u-icon {
      margin-left: calc(var(--theme-global-spacing) * 2);
    }
    //Left Icon Button
    &.u-button-icon-left .u-icon {
      margin-right: calc(var(--theme-global-spacing) * 2);
    }
    //Large Button
    &.u-button--large {
      padding: calc(var(--theme-global-spacing) * 2.5) calc(var(--theme-global-spacing) * 4);
      height: calc(var(--theme-global-spacing) * 12);
      .u-icon {
        font-size: var(--text-xs);
      }
      .c-spinner .c-spinner__block {
        width: calc(var(--theme-global-spacing) * 8);
        height: calc(var(--theme-global-spacing) * 8);
      }
    }
    //Small Button
    &.u-button--small {
      padding: calc(var(--theme-global-spacing) * 0.5) calc(var(--theme-global-spacing) * 4);
      height: calc(var(--theme-global-spacing) * 8);
        .u-icon {
          font-size: var(--text-base-size);
        }
      .c-spinner .c-spinner__block {
        width: calc(var(--theme-global-spacing) * 4);
        height: calc(var(--theme-global-spacing) * 4);
        -webkit-mask: radial-gradient(farthest-side,var(--theme-transparent-background) calc(100% - var(--theme-global-spacing) * 0.5),var(--theme-color-neutrals-1000) 0);
      }
    }
    //Circle Button
    &.u-button--circle {
      border-radius: var(--radius-800);
    }
    //Square Button
    &.u-button--square {
      border-radius: var(--radius);
    }
    //ghost Button
    &.u-button--ghost {
      background-color: var(--theme-color-neutrals-0);
      color: var(--theme-color-neutrals-1000);
      border-color: var(--theme-color-neutrals-0);
      &:hover {
        background-color: var(--theme-color-950);
        border-color: var(--theme-color-950);
        color: var(--theme-color-neutrals-0);
      }
      &:focus {
        outline-color: var(--theme-color-950);
      }
      &:active {
        background-color: var(--theme-color-neutrals-0);
        color: var(--theme-color-neutrals-1000);
        border-color: var(--theme-color-neutrals-0);
      }
      &:disabled,
      &.disabled,
      &.u-button--disabled {
        background-color: var(--theme-color-neutrals-0);
        border-color: var(--theme-color-neutrals-0);
        color: var(--theme-color-neutrals-400);
      }
      &.u-button-icon-left .u-icon {
        margin-right: calc(var(--theme-global-spacing) * 0);
      }
      &.u-button-icon-right .u-icon {
        margin-left: calc(var(--theme-global-spacing) * 0);
      }
    }
    //ghost Outline Button
    &.u-button--ghost__outline {
      background-color: var(--theme-color-neutrals-0);
      border-color: var(--theme-color-neutrals-1000);
      &.u-button-icon-left .u-icon {
        margin-right: calc(var(--theme-global-spacing) * 0);
      }
      &.u-button-icon-right .u-icon {
        margin-left: calc(var(--theme-global-spacing) * 0);
      }
    }
  }
  .u-button[aria-label=""]{
    .c-spinner {
      margin: calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 8.4) ;
    }
  }
  .u-button.u-button--back.u-button--link {
    color: var(--theme-color-neutrals-1000);
    padding: calc(var(--theme-global-spacing) * 2);
    border-color: var(--theme-color-500);
    &:hover {
      background-color: var(--theme-color-neutrals-1000);
      border-color: var(--theme-color-neutrals-1000);
      .u-icon {
        color: var(--theme-color-neutrals-0);
      }
    }
    &:focus {
      outline-color: var(--theme-color-950);
    }
    &:focus {
      outline-color: var(--theme-color-950);
    }
    @media (max-width: map-get($context--breakpoints, sm-max)) {
      padding: calc(var(--theme-global-spacing) * 1.5);
    }
    @media (min-width: map-get($context--breakpoints, xxl)) {
      padding: calc(var(--theme-global-spacing) * 2.5);
    }
  }
}
.content--buttons .u-button {
  margin: calc(var(--theme-global-spacing) * 1);
}
.u-button--primary__outline,
.u-button--secondary__outline,
.u-button--default__outline,
.u-button--success__outline,
.u-button--danger__outline,
.u-button--warning__outline,
.u-button--info__outline,
.u-button--link__outline,
.u-button--tertiary__outline  {
  border-color: var(--theme-color-neutrals-1000);
}
@media (hover:none),
(hover:on-demand) {
  body .u-button.u-button--default:hover{
    background-color: var(--theme-color-neutrals-100);
    color: var(--theme-color-neutrals-1000);
    border-color: var(--theme-color-neutrals-100);
  }
  body .u-button.u-button--primary:hover{
    background-color: var(--theme-color-500);
    color: var(--theme-color-neutrals-0);
    border-color: var(--theme-color-500);
  }
  body .u-button.u-button--secondary:hover{
    background-color: var(--theme-color-neutrals-0);
    color: var(--theme-color-neutrals-1000);
    border-color: var(--theme-color-500);
  }
  body .u-button.u-button--tertiary:hover {
    background-color: var(--theme-color-neutrals-100);
    color: var(--theme-color-neutrals-1000);
    border-color: var(--theme-color-neutrals-100);
  }
  body .u-button.u-button--tertiary:disabled:hover, 
  body .u-button.u-button--tertiary.disabled:hover, 
  body .u-button.u-button--tertiary.u-button--disabled:hover {
    background-color: var(--theme-color-neutrals-50);
    border-color: var(--theme-color-neutrals-50);
    color: var(--theme-color-neutrals-400);
  }
  body .u-button.u-button--back.u-button--link:hover {
    background-color: var(--theme-transparent-background);
      color: var(--theme-color-neutrals-1000);
      border-color: var(--theme-color-500);
      .u-icon {
        color: inherit;
      }
  }
  body .u-button.u-button--secondary.disabled:hover, 
  body .u-button.u-button--secondary.u-button--disabled:hover,
  body .u-button.u-button--secondary:disabled:hover {
    background-color: var(--theme-color-neutrals-0);
    border-color: var(--theme-color-200);
    color: var(--theme-color-neutrals-400);
  }
  body .u-button.u-button--primary:disabled:hover,
  body .u-button.u-button--primary.disabled:hover, 
  body .u-button.u-button--primary.u-button--disabled:hover {
    background-color: var(--theme-color-200);
    border-color: var(--theme-color-200);
    color: var(--theme-color-neutrals-0);
  }
  body .u-button.u-button--default.disabled:hover, 
  body .u-button.u-button--default:disabled:hover,
  body .u-button.u-button--default.u-button--disabled:hover {
    background-color: var(--theme-color-neutrals-50);
    border-color: var(--theme-color-neutrals-50);
    color: var(--theme-color-neutrals-400);
  }
}