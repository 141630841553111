// info toast
@mixin toast-info {
	@include toast($toast-info);
}
// toast themes
@mixin toast-info {
	@if variable-exists('toast-info') {
		@include toast-theme($toast-info);
	}
}
@mixin toast-success {
	@if variable-exists('toast-success') {
		@include toast-theme($toast-success);
	}
}
@mixin toast-danger {
	@if variable-exists('toast-danger') {
		@include toast-theme($toast-danger);
	}
}
@mixin toast-warning {
	@if variable-exists('toast-warning') {
		@include toast-theme($toast-warning);
	}
}
// Base toast styles
@mixin toast ($theme) {
	@include toast-theme($theme);
}
// Add toast theme
@mixin toast-theme($theme) {
	color: map-get($theme, 'color');
	background-color: map-get($theme, 'backgroundColor');
	background-image: map-get($theme, 'backgroundImage');
	box-shadow: inset 0 0 0 2px map-get($theme, 'box-shadowColor');
}