/**indicator settings*/
$indicator-not-started-yet: (
	'backgroundColor': var(--theme-color-magenta-500),
	'borderColor' : var(--theme-color-magenta-200),
);
$indicator-not-completed: (
	'backgroundColor': var(--theme-color-purple-500),
	'borderColor' : var(--theme-color-purple-200),
);
$indicator-awaiting-grading: (
	'backgroundColor':var(--theme-border-orange-700),
	'borderColor' : var(--theme-color-orange-200),
);
$indicator-complete: (
	'backgroundColor':var(--theme-color-green-500),
	'borderColor' : var(--theme-color-green-200),
);