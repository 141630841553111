.c-error-state{
    padding: calc(var(--theme-global-spacing) * 12.5) calc(var(--theme-global-spacing) * 5);
    background-color: var(--theme-color-neutrals-0);
    @media (min-width: map-get($context--breakpoints, xxl)) {
        padding: calc(var(--theme-global-spacing) * 22.5) calc(var(--theme-global-spacing) * 12.5);
    }
    @media (max-width: map-get($context--breakpoints, sm-max)) {
        padding: calc(var(--theme-global-spacing) * 7.5) calc(var(--theme-global-spacing) * 5);
        .u-button.u-button--custom-error-state{
            width: 100%;
        }
    }
    .c-error-state__figure{
        margin: 0;
        margin-right: calc(var(--theme-global-spacing) * 7);
        @media (min-width: map-get($context--breakpoints, xxl)) {
            margin-right: calc(var(--theme-global-spacing) * 8);
        }
        @media (max-width: map-get($context--breakpoints, sm-max)) {
            margin-right: 0;
            margin-bottom: calc(var(--theme-global-spacing) * 8);
        }
    }
    .c-error-state__name{
        @include font-style(var(--text-xxxs), var(--text-xxs));
        margin-top: 0;
        margin-bottom: calc(var(--theme-global-spacing) * 2);
        @media (max-width: map-get($context--breakpoints, sm-max)) {
            margin-right: 0;
            margin-bottom: calc(var(--theme-global-spacing) * 3);
        }
    }
    .c-error-state__title{
        @include context--font-size-h2;
        margin-top: 0;
        margin-bottom: calc(var(--theme-global-spacing) * 2);
    }
    .c-error-state__description{
        @include font-style(var(--text-xxxs), var(--text-xxs));
        margin-top: 0;
        margin-bottom: calc(var(--theme-global-spacing) * 5);
        @media (min-width: map-get($context--breakpoints, xxl)) {
            margin-right: calc(var(--theme-global-spacing) * 8);
        }
        @media (max-width: map-get($context--breakpoints, sm-max)) {
            margin-bottom: calc(var(--theme-global-spacing) * 8);
        }
    }
    maced-button{
        margin-right: calc(var(--theme-global-spacing) * 2);
        @media (max-width: map-get($context--breakpoints, sm-max)) {
            margin-bottom: calc(var(--theme-global-spacing) * 3);
        }
    }
    // This is the link components utility whenever done the link components and we will remove the below CSS    
    .u-link-primary{
        color: var(--theme-color-neutrals-1000);
        @include font-style(var(--text-base-size), var(--text-xs));
        font-weight: var(--font-weight-bold);
        font-family: var(--font-primary);
        text-decoration: none;
        &:hover,
        &:focus{
            text-decoration: underline;
        }
        @media (max-width: map-get($context--breakpoints, sm-max)) {
            display: block;
            margin-top: calc(var(--theme-global-spacing) * 3);
        }
    }
}