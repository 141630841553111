.u-text-normal {
	font-weight: var(--font-weight-normal);
}

.u-text-bold {
	font-weight: var(--font-weight-bold);
}

.u-double-underline {
	@include u-double-underline;
}

.u-upper {
	text-transform: uppercase;
}
.u-capitalize {
	text-transform: capitalize;
}

.u-underline {
	text-decoration: underline;
	-webkit-text-decoration-skip: skip;
	text-decoration-skip-ink: auto;
}

.u-unstyled {
	font-weight: normal;
	font-style: normal;
}

body {
	@include context--base;
	font-weight: var(--font-weight-normal);
	font-family: var(--font-primary);
}

.u-h1,
h1 {
	@include context--font-size-h1;
	font-weight: var(--font-weight-bold);
}

.u-h2,
h2 {
	@include context--font-size-h2;
	font-weight: var(--font-weight-bold);
}

.u-h3,
h3 {
	@include context--font-size-h3;
	font-weight: var(--font-weight-bold);
}

.u-h4,
h4 {
	@include context--font-size-h4;
	font-weight: var(--font-weight-bold);
}

p,
pre {
	@include context--base;
}

small {
	@include context--smallest;
	font-weight: var(--font-weight-bold);
}

.u-text {
	&--sm {
		@include context--base-sm;
	}
	&--overline {
		text-decoration: overline;
		@include context--overline;
	}
}

.u-word-wrap {
	@include u-word-wrap;
}

.u-lower {
	text-transform: lowercase;
}