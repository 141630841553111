
[data-theme="calm-blue"],[data-theme="purple-sky"], [data-theme="turtle-bay"]{
    .page{
        &.page--grading, &.page--progress-courses{
            .u-button.u-button--medium{
                padding: calc(var(--theme-global-spacing)* 4) calc(var(--theme-global-spacing)* 6);
            }
            [class^="main-layout main-layout--"]{
                // pt/grading page header
                .c-page-header{
                    .c-page-header__top-panel{
                        .c-page-header-link{
                            padding-top: 0;
                            .c-link, .u-button.u-button--link{
                                text-decoration: none;
                                border-radius: var(--radius-300);
                                background-color: var(--theme-color-neutrals-0);
                                color: var(--theme-color-neutrals-1000);
                                border-color: var(--theme-color-500);
                                border-width: 2px;
                                border-style: solid;
                                padding: calc(var(--theme-global-spacing)* 4) calc(var(--theme-global-spacing)* 6);
                                -webkit-tap-highlight-color: var(--theme-transparent-background);
                                max-height: 56px;
                                @media (min-width: map-get($context--breakpoints, sm-max)) {
                                    margin-bottom:calc(var(--theme-global-spacing)* 4);
                                }
                                &:hover {
                                    background-color: var(--theme-color-neutrals-0);
                                    color: var(--theme-color-neutrals-1000);
                                    border-color: var(--theme-color-950);
                                    text-decoration: none;
                                }
                                &:focus {
                                    outline-color: var(--theme-color-950);
                                    outline-width: 2px;
                                    outline-style: solid;
                                    outline-offset: 1px;
                                }
                                &:active {
                                    background-color: var(--theme-color-neutrals-0);
                                    border-color: var(--theme-color-700);
                                }
                                &:disabled,
                                &.disabled {
                                    background-color: var(--theme-color-neutrals-0);
                                    border-color: var(--theme-color-200);
                                    color: var(--theme-color-neutrals-400);
                                    cursor: default;
                                }
                                &.c-link--xsmall .u-icon, .u-icon.icon-chevron-left{
                                    font-size: var(--text-xs);
                                }
                                &.c-link--is-active .c-link__text{
                                    text-decoration: none;
                                }
                            }
                        }
                        .c-page-header-content{
                            .c-page-header-content__title{
                                @include font-style(var(--text-sm), var(--text-lg));
                            }
                        }
                        .c-page-header-meta-data .c-page-header__list{
                            @media (min-width: map-get($context--breakpoints, sm-max)) {
                                margin-top:calc(var(--theme-global-spacing)* 2);
                            }
                        }
                        .c-page-header-button-group {
                            .c-page-header__button-list .c-page-header__button-list-item .c-dropdown {
                                .c-dropdown__button{
                                    @media (max-width: map-get($context--breakpoints, sm-max)){
                                        padding: calc(var(--theme-global-spacing)* 4);
                                        height: 56px;
                                    }
                                }
                                .c-dropdown__list-outer {
                                    @media (min-width: map-get($context--breakpoints, sm-max)) {
                                        width: 366px;
                                        left: inherit;
                                        right: 0;
                                    }
                                }
                            }
                        }
                    }
                    .footer-pagination .c-pagination {
                        padding: calc(var(--theme-global-spacing)* 6) ;
                        .c-paggination__length .c-dropdown .c-dropdown__button{
                            height: 48px;
                        }
                    }
                }
                // pt/grading page header
            }
            .c-multiselect{
                &.c-multiselect--truncate{
                    @media (min-width: map-get($context--breakpoints, sm-max)) {
                        height: 56px;
                        max-height: 56px;
                    }
                    .c-multiselect__input{
                        @media (max-width: map-get($context--breakpoints, sm-max)) {
                            height: 56px;
                            align-items: center;
                        }
                    }
                    .c-multiselect__action .c-multiselect__checbox-label{
                        height: 54px;
                    }
                }
                &.c-multiselect--is-selected{
                    align-items: center;
                }
            }
            // pt/grading filter toolbar
            .c-filter-toolbar{
                .c-filter-toolbar__search-wrap .c-search-form{
                    .c-search-form__group{
                        border-radius: var(--radius-300);
                        .c-search-form__input{
                            height: 56px;
                        }
                        .c-search-form .c-search-form__clear-button{
                            height: 56px;
                        }
                    }
                    .c-search-form__search-icon, .c-search-form__clear-button{
                        height: 56px;
                        &:focus{
                            border-radius: var(--radius-300); 
                        }
                    }
                }
                .c-filter-toolbar__sort-filter-group .c-filter-toolbar__sort-filter-group-list {
                    .c-filter-toolbar__sort-filter-group-list-item .c-floating-panel{
                        .c-floating-panel__content{
                            @media (max-width: map-get($context--breakpoints, sm-max)) {
                                min-height: calc(100% - 89px);
                            }
                            .c-multiselect .c-multiselect__input{
                                border-radius: var(--radius-300);
                            }
                        }
                    }
                }
            }
            // pt/grading filter toolbar
            // grading card
            .c-grading-card .c-card__body{
                border-color: var(--theme-color-neutrals-200);
                .c-grading-card__button-group .c-grading-card__button-group-content .c-dropdown .c-dropdown__content .c-dropdown__button{
                    height: 56px;
                    @media (max-width: map-get($context--breakpoints, md-max)) {
                        padding-top: calc(var(--theme-global-spacing)* 4);
                        padding-bottom: calc(var(--theme-global-spacing)* 4);
                    }
                }
                .c-grading-card__attempt-cards .c-card{
                    border-radius: var(--radius-400);
                }
            }
            // grading card
            // progress card
            .c-progress-card{
                .c-card{
                    border-color: var(--theme-color-neutrals-200) !important; // require this properities at first for theme
                    .c-progress-card__data-list-item .c-card{
                        border-color: var(--theme-color-neutrals-150) !important; // require this properities at first for theme
                    }
                }
                .c-progress-card__header{
                    .c-progress-card__header-logo{
                        width: 56px;
                        height: 56px;
                    }
                    .c-progress-card__header-content .c-progress-card__title{
                        font-size: var(--text-xxs);
                    }
                }
                .c-progress-card__button-group .u-button{
                    height: calc(var(--theme-global-spacing)* 14) !important; // require this properities at first for theme
                }
            }
            // progress card
            .c-summary-bar-outer .c-summary-bar .c-accordion-outer .c-accordion .c-accordion__panel .c-accordion__header .c-accordion__action-icon{
                @media (max-width: map-get($context--breakpoints, sm-max)) {
                    font-size: var(--text-xs);
                }
            }
            .c-summary-bar-outer .c-summary-bar .c-summary-bar__content-outer, .feedback-card .c-function-card, .feedback-card .c-action-card{
                border-color: var(--theme-color-neutrals-200);
            }
            // radius 12px
            .c-summary-bar-outer .c-summary-bar .c-summary-bar__content-outer, .c-summary-bar-outer .c-summary-bar .c-accordion-outer .c-accordion, .c-multiselect,
            .main-layout.main-layout--grading-dashboard .c-filter-toolbar .c-filter-toolbar__sort-filter-group .c-filter-toolbar__sort-filter-group-list .c-filter-toolbar__sort-filter-group-list-item .c-floating-panel .c-floating-panel__content .c-floating-panel__body-block .c-floating-panel__body-block-list .c-checkbox__body-block-item .c-input-group--check-box:hover, 
            .main-layout.main-layout--grading-dashboard .c-filter-toolbar .c-filter-toolbar__sort-filter-group .c-filter-toolbar__sort-filter-group-list .c-filter-toolbar__sort-filter-group-list-item .c-floating-panel .c-floating-panel__content .c-floating-panel__body-block .c-floating-panel__body-block-list .c-floating-panel__body-block-list-checkbox .c-input-group--check-box:hover{
                border-radius: var(--radius-300);
            }
            // radius 16px
            .c-progress-card__body-content .c-card{
                border-radius: var(--radius-400);
            }
            // radius 20px
            .main-layout__content, .main-layout__content-empty, .main-layout__empty, .main-layout__content-table-data, .c-dialog .c-dialog__inner, .feedback-card .c-function-card, .feedback-card .c-action-card{
                border-radius: var(--radius-500) !important; // need this property at first for themes
            }
            .c-toast__close-button, .c-notification-inline__close-button {
                .u-button.u-button--fixed-size.u-button--large{
                    width: 48px;
                    height: 48px;
                    @media (max-width: map-get($context--breakpoints, sm-max)) {
                        width: 32px;
                        height: 32px;
                        padding: 0;
                    }
                }
            }
            .table-content .c-table-outer .c-table.c-table--bordered .c-table__body .c-table__row .c-table__row-column .c-dropdown .c-dropdown__content{
                .c-dropdown__button{
                    @media (max-width: map-get($context--breakpoints, md-max)) {
                        height: 56px !important; //need to overwrite globally
                    }
                }
                .c-dropdown__list-outer{
                    @media (max-width: map-get($context--breakpoints, md-max)) {
                        top: 60px;
                    }
                }
            }
            // pt/grading footer pagination
            .footer-pagination .c-pagination{
                padding: calc(var(--theme-global-spacing)* 6) !important; //need to overwrite globally
                @media (min-width: map-get($context--breakpoints, md-max)) {
                    max-height: 96px;
                }
            }
            //search student search box
            .main-layout.main-layout--search-student {
                .c-search-form{
                    .c-search-form__group{
                        border-radius: var(--radius-300);
                        .c-search-form__input{
                            height: 56px;
                            padding-right: calc(var(--theme-global-spacing)* 14);
                        }
                        .c-search-form .c-search-form__clear-button{
                            height: 56px;
                        }
                    }
                    .c-search-form__search-icon, .c-search-form__clear-button{
                        height: 56px;
                        &:focus{
                            border-radius: var(--radius-300); 
                        }
                    }
                }
            }
            // grading classes
            .main-layout.main-layout--grading-classes{
                .c-grading-card__header-block-heading{
                    @media (min-width: map-get($context--breakpoints, sm-max)) {
                        min-height: 56px;
                        display: flex;
                        align-items: center;
                    }
                }
                .class-cards .class-cards__item .c-grading-card .c-card__body .c-grading-card__header .c-grading-card__header-block-metadata{
                    @media (min-width: map-get($context--breakpoints, sm-max)) {
                        margin-top: calc(var(--theme-global-spacing)* 4);
                    }
                }
            }
            // grading student list
            .main-layout.main-layout--grading-student-list{
                .c-grading-card .c-card__body .c-grading-card__header .c-grading-card__header-block-metadata{
                    @media (min-width: map-get($context--breakpoints, sm-max)) {
                        margin-top: calc(var(--theme-global-spacing)* 7);
                    }
                }
            }
        }
        &.page--grading .page__container{
            //input check tick color
            .c-filter-toolbar .c-floating-panel{
                .c-multiselect__list .c-input-group .c-input-group__control--check:checked,
                .c-input-group .c-input-group__control--check:checked{
                    &:hover ~ .c-input-group__label:after{
                        filter: none;
                        color: var(--theme-color-neutrals-1000);
                    }
                }              
            }  
            //input check tick color
        }
    }
}
// css for temporary height purpose
.page.page--grading [class^="main-layout main-layout--"] .c-page-header .c-page-header__top-panel .c-page-header-meta-data .c-page-header__list{
    &.c-page-header__list--hide{
        margin-top: 0 !important;
        .c-page-header__list-item.u-display-none{
            display: none !important;
        }
    }
}