// modal main content part
.c-modal-content {
	padding: calc(var(--theme-global-spacing) * 6) calc(var(--theme-global-spacing) * 6);
	.c-modal-content__title {
		margin: 0 0 calc(var(--theme-global-spacing) * 2);
		@media (min-width: map-get($context--breakpoints, xxl)) {
			margin: 0 0 calc(var(--theme-global-spacing) * 3);
		}
	}
	.c-modal-content__text {
		@include context--base;
		margin: 0;
		margin-bottom: calc(var(--theme-global-spacing) * 6);
		@media (min-width: map-get($context--breakpoints, xxl)) {
			margin-bottom: calc(var(--theme-global-spacing) * 9);
		}
		@media (max-width: map-get($context--breakpoints, sm-max)) {
			margin-bottom: calc(var(--theme-global-spacing) * 5);
		}
	}
}