.c-tabs {
  .c-tabs__inner {
    padding: 0;
    background-color: var(--theme-color-neutrals-0);
    &:before{
      background-color: var(--theme-color-neutrals-150);
    }
  }
  &.c-tabs--padding .c-tabs__inner{
    padding:calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 4) 0 calc(var(--theme-global-spacing) * 4);
  }
  .c-tabs__nav {
    margin: calc(var(--theme-global-spacing) * 0);
    padding: calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 1.5) calc(var(--theme-global-spacing) * 0.5); 
    @media (max-width: map-get($context--breakpoints, md-max)) {
      padding: 0 0 calc(var(--theme-global-spacing) * 1.5) calc(var(--theme-global-spacing) * 0.5);
    }
  }
  .c-tabs__item {
    padding: calc(var(--theme-global-spacing) * 1) calc(var(--theme-global-spacing) * 0.5) 0;
    -webkit-tap-highlight-color: var(--theme-transparent-background);
    &.c-tabs__item--active{
      &::after{
        background-color: var(--theme-color-500);
      }
    }
  }
  .c-tabs__link {
    color: var(--theme-color-neutrals-1000);
    font-family: var(--font-primary);
    @include context--base;
	  font-weight: var(--font-weight-normal);
    background-color: var(--theme-color-neutrals-0);
    border-radius: var(--radius) var(--radius) 0 0;
    padding: calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 4);
    margin:0 calc(var(--theme-global-spacing) * 0.5) 0 calc(var(--theme-global-spacing) * 0.5) ;
    @media (min-width: map-get($context--breakpoints, xxl)) {
      padding: calc(var(--theme-global-spacing) * 3);
    }
    &:hover{
      background-color: var(--theme-color-950);
      color: var(--theme-color-neutrals-0);
      border-radius: var(--radius) var(--radius) 0 0;
    }
    &:active{
      background-color: var(--theme-color-950);
      color: var(--theme-color-neutrals-0);
      border-radius: var(--radius) var(--radius) 0 0;
    }
    &.c-tabs__link--active {
      font-weight: var(--font-weight-bold);
      &:hover{
        border-radius: var(--radius) var(--radius) 0 0;
      }
    }
    &:disabled,
    &.c-tabs__link--disabled, .c-tabs__link--disabled {
      color: var(--theme-color-neutrals-400);
      &:hover,
      &.active,
      &:active {
        color: var(--theme-color-neutrals-400);
      }
    }
    .c-tabs__link-icon{
      @include font-style(var(--text-xs), var(--text-xs));
      margin-right: calc(var(--theme-global-spacing) * 2);
    }
    .c-tabs__link-sublabel{
      @include font-style(var(--text-smallest), var(--text-xxxs));
      font-weight: var(--font-weight-bold);
    }
    .c-tabs__link-badge{
      margin-left: calc(var(--theme-global-spacing) * 2);
    }
  }
  .c-tabs__nav-button {
    background-color: var(--theme-color-neutrals-0);
    color: var(--theme-color-neutrals-1000);
    border-radius: var(--radius);
    padding:calc(var(--theme-global-spacing) * 2);
    -webkit-tap-highlight-color: var(--theme-transparent-background);
    &:hover{
      background-color: var(--theme-color-950);
      color: var(--theme-color-neutrals-0);
    }
    &:disabled,
    &.c-tabs__link--disabled, .c-tabs__link--disabled {
      color: var(--theme-color-neutrals-400);
      cursor: default;
      &:hover,
      &.c-tabs__link--active,
      &:active,
      &.focus,
      &:focus {
        color: var(--theme-color-neutrals-400);
        background-color: transparent;
      }
    }
    &:focus {
      outline-color: var(--theme-color-950);
    }
  }
  .c-tabs__nav-button .u-icon{
    @include font-style(var(--text-xs), var(--text-xs));
  }
  &.c-tabs--no-wrap{
    .c-tabs__inner{
      padding: 0 calc(var(--theme-global-spacing) * 11);
    }
    &.c-tabs--control-hide .c-tabs__inner{
      padding: 0;
    }
    &.c-tabs--padding .c-tabs__inner{
      padding:calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 14) 0;
    }
    &.c-tabs--control-hide.c-tabs--padding .c-tabs__inner{
      padding:calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 4) 0;
    }
  }
  @media (max-width: map-get($context--breakpoints, lg)) {
    &.c-tabs--swiper {
      &.c-tabs--no-wrap .c-tabs__inner{
          padding: 0 ;
      }
    }
  }
}
/*For Gray Background*/
.c-tabs--gray {
  .c-tabs__link {
    background-color: var(--theme-color-neutrals-50);
    color: var(--theme-color-neutrals-1000);
    &:hover {
      background-color: var(--theme-color-neutrals-950);
      color: var(--theme-color-neutrals-0);
    }
    &:active {
      background-color: var(--theme-color-150);
      color: var(--theme-color-neutrals-1000);
    }
    &:focus {
      border-radius: var(--radius);
    }
    &:disabled,
    &.c-tabs__link--disabled, .c-tabs__link--disabled {
      color: var(--theme-color-neutrals-400);
      &:hover,
      &.active,
      &:active {
        color: var(--theme-color-neutrals-400);
      }
    }
  }
  .c-tabs__link--active{
    background-color: var(--theme-color-150);
    &::after{
      background-color: var(--theme-color-500);
    }
    &:hover {
      color: var(--theme-color-neutrals-0);
    }
    .c-tabs__link{
      background-color: var(--theme-color-150);
      color: var(--theme-color-neutrals-1000);
    }
  }
}
/*For White Background*/
.c-tabs--white {
  .c-tabs__link {
    background-color: var(--theme-color-neutrals-0);
    color: var(--theme-color-neutrals-1000);
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    &:hover {
      background-color: var(--theme-color-neutrals-950);
      color: var(--theme-color-neutrals-0);
    }
    &:active {
      background-color: var(--theme-color-150);
      color: var(--theme-color-neutrals-1000);
    }
    &:focus {
      border-radius: var(--radius);
    }
    &:disabled,
    &.c-tabs__link--disabled, .c-tabs__link--disabled {
      color: var(--theme-color-neutrals-400);
      &:hover,
      &.active,
      &:active {
        color: var(--theme-color-neutrals-400);
      }
    }
  }
  .c-tabs__link--active{
    background-color: var(--theme-color-150);
    &::after{
      background-color: var(--theme-color-500);
    }
    &:hover {
      color: var(--theme-color-neutrals-0);
    }
    .c-tabs__link{
      background-color: var(--theme-color-150);
      color: var(--theme-color-neutrals-1000);
    }
  }
}
/*Simplified for equal spacing*/
.c-tabs--simplified {
  .c-tabs__link {
    padding: calc(var(--theme-global-spacing) * 2);
  }
}
.c-tabs.c-tabs--no-wrap.c-tabs--padding.c-tabs--simplified,
.c-tabs.c-tabs--no-wrap.c-tabs--padding.c-tabs--simplified.c-tabs--swiper {
  .c-tabs__inner{
    padding-top:calc(var(--theme-global-spacing) * 2);
    @media (max-width: map-get($context--breakpoints, lg)) {
      padding-top:0;
    }
  }
}
/*Override all the hover styles only for touch devices*/
@media (hover:none), (hover:on-demand) {
  .c-tabs .c-tabs__link:hover, 
  .c-tabs .c-tabs__link.hover,
  .c-tabs.c-tabs--white .c-tabs__link:hover,
  .c-tabs.c-tabs--white .c-tabs__link.hover {
    background-color: var(--theme-color-neutrals-0);
    color: var(--theme-color-neutrals-1000);
  }
  .c-tabs.c-tabs--gray .c-tabs__link:hover,
  .c-tabs.c-tabs--gray .c-tabs__link.hover {
    background-color: var(--theme-color-neutrals-50);
    color: var(--theme-color-neutrals-1000);
  }
  .c-tabs .c-tabs__nav-button:hover {
    background-color:   var(--theme-color-neutrals-0);
    color: var(--theme-color-950);
  }
}
.c-tabs--gray .c-tabs__item.c-tabs__link--active.c-tabs__item--active .c-tabs__link, .c-tabs--white .c-tabs__item.c-tabs__link--active.c-tabs__item--active .c-tabs__link {
  background-color: var(--theme-color-150);
  font-weight: var(--font-weight-bold);
}