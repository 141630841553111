.c-input-group {
  .c-input-group__label,
  .c-input-group__help-text {
    display: inline-block;
  }
  .c-input-group__control.c-input-group__control--datepicker {
    padding: calc(var(--theme-global-spacing) * 3) calc(var(--theme-global-spacing) * 12)
      calc(var(--theme-global-spacing) * 3) calc(var(--theme-global-spacing) * 4);
    border-color: var(--theme-color-neutrals-500);
    border-radius: var(--radius);
    color: var(--theme-color-neutrals-1000);
    @media (max-width: map-get($context--breakpoints, sm-max)) {
      background-position: calc(100% - (var(--theme-global-spacing) * 4)) calc(var(--theme-global-spacing) * 2);
    }
    &:focus::placeholder {
      color: var(--theme-color-neutrals-500);
    }
    &:hover {
      border-color: var(--theme-color-neutrals-950);
    }
    &:valid {
      color: var(--theme-color-neutrals-1000);
    }
  }
  .c-input-group__control.c-input-group__control--datepicker.owl-dt-trigger-disabled:focus::placeholder {
    color: var(--theme-color-neutrals-400);
  }
  .c-input-group__hide {
    &:focus ~ .c-input-group__control--datepicker {
      outline-color: var(--theme-color-neutrals-950);
    }
    &:disabled ~ .c-input-group__control--datepicker {
      background-color: var(--theme-color-neutrals-50);
      border-color: var(--theme-color-neutrals-200);
      color: var(--theme-color-neutrals-400);
    }
  }
  &.c-input-group--disabled {
    .c-input-group__label,
    .c-input-group__help-text {
      color: var(--theme-color-neutrals-400);
    }
  }
  &.c-input-group--error {
    .c-input-group__help-text {
      color: var(--theme-error);
    }
    .c-input-group__control--datepicker {
      border-color: var(--theme-error);
      &:hover {
        border-color: var(--theme-error);
      }
    }
  }
  .c-input-group__calendar-button {
    @media (max-width: map-get($context--breakpoints, md-max)) {
      background-color: var(--theme-transparent-background);
      color: var(--theme-color-neutrals-1000);
    }
  }
  .c-input-group__calendar-button.owl-dt-trigger-disabled {
    @media (max-width: map-get($context--breakpoints, md-max)) {
      background-color: var(--theme-transparent-background);
      color: var(--theme-color-neutrals-400);
    }
  }
}
.owl-dt-container-disabled,
.owl-dt-trigger-disabled {
  opacity: 1;
}
.owl-dt-inline-container,
.owl-dt-popup-container {
  box-shadow: map-get($shadow, diffuse-8) rgba(0, 0, 0, 0.25);
  border-radius: var(--radius-200);
  background-color: var(--theme-color-neutrals-0);
  border: none;
  width: 312px;
  .owl-dt-calendar {
    height: auto;
  }
  .owl-dt-calendar-control {
    padding: calc(var(--theme-global-spacing) * 4);
    border-bottom: 1px solid var(--theme-color-neutrals-150);
    .owl-dt-control-button {
      &.owl-dt-control-arrow-button .owl-dt-control-button-content {
        width: 32px;
        height: 32px;
        border-radius: var(--radius);
        &:hover {
          background-color: var(--theme-color-200);
        }
        &:focus {
          background-color: var(--theme-color-neutrals-0);
          outline-color: var(--theme-color-neutrals-1000);
 
        }
      }
      &:disabled .owl-dt-control-button-content:hover {
        background-color: var(--theme-color-neutrals-50);
        cursor: not-allowed;
      }
      &.owl-dt-control-period-button {
        font-family: var(--font-primary);
        font-weight: var(--font-weight-bold);
        @include context--base;
        color: var(--theme-color-neutrals-800);
        .owl-dt-control-button-arrow {
          display: none;
        }
        .owl-dt-control-button-content {
          text-decoration: underline;
          min-height: 32px;
        }
        &:hover {
          .owl-dt-control-button-content {
            text-decoration: none;
            background-color: var(--theme-color-200);
          }
        }
      }
      &:focus > .owl-dt-control-content {
        background-color: var(--theme-color-neutrals-0);
        outline-color: var(--theme-color-neutrals-1000);
        outline-width: 2px;
        outline-style: solid;
        outline-offset: 2px;
      }
      svg {
        width: 45%;
      }
    }
  }
  .owl-dt-calendar-main {
    padding: calc(var(--theme-global-spacing) * 4);
    .owl-dt-calendar-view {
      .owl-dt-calendar-table {
        .owl-dt-calendar-header {
          .owl-dt-weekday {
            @include context--base-sm;
            color: var(--theme-color-neutrals-500);
            padding-bottom: calc(var(--theme-global-spacing) * 1);
            text-transform: capitalize;
          }
          .owl-dt-calendar-table-divider {
            display: none;
          }
        }
        .owl-dt-calendar-cell {
          color: var(--theme-color-neutrals-1000);
          padding: calc(var(--theme-global-spacing) * 1) 0 0 !important;
          width: 40px !important;
          .owl-dt-calendar-cell-content {
            width: 100%;
            height: 40px;
            @include context--base;
            border-radius: var(--radius);
            position: relative;
            background-color: var(--theme-color-neutrals-0);
            top: 0;
            left: 0;
            //outline: none;
            &.owl-dt-calendar-cell-out {
              opacity: 1;
              color: var(--theme-color-neutrals-400);
            }
            &.owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
              border-color: var(--theme-color-500);             }
            &.owl-dt-calendar-cell-selected {
              background-color: var(--theme-color-500);
              border-color: var(--theme-color-500);
              color: var(--theme-color-neutrals-0);             
              &.owl-dt-calendar-cell-today {
                box-shadow: none;
              }
            }
          }
          &.owl-dt-calendar-cell-in-range {
            background-color: transparent;
            .owl-dt-calendar-cell-content {
              background-color: var(--theme-color-200);
              border-radius: 0;
              &.owl-dt-calendar-cell-selected {
                background-color: var(--theme-color-500);
              }
            }
          }
          &.owl-dt-calendar-cell-range-from .owl-dt-calendar-cell-content {
            border-radius: var(--radius) 0 0 var(--radius);
          }
          &.owl-dt-calendar-cell-range-to .owl-dt-calendar-cell-content {
            border-radius: 0 var(--radius) var(--radius) 0;
          }
          &:not(.owl-dt-calendar-cell-disabled):hover
          > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected){
             background-color: var(--theme-color-200);
          }
          &.owl-dt-calendar-cell-active:focus > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
            outline-color: var(--theme-color-neutrals-1000);
            outline-width: 2px;
            outline-style: solid;
            outline-offset: 2px;
            z-index: 1;
            box-shadow: 0 0 0 .2em var(--theme-color-neutrals-0);
         }
          &:focus{
            background-color: var(--theme-color-neutrals-0);
            .owl-dt-calendar-cell-content.owl-dt-calendar-cell-selected{
              outline-color: var(--theme-color-neutrals-1000);
              outline-width: 2px;
              outline-style: solid;
              outline-offset: 2px;
              z-index: 1;
              box-shadow: 0 0 0 .2em var(--theme-color-neutrals-0);
            }
            .owl-dt-calendar-cell-content.owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected){
              background-color: var(--theme-transparent-background);
            }
          }
          &.owl-dt-calendar-cell-disabled {
            .owl-dt-calendar-cell-content {
              border-color: var(--theme-color-neutrals-50);
              background-color: var(--theme-color-neutrals-50);
              cursor: not-allowed;
              opacity: 1;
              color: var(--theme-color-neutrals-400);
            }
          }
        }
        &.owl-dt-calendar-year-table {
          .owl-dt-calendar-cell-content {
            height: 56px;
          }
        }
      }
      &.owl-dt-calendar-multi-year-view {
        .owl-dt-control-arrow-button {
          position: absolute;
          top: 16px;
          left: 16px;
          .owl-dt-control-button-content {
            width: 32px;
            height: 32px;
            border-radius: var(--radius);
            &:hover {
              background-color: var(--theme-color-200);
            }
          }
          &:disabled .owl-dt-control-button-content:hover {
            background-color: var(--theme-color-neutrals-50);
            cursor: not-allowed;
          }
          &:focus > .owl-dt-control-content {
            background-color: var(--theme-color-200);
          }
          svg {
            width: 45%;
          }
        }
        .owl-dt-control-button{
          &:focus > .owl-dt-control-button-content{
            background-color: var(--theme-color-neutrals-0);
            outline-color: var(--theme-color-neutrals-1000);
            outline-width: 2px;
            outline-style: solid;
            outline-offset: 2px;
          }
        }
        .owl-dt-control-arrow-button + .owl-dt-calendar-multi-year-table + .owl-dt-control-arrow-button {
          left: calc(100% - 48px);
        }
        .owl-dt-calendar-table {
          width: 100%;
          .owl-dt-calendar-header {
            @include context--base-sm;
            color: var(--theme-color-neutrals-400);
            th {
              font-weight: var(--font-weight-normal);
            }
          }
        }
        .owl-dt-calendar-control .owl-dt-control-button{
          display: none;
        }
      }
    }
  }
  .owl-dt-container-info {
    display: none;
  }
}
