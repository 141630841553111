/**
 * Breakpoints
 * Shared media query values
 */

 $context--breakpoints: (
	'xs': 320px,
    'xs-md': 360px,
    'xs-max': 599px,
	'sm': 600px,
    'sm-max': 767px,
	'md': 768px,
    'md-max': 1023px,
	'lg': 1024px,
    'lg-md': 1060px,
    'lg-max': 1439px,
    'xl': 1440px,
    'xl-max': 1919px,
    'xxl': 1920px
);
// media queries

@mixin MQ($canvas) {
    @if $canvas == xs {
     @media only screen and (min-width: $xs) { @content; } 
    }
    @if $canvas == xs-md {
     @media only screen and (min-width: $xs-md) { @content; } 
    }
    @if $canvas == xs-max {
     @media only screen and (min-width: $xs-max) { @content; } 
    }
    @else if $canvas == sm {
     @media only screen and (min-width: $sm) { @content; } 
    }
    @if $canvas == sm-max {
     @media only screen and (min-width: $sm-max) { @content; } 
    }
    @else if $canvas == md {
     @media only screen and (min-width: $md) { @content; } 
    }
    @if $canvas == md-max {
     @media only screen and (min-width: $md-max) { @content; } 
    }
    @else if $canvas == lg {
     @media only screen and (min-width: $lg) { @content; } 
    }
    @else if $canvas == lg-md {
     @media only screen and (min-width: $lg) { @content; } 
    }
    @if $canvas == lg-max {
     @media only screen and (min-width: $lg-max) { @content; } 
    }
    @else if $canvas == xl {
     @media only screen and (min-width: $xl) { @content; } 
    }
    @if $canvas == xl-max {
     @media only screen and (min-width: $xl-max) { @content; } 
    }
    @else if $canvas == xxl {
    @media only screen and (min-width: $xxl) { @content; } 
    }
  }