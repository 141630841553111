.c-draggable {
    background-color: var(--theme-color-neutrals-0);
    color: var(--theme-color-neutrals-1000);
    border-color: var(--theme-color-neutrals-400);
    .c-draggable__actions {
        margin-right: calc(var(--theme-global-spacing) * 4);
        padding-right:calc(var(--theme-global-spacing) * 4) ;
        @media (max-width: map-get($context--breakpoints, xl)) {
            padding-right: calc(var(--theme-global-spacing) * 4);
        }
        @media (max-width: map-get($context--breakpoints, sm-max)) {
            padding:0 calc(var(--theme-global-spacing) * 3) 0 0;
        }
        @media (max-width: map-get($context--breakpoints, sm)) {
            padding-right: calc(var(--theme-global-spacing) * 1);
            margin-right: calc(var(--theme-global-spacing) * 0);
        }
        .c-draggable__actions-button {
            padding: calc(var(--theme-global-spacing) * 2);
            border-radius: calc(var(--theme-global-spacing) * 1) 0 calc(var(--theme-global-spacing) * 1) 0;
            color: var(--theme-color-neutrals-0);
            border-color: var(--theme-color-neutrals-400);
            background-color: var(--theme-color-neutrals-400);
            margin-right: calc(var(--theme-global-spacing) * 4);
            margin-left: calc(var(--theme-global-spacing) * 0);
            @media (max-width: map-get($context--breakpoints, sm)) {
                margin-right: calc(var(--theme-global-spacing) * 4);
            }
            &:hover {
                background-color: var(--theme-color-500);
                color: var(--theme-color-neutrals-0);
            }
            &:focus {
                outline-color: var(--theme-color-950);
                outline-style: solid;
                outline-offset: 1px;
                border-radius: var(--radius);
            }
            &:active {
                border-color: var(--theme-color-neutrals-400);
                background-color: var(--theme-color-neutrals-400);
            }
            &:disabled,
            &.disabled {
                background-color: var(--theme-color-neutrals-0);
                border-color: var(--theme-color-neutrals-200);
                color: var(--theme-color-neutrals-200);
            }
        }
        .c-draggable__actions-button--drag {
            background: var(--theme-color-neutrals-50);
            color: var(--theme-color-neutrals-400);
            padding-left: calc(var(--theme-global-spacing) * 0);
            padding-right: calc(var(--theme-global-spacing) * 0);
            border-radius:calc(var(--theme-global-spacing) * 1) 0 0 calc(var(--theme-global-spacing) * 1);
            &:hover,
            &:active {
                background-color: var(--theme-color-neutrals-50);
            }
            &:focus {
                border-radius: var(--radius);
            }
        }
    }
    .c-draggable__actions-button--close{
        border-radius: calc(var(--theme-global-spacing) * 1);
        padding: calc(var(--theme-global-spacing) * 2);
        @media (max-width: map-get($context--breakpoints, sm-max)) {
            padding: calc(var(--theme-global-spacing) * 0);
        }
    }
    .u-button.u-button--secondary.c-draggable__actions-button--close:hover {
        background-color: var(--theme-color-neutrals-1000);
        color: var(--theme-color-neutrals-0);
        /*Override all the hover styles only for touch devices*/
        @media (hover:none),
        (hover:on-demand) {
            background-color: inherit;
            color: inherit;
        }
    }
}