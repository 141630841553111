.c-headers {
  background-color: var(--theme-color-neutrals-0);
  .c-headers__wrapper {
    background-color: var(--theme-color-neutrals-0);
    padding: calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 8);
    @media (max-width: map-get($context--breakpoints, sm-max)) {
      padding: calc(var(--theme-global-spacing) * 4);
    }
    &:before {
      background-color: var(--theme-color-500);
    }
    .c-headers-title {
      margin: calc(var(--theme-global-spacing) * 0);
      .c-headers-title__text{
        @media (min-width: map-get($context--breakpoints, xxl)) {
            @include context--font-size-h4;
        } 
        @media (max-width: map-get($context--breakpoints, sm-max)) {
            @include font-style(var(--text-xxs), var(--text-sm));
        }
      }
    }
    .c-headers-title__sub-title {
      @include font-style(var(--text-base-size), var(--text-xs));
      font-family: var(--font-primary);
      font-weight: var(--font-weight-normal);
      @media (max-width: map-get($context--breakpoints, sm-max)) {
        @include font-style(var(--text-xxxs), var(--text-xxs));
      }
    }
    .c-headers__list {
      margin: calc(var(--theme-global-spacing) * 0);
      padding: calc(var(--theme-global-spacing) * 0);
      @media (max-width: map-get($context--breakpoints, sm-max)) {
        background-color: var(--theme-color-neutrals-0);
        padding: calc(var(--theme-global-spacing) * 1) calc(var(--theme-global-spacing) * 2.5);
      }
      @media (max-width: map-get($context--breakpoints, xs-max)) {
        margin-left: calc(var(--theme-global-spacing) * 0);
      }
    }
    .c-headers__branding .c-headers__title-back,
    .c-headers__branding .c-headers__title-cancel {
      margin-right: calc(var(--theme-global-spacing) * 6);
      @media (max-width: map-get($context--breakpoints, sm-max)) {
        margin-right: calc(var(--theme-global-spacing) * 4);
      }
    }
    .c-headers__title-back .u-icon {
      font-size: var(--text-xs);
      font-weight: var(--font-weight-bold);
    }
    /*As the menus are coming from testharnes we add style here*/
    .c-header__action-menu {
      display: flex;
      align-items: center;
      @media (max-width: map-get($context--breakpoints, sm-max)) {
        width: 100%;
        height: 100vh;
        -webkit-transform: translate3d(103%, 0px, 0px);
        transform: translate3d(103%, 0px, 0px);
        transition: all 0.5s ease 0s;
        -webkit-transition: all 0.5s ease 0s;
        display: block;
        position: fixed;
      }
      .c-headers__list-item {
        display: inline-block;
        padding: calc(var(--theme-global-spacing) * 0);
        margin: calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 0);
        &:last-child {
          padding-right: calc(var(--theme-global-spacing) * 0);
          padding-left: calc(var(--theme-global-spacing) * 0);
          margin-right: calc(var(--theme-global-spacing) * 0);
        }
        .u-button{
          min-width: 134px;
        }
        @media (max-width: map-get($context--breakpoints, sm-max)) {
          display: block;
          text-align: left;
          border-bottom-width: 2px;
          border-bottom-style: solid;
          padding: calc(var(--theme-global-spacing) * 0);
          margin: calc(var(--theme-global-spacing) * 0);
          border-bottom-color: var(--theme-color-neutrals-150);
          &:last-child {
            border-bottom: 0;
          }
          .u-button.u-button--primary {
            border: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            .u-icon {
              display: none;
            }
          }
        }
      }
    }
    .c-headers__logo-link {
      padding: calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 6) calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 0);
      border-color: var(--theme-color-neutrals-150);
      margin-right: calc(var(--theme-global-spacing) * 6);
      @media (max-width: map-get($context--breakpoints, sm-max)) {
        margin-right: calc(var(--theme-global-spacing) * 4);
        padding-right: calc(var(--theme-global-spacing) * 0);
      }
    }
    .c-headers__logo-link:focus .c-headers__logo-img {
      outline-color: var(--theme-color-neutrals-1000);
    }
    .c-headers__actions {
      margin-left: calc(var(--theme-global-spacing) * 4);
      .c-headers__actions-plus-button {
        padding: calc(var(--theme-global-spacing) * 7) calc(var(--theme-global-spacing) * 5);
        margin-left: calc(var(--theme-global-spacing) * 4);
        @media (max-width: map-get($context--breakpoints, sm-max)) {
          padding: calc(var(--theme-global-spacing));
          color: var(--theme-color-neutrals-0);
        }
        .u-icon {
          @media (max-width: map-get($context--breakpoints, sm-max)) {
            font-size: var(--text-xs);
          }
        }
      }
      .c-header__action-item {
        padding: calc(var(--theme-global-spacing) * 0);
        margin-left: calc(var(--theme-global-spacing) * 6);
        @media (max-width: map-get($context--breakpoints, sm-max)) {
          margin-left: calc(var(--theme-global-spacing) * 0);
        }
        /*The dropdown is coming from test-harnes, for the the dropdown css*/
        .c-dropdown {
          min-width: 148px;
          @media (max-width: map-get($context--breakpoints, xs-max)) {
            height: auto;
            .c-dropdown__list-outer {
              position: relative;
            }
          }
          .c-dropdown__button {
            border-color: var(--theme-color-neutrals-500);
            margin-bottom: calc(var(--theme-global-spacing) * 0);
            .c-dropdown__button-text {
              font-weight: var(--font-weight-normal);
            }
          }
          .c-dropdown__list-outer {
            top: calc(100% + 4px);
          }
        }
      }
      // test-harness changes
      @media (max-width: map-get($context--breakpoints, md-max)) {
        .c-header__action-menu-single .u-button{
          text-indent: -999em;
          width: 40px;
          height: 40px;
          padding: 0;
          text-align: center;
          position: relative;
          display: block;
          &::before{
            content: "\e971";
            font-family: mee-platform!important;
            text-indent: initial;
            font-size: var(--text-xs);
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 24px;
            height: 24px;
            position: absolute;
          }
        }
      }
      /*Global header Hamburger menu( the css for the hamburger is written here because the hamburger is coming from testharnes)*/
      .c-header__action-menu-single {
        .c-headers__actions-hamburger-button .u-button {
          display: none;
          &:before {
            display: none;
          }
          @media (max-width: map-get($context--breakpoints, md-max)) {
            margin-left: calc(var(--theme-global-spacing) * 2);
            background-image: url('../../assets/default/images/hamburger-icon.svg');
            background-position: center;
            background-repeat: no-repeat;
            display: block;
          }
        }
        .c-headers__actions-close-button .u-button {
          display: none;
          &:before {
            display: none;
          }
          @media (max-width: map-get($context--breakpoints, md-max)) {
            margin-left: calc(var(--theme-global-spacing) * 2);
            display: block;
            &:before {
              content: "\e9a3";
              display: block;
            }
          }
        }
      }
    }
    &.c-headers__wrapper--open {
      .c-header__action-menu {
        padding: calc(var(--theme-global-spacing)) calc(var(--theme-global-spacing) * 4) 0;
        margin: calc(var(--theme-global-spacing) * 0);
        background-color: var(--theme-color-neutrals-0);
        height: calc(100% - 85px);
        position: fixed;
        right: 0;
        top: 85px;
        z-index: 1;
        width: 100%;
        -webkit-transform: translate3d(0px, 0px, 0px);
        transform: translate3d(0px, 0px, 0px);
        visibility: visible;
        .c-headers__list {
          padding-left: calc(var(--theme-global-spacing) * 0);
          padding-right: calc(var(--theme-global-spacing) * 0);
          .c-headers__list-item {
            padding-bottom: calc(var(--theme-global-spacing) * 1);
            margin-bottom: calc(var(--theme-global-spacing) * 1);
            display: inline-block;
            @media (max-width: map-get($context--breakpoints, sm-max)) {
              display: block;
              text-align: left;
              border-bottom-width: 1px;
              border-bottom-style: solid;
              &:last-child {
                border-bottom: 0;
              }
              .u-button {
                display: flex;
              }
              .u-button.u-button--primary {
                border: 0;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                .u-icon {
                  display: none;
                }
              }
            }
          }
        }
        .c-header__action-item {
          padding-left: calc(var(--theme-global-spacing) * 0);
          padding-right: calc(var(--theme-global-spacing) * 0);
          @media (max-width: map-get($context--breakpoints, sm-max)) {
            .u-button {
              display: block;
              width: 100%;
              text-align: left;
            }
          }
        }
      }
    }
    .c-headers__logo-img--mobile {
      @media (max-width: map-get($context--breakpoints, sm-max)) {
        padding: calc(var(--theme-global-spacing) * 0);
      }
    }
  }
  @media (max-width: map-get($context--breakpoints, sm-max)) {
    .c-headers .c-headers__wrapper .c-headers__branding {
      padding-right: calc(var(--theme-global-spacing) * 11);
    }
  }
}
