/**
*  notification-inline settings
*/
$notification-inline-info: (
	'color':var(--theme-color-neutrals-1000),
	'backgroundColor':var(--theme-color-100),
	'box-shadowColor':var(--theme-color-500)
);
$notification-inline-success: (
	'color': var(--theme-color-neutrals-1000),
	'backgroundColor': var(--theme-success-light-100),
	'box-shadowColor': var(--theme-success)
);
$notification-inline-danger: (
	'color': var(--theme-color-neutrals-1000),
	'backgroundColor':var(--theme-error-light-100),
	'box-shadowColor': var(--theme-error)
);
$notification-inline-warning: (
	'color':var(--theme-color-neutrals-1000),
	'backgroundColor':var(--theme-warning-light-100),
	'box-shadowColor': var(--theme-warning)
);