// Floating panel type css start here
.c-type {
	// Common wrapper for type style
	.c-type__style {
		padding: calc(var(--theme-global-spacing) * 3);
		/* type form control */
		.c-type__style-form-control {
			margin: 0 calc(var(--theme-global-spacing) * 1);
			/* type form control radio button hover style */
			input[type="radio"]:hover, 
			input[type="radio"]:focus,
			input[type="radio"].disabled:checked:focus  {
				outline-color: var(--theme-color-500);
			}
			/* type form control radio button custom checked*/
			input[type="radio"]:checked::before {
				background-color: var(--theme-color-500);
			}
			input[type="radio"].disabled:checked::before {
				background-color: var(--theme-color-neutrals-0);
				outline-color: var(--theme-color-500);
			}
			input[type="radio"]:hover,
			input[type="radio"]:disabled:hover,
			input[type="radio"].disabled:hover,
			input[type="radio"].disabled:focus {
				outline-color: var(--theme-color-500);
			}
		}
	}
	.c-type__divider {
		background-color: var(--theme-color-neutrals-150);
	}
	// Common wrapper for type color
	.c-type__color {
		padding: calc(var(--theme-global-spacing) * 2);
		/* type form control */
		.c-type__color-form-control {
			margin: calc(var(--theme-global-spacing) * 1);
			/* type form control radio style */
			input[type="radio"] {
				border-color: var(--theme-color-neutrals-150);
			}
		}
	}
	.c-type__color-dropdown {
		background-color: var(--theme-color-neutrals-0);
	}
	.c-type__list {
		padding: calc(var(--theme-global-spacing) * 5);
	}
	.c-type__color-label--text {
		border-color: var(--theme-color-neutrals-1000);
		background-color: var(--theme-color-neutrals-1000);
	}
	.c-type__color-label--background {
		border-color: var(--theme-color-neutrals-150);
		background-color: var(--theme-color-neutrals-0);
	}
	.c-type__link {
		padding: calc(var(--theme-global-spacing) * 3) calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 3);
		color: var(--theme-color-neutrals-1000);
		@include context--overline;
		&:hover {
			background-color: var(--theme-color-neutrals-1000);
			color: var(--theme-color-neutrals-0);
			.c-type__color-label--text {
				border-color: var(--theme-color-neutrals-0);
			}
			.c-type__color-label--background {
				border-color: var(--theme-color-neutrals-0);
			}
		}
		&:focus {
			background-color: var(--theme-color-neutrals-0);
			color: var(--theme-color-neutrals-1000);
			outline-color: var(--theme-color-950);
		}
		.c-type__color-dropdown {
			background-color: var(--theme-color-neutrals-0);
			border-color: var(--theme-color-neutrals-150);
		}
		.c-type__title {
			margin-left: calc(var(--theme-global-spacing) * 2);
		}
		.active {
			background-color: var(--theme-color-200);
			color: var(--theme-color-neutrals-1000);
		}
		.disabled {
			background-color: var(--theme-color-neutrals-0);
		}
	}
	.c-type__item.active {
		.c-type__link {
			background-color: var(--theme-color-200);
			color: var(--theme-color-neutrals-1000);
		}
		.c-type__color-label--text {
			border-color: var(--theme-color-neutrals-1000);
		}
	}
	.c-type__item.disabled {
		.c-type__link {
			background-color: var(--theme-color-neutrals-0);
		}
	}
}
// Floating panel IWB css start here
@media (min-width: map-get($context--breakpoints, xxl)) {
	.c-type .c-type__style {
		padding: calc(var(--theme-global-spacing) * 5);
	}
	/* type form control */
	.c-type .c-type__color .c-type__color-form-control {
		margin: calc(var(--theme-global-spacing) * 3);
	}
	// Floating panel IWB css start here
	.c-type__link {
		color: var(--theme-color-neutrals-1000);
		&:hover,
		&:focus {
			background-color: var(--theme-color-neutrals-1000);
			color: var(--theme-color-neutrals-0);
			.c-type__color {
				background-color: var(--theme-color-neutrals-1000);
			}
		}
	}
}
// Floating panel Mobile css start here
@media (max-width: map-get($context--breakpoints, sm-max)) {
	.c-type .c-type__color {
		padding: calc(var(--theme-global-spacing) * 6) calc(var(--theme-global-spacing) * 6);
		@media (max-width: map-get($context--breakpoints, sm-max)) {
			padding: 0;
			overflow-x: auto;
		}
		.c-type__color-form-control {
			margin: calc(var(--theme-global-spacing) * 3);
		}
	}
}
@media (max-width: map-get($context--breakpoints, sm-max)) {
	.c-type__color-selection {
		border-color: var(--theme-color-neutrals-150);
		&:focus{
			outline-color: var(--theme-color-500);
		}
	}
}