// dialog-box
.c-dialog {
  padding: calc(var(--theme-global-spacing) * 4);
  .c-dialog__inner {
    background-color: var(--theme-color-neutrals-0);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
    border-radius: var(--radius-200);
    .c-dialog-header {
      background-color: var(--theme-color-neutrals-0);
      padding: calc(var(--theme-global-spacing) * 6)
        calc(var(--theme-global-spacing) * 6)
        calc(var(--theme-global-spacing) * 4);
      border-bottom-color: var(--theme-color-neutrals-200);
      @media (max-width: map-get($context--breakpoints, md-max)) {
        padding: calc(var(--theme-global-spacing) * 6)
          calc(var(--theme-global-spacing) * 4)
          calc(var(--theme-global-spacing) * 5);
      }
      .c-dialog__title {
        .c-dialog__title-text {
          @include context--font-size-h3;
        }
        .c-dialog__title-text,
        .c-dialog__title-content {
          margin: calc(var(--theme-global-spacing) * 0);
          .c-dialog__title-icon {
            margin-right: calc(var(--theme-global-spacing) * 2);
            font-size: var(--text-md);
            @media (max-width: map-get($context--breakpoints, md-max)) {
              font-size: var(--text-xs);
              margin-right: calc(var(--theme-global-spacing) * 2);
            }
          }
        }
      }
    }
    .c-dialog__buttons-group .u-button {
      margin-left: calc(var(--theme-global-spacing) * 4);
      min-width: auto;
    }
    .c-dialog-content {
      background-color: var(--theme-color-neutrals-0);
      padding: calc(var(--theme-global-spacing) * 4)
        calc(var(--theme-global-spacing) * 6)
        calc(var(--theme-global-spacing) * 4);
      @media (max-width: map-get($context--breakpoints, md-max)) {
        padding: calc(var(--theme-global-spacing) * 4);
      }
    }
    .c-dialog-footer {
      background-color: var(--theme-color-neutrals-50);
      padding: calc(var(--theme-global-spacing) * 4)
        calc(var(--theme-global-spacing) * 6);
      border-top-color: var(--theme-color-neutrals-200);
      @media (max-width: map-get($context--breakpoints, md-max)) {
        padding: calc(var(--theme-global-spacing) * 4);
      }
      .c-dialog-footer__button-item{
        @media (max-width: map-get($context--breakpoints, sm-max)) {
          -ms-flex-preferred-size: 0;
          flex-basis: 0;
          -ms-flex-positive: 1;
          flex-grow: 1;
          max-width: 100%;
          .u-button{
              width: 100%;
              justify-content: center;
          }
        }
        & + .c-dialog-footer__button-item{
          margin-left: calc(var(--theme-global-spacing) * 4);
        }
      } 
    }
  }
  &.c-dialog--overlay {
    background-color: var(--theme-dialog-overlay);
  }
  &.c-dialog-maximise {
    padding: calc(var(--theme-global-spacing) * 0);
    .c-dialog__inner {
      border-radius: 0;
      .c-dialog-content {
        height: calc(100% - 150px);
        overflow: auto;
      }
      .c-dialog-footer {
        position: fixed;
        bottom: 0;
      }
    }
  }
  maced-button + maced-button{
    margin-left: calc(var(--theme-global-spacing) * 4);
  }
  /*Dialog with Textarea*/
    .c-dialog__inner.c-dialog--text-area {
      .c-dialog-content {
        padding: calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 6) calc(var(--theme-global-spacing) * 6);
        @media (max-width: map-get($context--breakpoints, sm-max)) {
          padding: calc(var(--theme-global-spacing) * 4);
        }
      }
      .c-input-group .c-input-group__label {
        margin-bottom: calc(var(--theme-global-spacing) * 4);
      }
      .c-input-group.c-input-group--inline {
        width: 100%;
        .c-input-group__control--textarea {
          max-width: 100%;
          min-width: 100%;
        }
      }
      @media (max-width: map-get($context--breakpoints, sm-max)) {
        maced-button {
          flex-basis: 50%;
          .u-button {
            justify-content: center;
            width: 100%;
          }
        }
      }
      .c-input-group {
      @media (max-width: map-get($context--breakpoints, sm-max)) {
        margin-bottom: calc(var(--theme-global-spacing) * 6);
      }
      @media (max-width: map-get($context--breakpoints, xs-max)) {
        margin-bottom: calc(var(--theme-global-spacing) * 11);
      }
    }
  }
}
