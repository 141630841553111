.c-read-aloud__audio-action{
    .c-read-aloud__actions-button {
        padding: calc(var(--theme-global-spacing) * 2);
        border-radius: calc(var(--theme-global-spacing) * 1);
        color: var(--theme-color-neutrals-1000);
        background-color: var(--theme-color-neutrals-0);
        margin-right: calc(var(--theme-global-spacing) * 4);
        margin-left: calc(var(--theme-global-spacing) * 0);
        @media (max-width: map-get($context--breakpoints, sm)) {
            margin-right: calc(var(--theme-global-spacing) * 2);
            margin-left: calc(var(--theme-global-spacing) * 2);
        }
        &:hover {
            background-color: var(--theme-color-neutrals-1000);
            color: var(--theme-color-neutrals-0);
        }
        &:focus {
            outline-color: var(--theme-color-950);
            outline-style: solid;
            outline-offset: 1px;
        }
        &:active {
            background-color: var(--theme-color-500);
            color:var(--theme-color-neutrals-0);
        }
        &:disabled,
        &.disabled,
        &:disabled.c-read-aloud__actions-button--backward:active,
		&.disabled.c-read-aloud__actions-button--backward.active,
		&:disabled.c-read-aloud__actions-button--forward:active,
		&.disabled.c-read-aloud__actions-button--forward.active {
            background-color: var(--theme-color-neutrals-0);
            color: var(--theme-color-neutrals-200);
        }
    }
    .c-read-aloud__actions-button--play {
        border-color: var(--theme-color-500);
        background-color: var(--theme-color-500);
        filter: none;
        .c-read-aloud__button-icon--play{ 
            filter: none !important;
        }
        &:hover {
            .c-read-aloud__button-icon--play{ 
                filter: none !important;
            }
        }
        &:active {
            background-color: var(--theme-color-500);
            color:var(--theme-color-neutrals-0);
        }
        &:disabled:active,
        &.disabled:active {
            background-color: var(--theme-color-neutrals-0);
            color: var(--theme-color-neutrals-200);
        }
        &:disabled{
            .c-read-aloud__button-icon.c-read-aloud__button-icon--play{
                filter: invert(.2) !important;
            }
        }
    }
    .c-read-aloud__actions-button--pause {
        border-color: var(--theme-color-500);
        background-color: var(--theme-color-500);
        filter: none !important;
        &:active {
            background-color: var(--theme-color-500);
            color:var(--theme-color-neutrals-0);
            .c-read-aloud__button-icon--pause{ 
                filter: none;
            }
        }
        &:hover {
            background-color: var(--theme-color-500);
            .c-read-aloud__button-icon--pause{ 
                filter: invert(1) !important;
            }
        }
    }
    .c-read-aloud__actions-button--drag {
        background: var(--theme-color-neutrals-0);
        color: var(--theme-color-neutrals-400);
        padding-left: calc(var(--theme-global-spacing) * 0);
        padding-right: calc(var(--theme-global-spacing) * 0);
        &:hover,
        &:active {
            background-color: var(--theme-color-neutrals-0);
            border-color: var(--theme-color-neutrals-0);
        }
    }
    .c-read-aloud__actions-button--audio {
        margin-right: calc(var(--theme-global-spacing) * 0);
    }
    .c-read-aloud__volume {
        background: var(--theme-color-neutrals-0);
        border-color: var(--theme-color-neutrals-500);
        margin-right: calc(var(--theme-global-spacing) * 11);
        @media (max-width: map-get($context--breakpoints, xl)) {
            margin-right: calc(var(--theme-global-spacing) * 11);
        }
        @media (max-width: map-get($context--breakpoints, sm-max)) {
            margin-right: calc(var(--theme-global-spacing) * 10);
        }
        .c-read-aloud__volumeicon {
            padding-bottom: calc(var(--theme-global-spacing) * 2);
            color: var(--theme-color-500);
        }
        .c-read-aloud__volume-range {
            background: var(--theme-volume-bar-background);
            width: var(--theme-global-spacing);
            height: calc(var(--theme-global-spacing) * 32);
            margin: calc(var(--theme-global-spacing) * 12) auto calc(var(--theme-global-spacing) * 0);
            .c-read-aloud__volume-button {
                width: calc(var(--theme-global-spacing) * 4);
                height: calc(var(--theme-global-spacing) * 4);
                background: var(--theme-color-500);
                border-color: var(--theme-color-neutrals-0);
                box-shadow: 0px 0px 6px var(--theme-dropdown-shadow);
                padding: calc(var(--theme-global-spacing) * 0);
                margin: calc(var(--theme-global-spacing) * 0) auto;
            }
        }
    }
    .c-read-aloud__volume--is-open {
        background-color: var(--theme-transparent-background);
        border-color: var(--theme-transparent-background);
        .c-read-aloud__actions-button--audio {
            background-color: var(--theme-transparent-background);
            border-color: var(--theme-transparent-background);
            color: var(--theme-color-500);
        }
        .c-read-aloud__volume-progress--is-top {
            .c-read-aloud__volume-range {
                margin: calc(var(--theme-global-spacing) * 0) auto;
                top: calc(var(--theme-global-spacing) * 5);
            }
        }
    }
}
/*Override all the hover styles only for touch devices*/
@media (hover:none),
(hover:on-demand) {
    .c-read-aloud__audio-action .c-read-aloud__actions-button:hover {
        background-color: inherit;
        color: inherit;
    }
    .c-read-aloud__audio-action .c-read-aloud__actions-button--pause:hover {
        background-color: var(--theme-color-500);
        color: inherit;
        .c-read-aloud__button-icon--pause{
            filter: invert(1) !important;
        }
    }
    .c-read-aloud__audio-action .c-read-aloud__actions-button--play:hover {
        background-color: var(--theme-color-500);
        color: inherit;
        .c-read-aloud__button-icon--play{
            filter: none !important;
        }
    }
    .c-read-aloud__audio-action .c-read-aloud__actions-button:active {
        background-color: var(--theme-color-500);
        color: var(--theme-color-neutrals-0);
    }
}