
@import '../40-base/fonts';
// Themes All secondary users CSS Variables
//neutrals
$--theme-color-neutrals-0: map-get(map-get($palette, neutrals), 0) !default;
$--theme-color-neutrals-50: map-get(map-get($palette, neutrals), 50) !default;
$--theme-color-neutrals-100: map-get(map-get($palette, neutrals), 100) !default;
$--theme-color-neutrals-150: map-get(map-get($palette, neutrals), 150) !default;
$--theme-color-neutrals-200: map-get(map-get($palette, neutrals), 200) !default;
$--theme-color-neutrals-300: map-get(map-get($palette, neutrals), 300) !default;
$--theme-color-neutrals-400: map-get(map-get($palette, neutrals), 400) !default;
$--theme-color-neutrals-500: map-get(map-get($palette, neutrals), 500) !default;
$--theme-color-neutrals-600: map-get(map-get($palette, neutrals), 600) !default;
$--theme-color-neutrals-700: map-get(map-get($palette, neutrals), 700) !default;
$--theme-color-neutrals-800: map-get(map-get($palette, neutrals), 800) !default;
$--theme-color-neutrals-850: map-get(map-get($palette, neutrals), 850) !default;
$--theme-color-neutrals-900: map-get(map-get($palette, neutrals), 900) !default;
$--theme-color-neutrals-950: map-get(map-get($palette, neutrals), 950) !default;
$--theme-color-neutrals-1000: map-get(map-get($palette, neutrals), 1000) !default;

//================= all secondary Theme
//Red
$--theme-color-50: map-get(map-get($palette, red), 50) !default;
$--theme-color-100: map-get(map-get($palette, red), 100) !default;
$--theme-color-150: map-get(map-get($palette, red), 150) !default;
$--theme-color-200: map-get(map-get($palette, red), 200) !default;
$--theme-color-300: map-get(map-get($palette, red), 300) !default;
$--theme-color-400: map-get(map-get($palette, red), 400) !default;
$--theme-color-500: map-get(map-get($palette, red), 500) !default;
$--theme-color-600: map-get(map-get($palette, red), 600) !default;
$--theme-color-700: map-get(map-get($palette, red), 700) !default;
$--theme-color-800: map-get(map-get($palette, red), 800) !default;
$--theme-color-850: map-get(map-get($palette, red), 850) !default;
$--theme-color-900: map-get(map-get($palette, red), 900) !default;
$--theme-color-950: map-get(map-get($palette, red), 950) !default;
//common
$--theme-error: map-get(map-get($palette, red), 500) !default;
$--theme-warning:map-get(map-get($palette, yellow), 500) !default;
$--theme-success:map-get(map-get($palette, green), 500) !default;
$--theme-dialog-overlay: rgba(0, 0, 0, 0.3) !default;
$--theme-global-shadow: map-get(map-get($palette, neutrals), 100) !default;
// border-radius All secondary     
$--theme-border-radius: var(--radius-100);
$--theme-border-xl-radius: var(--radius-500);
// Spacing All secondary users CSS Variables
$--theme-transparent-background: transparent; 
$--theme-global-spacing: 4px;
$--theme-global-font-family: 'Open Sans', sans-serif;

// playful theme variable
$--playful-theme-global-font-family: 'Castledown';
$--playful-theme-dialog-overlay-purple-sky: rgba(87, 54, 110, 0.9) !default;
$--playful-theme-dialog-overlay-turtle-bay: rgba(17, 77, 61, 0.9) !default;
$--playful-theme-dialog-overlay-calm-blue: rgba(36, 66, 126, 0.9) !default;

//chips background

$--theme-success-light:map-get(map-get($palette, green), 50) !default;
$--theme-warning-light:map-get(map-get($palette, yellow), 50) !default;
$--theme-error-light:map-get(map-get($palette, red), 50) !default;

//toast background
$--theme-success-light-100:map-get(map-get($palette, green), 100) !default;
$--theme-warning-light-100:map-get(map-get($palette, yellow), 100) !default;
$--theme-error-light-100:map-get(map-get($palette, red), 100) !default;
$--theme-border-orange-500:map-get(map-get($palette, orange), 500) !default;

//indicator background
$--theme-color-magenta-50: map-get(map-get($palette, magenta), 50) !default;
$--theme-color-magenta-100: map-get(map-get($palette, magenta), 100) !default;
$--theme-color-magenta-150: map-get(map-get($palette, magenta), 150) !default;
$--theme-color-magenta-200: map-get(map-get($palette, magenta), 200) !default;
$--theme-color-magenta-300: map-get(map-get($palette, magenta), 300) !default;
$--theme-color-magenta-400: map-get(map-get($palette, magenta), 400) !default;
$--theme-color-magenta-500: map-get(map-get($palette, magenta), 500) !default;
$--theme-color-magenta-600: map-get(map-get($palette, magenta), 600) !default;
$--theme-color-magenta-700: map-get(map-get($palette, magenta), 700) !default;
$--theme-color-magenta-800: map-get(map-get($palette, magenta), 800) !default;
$--theme-color-magenta-850: map-get(map-get($palette, magenta), 850) !default;
$--theme-color-magenta-900: map-get(map-get($palette, magenta), 900) !default;
$--theme-color-magenta-950: map-get(map-get($palette, magenta), 950) !default;

$--theme-color-purple-50: map-get(map-get($palette, purple), 50) !default;
$--theme-color-purple-100: map-get(map-get($palette, purple), 100) !default;
$--theme-color-purple-150: map-get(map-get($palette, purple), 150) !default;
$--theme-color-purple-200: map-get(map-get($palette, purple), 200) !default;
$--theme-color-purple-300: map-get(map-get($palette, purple), 300) !default;
$--theme-color-purple-400: map-get(map-get($palette, purple), 400) !default;
$--theme-color-purple-500: map-get(map-get($palette, purple), 500) !default;
$--theme-color-purple-600: map-get(map-get($palette, purple), 600) !default;
$--theme-color-purple-700: map-get(map-get($palette, purple), 700) !default;
$--theme-color-purple-800: map-get(map-get($palette, purple), 800) !default;
$--theme-color-purple-850: map-get(map-get($palette, purple), 850) !default;
$--theme-color-purple-900: map-get(map-get($palette, purple), 900) !default;
$--theme-color-purple-950: map-get(map-get($palette, purple), 950) !default;

$--theme-color-green-50: map-get(map-get($palette, green), 50) !default;
$--theme-color-green-100: map-get(map-get($palette, green), 100) !default;
$--theme-color-green-150: map-get(map-get($palette, green), 150) !default;
$--theme-color-green-200: map-get(map-get($palette, green), 200) !default;
$--theme-color-green-300: map-get(map-get($palette, green), 300) !default;
$--theme-color-green-400: map-get(map-get($palette, green), 400) !default;
$--theme-color-green-500: map-get(map-get($palette, green), 500) !default;
$--theme-color-green-600: map-get(map-get($palette, green), 600) !default;
$--theme-color-green-700: map-get(map-get($palette, green), 700) !default;
$--theme-color-green-800: map-get(map-get($palette, green), 800) !default;
$--theme-color-green-850: map-get(map-get($palette, green), 850) !default;
$--theme-color-green-900: map-get(map-get($palette, green), 900) !default;
$--theme-color-green-950: map-get(map-get($palette, green), 950) !default;

$--theme-color-orange-50: map-get(map-get($palette, orange), 50) !default;
$--theme-color-orange-100: map-get(map-get($palette, orange), 100) !default;
$--theme-color-orange-150: map-get(map-get($palette, orange), 150) !default;
$--theme-color-orange-200: map-get(map-get($palette, orange), 200) !default;
$--theme-color-orange-300: map-get(map-get($palette, orange), 300) !default;
$--theme-color-orange-400: map-get(map-get($palette, orange), 400) !default;
$--theme-color-orange-500: map-get(map-get($palette, orange), 500) !default;
$--theme-color-orange-600: map-get(map-get($palette, orange), 600) !default;
$--theme-color-orange-700: map-get(map-get($palette, orange), 700) !default;
$--theme-color-orange-800: map-get(map-get($palette, orange), 800) !default;
$--theme-color-orange-850: map-get(map-get($palette, orange), 850) !default;
$--theme-color-orange-900: map-get(map-get($palette, orange), 900) !default;
$--theme-color-orange-950: map-get(map-get($palette, orange), 950) !default;

$--theme-color-blue-50: map-get(map-get($palette, blue), 50) !default;
$--theme-color-blue-100: map-get(map-get($palette, blue), 100) !default;
$--theme-color-blue-150: map-get(map-get($palette, blue), 150) !default;
$--theme-color-blue-200: map-get(map-get($palette, blue), 200) !default;
$--theme-color-blue-300: map-get(map-get($palette, blue), 300) !default;
$--theme-color-blue-400: map-get(map-get($palette, blue), 400) !default;
$--theme-color-blue-500: map-get(map-get($palette, blue), 500) !default;
$--theme-color-blue-600: map-get(map-get($palette, blue), 600) !default;
$--theme-color-blue-700: map-get(map-get($palette, blue), 700) !default;
$--theme-color-blue-800: map-get(map-get($palette, blue), 800) !default;
$--theme-color-blue-850: map-get(map-get($palette, blue), 850) !default;
$--theme-color-blue-900: map-get(map-get($palette, blue), 900) !default;
$--theme-color-blue-950: map-get(map-get($palette, blue), 950) !default;

$--theme-color-teal-50: map-get(map-get($palette, teal), 50) !default;
$--theme-color-teal-100: map-get(map-get($palette, teal), 100) !default;
$--theme-color-teal-150: map-get(map-get($palette, teal), 150) !default;
$--theme-color-teal-200: map-get(map-get($palette, teal), 200) !default;
$--theme-color-teal-300: map-get(map-get($palette, teal), 300) !default;
$--theme-color-teal-400: map-get(map-get($palette, teal), 400) !default;
$--theme-color-teal-500: map-get(map-get($palette, teal), 500) !default;
$--theme-color-teal-600: map-get(map-get($palette, teal), 600) !default;
$--theme-color-teal-700: map-get(map-get($palette, teal), 700) !default;
$--theme-color-teal-800: map-get(map-get($palette, teal), 800) !default;
$--theme-color-teal-850: map-get(map-get($palette, teal), 850) !default;
$--theme-color-teal-900: map-get(map-get($palette, teal), 900) !default;
$--theme-color-teal-950: map-get(map-get($palette, teal), 950) !default;

$--theme-color-cyan-50: map-get(map-get($palette, cyan), 50) !default;
//================= Override the Primary Teacher MEP With Navio Theme
//Blue
$--standard-theme-color-50: map-get(map-get($palette, blue), 50) !default;
$--standard-theme-color-100: map-get(map-get($palette, blue), 100) !default;
$--standard-theme-color-150: map-get(map-get($palette, blue), 150) !default;
$--standard-theme-color-200: map-get(map-get($palette, blue), 200) !default;
$--standard-theme-color-300: map-get(map-get($palette, blue), 300) !default;
$--standard-theme-color-400: map-get(map-get($palette, blue), 400) !default;
$--standard-theme-color-500: map-get(map-get($palette, blue), 500) !default;
$--standard-theme-color-600: map-get(map-get($palette, blue), 600) !default;
$--standard-theme-color-700: map-get(map-get($palette, blue), 700) !default;
$--standard-theme-color-800: map-get(map-get($palette, blue), 800) !default;
$--standard-theme-color-850: map-get(map-get($palette, blue), 850) !default;
$--standard-theme-color-900: map-get(map-get($palette, blue), 900) !default;
$--standard-theme-color-950: map-get(map-get($palette, blue), 950) !default;

$--standard-theme-global-spacing: 4px;
$--standard-theme-global-font-family: 'Open Sans', sans-serif;

//================= Override the Primary Teacher MEP With Navio Theme
//Blue
$--primary-student-theme-color-50: map-get(map-get($palette, yellow), 50) !default;
$--primary-student-theme-color-100: map-get(map-get($palette, yellow), 100) !default;
$--primary-student-theme-color-150: map-get(map-get($palette, yellow), 150) !default;
$--primary-student-theme-color-200: map-get(map-get($palette, yellow), 200) !default;
$--primary-student-theme-color-300: map-get(map-get($palette, yellow), 300) !default;
$--primary-student-theme-color-400: map-get(map-get($palette, yellow), 400) !default;
$--primary-student-theme-color-500: map-get(map-get($palette, yellow), 500) !default;
$--primary-student-theme-color-600: map-get(map-get($palette, yellow), 600) !default;
$--primary-student-theme-color-700: map-get(map-get($palette, yellow), 700) !default;
$--primary-student-theme-color-800: map-get(map-get($palette, yellow), 800) !default;
$--primary-student-theme-color-850: map-get(map-get($palette, yellow), 850) !default;
$--primary-student-theme-color-900: map-get(map-get($palette, yellow), 900) !default;
$--primary-student-theme-color-950: map-get(map-get($palette, yellow), 950) !default;

$--primary-student-theme-global-spacing: 4px;
$--primary-student-theme-global-font-family: 'Open Sans', sans-serif;


// ====================All secondary users theme variable mixins
[data-theme="all-secondary"] {
    --theme-color-neutrals-0: #{$--theme-color-neutrals-0};
    --theme-color-neutrals-50: #{$--theme-color-neutrals-50};
    --theme-color-neutrals-100: #{$--theme-color-neutrals-100};
    --theme-color-neutrals-150: #{$--theme-color-neutrals-150};
    --theme-color-neutrals-200: #{$--theme-color-neutrals-200};
    --theme-color-neutrals-300: #{$--theme-color-neutrals-300};
    --theme-color-neutrals-400: #{$--theme-color-neutrals-400};
    --theme-color-neutrals-500: #{$--theme-color-neutrals-500};
    --theme-color-neutrals-600: #{$--theme-color-neutrals-600};
    --theme-color-neutrals-700: #{$--theme-color-neutrals-700};
    --theme-color-neutrals-800: #{$--theme-color-neutrals-800};
    --theme-color-neutrals-850: #{$--theme-color-neutrals-850};
    --theme-color-neutrals-900: #{$--theme-color-neutrals-900};
    --theme-color-neutrals-950: #{$--theme-color-neutrals-950};
    --theme-color-neutrals-1000: #{$--theme-color-neutrals-1000};

    --theme-color-50: #{$--theme-color-50};
    --theme-color-100: #{$--theme-color-100};
    --theme-color-150: #{$--theme-color-150};
    --theme-color-200: #{$--theme-color-200};
    --theme-color-300: #{$--theme-color-300};
    --theme-color-400: #{$--theme-color-400};
    --theme-color-500: #{$--theme-color-500};
    --theme-color-600: #{$--theme-color-600};
    --theme-color-700: #{$--theme-color-700};
    --theme-color-800: #{$--theme-color-800};
    --theme-color-850: #{$--theme-color-850};
    --theme-color-900: #{$--theme-color-900};
    --theme-color-950: #{$--theme-color-950};

    --theme-error: #{$--theme-error};
    --theme-success: #{$--theme-success};
    --theme-warning: #{$--theme-warning};
    
    --theme-success-light: #{$--theme-success-light};
    --theme-warning-light: #{$--theme-warning-light};
    --theme-error-light: #{$--theme-error-light};

    --theme-success-light-100: #{$--theme-success-light-100};
    --theme-warning-light-100: #{$--theme-warning-light-100};
    --theme-error-light-100: #{$--theme-error-light-100};
    --theme-border-orange-500: #{$--theme-border-orange-500};

    --theme-color-magenta-50: #{$--theme-color-magenta-50};
    --theme-color-magenta-100: #{$--theme-color-magenta-100};
    --theme-color-magenta-150: #{$--theme-color-magenta-150};
    --theme-color-magenta-200: #{$--theme-color-magenta-200};
    --theme-color-magenta-300: #{$--theme-color-magenta-300};
    --theme-color-magenta-400: #{$--theme-color-magenta-400};
    --theme-color-magenta-500: #{$--theme-color-magenta-500};
    --theme-color-magenta-600: #{$--theme-color-magenta-600};
    --theme-color-magenta-700: #{$--theme-color-magenta-700};
    --theme-color-magenta-800: #{$--theme-color-magenta-800};
    --theme-color-magenta-850: #{$--theme-color-magenta-850};
    --theme-color-magenta-900: #{$--theme-color-magenta-900};
    --theme-color-magenta-950: #{$--theme-color-magenta-950};

    --theme-color-purple-50: #{$--theme-color-purple-50};
    --theme-color-purple-100: #{$--theme-color-purple-100};
    --theme-color-purple-150: #{$--theme-color-purple-150};
    --theme-color-purple-200: #{$--theme-color-purple-200};
    --theme-color-purple-300: #{$--theme-color-purple-300};
    --theme-color-purple-400: #{$--theme-color-purple-400};
    --theme-color-purple-500: #{$--theme-color-purple-500};
    --theme-color-purple-600: #{$--theme-color-purple-600};
    --theme-color-purple-700: #{$--theme-color-purple-700};
    --theme-color-purple-800: #{$--theme-color-purple-800};
    --theme-color-purple-850: #{$--theme-color-purple-850};
    --theme-color-purple-900: #{$--theme-color-purple-900};
    --theme-color-purple-950: #{$--theme-color-purple-950};

    --theme-color-green-50: #{$--theme-color-green-50};
    --theme-color-green-100: #{$--theme-color-green-100};
    --theme-color-green-150: #{$--theme-color-green-150};
    --theme-color-green-200: #{$--theme-color-green-200};
    --theme-color-green-300: #{$--theme-color-green-300};
    --theme-color-green-400: #{$--theme-color-green-400};
    --theme-color-green-500: #{$--theme-color-green-500};
    --theme-color-green-600: #{$--theme-color-green-600};
    --theme-color-green-700: #{$--theme-color-green-700};
    --theme-color-green-800: #{$--theme-color-green-800};
    --theme-color-green-850: #{$--theme-color-green-850};
    --theme-color-green-900: #{$--theme-color-green-900};
    --theme-color-green-950: #{$--theme-color-green-950};

    --theme-color-orange-50: #{$--theme-color-orange-50};
    --theme-color-orange-100: #{$--theme-color-orange-100};
    --theme-color-orange-150: #{$--theme-color-orange-150};
    --theme-color-orange-200: #{$--theme-color-orange-200};
    --theme-color-orange-300: #{$--theme-color-orange-300};
    --theme-color-orange-400: #{$--theme-color-orange-400};
    --theme-color-orange-500: #{$--theme-color-orange-500};
    --theme-color-orange-600: #{$--theme-color-orange-600};
    --theme-color-orange-700: #{$--theme-color-orange-700};
    --theme-color-orange-800: #{$--theme-color-orange-800};
    --theme-color-orange-850: #{$--theme-color-orange-850};
    --theme-color-orange-900: #{$--theme-color-orange-900};
    --theme-color-orange-950: #{$--theme-color-orange-950};

    --theme-color-cyan-50: #{$--theme-color-cyan-50};
    //border-radius
    --theme-border-radius: #{$--theme-border-radius};
    --theme-dialog-overlay: #{$--theme-dialog-overlay};
    --theme-global-shadow: #{$--theme-global-shadow};
    //Spacing
    --theme-transparent-background: #{$--theme-transparent-background};
    --theme-global-spacing: #{$--theme-global-spacing};
    //Fonts
    --theme-global-font-familly: #{$--theme-global-font-family};

}

// ====================Primary teacher users theme variable mixins
[data-theme="standard"], [data-theme="blue"], html {
    --theme-color-neutrals-0: #{$--theme-color-neutrals-0};
    --theme-color-neutrals-50: #{$--theme-color-neutrals-50};
    --theme-color-neutrals-100: #{$--theme-color-neutrals-100};
    --theme-color-neutrals-150: #{$--theme-color-neutrals-150};
    --theme-color-neutrals-200: #{$--theme-color-neutrals-200};
    --theme-color-neutrals-300: #{$--theme-color-neutrals-300};
    --theme-color-neutrals-400: #{$--theme-color-neutrals-400};
    --theme-color-neutrals-500: #{$--theme-color-neutrals-500};
    --theme-color-neutrals-600: #{$--theme-color-neutrals-600};
    --theme-color-neutrals-700: #{$--theme-color-neutrals-700};
    --theme-color-neutrals-800: #{$--theme-color-neutrals-800};
    --theme-color-neutrals-850: #{$--theme-color-neutrals-850};
    --theme-color-neutrals-900: #{$--theme-color-neutrals-900};
    --theme-color-neutrals-950: #{$--theme-color-neutrals-950};
    --theme-color-neutrals-1000: #{$--theme-color-neutrals-1000};

    --theme-color-50: #{$--standard-theme-color-50};
    --theme-color-100: #{$--standard-theme-color-100};
    --theme-color-150: #{$--standard-theme-color-150};
    --theme-color-200: #{$--standard-theme-color-200};
    --theme-color-300: #{$--standard-theme-color-300};
    --theme-color-400: #{$--standard-theme-color-400};
    --theme-color-500: #{$--standard-theme-color-500};
    --theme-color-600: #{$--standard-theme-color-600};
    --theme-color-700: #{$--standard-theme-color-700};
    --theme-color-800: #{$--standard-theme-color-800};
    --theme-color-850: #{$--standard-theme-color-850};
    --theme-color-900: #{$--standard-theme-color-900};
    --theme-color-950: #{$--standard-theme-color-950};

    --theme-error: #{$--theme-error};
    --theme-success: #{$--theme-success};
    --theme-warning: #{$--theme-warning};

    --theme-success-light: #{$--theme-success-light};
    --theme-warning-light: #{$--theme-warning-light};
    --theme-error-light: #{$--theme-error-light};

    --theme-success-light-100: #{$--theme-success-light-100};
    --theme-warning-light-100: #{$--theme-warning-light-100};
    --theme-error-light-100: #{$--theme-error-light-100};
    --theme-border-orange-500: #{$--theme-border-orange-500};

    --theme-color-magenta-50: #{$--theme-color-magenta-50};
    --theme-color-magenta-100: #{$--theme-color-magenta-100};
    --theme-color-magenta-150: #{$--theme-color-magenta-150};
    --theme-color-magenta-200: #{$--theme-color-magenta-200};
    --theme-color-magenta-300: #{$--theme-color-magenta-300};
    --theme-color-magenta-400: #{$--theme-color-magenta-400};
    --theme-color-magenta-500: #{$--theme-color-magenta-500};
    --theme-color-magenta-600: #{$--theme-color-magenta-600};
    --theme-color-magenta-700: #{$--theme-color-magenta-700};
    --theme-color-magenta-800: #{$--theme-color-magenta-800};
    --theme-color-magenta-850: #{$--theme-color-magenta-850};
    --theme-color-magenta-900: #{$--theme-color-magenta-900};
    --theme-color-magenta-950: #{$--theme-color-magenta-950};

    --theme-color-purple-50: #{$--theme-color-purple-50};
    --theme-color-purple-100: #{$--theme-color-purple-100};
    --theme-color-purple-150: #{$--theme-color-purple-150};
    --theme-color-purple-200: #{$--theme-color-purple-200};
    --theme-color-purple-300: #{$--theme-color-purple-300};
    --theme-color-purple-400: #{$--theme-color-purple-400};
    --theme-color-purple-500: #{$--theme-color-purple-500};
    --theme-color-purple-600: #{$--theme-color-purple-600};
    --theme-color-purple-700: #{$--theme-color-purple-700};
    --theme-color-purple-800: #{$--theme-color-purple-800};
    --theme-color-purple-850: #{$--theme-color-purple-850};
    --theme-color-purple-900: #{$--theme-color-purple-900};
    --theme-color-purple-950: #{$--theme-color-purple-950};

    --theme-color-green-50: #{$--theme-color-green-50};
    --theme-color-green-100: #{$--theme-color-green-100};
    --theme-color-green-150: #{$--theme-color-green-150};
    --theme-color-green-200: #{$--theme-color-green-200};
    --theme-color-green-300: #{$--theme-color-green-300};
    --theme-color-green-400: #{$--theme-color-green-400};
    --theme-color-green-500: #{$--theme-color-green-500};
    --theme-color-green-600: #{$--theme-color-green-600};
    --theme-color-green-700: #{$--theme-color-green-700};
    --theme-color-green-800: #{$--theme-color-green-800};
    --theme-color-green-850: #{$--theme-color-green-850};
    --theme-color-green-900: #{$--theme-color-green-900};
    --theme-color-green-950: #{$--theme-color-green-950};

    --theme-color-orange-50: #{$--theme-color-orange-50};
    --theme-color-orange-100: #{$--theme-color-orange-100};
    --theme-color-orange-150: #{$--theme-color-orange-150};
    --theme-color-orange-200: #{$--theme-color-orange-200};
    --theme-color-orange-300: #{$--theme-color-orange-300};
    --theme-color-orange-400: #{$--theme-color-orange-400};
    --theme-color-orange-500: #{$--theme-color-orange-500};
    --theme-color-orange-600: #{$--theme-color-orange-600};
    --theme-color-orange-700: #{$--theme-color-orange-700};
    --theme-color-orange-800: #{$--theme-color-orange-800};
    --theme-color-orange-850: #{$--theme-color-orange-850};
    --theme-color-orange-900: #{$--theme-color-orange-900};
    --theme-color-orange-950: #{$--theme-color-orange-950};

    --theme-color-blue-50: #{$--theme-color-blue-50};
    --theme-color-blue-100: #{$--theme-color-blue-100};
    --theme-color-blue-150: #{$--theme-color-blue-150};
    --theme-color-blue-200: #{$--theme-color-blue-200};
    --theme-color-blue-300: #{$--theme-color-blue-300};
    --theme-color-blue-400: #{$--theme-color-blue-400};
    --theme-color-blue-500: #{$--theme-color-blue-500};
    --theme-color-blue-600: #{$--theme-color-blue-600};
    --theme-color-blue-700: #{$--theme-color-blue-700};
    --theme-color-blue-800: #{$--theme-color-blue-800};
    --theme-color-blue-850: #{$--theme-color-blue-850};
    --theme-color-blue-900: #{$--theme-color-blue-900};
    --theme-color-blue-950: #{$--theme-color-blue-950};

    --theme-color-teal-50: #{$--theme-color-teal-50};
    --theme-color-teal-100: #{$--theme-color-teal-100};
    --theme-color-teal-150: #{$--theme-color-teal-150};
    --theme-color-teal-200: #{$--theme-color-teal-200};
    --theme-color-teal-300: #{$--theme-color-teal-300};
    --theme-color-teal-400: #{$--theme-color-teal-400};
    --theme-color-teal-500: #{$--theme-color-teal-500};
    --theme-color-teal-600: #{$--theme-color-teal-600};
    --theme-color-teal-700: #{$--theme-color-teal-700};
    --theme-color-teal-800: #{$--theme-color-teal-800};
    --theme-color-teal-850: #{$--theme-color-teal-850};
    --theme-color-teal-900: #{$--theme-color-teal-900};
    --theme-color-teal-950: #{$--theme-color-teal-950};

    --theme-color-cyan-50: #{$--theme-color-cyan-50};
    //border-radius
    --theme-border-radius: #{$--theme-border-radius};
    --theme-dialog-overlay: #{$--theme-dialog-overlay};
    --theme-global-shadow: #{$--theme-global-shadow};
    //Spacing
    --theme-transparent-background: #{$--theme-transparent-background};
    --theme-global-spacing: #{$--standard-theme-global-spacing};
    //Fonts
    --theme-global-font-familly: #{$--standard-theme-global-font-family};
}

// =====================Primary student users theme variable mixins
[data-theme="primary-student"] {
    --theme-color-neutrals-0: #{$--theme-color-neutrals-0};
    --theme-color-neutrals-50: #{$--theme-color-neutrals-50};
    --theme-color-neutrals-100: #{$--theme-color-neutrals-100};
    --theme-color-neutrals-150: #{$--theme-color-neutrals-150};
    --theme-color-neutrals-200: #{$--theme-color-neutrals-200};
    --theme-color-neutrals-300: #{$--theme-color-neutrals-300};
    --theme-color-neutrals-400: #{$--theme-color-neutrals-400};
    --theme-color-neutrals-500: #{$--theme-color-neutrals-500};
    --theme-color-neutrals-600: #{$--theme-color-neutrals-600};
    --theme-color-neutrals-700: #{$--theme-color-neutrals-700};
    --theme-color-neutrals-800: #{$--theme-color-neutrals-800};
    --theme-color-neutrals-850: #{$--theme-color-neutrals-850};
    --theme-color-neutrals-900: #{$--theme-color-neutrals-900};
    --theme-color-neutrals-950: #{$--theme-color-neutrals-950};
    --theme-color-neutrals-1000: #{$--theme-color-neutrals-1000};

    --theme-color-50: #{$--primary-student-theme-color-50};
    --theme-color-100: #{$--primary-student-theme-color-100};
    --theme-color-150: #{$--primary-student-theme-color-150};
    --theme-color-200: #{$--primary-student-theme-color-200};
    --theme-color-300: #{$--primary-student-theme-color-300};
    --theme-color-400: #{$--primary-student-theme-color-400};
    --theme-color-500: #{$--primary-student-theme-color-500};
    --theme-color-600: #{$--primary-student-theme-color-600};
    --theme-color-700: #{$--primary-student-theme-color-700};
    --theme-color-800: #{$--primary-student-theme-color-800};
    --theme-color-850: #{$--primary-student-theme-color-850};
    --theme-color-900: #{$--primary-student-theme-color-900};
    --theme-color-950: #{$--primary-student-theme-color-950};

    --theme-error: #{$--theme-error};
    --theme-success: #{$--theme-success};
    --theme-warning: #{$--theme-warning};

    --theme-success-light: #{$--theme-success-light};
    --theme-warning-light: #{$--theme-warning-light};
    --theme-error-light: #{$--theme-error-light};

    --theme-success-light-100: #{$--theme-success-light-100};
    --theme-warning-light-100: #{$--theme-warning-light-100};
    --theme-error-light-100: #{$--theme-error-light-100};
    --theme-border-orange-500: #{$--theme-border-orange-500};

    --theme-color-magenta-50: #{$--theme-color-magenta-50};
    --theme-color-magenta-100: #{$--theme-color-magenta-100};
    --theme-color-magenta-150: #{$--theme-color-magenta-150};
    --theme-color-magenta-200: #{$--theme-color-magenta-200};
    --theme-color-magenta-300: #{$--theme-color-magenta-300};
    --theme-color-magenta-400: #{$--theme-color-magenta-400};
    --theme-color-magenta-500: #{$--theme-color-magenta-500};
    --theme-color-magenta-600: #{$--theme-color-magenta-600};
    --theme-color-magenta-700: #{$--theme-color-magenta-700};
    --theme-color-magenta-800: #{$--theme-color-magenta-800};
    --theme-color-magenta-850: #{$--theme-color-magenta-850};
    --theme-color-magenta-900: #{$--theme-color-magenta-900};
    --theme-color-magenta-950: #{$--theme-color-magenta-950};

    --theme-color-purple-50: #{$--theme-color-purple-50};
    --theme-color-purple-100: #{$--theme-color-purple-100};
    --theme-color-purple-150: #{$--theme-color-purple-150};
    --theme-color-purple-200: #{$--theme-color-purple-200};
    --theme-color-purple-300: #{$--theme-color-purple-300};
    --theme-color-purple-400: #{$--theme-color-purple-400};
    --theme-color-purple-500: #{$--theme-color-purple-500};
    --theme-color-purple-600: #{$--theme-color-purple-600};
    --theme-color-purple-700: #{$--theme-color-purple-700};
    --theme-color-purple-800: #{$--theme-color-purple-800};
    --theme-color-purple-850: #{$--theme-color-purple-850};
    --theme-color-purple-900: #{$--theme-color-purple-900};
    --theme-color-purple-950: #{$--theme-color-purple-950};

    --theme-color-green-50: #{$--theme-color-green-50};
    --theme-color-green-100: #{$--theme-color-green-100};
    --theme-color-green-150: #{$--theme-color-green-150};
    --theme-color-green-200: #{$--theme-color-green-200};
    --theme-color-green-300: #{$--theme-color-green-300};
    --theme-color-green-400: #{$--theme-color-green-400};
    --theme-color-green-500: #{$--theme-color-green-500};
    --theme-color-green-600: #{$--theme-color-green-600};
    --theme-color-green-700: #{$--theme-color-green-700};
    --theme-color-green-800: #{$--theme-color-green-800};
    --theme-color-green-850: #{$--theme-color-green-850};
    --theme-color-green-900: #{$--theme-color-green-900};
    --theme-color-green-950: #{$--theme-color-green-950};

    --theme-color-orange-50: #{$--theme-color-orange-50};
    --theme-color-orange-100: #{$--theme-color-orange-100};
    --theme-color-orange-150: #{$--theme-color-orange-150};
    --theme-color-orange-200: #{$--theme-color-orange-200};
    --theme-color-orange-300: #{$--theme-color-orange-300};
    --theme-color-orange-400: #{$--theme-color-orange-400};
    --theme-color-orange-500: #{$--theme-color-orange-500};
    --theme-color-orange-600: #{$--theme-color-orange-600};
    --theme-color-orange-700: #{$--theme-color-orange-700};
    --theme-color-orange-800: #{$--theme-color-orange-800};
    --theme-color-orange-850: #{$--theme-color-orange-850};
    --theme-color-orange-900: #{$--theme-color-orange-900};
    --theme-color-orange-950: #{$--theme-color-orange-950};

    --theme-color-blue-50: #{$--theme-color-blue-50};
    --theme-color-blue-100: #{$--theme-color-blue-100};
    --theme-color-blue-150: #{$--theme-color-blue-150};
    --theme-color-blue-200: #{$--theme-color-blue-200};
    --theme-color-blue-300: #{$--theme-color-blue-300};
    --theme-color-blue-400: #{$--theme-color-blue-400};
    --theme-color-blue-500: #{$--theme-color-blue-500};
    --theme-color-blue-600: #{$--theme-color-blue-600};
    --theme-color-blue-700: #{$--theme-color-blue-700};
    --theme-color-blue-800: #{$--theme-color-blue-800};
    --theme-color-blue-850: #{$--theme-color-blue-850};
    --theme-color-blue-900: #{$--theme-color-blue-900};
    --theme-color-blue-950: #{$--theme-color-blue-950};

    --theme-color-teal-50: #{$--theme-color-teal-50};
    --theme-color-teal-100: #{$--theme-color-teal-100};
    --theme-color-teal-150: #{$--theme-color-teal-150};
    --theme-color-teal-200: #{$--theme-color-teal-200};
    --theme-color-teal-300: #{$--theme-color-teal-300};
    --theme-color-teal-400: #{$--theme-color-teal-400};
    --theme-color-teal-500: #{$--theme-color-teal-500};
    --theme-color-teal-600: #{$--theme-color-teal-600};
    --theme-color-teal-700: #{$--theme-color-teal-700};
    --theme-color-teal-800: #{$--theme-color-teal-800};
    --theme-color-teal-850: #{$--theme-color-teal-850};
    --theme-color-teal-900: #{$--theme-color-teal-900};
    --theme-color-teal-950: #{$--theme-color-teal-950};

    --theme-color-cyan-50: #{$--theme-color-cyan-50};
    //border-radius
    --theme-border-radius: #{$--theme-border-radius};
    --theme-dialog-overlay: #{$--theme-dialog-overlay};
    --theme-global-shadow: #{$--theme-global-shadow};
    //Spacing
    --theme-transparent-background: #{$--theme-transparent-background};
    --theme-global-spacing: #{$--primary-student-theme-global-spacing};
    //Fonts
    --theme-global-font-familly: #{$--primary-student-theme-global-font-family};
}

// ===================== purple sky theme variable mixins
[data-theme="calm-blue"] {
    --theme-color-neutrals-0: #{$--theme-color-neutrals-0};
    --theme-color-neutrals-50: #{$--theme-color-neutrals-50};
    --theme-color-neutrals-100: #{$--theme-color-neutrals-100};
    --theme-color-neutrals-150: #{$--theme-color-neutrals-150};
    --theme-color-neutrals-200: #{$--theme-color-neutrals-200};
    --theme-color-neutrals-300: #{$--theme-color-neutrals-300};
    --theme-color-neutrals-400: #{$--theme-color-neutrals-400};
    --theme-color-neutrals-500: #{$--theme-color-neutrals-500};
    --theme-color-neutrals-600: #{$--theme-color-neutrals-600};
    --theme-color-neutrals-700: #{$--theme-color-neutrals-700};
    --theme-color-neutrals-800: #{$--theme-color-neutrals-800};
    --theme-color-neutrals-850: #{$--theme-color-neutrals-850};
    --theme-color-neutrals-900: #{$--theme-color-neutrals-900};
    --theme-color-neutrals-950: #{$--theme-color-neutrals-950};
    --theme-color-neutrals-1000: #{$--theme-color-neutrals-1000};

    --theme-color-50: #{$--theme-color-blue-50};
    --theme-color-100: #{$--theme-color-blue-100};
    --theme-color-150: #{$--theme-color-blue-150};
    --theme-color-200: #{$--theme-color-blue-200};
    --theme-color-300: #{$--theme-color-blue-300};
    --theme-color-400: #{$--theme-color-blue-400};
    --theme-color-500: #{$--theme-color-blue-500};
    --theme-color-600: #{$--theme-color-blue-600};
    --theme-color-700: #{$--theme-color-blue-700};
    --theme-color-800: #{$--theme-color-blue-800};
    --theme-color-850: #{$--theme-color-blue-850};
    --theme-color-900: #{$--theme-color-blue-900};
    --theme-color-950: #{$--theme-color-blue-950};

    --theme-color-magenta-50: #{$--theme-color-magenta-50};
    --theme-color-magenta-100: #{$--theme-color-magenta-100};
    --theme-color-magenta-150: #{$--theme-color-magenta-150};
    --theme-color-magenta-200: #{$--theme-color-magenta-200};
    --theme-color-magenta-300: #{$--theme-color-magenta-300};
    --theme-color-magenta-400: #{$--theme-color-magenta-400};
    --theme-color-magenta-500: #{$--theme-color-magenta-500};
    --theme-color-magenta-600: #{$--theme-color-magenta-600};
    --theme-color-magenta-700: #{$--theme-color-magenta-700};
    --theme-color-magenta-800: #{$--theme-color-magenta-800};
    --theme-color-magenta-850: #{$--theme-color-magenta-850};
    --theme-color-magenta-900: #{$--theme-color-magenta-900};
    --theme-color-magenta-950: #{$--theme-color-magenta-950};

    --theme-color-purple-50: #{$--theme-color-purple-50};
    --theme-color-purple-100: #{$--theme-color-purple-100};
    --theme-color-purple-150: #{$--theme-color-purple-150};
    --theme-color-purple-200: #{$--theme-color-purple-200};
    --theme-color-purple-300: #{$--theme-color-purple-300};
    --theme-color-purple-400: #{$--theme-color-purple-400};
    --theme-color-purple-500: #{$--theme-color-purple-500};
    --theme-color-purple-600: #{$--theme-color-purple-600};
    --theme-color-purple-700: #{$--theme-color-purple-700};
    --theme-color-purple-800: #{$--theme-color-purple-800};
    --theme-color-purple-850: #{$--theme-color-purple-850};
    --theme-color-purple-900: #{$--theme-color-purple-900};
    --theme-color-purple-950: #{$--theme-color-purple-950};

    --theme-color-green-50: #{$--theme-color-green-50};
    --theme-color-green-100: #{$--theme-color-green-100};
    --theme-color-green-150: #{$--theme-color-green-150};
    --theme-color-green-200: #{$--theme-color-green-200};
    --theme-color-green-300: #{$--theme-color-green-300};
    --theme-color-green-400: #{$--theme-color-green-400};
    --theme-color-green-500: #{$--theme-color-green-500};
    --theme-color-green-600: #{$--theme-color-green-600};
    --theme-color-green-700: #{$--theme-color-green-700};
    --theme-color-green-800: #{$--theme-color-green-800};
    --theme-color-green-850: #{$--theme-color-green-850};
    --theme-color-green-900: #{$--theme-color-green-900};
    --theme-color-green-950: #{$--theme-color-green-950};

    --theme-color-orange-50: #{$--theme-color-orange-50};
    --theme-color-orange-100: #{$--theme-color-orange-100};
    --theme-color-orange-150: #{$--theme-color-orange-150};
    --theme-color-orange-200: #{$--theme-color-orange-200};
    --theme-color-orange-300: #{$--theme-color-orange-300};
    --theme-color-orange-400: #{$--theme-color-orange-400};
    --theme-color-orange-500: #{$--theme-color-orange-500};
    --theme-color-orange-600: #{$--theme-color-orange-600};
    --theme-color-orange-700: #{$--theme-color-orange-700};
    --theme-color-orange-800: #{$--theme-color-orange-800};
    --theme-color-orange-850: #{$--theme-color-orange-850};
    --theme-color-orange-900: #{$--theme-color-orange-900};
    --theme-color-orange-950: #{$--theme-color-orange-950};

    --theme-color-blue-50: #{$--theme-color-blue-50};
    --theme-color-blue-100: #{$--theme-color-blue-100};
    --theme-color-blue-150: #{$--theme-color-blue-150};
    --theme-color-blue-200: #{$--theme-color-blue-200};
    --theme-color-blue-300: #{$--theme-color-blue-300};
    --theme-color-blue-400: #{$--theme-color-blue-400};
    --theme-color-blue-500: #{$--theme-color-blue-500};
    --theme-color-blue-600: #{$--theme-color-blue-600};
    --theme-color-blue-700: #{$--theme-color-blue-700};
    --theme-color-blue-800: #{$--theme-color-blue-800};
    --theme-color-blue-850: #{$--theme-color-blue-850};
    --theme-color-blue-900: #{$--theme-color-blue-900};
    --theme-color-blue-950: #{$--theme-color-blue-950};

    --theme-color-teal-50: #{$--theme-color-teal-50};
    --theme-color-teal-100: #{$--theme-color-teal-100};
    --theme-color-teal-150: #{$--theme-color-teal-150};
    --theme-color-teal-200: #{$--theme-color-teal-200};
    --theme-color-teal-300: #{$--theme-color-teal-300};
    --theme-color-teal-400: #{$--theme-color-teal-400};
    --theme-color-teal-500: #{$--theme-color-teal-500};
    --theme-color-teal-600: #{$--theme-color-teal-600};
    --theme-color-teal-700: #{$--theme-color-teal-700};
    --theme-color-teal-800: #{$--theme-color-teal-800};
    --theme-color-teal-850: #{$--theme-color-teal-850};
    --theme-color-teal-900: #{$--theme-color-teal-900};
    --theme-color-teal-950: #{$--theme-color-teal-950};

    --theme-error: #{$--theme-error};
    --theme-success: #{$--theme-success};
    --theme-warning: #{$--theme-warning};

    --theme-success-light: #{$--theme-success-light};
    --theme-warning-light: #{$--theme-warning-light};
    --theme-error-light: #{$--theme-error-light};

    --theme-success-light-100: #{$--theme-success-light-100};
    --theme-warning-light-100: #{$--theme-warning-light-100};
    --theme-error-light-100: #{$--theme-error-light-100};
    --theme-border-orange-500: #{$--theme-border-orange-500};

    //border-radius
    --theme-border-radius: #{$--theme-border-radius};
    --theme-dialog-overlay: #{$--playful-theme-dialog-overlay-calm-blue};
    --theme-global-shadow: #{$--theme-global-shadow};
    //Spacing
    --theme-transparent-background: #{$--theme-transparent-background};
    --theme-global-spacing: #{$--primary-student-theme-global-spacing};
    //Fonts
    --theme-global-font-familly: #{$--playful-theme-global-font-family};
}

// ===================== purple sky theme variable mixins
[data-theme="purple-sky"], [data-theme="purple"] {
    --theme-color-neutrals-0: #{$--theme-color-neutrals-0};
    --theme-color-neutrals-50: #{$--theme-color-neutrals-50};
    --theme-color-neutrals-100: #{$--theme-color-neutrals-100};
    --theme-color-neutrals-150: #{$--theme-color-neutrals-150};
    --theme-color-neutrals-200: #{$--theme-color-neutrals-200};
    --theme-color-neutrals-300: #{$--theme-color-neutrals-300};
    --theme-color-neutrals-400: #{$--theme-color-neutrals-400};
    --theme-color-neutrals-500: #{$--theme-color-neutrals-500};
    --theme-color-neutrals-600: #{$--theme-color-neutrals-600};
    --theme-color-neutrals-700: #{$--theme-color-neutrals-700};
    --theme-color-neutrals-800: #{$--theme-color-neutrals-800};
    --theme-color-neutrals-850: #{$--theme-color-neutrals-850};
    --theme-color-neutrals-900: #{$--theme-color-neutrals-900};
    --theme-color-neutrals-950: #{$--theme-color-neutrals-950};
    --theme-color-neutrals-1000: #{$--theme-color-neutrals-1000};

    --theme-color-50: #{$--theme-color-purple-50};
    --theme-color-100: #{$--theme-color-purple-100};
    --theme-color-150: #{$--theme-color-purple-150};
    --theme-color-200: #{$--theme-color-purple-200};
    --theme-color-300: #{$--theme-color-purple-300};
    --theme-color-400: #{$--theme-color-purple-400};
    --theme-color-500: #{$--theme-color-purple-500};
    --theme-color-600: #{$--theme-color-purple-600};
    --theme-color-700: #{$--theme-color-purple-700};
    --theme-color-800: #{$--theme-color-purple-800};
    --theme-color-850: #{$--theme-color-purple-850};
    --theme-color-900: #{$--theme-color-purple-900};
    --theme-color-950: #{$--theme-color-purple-950};

    --theme-color-magenta-50: #{$--theme-color-magenta-50};
    --theme-color-magenta-100: #{$--theme-color-magenta-100};
    --theme-color-magenta-150: #{$--theme-color-magenta-150};
    --theme-color-magenta-200: #{$--theme-color-magenta-200};
    --theme-color-magenta-300: #{$--theme-color-magenta-300};
    --theme-color-magenta-400: #{$--theme-color-magenta-400};
    --theme-color-magenta-500: #{$--theme-color-magenta-500};
    --theme-color-magenta-600: #{$--theme-color-magenta-600};
    --theme-color-magenta-700: #{$--theme-color-magenta-700};
    --theme-color-magenta-800: #{$--theme-color-magenta-800};
    --theme-color-magenta-850: #{$--theme-color-magenta-850};
    --theme-color-magenta-900: #{$--theme-color-magenta-900};
    --theme-color-magenta-950: #{$--theme-color-magenta-950};

    --theme-color-purple-50: #{$--theme-color-purple-50};
    --theme-color-purple-100: #{$--theme-color-purple-100};
    --theme-color-purple-150: #{$--theme-color-purple-150};
    --theme-color-purple-200: #{$--theme-color-purple-200};
    --theme-color-purple-300: #{$--theme-color-purple-300};
    --theme-color-purple-400: #{$--theme-color-purple-400};
    --theme-color-purple-500: #{$--theme-color-purple-500};
    --theme-color-purple-600: #{$--theme-color-purple-600};
    --theme-color-purple-700: #{$--theme-color-purple-700};
    --theme-color-purple-800: #{$--theme-color-purple-800};
    --theme-color-purple-850: #{$--theme-color-purple-850};
    --theme-color-purple-900: #{$--theme-color-purple-900};
    --theme-color-purple-950: #{$--theme-color-purple-950};

    --theme-color-green-50: #{$--theme-color-green-50};
    --theme-color-green-100: #{$--theme-color-green-100};
    --theme-color-green-150: #{$--theme-color-green-150};
    --theme-color-green-200: #{$--theme-color-green-200};
    --theme-color-green-300: #{$--theme-color-green-300};
    --theme-color-green-400: #{$--theme-color-green-400};
    --theme-color-green-500: #{$--theme-color-green-500};
    --theme-color-green-600: #{$--theme-color-green-600};
    --theme-color-green-700: #{$--theme-color-green-700};
    --theme-color-green-800: #{$--theme-color-green-800};
    --theme-color-green-850: #{$--theme-color-green-850};
    --theme-color-green-900: #{$--theme-color-green-900};
    --theme-color-green-950: #{$--theme-color-green-950};

    --theme-color-orange-50: #{$--theme-color-orange-50};
    --theme-color-orange-100: #{$--theme-color-orange-100};
    --theme-color-orange-150: #{$--theme-color-orange-150};
    --theme-color-orange-200: #{$--theme-color-orange-200};
    --theme-color-orange-300: #{$--theme-color-orange-300};
    --theme-color-orange-400: #{$--theme-color-orange-400};
    --theme-color-orange-500: #{$--theme-color-orange-500};
    --theme-color-orange-600: #{$--theme-color-orange-600};
    --theme-color-orange-700: #{$--theme-color-orange-700};
    --theme-color-orange-800: #{$--theme-color-orange-800};
    --theme-color-orange-850: #{$--theme-color-orange-850};
    --theme-color-orange-900: #{$--theme-color-orange-900};
    --theme-color-orange-950: #{$--theme-color-orange-950};

    --theme-color-blue-50: #{$--theme-color-blue-50};
    --theme-color-blue-100: #{$--theme-color-blue-100};
    --theme-color-blue-150: #{$--theme-color-blue-150};
    --theme-color-blue-200: #{$--theme-color-blue-200};
    --theme-color-blue-300: #{$--theme-color-blue-300};
    --theme-color-blue-400: #{$--theme-color-blue-400};
    --theme-color-blue-500: #{$--theme-color-blue-500};
    --theme-color-blue-600: #{$--theme-color-blue-600};
    --theme-color-blue-700: #{$--theme-color-blue-700};
    --theme-color-blue-800: #{$--theme-color-blue-800};
    --theme-color-blue-850: #{$--theme-color-blue-850};
    --theme-color-blue-900: #{$--theme-color-blue-900};
    --theme-color-blue-950: #{$--theme-color-blue-950};

    --theme-color-teal-50: #{$--theme-color-teal-50};
    --theme-color-teal-100: #{$--theme-color-teal-100};
    --theme-color-teal-150: #{$--theme-color-teal-150};
    --theme-color-teal-200: #{$--theme-color-teal-200};
    --theme-color-teal-300: #{$--theme-color-teal-300};
    --theme-color-teal-400: #{$--theme-color-teal-400};
    --theme-color-teal-500: #{$--theme-color-teal-500};
    --theme-color-teal-600: #{$--theme-color-teal-600};
    --theme-color-teal-700: #{$--theme-color-teal-700};
    --theme-color-teal-800: #{$--theme-color-teal-800};
    --theme-color-teal-850: #{$--theme-color-teal-850};
    --theme-color-teal-900: #{$--theme-color-teal-900};
    --theme-color-teal-950: #{$--theme-color-teal-950};

    --theme-error: #{$--theme-error};
    --theme-success: #{$--theme-success};
    --theme-warning: #{$--theme-warning};

    --theme-success-light: #{$--theme-success-light};
    --theme-warning-light: #{$--theme-warning-light};
    --theme-error-light: #{$--theme-error-light};

    --theme-success-light-100: #{$--theme-success-light-100};
    --theme-warning-light-100: #{$--theme-warning-light-100};
    --theme-error-light-100: #{$--theme-error-light-100};
    --theme-border-orange-500: #{$--theme-border-orange-500};

    //border-radius
    --theme-border-radius: #{$--theme-border-radius};
    --theme-dialog-overlay: #{$--playful-theme-dialog-overlay-purple-sky};
    --theme-global-shadow: #{$--theme-global-shadow};
    //Spacing
    --theme-transparent-background: #{$--theme-transparent-background};
    --theme-global-spacing: #{$--primary-student-theme-global-spacing};
    //Fonts
    --theme-global-font-familly: #{$--playful-theme-global-font-family};
}

// ===================== turtle bay theme variable mixins
[data-theme="turtle-bay"], [data-theme="teal"] {
    --theme-color-neutrals-0: #{$--theme-color-neutrals-0};
    --theme-color-neutrals-50: #{$--theme-color-neutrals-50};
    --theme-color-neutrals-100: #{$--theme-color-neutrals-100};
    --theme-color-neutrals-150: #{$--theme-color-neutrals-150};
    --theme-color-neutrals-200: #{$--theme-color-neutrals-200};
    --theme-color-neutrals-300: #{$--theme-color-neutrals-300};
    --theme-color-neutrals-400: #{$--theme-color-neutrals-400};
    --theme-color-neutrals-500: #{$--theme-color-neutrals-500};
    --theme-color-neutrals-600: #{$--theme-color-neutrals-600};
    --theme-color-neutrals-700: #{$--theme-color-neutrals-700};
    --theme-color-neutrals-800: #{$--theme-color-neutrals-800};
    --theme-color-neutrals-850: #{$--theme-color-neutrals-850};
    --theme-color-neutrals-900: #{$--theme-color-neutrals-900};
    --theme-color-neutrals-950: #{$--theme-color-neutrals-950};
    --theme-color-neutrals-1000: #{$--theme-color-neutrals-1000};

    --theme-color-50: #{$--theme-color-teal-50};
    --theme-color-100: #{$--theme-color-teal-100};
    --theme-color-150: #{$--theme-color-teal-150};
    --theme-color-200: #{$--theme-color-teal-200};
    --theme-color-300: #{$--theme-color-teal-300};
    --theme-color-400: #{$--theme-color-teal-400};
    --theme-color-500: #{$--theme-color-teal-500};
    --theme-color-600: #{$--theme-color-teal-600};
    --theme-color-700: #{$--theme-color-teal-700};
    --theme-color-800: #{$--theme-color-teal-800};
    --theme-color-850: #{$--theme-color-teal-850};
    --theme-color-900: #{$--theme-color-teal-900};
    --theme-color-950: #{$--theme-color-teal-950};

    --theme-color-magenta-50: #{$--theme-color-magenta-50};
    --theme-color-magenta-100: #{$--theme-color-magenta-100};
    --theme-color-magenta-150: #{$--theme-color-magenta-150};
    --theme-color-magenta-200: #{$--theme-color-magenta-200};
    --theme-color-magenta-300: #{$--theme-color-magenta-300};
    --theme-color-magenta-400: #{$--theme-color-magenta-400};
    --theme-color-magenta-500: #{$--theme-color-magenta-500};
    --theme-color-magenta-600: #{$--theme-color-magenta-600};
    --theme-color-magenta-700: #{$--theme-color-magenta-700};
    --theme-color-magenta-800: #{$--theme-color-magenta-800};
    --theme-color-magenta-850: #{$--theme-color-magenta-850};
    --theme-color-magenta-900: #{$--theme-color-magenta-900};
    --theme-color-magenta-950: #{$--theme-color-magenta-950};

    --theme-color-purple-50: #{$--theme-color-purple-50};
    --theme-color-purple-100: #{$--theme-color-purple-100};
    --theme-color-purple-150: #{$--theme-color-purple-150};
    --theme-color-purple-200: #{$--theme-color-purple-200};
    --theme-color-purple-300: #{$--theme-color-purple-300};
    --theme-color-purple-400: #{$--theme-color-purple-400};
    --theme-color-purple-500: #{$--theme-color-purple-500};
    --theme-color-purple-600: #{$--theme-color-purple-600};
    --theme-color-purple-700: #{$--theme-color-purple-700};
    --theme-color-purple-800: #{$--theme-color-purple-800};
    --theme-color-purple-850: #{$--theme-color-purple-850};
    --theme-color-purple-900: #{$--theme-color-purple-900};
    --theme-color-purple-950: #{$--theme-color-purple-950};

    --theme-color-green-50: #{$--theme-color-green-50};
    --theme-color-green-100: #{$--theme-color-green-100};
    --theme-color-green-150: #{$--theme-color-green-150};
    --theme-color-green-200: #{$--theme-color-green-200};
    --theme-color-green-300: #{$--theme-color-green-300};
    --theme-color-green-400: #{$--theme-color-green-400};
    --theme-color-green-500: #{$--theme-color-green-500};
    --theme-color-green-600: #{$--theme-color-green-600};
    --theme-color-green-700: #{$--theme-color-green-700};
    --theme-color-green-800: #{$--theme-color-green-800};
    --theme-color-green-850: #{$--theme-color-green-850};
    --theme-color-green-900: #{$--theme-color-green-900};
    --theme-color-green-950: #{$--theme-color-green-950};

    --theme-color-orange-50: #{$--theme-color-orange-50};
    --theme-color-orange-100: #{$--theme-color-orange-100};
    --theme-color-orange-150: #{$--theme-color-orange-150};
    --theme-color-orange-200: #{$--theme-color-orange-200};
    --theme-color-orange-300: #{$--theme-color-orange-300};
    --theme-color-orange-400: #{$--theme-color-orange-400};
    --theme-color-orange-500: #{$--theme-color-orange-500};
    --theme-color-orange-600: #{$--theme-color-orange-600};
    --theme-color-orange-700: #{$--theme-color-orange-700};
    --theme-color-orange-800: #{$--theme-color-orange-800};
    --theme-color-orange-850: #{$--theme-color-orange-850};
    --theme-color-orange-900: #{$--theme-color-orange-900};
    --theme-color-orange-950: #{$--theme-color-orange-950};

    --theme-color-blue-50: #{$--theme-color-blue-50};
    --theme-color-blue-100: #{$--theme-color-blue-100};
    --theme-color-blue-150: #{$--theme-color-blue-150};
    --theme-color-blue-200: #{$--theme-color-blue-200};
    --theme-color-blue-300: #{$--theme-color-blue-300};
    --theme-color-blue-400: #{$--theme-color-blue-400};
    --theme-color-blue-500: #{$--theme-color-blue-500};
    --theme-color-blue-600: #{$--theme-color-blue-600};
    --theme-color-blue-700: #{$--theme-color-blue-700};
    --theme-color-blue-800: #{$--theme-color-blue-800};
    --theme-color-blue-850: #{$--theme-color-blue-850};
    --theme-color-blue-900: #{$--theme-color-blue-900};
    --theme-color-blue-950: #{$--theme-color-blue-950};

    --theme-color-teal-50: #{$--theme-color-teal-50};
    --theme-color-teal-100: #{$--theme-color-teal-100};
    --theme-color-teal-150: #{$--theme-color-teal-150};
    --theme-color-teal-200: #{$--theme-color-teal-200};
    --theme-color-teal-300: #{$--theme-color-teal-300};
    --theme-color-teal-400: #{$--theme-color-teal-400};
    --theme-color-teal-500: #{$--theme-color-teal-500};
    --theme-color-teal-600: #{$--theme-color-teal-600};
    --theme-color-teal-700: #{$--theme-color-teal-700};
    --theme-color-teal-800: #{$--theme-color-teal-800};
    --theme-color-teal-850: #{$--theme-color-teal-850};
    --theme-color-teal-900: #{$--theme-color-teal-900};
    --theme-color-teal-950: #{$--theme-color-teal-950};

    --theme-error: #{$--theme-error};
    --theme-success: #{$--theme-success};
    --theme-warning: #{$--theme-warning};

    --theme-success-light: #{$--theme-success-light};
    --theme-warning-light: #{$--theme-warning-light};
    --theme-error-light: #{$--theme-error-light};

    --theme-success-light-100: #{$--theme-success-light-100};
    --theme-warning-light-100: #{$--theme-warning-light-100};
    --theme-error-light-100: #{$--theme-error-light-100};
    --theme-border-orange-500: #{$--theme-border-orange-500};

    //border-radius
    --theme-border-radius: #{$--theme-border-radius};
    --theme-dialog-overlay: #{$--playful-theme-dialog-overlay-turtle-bay};
    --theme-global-shadow: #{$--theme-global-shadow};
    //Spacing
    --theme-transparent-background: #{$--theme-transparent-background};
    --theme-global-spacing: #{$--primary-student-theme-global-spacing};
    //Fonts
    --theme-global-font-familly: #{$--playful-theme-global-font-family};
}