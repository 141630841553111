.ql-snow.ql-toolbar{
    padding: calc(var(--theme-global-spacing) * 1) calc(var(--theme-global-spacing) * 2);
    border-radius: var(--radius) var(--radius) 0 0;
    @media (max-width: map-get($context--breakpoints, sm-max)) {
        padding: calc(var(--theme-global-spacing) * 1) calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 1.5);
    }
    //for all button in toolbar
    .ql-bold, .ql-italic, .ql-underline, .ql-strike, .ql-list, .ql-indent, .ql-script, .ql-link, .ql-image, .ql-formula, .ql-color .ql-picker-label,.ql-background .ql-picker-label{
        background-repeat: no-repeat;
        background-size: 18px;
        background-position: center;
        width: 24px;
        margin-right: calc(var(--theme-global-spacing) * 2);
        margin-top: calc(var(--theme-global-spacing) * 1);
        margin-bottom: calc(var(--theme-global-spacing) * 1);        
        svg{
            display: none;
        } 
        &:focus{
            outline-color: var(--theme-color-950);
            outline-width: 2px;
            outline-style: solid;
        }
        @media (max-width: map-get($context--breakpoints, sm-max)) {
            height: 32px;
            width: 32px;
            margin-right: calc(var(--theme-global-spacing) * 1);
        }
    }
    .ql-formats {
        margin-right: calc(var(--theme-global-spacing) * 2);
        @media (max-width: map-get($context--breakpoints, sm-max)) {
            margin-right: calc(var(--theme-global-spacing) * 1.3325);
        }
        .ql-font{
            .ql-picker-label{
                font-family: var(--font-primary);
                @include context--base-sm;
                font-weight: var(--font-weight-normal);
                color: var(--theme-color-neutrals-1000);
            }
            &.ql-expanded{
                .ql-picker-label{
                    color: var(--theme-color-neutrals-1000);
                }
            }
        }
        .ql-header{
            .ql-picker-label{
                font-family: var(--font-primary);
                @include context--base-sm;
                font-weight: var(--font-weight-normal);
                color: var(--theme-color-neutrals-1000);
                @media (min-width: map-get($context--breakpoints, xxl)) {
                    @include font-style(var(--text-xxxs), var(--text-xxs));
                }
            }
            &.ql-expanded{
                .ql-picker-label{
                    color: var(--theme-color-neutrals-1000);
                }
            }
        }
        .ql-color-picker{
            margin-right: calc(var(--theme-global-spacing) * 2);
            width: 24px;
            height: 24px;
            margin-top: calc(var(--theme-global-spacing) * 1);
            margin-bottom: calc(var(--theme-global-spacing) * 1);
            .ql-picker-label{
                margin: calc(var(--theme-global-spacing) * 0);
            }
            @media (max-width: map-get($context--breakpoints, sm-max)) {
                width: 32px;
                height: 32px;
            }
        }
        .ql-active{
            background-color: var(--theme-color-100);
        }
    }
    .ql-bold{
        background-image: url('../../assets/default/images/boldicon.svg');
    }
    .ql-italic{
        background-image: url('../../assets/default/images/italicIcon.svg');  
    }
    .ql-color{
        .ql-picker-label{
            background-image: url('../../assets/default/images/textcolortextcolor.svg');
        }
    }
    .ql-background{
        .ql-picker-label{
            background-image: url('../../assets/default/images/backgroundcolor.svg');
            @extend .ql-color,.ql-picker-label; 
            background-position: center;
            background-repeat: no-repeat;
            background-size: calc(var(--theme-global-spacing) * 4.5); 
        }
    }
    .ql-underline{
        background-image: url('../../assets/default/images/underline.svg'); 
        background-size: calc(var(--theme-global-spacing) * 4);
    }
    .ql-size, .ql-font, .ql-header{
        margin-right: calc(var(--theme-global-spacing) * 2);
        font-family: var(--font-primary);
        @include context--base-sm;
        font-weight: var(--font-weight-normal);
        .ql-picker-label{
            background-image: url('../../assets/default/images/chevron-vertical.svg');
            background-repeat: no-repeat;
            background-size: calc(var(--theme-global-spacing) * 4);
            background-position: center right;
            @media (max-width: map-get($context--breakpoints, sm-max)) {
                &::before{
                    line-height: calc(var(--theme-global-spacing) * 8);
                }
            }
        }
        svg{
            display: none;
        }
        @media (max-width: map-get($context--breakpoints, sm-max)) {
            height: 32px;
        }
        @media (min-width: map-get($context--breakpoints, xxl)) {
            @include font-style(var(--text-xxxs), var(--text-xxs));
        }
    }
    .ql-strike{
        background-image: url('../../assets/default/images/strike.svg'); 
    }
    .ql-blockquote{
        background-image: url('../../assets/default/images/blockquote.svg');
    }
    .ql-code-block{
        background-image: url('../../assets/default/images/codeView.svg');
    }
    .ql-list[value="ordered"]{
        background-image: url('../../assets/default/images/orderedList.svg');
    }
    .ql-list[value="bullet"]{
        background-image: url('../../assets/default/images/unorderedList.svg');
    }
    .ql-indent[value="-1"]{
        background-image: url('../../assets/default/images/outdent.svg');
    }
    .ql-indent[value="+1"]{
        background-image: url('../../assets/default/images/indent.svg');
    }
    .ql-script[value="sub"]{
        background-image: url('../../assets/default/images/subscript.svg');
    }
    .ql-script[value="super"]{
        background-image: url('../../assets/default/images/superscript.svg');
    }
    .ql-clean{
        display: none;
        background-image: url('../../assets/default/images/clearFormatting.svg');
    }
    .ql-link{
        background-image: url('../../assets/default/images/linkStyles.svg');
    }
    .ql-image{
        background-image: url('../../assets/default/images/imageManager.svg');
    }
    .ql-align{
        display: none;
    }
    .ql-formula{
        background-image: url('../../assets/default/images/formula-icon.svg');
        background-size: 16px;
    }
    .ql-direction{
        display: none;
        background-image: url('../../assets/default/images/pilcrow.svg');
        background-size: calc(var(--theme-global-spacing) * 2.7);
        &.ql-active{
            background-image: url('../../assets/default/images/pilcrowhover.svg');
            svg{
                display: none;
            }
        }
    }
    .ql-formats button,.ql-picker-label{
        border-radius: var(--radius);
        &:hover {
            background-color: var(--theme-color-100);
            .ql-stroke{
                stroke: var(--theme-color-neutrals-1000);
            }
            .ql-fill{
                fill: var(--theme-color-neutrals-1000);
            }
        }
        &:focus {
            outline-width: 2px;
            outline-style: solid;
            outline-color: var(--theme-color-950);
        }
    }
}
.ql-container.ql-snow{
    height: calc(var(--theme-global-spacing) * 47);
    border-radius: 0 0 var(--radius) var(--radius);
    .ql-editor{
        font-family: var(--font-primary);
        @include context--base-sm;
        font-weight: var(--font-weight-normal);
        padding: calc(var(--theme-global-spacing) * 3) calc(var(--theme-global-spacing) * 4);
        height: 100%;
        &::before{
            left: 0;
            height: 100%;
            width: 100%;
            top: 0;
            padding: calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 3.5);
            font-style: var(--font-weight-normal);
        }
        @media (min-width: map-get($context--breakpoints, xxl)) {
            @include font-style(var(--text-xxxs), var(--text-xxs));
        }
        p{
            @include context--base-sm;
            color: var(--theme-color-neutrals-1000);
            @media (min-width: map-get($context--breakpoints, xxl)) {
                @include font-style(var(--text-xxxs), var(--text-xxs));
            }
        }
    }
    .ql-tooltip{
        box-shadow: map-get($shadow, diffuse-4) rgba(0, 0, 0, 0.25);
        border-radius: var(--radius-200);
        background-color: var(--theme-color-neutrals-0);
        border: 1px solid var(--theme-color-neutrals-400);
        padding: calc(var(--theme-global-spacing) * 4);
    }
    .ql-tooltip.ql-editing {
        left: 0 !important; /*To overwrite the third party plugin style "!important" tag has been used here*/
    }
    .ql-editing[data-mode=formula]{
        width: 354px;
        padding: calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 1);
        left: 88px !important;
        top: -11px !important;
        z-index: 1;
        &::before{
            content: none;
        }
        .mathquill4quill-latex-input{
            padding: calc(var(--theme-global-spacing) * 3) calc(var(--theme-global-spacing) * 4);
            @include context--base;
            height: 48px;
            border: 1px solid var(--theme-color-neutrals-500);
            border-radius: var(--radius);
            width: 238px;
        }
        .mathquill4quill-mathquill-input{
            margin-left: calc(var(--theme-global-spacing) * 2);
            width: 238px;
            height: auto;
            min-height: 48px;
            border: 1px solid var(--theme-color-neutrals-500);
            border-radius: var(--radius);
            padding: 0;
            vertical-align: middle;
            display: inline-flex;
            align-items: center;
            @include font-style(var(--text-base-size), 1.3);
            .mq-root-block{
                padding: calc(var(--theme-global-spacing) * 2);
            }
            @media (max-width: map-get($context--breakpoints, sm-max)) {
                width: calc(100% - 16px);
            }
            &:hover{
                border-color: var(--theme-color-neutrals-950);
            }
            &.mq-focused{
                outline-width: 2px;
                outline-style: solid;
                outline-offset: 1px;
                outline-color: var(--theme-color-950);
                box-shadow: none;
            }
        }
        .ql-action{
            background-color: var(--theme-color-500);
            color: var(--theme-color-neutrals-0);
            font-weight: var(--font-weight-bold);
            padding: calc(var(--theme-global-spacing) * 3) calc(var(--theme-global-spacing) * 4);
            margin-left: calc(var(--theme-global-spacing) * 4);
            border-radius: var(--radius);
            display: inline-block;
            vertical-align: middle;
            height: 48px;
            font-family: "Open Sans", sans-serif;
            @include context--base;
            &:after{
                margin: calc(var(--theme-global-spacing) * 0);
            }
            @media (min-width: map-get($context--breakpoints, xxl)) {
                @include context--base-sm;
            }
            @media (max-width: map-get($context--breakpoints, sm-max)) {
                display: block;
                margin: calc(var(--theme-global-spacing) * 4) auto calc(var(--theme-global-spacing) * 0);
                width: calc(100% - 16px);
                text-align: center;
            }
            &:hover{
                background-color: var(--theme-color-950);
                border-color: var(--theme-color-950);
            }
            &:focus{
                outline-width: 2px;
                outline-style: solid;
                outline-offset: 1px;
                outline-color: var(--theme-color-950);
            }
        }
        .mathquill4quill-operator-container{
            padding-top: calc(var(--theme-global-spacing) * 1);
            margin-top: calc(var(--theme-global-spacing) * 4);
            position: relative;
            &:before{
                content: '';
                position: absolute;
                top: 0;
                height: 1px;
                left: -8px;
                right: -8px;
                background-color: var(--theme-color-neutrals-150);
            }
            .mathquill4quill-operator-button{
                width: 40px;
                height: 40px;
                border: none;
                margin: calc(var(--theme-global-spacing) * 3) calc(var(--theme-global-spacing) * 2);
                padding: calc(var(--theme-global-spacing) * 0);
                @include context--avrage;
                color: var(--theme-color-neutrals-1000);
                cursor: pointer;
                &:hover{
                    background-color: var(--theme-color-950);
                    color: var(--theme-color-neutrals-0);
                }
                &:active{
                    background-color: var(--theme-color-700);
                    color: var(--theme-color-neutrals-0);
                }
                &:focus{
                    outline-width: 2px;
                    outline-style: solid;
                    outline-offset: 1px;
                    outline-color: var(--theme-color-950);
                }
                @media (max-width: map-get($context--breakpoints, sm-max)) {
                    margin: calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 1.75);
                }
                .katex {
                    font-family: "Open Sans", sans-serif;
                    font-weight: var(--font-weight-bold);
                    .mathnormal {
                        font-family: "Open Sans", sans-serif;
                    }
                }
                &[data-value="+"], &[data-value*="times"], &[data-value*="div"], &[data-value*="pm"]{
                    .mord{
                        @include font-style(calc(var(--text-xs) * 0.9), calc(var(--text-xxs) * 0.9));
                    }
                }
                &[data-value*="log{x}"], &[data-value*="log_{a}"], &[data-value*="sin{x}"], &[data-value*="cos{x}"], &[data-value*="tan{x}"], &[data-value*="csc{x}"], &[data-value*="sec{x}"], &[data-value*="cot{x}"]{
                    .base{
                        @include font-style(var(--text-avrage), var(--text-avrage));
                    }
                }
            }
            @media (max-width: map-get($context--breakpoints, sm-max)) {
                padding-left: calc(var(--theme-global-spacing) * 0.25);
                padding-right: calc(var(--theme-global-spacing) * 0.25);
                padding-top: calc(var(--theme-global-spacing) * 2);
            }
        }
        @media (max-width: map-get($context--breakpoints, sm-max)) {
            left: 0 !important;
            padding: calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 2);
            right: 0;
            margin: 0 auto;
        }
        @media (max-width: map-get($context--breakpoints, xs-max)) {
            width: 100%;
        }
    }
}