.c-hot-corner{
    .c-hot-corner__top-left, 
    .c-hot-corner__top-right, 
    .c-hot-corner__bottom-left,
    .c-hot-corner__bottom-right{
        color: var(--theme-color-500);
        padding: calc(var(--theme-global-spacing) * 4);
        &:hover,
        &:focus {
            color: var(---theme-color-neutrals-1000);
        }
    }
}
.icon-easy-reach{
    background-color: var(--theme-color-500);
  }