.c-progress__container {
  margin-bottom: calc(var(--theme-global-spacing) * 2);
  background-color: var(--theme-color-neutrals-100);
  border-radius: var(--radius-800);
  .c-progress__title {
    @include font-style(var(--text-base-size), var(--text-xs));
    font-weight: var(--font-weight-normal);
    font-family: var(--font-primary);
    color: var(--theme-color-neutrals-1000);
  }
}
progress {
  progress:focus{
    background: transparent;
    box-shadow: none;    
  }
}
.c-progress--success .c-progress__value {
  background-color: var(--theme-success);
}
.c-progress--danger{
  .c-progress__value {
    background-color: var(--theme-error);
  }
  .c-progress__title{
    color: var(--theme-error);
  }
} 