:root{
  --form-input-base-padding: var(--theme-global-spacing);

  --form-input-padding-y: calc(var(--form-input-base-padding)*2.5);
  @media (min-width: map-get($context--breakpoints, xxl)) {
    --form-input-padding-y: calc(var(--form-input-base-padding)*3);
  }
  @media (max-width: map-get($context--breakpoints, sm-max)) {
    --form-input-padding-y: calc(var(--form-input-base-padding)*2);
  }
}