//Global Navigation / label 
.c-page-list__item {
    margin-bottom: calc(var(--theme-global-spacing) * 1);
    padding: calc(var(--theme-global-spacing) * 5) calc(var(--theme-global-spacing) * 13) calc(var(--theme-global-spacing) * 5) 0;
    border-bottom-color: var(--theme-color-neutrals-1000);
    @include font-style(var(--text-base-size), var(--text-xs));
    font-weight: var(--font-weight-normal);
    font-family: var(--font-primary);
}
.c-page-list__item-name{
    font-weight: var(--font-weight-bold);
    margin-bottom: calc(var(--theme-global-spacing) * 1.5);
}
.c-page-list__item-details{
    margin-bottom: calc(var(--theme-global-spacing) * 1.5);
}
.c-page-list__item-link {
    color: var(--theme-color-neutrals-1000);
    border-radius: calc(var(--theme-global-spacing) * 1);
}
.c-page-list__item-status{
    background-color: var(--theme-color-500);
}
.c-page-list__item-content a{
    border-radius: calc(var(--theme-global-spacing) * 1);
}