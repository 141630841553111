//Cards
.c-card {
  background-color: var(--theme-color-neutrals-0);
  color: var(--theme-color-neutrals-1000);
  border-color: var(--theme-color-neutrals-200);
  &.c-card--enhanced:disabled,
  &.c-card--enhanced.disabled,
  &.c-card--create:disabled,
  &.c-card--create.disabled {
    background-color: var(--theme-color-neutrals-100);
    border-color: var(--theme-color-neutrals-150);
    color: var(--theme-color-neutrals-1000);
  }
  &:hover .c-card__link:before,
	&:hover .c-card__input-element:before,
	&:active .c-card__link:before,
	&:active .c-card__input-element:before  {
    border-color: (--theme-color-neutrals-950);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius:var(--radius-200);
  }
  .c-card__input-element,
  .c-card__link {
    .c-card__link-extra-resource {
      background-color: var(--theme-color-neutrals-0);
    }
    &:hover {
      border-color: var(--theme-transparent-background);
      box-shadow:none;
    }
    &:active {
      border-color: var(--theme-transparent-background);
      box-shadow: none;
    }
  }
  .c-card__header {
    padding: calc(var(--theme-global-spacing) * 6) calc(var(--theme-global-spacing) * 6)
      calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 6);

    @media (max-width: map-get($context--breakpoints, sm-max)) {
      padding: calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 4)
        calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 4);
    }
  }
  .c-card__subtitle {
    @include font-style(var(--text-base-size), var(--text-xs));
    font-weight: var(--font-weight-bold);
  }
  .c-card__body {
    @include context--base-sm;
    padding: calc(var(--theme-global-spacing) * 6);

      .c-card__img {
      padding: calc(var(--theme-global-spacing) * 3);
      @media (min-width: map-get($context--breakpoints, xxl)) {
        padding: calc(var(--theme-global-spacing) * 4);
      }
    }
    .c-card__add {
      padding-top: calc(var(--theme-global-spacing) * 8);
      padding-bottom: calc(var(--theme-global-spacing) * 4);
      @media (max-width: map-get($context--breakpoints, sm-max)) {
        padding-top: calc(var(--theme-global-spacing) * 3);
      }
      @media (min-width: map-get($context--breakpoints, xxl)) {
        padding-top: calc(var(--theme-global-spacing) * 12);
      }
    }
    @media (max-width: map-get($context--breakpoints, sm-max)) {
      padding: calc(var(--theme-global-spacing) * 4);
    }
    @media (min-width: map-get($context--breakpoints, xxl)) {
      padding: calc(var(--theme-global-spacing) * 6);
    }
    &--modified {
      padding-bottom: calc(var(--theme-global-spacing) * 14);
    }
    .c-card__body-content {
      @include font-style(var(--text-xxxs), var(--text-xxs));
    }
  }
  .c-card__header + .c-card__body {
    padding-top: calc(var(--theme-global-spacing) * 0);
  }
  .c-card__list dd {
    margin-left: 0 0 0 calc(var(--theme-global-spacing) * 19);
    padding-bottom: 0 0 calc(var(--theme-global-spacing) * 1) 0;
  }
  .c-card__footer {
    background: transparent;
    padding: 0 calc(var(--theme-global-spacing) * 6) calc(var(--theme-global-spacing) * 6)
      calc(var(--theme-global-spacing) * 6);
    @media (max-width: map-get($context--breakpoints, sm-max)) {
      padding: 0 calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 4)
        calc(var(--theme-global-spacing) * 4);
    }
    @media (min-width: map-get($context--breakpoints, xxl)) {
      padding: 0 calc(var(--theme-global-spacing) * 6) calc(var(--theme-global-spacing) * 6)
        calc(var(--theme-global-spacing) * 6);
    }
    .c-card__actions .u-button {
      padding:calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 4) ;
    }
  }
  .c-card__footer:empty,
  .c-card__header:empty,
  .c-card__aside:empty,
  .c-card__body:empty {
    display: none;
  }
  .c-card__aside {
    padding: calc(var(--theme-global-spacing) * 6);
    border-bottom-color: var(--theme-color-neutrals-150);
  }
}
.c-card--is-course-level .c-card__aside {
  padding: calc(var(--theme-global-spacing) * 6);
}
.c-card--global {
  &:hover {
    background-color: initial;
    color: initial;
  }
}
input,
select,
button,
a {
  outline: none;
}
