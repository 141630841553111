@font-face {
    font-family: 'Castledown';
    src: url('../../assets/default/fonts/Castledown-Bold.eot');
    src: url('../../assets/default/fonts/Castledown-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../assets/default/fonts/Castledown-Bold.woff2') format('woff2'),
        url('../../assets/default/fonts/Castledown-Bold.woff') format('woff'),
        url('../../assets/default/fonts/Castledown-Bold.ttf') format('truetype'),
        url('../../assets/default/fonts/Castledown-Bold.svg#Castledown-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Castledown';
    src: url('../../assets/default/fonts/Castledown-Regular.eot');
    src: url('../../assets/default/fonts/Castledown-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../assets/default/fonts/Castledown-Regular.woff2') format('woff2'),
        url('../../assets/default/fonts/Castledown-Regular.woff') format('woff'),
        url('../../assets/default/fonts/Castledown-Regular.ttf') format('truetype'),
        url('../../assets/default/fonts/Castledown-Regular.svg#Castledown-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Castledown';
    src: url('../../assets/default/fonts/Castledown-Heavy.eot');
    src: url('../../assets/default/fonts/Castledown-Heavy.eot?#iefix') format('embedded-opentype'),
        url('../../assets/default/fonts/Castledown-Heavy.woff2') format('woff2'),
        url('../../assets/default/fonts/Castledown-Heavy.woff') format('woff'),
        url('../../assets/default/fonts/Castledown-Heavy.ttf') format('truetype'),
        url('../../assets/default/fonts/Castledown-Heavy.svg#Castledown-Heavy') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Castledown Cursive';
    src: url('../../assets/default/fonts/CastledownCursive-Regular.eot');
    src: url('../../assets/default/fonts/CastledownCursive-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../assets/default/fonts/CastledownCursive-Regular.woff2') format('woff2'),
        url('../../assets/default/fonts/CastledownCursive-Regular.woff') format('woff'),
        url('../../assets/default/fonts/CastledownCursive-Regular.ttf') format('truetype'),
        url('../../assets/default/fonts/CastledownCursive-Regular.svg#CastledownCursive-Regular') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}