@import "theme/enhanced.scss";
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import '~quill/dist/quill.snow.css';
@import 'module-styles/common.scss';
html,
body {
    height: 100%;
    padding: 0;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
}
.skip-navigation {
    position: fixed !important;
    left: 20px;
    top: -100px;
    &:focus {
        top: 20px;
        opacity: 1;
        display: block;
        z-index: 10;
        outline: none;
        &:before {
            content: "";
            left: -3px;
            right: -3px;
            bottom: -3px;
            top: -3px;
            position: absolute;
            border: 2px solid rgba(204, 42, 54, 0.4);
            border-radius: 30px;
        }
    }
}
iframe {
    width: 100%;
    height: 100%;
}
.icon-wrap {
    position: absolute;
    width: 44px;
    height: 44px;
    cursor: pointer;
    z-index: 2;
    //for SA-6296 
}
.cursor-icon-hand {
  background-image: url("assets/default/images/rcficons/hand-default.svg");
  &:hover{
    background-image: url("assets/default/images/rcficons/hand-hover.svg");
  }
  &:active{
    background-image: url("assets/default/images/rcficons/hand-pressed.svg");
  }
  &.cursor-icon-hand--active {
    background-image: url("assets/default/images/rcficons/hand-active.svg");
  }
  &.cursor-icon-hand--focus {
    background-image: url("assets/default/images/rcficons/hand-default.svg");      
  }
  &:disabled {
    background-image: url("assets/default/images/rcficons/hand-disabled.svg");
  }
}
.cursor-icon-audio {
  background-image: url("assets/default/images/rcficons/audio-default.svg");
  &:hover{
    background-image: url("assets/default/images/rcficons/audio-hover.svg");
  }
  &:active{
    background-image: url("assets/default/images/rcficons/audio-pressed.svg");
  }
  &.cursor-icon-audio--active {
    background-image: url("assets/default/images/rcficons/audio-active.svg");
  }
  &.cursor-icon-audio--focus {
    background-image: url("assets/default/images/rcficons/audio-default.svg");
  }
  &:disabled {
    background-image: url("assets/default/images/rcficons/audio-disabled.svg");
  }
}
.cursor-icon-audio-multiple {
  background-image: url("assets/default/images/rcficons/audio-multi-default.svg");
  &:hover{
    background-image: url("assets/default/images/rcficons/audio-multi-hover.svg");
  }
  &:active{
    background-image: url("assets/default/images/rcficons/audio-multi-pressed.svg");
  }
  &.cursor-icon-audio-multiple--active {
    background-image: url("assets/default/images/rcficons/audio-multi-active.svg");
  }
  &.cursor-icon-audio-multiple--focus {
    background-image: url("assets/default/images/rcficons/audio-multi-default.svg");
  }
  &:disabled {
    background-image: url("assets/default/images/rcficons/audio-multi-disabled.svg");
  }
}
.cursor-icon-video {
  background-image: url("assets/default/images/rcficons/video-default.svg");
  &:hover{
    background-image: url("assets/default/images/rcficons/video-hover.svg");
  }
  &:active{
    background-image: url("assets/default/images/rcficons/video-pressed.svg");
  }
  &.cursor-icon-video--active {
    background-image: url("assets/default/images/rcficons/video-active.svg");
  }
  &.cursor-icon-video--focus {
    background-image: url("assets/default/images/rcficons/video-default.svg");
  }
  &:disabled {
    background-image: url("assets/default/images/rcficons/video-disabled.svg");
  }
}
.cursor-icon-video-multiple {
  background-image: url("assets/default/images/rcficons/video-multi-default.svg");
  &:hover{
    background-image: url("assets/default/images/rcficons/video-multi-hover.svg");
  }
  &:active{
    background-image: url("assets/default/images/rcficons/video-multi-pressed.svg");
  }
  &.cursor-icon-video-multiple--active {
    background-image: url("assets/default/images/rcficons/video-multi-active.svg");
  }
  &.cursor-icon-video-multiple--focus {
    background-image: url("assets/default/images/rcficons/video-multi-default.svg");
  }
  &:disabled {
    background-image: url("assets/default/images/rcficons/video-multi-disabled.svg");
  }
}
.cursor-icon-hand-multiple {
  background-image: url("assets/default/images/rcficons/hand-default-multi-activity.svg");
  &:hover{
    background-image: url("assets/default/images/rcficons/hand-default-multi-hover.svg");
  }
  &:active{
    background-image: url("assets/default/images/rcficons/hand-default-multi-pressed.svg");
  }
  &.cursor-icon-hand-multiple--active {
    background-image: url("assets/default/images/rcficons/hand-default-multi-active.svg");
  }
  &.cursor-icon-hand-multiple--focus {
    background-image: url("assets/default/images/rcficons/hand-default-multi-activity.svg");      
  }
  &:disabled {
    background-image: url("assets/default/images/rcficons/hand-default-multi-disabled.svg");
  }
}
.cursor-icon-story {
  background-image: url("assets/default/images/rcficons/story-default.svg");
  &:hover{
    background-image: url("assets/default/images/rcficons/story-hover.svg");
  }
  &:active{
    background-image: url("assets/default/images/rcficons/story-pressed.svg");
  }
  &.cursor-icon-story--active {
    background-image: url("assets/default/images/rcficons/story-active.svg");
  }
  &.cursor-icon-story--focus {
    background-image: url("assets/default/images/rcficons/story-default.svg");
  }
  &:disabled {
    background-image: url("assets/default/images/rcficons/story-disabled.svg");
  }
}
.cursor-icon-picture-study {
  background-image: url("assets/default/images/rcficons/slideshow-default.svg");
  &:hover{
    background-image: url("assets/default/images/rcficons/slideshow-hover.svg");
  }
  &:active{
    background-image: url("assets/default/images/rcficons/slideshow-pressed.svg");
  } 
  &.cursor-icon-picture-study--active {
    background-image: url("assets/default/images/rcficons/slideshow-active.svg");
  }
  &.cursor-icon-picture-study--focus {
    background-image: url("assets/default/images/rcficons/slideshow-default.svg");
  }
  &:disabled {
    background-image: url("assets/default/images/rcficons/slideshow-disabled.svg");
  }
}
.cursor-icon-author {
  background-image: url("assets/default/images/rcficons/author-default.svg");
  &:hover{
    background-image: url("assets/default/images/rcficons/author-hover.svg");
  }
  &:active{
    background-image: url("assets/default/images/rcficons/author-pressed.svg");
  }
  &.cursor-icon-author--active {
    background-image: url("assets/default/images/rcficons/author-active.svg");
  }
  &.cursor-icon-author--focus {
    background-image: url("assets/default/images/rcficons/author-default.svg");
  }
  &:disabled {
    background-image: url("assets/default/images/rcficons/author-disabled.svg");
  }
}
.cursor-icon-slide-show {
  background-image: url("assets/default/images/rcficons/slideshow-default.svg");
  &:hover{
    background-image: url("assets/default/images/rcficons/slideshow-hover.svg");
  }
  &:active{
    background-image: url("assets/default/images/rcficons/slideshow-pressed.svg");
  }
  &.cursor-icon-slide-show--active {
    background-image: url("assets/default/images/rcficons/slideshow-active.svg");
  }
  &.cursor-icon-slide-show--focus {
    background-image: url("assets/default/images/rcficons/slideshow-default.svg");
  }
  &:disabled {
    background-image: url("assets/default/images/rcficons/slideshow-disabled.svg");
  }
}
.plus {
    position: absolute;
    background-repeat: no-repeat;
    background-image: url("assets/default/images/rcficons/plus.svg");
    height: 40px;
    width: 40px;
    left: 8px;
    top: 5px;
}
.icon-wrap {
    .plus--activity {
        width: 25%;
        height: 25%;
        left: 22%;
        top: 14%;
        background-size: cover;
    }
}

// Toast message css start here 
body .page .c-toast {
    display: inline-flex;
    @include position(fixed, $top: 110px, $right: 32px);
    z-index: 10;
    .flex-mr-auto {
        padding-right: calc(var(--theme-global-spacing) * 10);
    }
    @media (max-width: map-get($context--breakpoints, sm-max)) {
        @include position(fixed, $top: 100px, $right: 16px, $left: 16px);
        display: flex;
        width: calc(100% - 32px);
        .flex-mr-auto {
            padding-right: 0px;
        }
    }
}

body.modal-open .page .c-toast {
  z-index: 500000;
}

.loader {
    padding: 10px 20px;
    display: inline-block;
    background-color: var(--theme-color-neutrals-0);
    border-width: 2px;
    border-style: solid;
    border-color: var(--theme-color-neutrals-0);
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1000000;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.loader .loader-block {
    width: 64px;
    height: 64px;
    display: block;
    border-radius: 50%;
    transform-origin: center;
    background: conic-gradient(var(--theme-color-neutrals-0) 0%, var(--theme-color-500));
    -webkit-mask: radial-gradient(farthest-side, var(--theme-transparent-background) calc(100% - 8px), var(--theme-color-neutrals-1000) 0);
    animation: s3 1s infinite linear;
}
.loader .loader-title {
    display: block;
    color: var(--theme-color-neutrals-1000);
    padding-top: calc(var(--theme-global-spacing) * 6);
}

@keyframes s3 {
    to {
        transform: rotate(1turn);
    }
}
.modal-open .page__head{
    z-index: 0 !important;
}

.page-block-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    &.u-w-100 {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
        height: calc(100vh - 116px);

        @media (max-width: map-get($context--breakpoints, md-max)) {
            height: calc(100vh - 197px);
        }
        @media (max-width: map-get($context--breakpoints, sm-max)) {
            height: calc(100vh - 186px);
        }
    }

    &.page-block-section--background-white {
        background-color: var(--theme-color-neutrals-0);
    }
}
.c-error-state .c-error-state__figure {
    @media (max-width: map-get($context--breakpoints, sm-max)) {
        width: 232px;
        margin: 0 auto;
    }
}




.icon-easy-reach {
    mask: url("assets/default/images/easy-reach.svg") no-repeat 50% 50% !important;
  }
  .c-player__button--play .c-player__button-icon--play {
    background-image: url("assets/default/images/player.svg") !important;
  }
  .c-player__button--pause .c-player__button-icon--pause {
    background-image: url("assets/default/images/pause.svg") !important;
  }
  .c-player__button--volume {
    .c-player__button-icon--audio {
      background-image: url("assets/default/images/sound.svg") !important;
    }
  
    .c-player__button-icon--mute {
      background-image: url("assets/default/images/sound-off.svg") !important;
    }
  }
  .c-player__volume-range--is-open {
    .c-player__button--volume .c-player__button-icon--audio {
      background-image: url("assets/default/images/sound-red.svg") !important;
    }
  }
  .c-read-aloud__audio-action {
    .c-read-aloud__actions-button {
      .c-read-aloud__button-icon--play {
        background-image: url("assets/default/images/play-icon.svg") !important;
      }
  
      .c-read-aloud__button-icon--pause {
        background-image: url("assets/default/images/pause-circle.svg") !important;
        filter: invert(1);
      }
  
      .c-read-aloud__button-icon--sound {
        background-image: url("assets/default/images/sound.svg") !important;
      }

      .c-read-aloud__button-icon--forward {
        background-image: url("assets/default/images/reload.svg") !important;
      }

      .c-read-aloud__button-icon--backward {
        background-image: url("assets/default/images/reload.svg") !important;
      }
    }
  }
  .c-draggable .c-draggable__actions-button .c-draggable__button-icon--drag{
    background-image: url("assets/default/images/darg-and-drop.svg") !important;
  }
  .c-stepper--complete .c-stepper__link:before {
    background-image: url("assets/default/images/progress-check.svg") !important;
  }
  .c-highlight__style {
    .c-highlight__style-form-control--small {
      input[type="radio"]:before {
        background-image: url("assets/default/images/highlight/highlight-sm-black.svg") !important;
      }
  
      input[type="radio"]:checked:before,
      input[type="radio"].active:before {
        background-image: url("assets/default/images/highlight/highlight-sm-white.svg") !important;
      }
  
      input[type="radio"]:disabled:before,
      input[type="radio"].disabled:before {
        background-image: url("assets/default/images/highlight/highlight-sm-disabled.svg") !important;
      }
    }
  
    .c-highlight__style-form-control--medium {
      input[type="radio"]:before {
        background-image: url("assets/default/images/highlight/highlight-md-black.svg") !important;
      }
  
      input[type="radio"]:checked:before,
      input[type="radio"].active:before {
        background-image: url("assets/default/images/highlight/highlight-md-white.svg") !important;
      }
  
      input[type="radio"]:disabled:before,
      input[type="radio"].disabled:before {
        background-image: url("assets/default/images/highlight/highlight-md-disabled.svg") !important;
      }
    }
  
    .c-highlight__style-form-control--large {
      input[type="radio"]:before {
        background-image: url("assets/default/images/highlight/highlight-large-black.svg") !important;
      }
  
      input[type="radio"]:checked:before,
      input[type="radio"].active:before {
        background-image: url("assets/default/images/highlight/highlight-large-white.svg") !important;
      }
  
      input[type="radio"]:disabled:before,
      input[type="radio"].disabled:before {
        background-image: url("assets/default/images/highlight/highlight-large-disabled.svg") !important;
      }
    }
  
    .c-highlight__style-form-control--extra-large {
      input[type="radio"]:before {
        background-image: url("assets/default/images/highlight/highlight-extra-large-black.svg") !important;
      }
  
      input[type="radio"]:checked:before,
      input[type="radio"].active:before {
        background-image: url("assets/default/images/highlight/highlight-extra-large-white.svg") !important;
      }
  
      input[type="radio"]:disabled:before,
      input[type="radio"].disabled:before {
        background-image: url("assets/default/images/highlight/highlight-extra-large-disabled.svg") !important;
      }
    }
  }
  .c-pen__style {
    .c-pen__style-form-control--small {
      input[type="radio"]:before {
        background-image: url("assets/default/images/pen/pen-sm-black.svg") !important;
      }
  
      input[type="radio"]:checked:before,
      input[type="radio"].active:before {
        background-image: url("assets/default/images/pen/pen-sm-white.svg") !important;
      }
  
      input[type="radio"]:disabled:before,
      input[type="radio"].disabled:before {
        background-image: url("assets/default/images/pen/pen-sm-disabled.svg") !important;
      }
    }
  
    .c-pen__style-form-control--medium {
      input[type="radio"]:before {
        background-image: url("assets/default/images/pen/pen-md-black.svg") !important;
      }
  
      input[type="radio"]:checked:before,
      input[type="radio"].active:before {
        background-image: url("assets/default/images/pen/pen-md-white.svg") !important;
      }
  
      input[type="radio"]:disabled:before,
      input[type="radio"].disabled:before {
        background-image: url("assets/default/images/pen/pen-md-disabled.svg") !important;
      }
    }
  
    .c-pen__style-form-control--large {
      input[type="radio"]:before {
        background-image: url("assets/default/images/pen/pen-large-black.svg") !important;
      }
  
      input[type="radio"]:checked:before,
      input[type="radio"].active:before {
        background-image: url("assets/default/images/pen/pen-large-white.svg") !important;
      }
  
      input[type="radio"]:disabled:before,
      input[type="radio"].disabled:before {
        background-image: url("assets/default/images/pen/pen-large-disabled.svg") !important;
      }
    }
  
    .c-pen__style-form-control--extra-large {
      input[type="radio"]:before {
        background-image: url("assets/default/images/pen/pen-extra-large-black.svg") !important;
      }
  
      input[type="radio"]:checked:before,
      input[type="radio"].active:before {
        background-image: url("assets/default/images/pen/pen-extra-large-white.svg") !important;
      }
  
      input[type="radio"]:disabled:before,
      input[type="radio"].disabled:before {
        background-image: url("assets/default/images/pen/pen-extra-large-disabled.svg") !important;
      }
    }
  }
  .c-type__style {
    .c-type__style-form-control--small {
      input[type="radio"]:before {
        background-image: url("assets/default/images/type/type-sm-black.svg") !important;
      }
  
      input[type="radio"]:checked:before,
      input[type="radio"].active:before {
        background-image: url("assets/default/images/type/type-sm-white.svg") !important;
      }
  
      input[type="radio"]:disabled:before,
      input[type="radio"].disabled:before {
        background-image: url("assets/default/images/type/type-sm-disabled.svg") !important;
      }
    }
  
    .c-type__style-form-control--medium {
      input[type="radio"]:before {
        background-image: url("assets/default/images/type/type-md-black.svg") !important;
      }
  
      input[type="radio"]:checked:before,
      input[type="radio"].active:before {
        background-image: url("assets/default/images/type/type-md-white.svg") !important;
      }
  
      input[type="radio"]:disabled:before,
      input[type="radio"].disabled:before {
        background-image: url("assets/default/images/type/type-md-disabled.svg") !important;
      }
    }
  
    .c-type__style-form-control--large {
      input[type="radio"]:before {
        background-image: url("assets/default/images/type/type-large-black.svg") !important;
      }
  
      input[type="radio"]:checked:before,
      input[type="radio"].active:before {
        background-image: url("assets/default/images/type/type-large-white.svg") !important;
      }
  
      input[type="radio"]:disabled:before,
      input[type="radio"].disabled:before {
        background-image: url("assets/default/images/type/type-large-disabled.svg") !important;
      }
    }
  
    .c-type__style-form-control--extra-large {
      input[type="radio"]:before {
        background-image: url("assets/default/images/type/type-large-extra-black.svg") !important;
      }
  
      input[type="radio"]:checked:before,
      input[type="radio"].active:before {
        background-image: url("assets/default/images/type/type-large-extra-white.svg") !important;
      }
  
      input[type="radio"]:disabled:before,
      input[type="radio"].disabled:before {
        background-image: url("assets/default/images/type/type-large-extra-disabled.svg") !important;
      }
    }
  }
  .c-type__color-selection {
    background-image: url("assets/default/images/type/type-text-color-selection.svg") !important;
  }
  .c-type__color-selection.c-type__color-selection--background {
    background-image: url("assets/default/images/type/type-background-color-selection.svg") !important;
  }
  .c-type__color-dropdown:checked:before {
    background-image: url("assets/default/images/check.svg") !important;
  }  
  .u-button{
    &--small{
      height: 32px;
      @media (min-width: map-get($context--breakpoints, xxl)) {
        height: 32px;
      }
    }
    &--medium{
      height: 40px;
      @media (min-width: map-get($context--breakpoints, xxl)) {
        height: 48px;
      }
    }
    &--large{
      height: 48px;
      @media (min-width: map-get($context--breakpoints, xxl)) {
        height: 48px;
      }
    }    
  }
  .c-header__action-menu-single .c-headers__actions-hamburger-button .u-button {
    @media (max-width: map-get($context--breakpoints, md-max)) {
      background-image: url("assets/default/images/hamburger-icon.svg") !important;
    }
  }
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
  }


  // Css for rewards modal
  #rewards-modal--dialog.rewards-modal{
    .c-dialog__inner.c-dialog--overlay {
      max-width: 100% !important;
      height: 100%;
        .c-dialog-content{
            height: calc(100vh - 33px);
            padding: 0;
            #rewardsFrame{
              border: 0;
            }
        }
  }
}
html mee-platform-builder-assign-students-footer [type=button],
html mee-platform-builder-students-review-footer [type=button],
html mee-platform-builder-review-questions-footer [type=button],
html mee-platform-builder-select-activities-footer [type=button]{
  -webkit-appearance: none;
}
html.device-android{
  ::-webkit-scrollbar-track{
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: var(--theme-color-neutrals-100);
      position: absolute;
      height: 100%;
  }
  ::-webkit-scrollbar{
    width: 5px;
    background-color: var(--theme-color-neutrals-100);
  }

  ::-webkit-scrollbar-thumb{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: var(--theme-color-neutrals-600);
  }
}
.icon-navigation-homework{
  background-image: url('assets/default/images/navigation-homework.svg');
  background-repeat: no-repeat;
  background-size: 100%;
}
.icon-usb-flash-drive{
  background-image: url('assets/default/images/usb-flash-drive.svg');
  background-repeat: no-repeat;
  background-size: 100%;
}
@media (max-width: map-get($context--breakpoints, md-max)) {
  .c-navigation .c-navigation__overlay{
    display: none;
  }
}

// Css for disabled button hover removal
@media (hover: none), (hover: on-demand) {
  body .u-button.u-button--default.u-button--disabled:hover {
    background-color: var(--theme-color-neutrals-50);
    border-color: var(--theme-color-neutrals-50);
    color: var(--theme-color-neutrals-400);
  }
}