/*Style for different text alignment*/
.text-aligned--default {
    text-align: left;
}
.text-aligned--left {
    text-align: left;
}
.text-aligned--center {
    text-align: center;
}
.text-aligned--right {
    text-align: right;
}