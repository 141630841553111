.c-footer {
  border-top-color: var(--theme-color-neutrals-500);
  background-color: var(--theme-color-neutrals-0);
  @include font-style(var(--text-base-size), var(--text-xs));
  font-weight: var(--font-weight-bold);
  font-family: var(--font-primary);
  padding: calc(var(--theme-global-spacing) * 11);
  @media (min-width: map-get($context--breakpoints, xxl)) {
    padding: calc(var(--theme-global-spacing) * 11);
  }
  @media (max-width: map-get($context--breakpoints, sm-max)) {
    padding: calc(var(--theme-global-spacing) * 4);
  }
  .c-footer__logo {
    margin-right: calc(var(--theme-global-spacing) * 4);
    @media (max-width: map-get($context--breakpoints, sm-max)) {
      padding-top: calc(var(--theme-global-spacing) * 10);
      margin: calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 10);
    }
  }
  .c-footer__nav {
    margin-left: auto;
    @media (max-width: map-get($context--breakpoints, sm-max)) {
      margin: calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 10);
    }
    .c-footer__nav-list {
      margin: calc(var(--theme-global-spacing) * 0);
      padding: calc(var(--theme-global-spacing) * 0);
      .c-footer__nav-list-item {
        padding: calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 5) calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 0);
        @media (min-width: map-get($context--breakpoints, xxl)) {
          padding: calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 7) calc(var(--theme-global-spacing) * 6) calc(var(--theme-global-spacing) * 0);
        }
        @media (max-width: map-get($context--breakpoints, sm-max)) {
          padding: calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 3) calc(var(--theme-global-spacing) * 0);
        }
        &:last-child {
          @media (max-width: map-get($context--breakpoints, sm-max)) {
            padding-bottom: calc(var(--theme-global-spacing) * 0);
          }
        }
        .c-footer__nav-list-item-link {
          color: var(--theme-link-background);
          @include font-style(var(--text-base-size), var(--text-xs));
          font-weight: var(--font-weight-bold);
          font-family: var(--font-primary);
          &:hover,
          &:focus {
            color: var(--theme-color-neutrals-1000);
          }
          &:focus {
            border-radius: var(--radius);
          }
        }
      }
    }
  }
  .c-footer__copy-right {
    color: var(--theme-color-neutrals-1000);
    font-weight: 400;
    padding-top: calc(var(--theme-global-spacing) * 2);
    @include font-style(var(--text-xxxs), var(--text-xxs));
    .c-footer__copy-right--version {
          display: inline-block;
          float: right;
    }
    @media (min-width: map-get($context--breakpoints, xxl)) {
      padding-top: calc(var(--theme-global-spacing) * 4.5);
    }
    @media (max-width: map-get($context--breakpoints, sm-max)) {
      padding-top: calc(var(--theme-global-spacing) * 0);
      padding-bottom: calc(var(--theme-global-spacing) * 6);
      .c-footer__copy-right--company-name {
          display: block;
      }
      .c-footer__copy-right--version {
          display: block;
          margin-top: calc(var(--theme-global-spacing) * 4);
          color: var(--theme-color-neutrals-800);
          float: none;
      }
    }
  }
}