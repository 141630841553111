.c-tooltip {
  background-color: var(--theme-color-neutrals-850);
  color: var(--theme-color-neutrals-0);
  padding: calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 6);
  border-radius: calc(var(--theme-global-spacing) * 2);
  @media (min-width: map-get($context--breakpoints, xxl)) {
    padding: calc(var(--theme-global-spacing) * 6);
  }
  .c-tooltip__close {
    border-radius: var(--theme-border-radius);
    background-color: var(--theme-transparent-background);
    color: var(--theme-color-neutrals-0);
    margin: calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 2);
    padding: calc(var(--theme-global-spacing) * 0);
    &:hover,
    &:focus {
      color: var(--theme-color-neutrals-0);
    }
    &:focus {
      border-color: var(--theme-color-neutrals-0);
    }
    .u-icon {
      @include context--base;
    }
  }
  .c-tooltip__content {
    font-size: var(--text-base-size);
    line-height: var(--text-xs);
    word-break: break-word;
  }
}

.c-tooltip__caret::before {
  background: var(--theme-color-neutrals-850);
}