.c-indicator {
  .c-indicator__count{
    @include font-style(var(--text-xxxs), var(--text-xxs));
    font-weight: var(--font-weight-bold);
    color: var(--theme-color-neutrals-0);
    padding: calc(var(--theme-global-spacing) * 2);
    @media (max-width: map-get($context--breakpoints, sm-max)) {
      padding: calc(var(--theme-global-spacing) * 1.5);
    }
    .c-indicator__count-text{
      padding: calc(var(--theme-global-spacing) * 0)  calc(var(--theme-global-spacing) * 0.2);
      @media (max-width: map-get($context--breakpoints, sm-max)) {
        padding: calc(var(--theme-global-spacing) * 0);
      }
    }
  }
  &.c-indicator--not-started-yet .c-indicator__count {
    @include indicator-not-started-yet;
  }
  &.c-indicator--not-completed .c-indicator__count {
    @include indicator-not-completed;
  }
  &.c-indicator--awaiting-grading .c-indicator__count {
    @include indicator-awaiting-grading;
  }
  &.c-indicator--complete .c-indicator__count {
    @include indicator-complete;
  }
  .c-indicator__link{
    @include context--base-sm;
    font-weight: var(--font-weight-bold);
    margin-top: calc(var(--theme-global-spacing) * 2);
    &:focus{
      outline-color: var(--theme-color-950);
      border-radius: var(--radius);
    }
    @media (max-width: map-get($context--breakpoints, sm-max)) {
      margin-top: calc(var(--theme-global-spacing) * 0);
      margin-left: calc(var(--theme-global-spacing) * 2);
    }
  }
  .c-indicator__label{
    @include context--base-sm;
    color: var(--theme-color-neutrals-800);
    font-weight: var(--font-weight-bold);
    margin-top: calc(var(--theme-global-spacing) * 2);
    @media (max-width: map-get($context--breakpoints, sm-max)) {
      margin-top: calc(var(--theme-global-spacing) * 0);
      margin-left: calc(var(--theme-global-spacing) * 2);
    }
  }
}
/*To overwrite numeric input component style*/
input[type=number].c-input-group__control.c-input-group__control--without-padding {
  padding-right: 0;
}