//modal-footer
.c-modal-footer{
  background-color: var(--theme-color-neutrals-0);
  padding: calc(var(--theme-global-spacing) * 3) calc(var(--theme-global-spacing) * 3);
  border-top-color: var(--theme-color-neutrals-150);
  @media (min-width: map-get($context--breakpoints, xxl)) {
    padding: calc(var(--theme-global-spacing) * 6) calc(var(--theme-global-spacing) * 4); 
  }
  @media (max-width: map-get($context--breakpoints, lg)) {
    padding: calc(var(--theme-global-spacing) * 3); 
  }
  .u-button{
    margin:0 calc(var(--theme-global-spacing) * 2.5);
  }
}