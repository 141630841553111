@mixin button-style-checkbox-size($theme) {
	padding: map-get($theme, 'padding');
}
/*This Mixin is for the size of custom checkbox which looks like button*/
/*default*/
@mixin button-style-checkbox-default {
	@include button-style-checkbox-size($button-style-checkbox-medium);
	@media (max-width: map-get($context--breakpoints, md)) {
		@include button-style-checkbox-size($button-style-checkbox-small);
	}
	@media (min-width: map-get($context--breakpoints, xxl)) {
		@include button-style-checkbox-size($button-style-checkbox-large);
	}
}
/*small*/
@mixin button-style-checkbox-small {
	@include button-style-checkbox-size($button-style-checkbox-small);
}
/*medium*/
@mixin button-style-checkbox-medium {
	@include button-style-checkbox-size($button-style-checkbox-medium);
}
/*large*/
@mixin button-style-checkbox-large {
	@include button-style-checkbox-size($button-style-checkbox-large);
}
