.c-header__gradient{
    background-color: var(--theme-color-500);
}


.c-header{
    background-color: var(--theme-color-neutrals-0);
    box-shadow: inset 0px -4px 0px var(--theme-color-500);
    padding: calc(var(--theme-global-spacing) * 5.5) calc(var(--theme-global-spacing) * 6);
    .c-header__nav{
        .c-header__nav-list{
          margin: calc(var(--theme-global-spacing) * 0);
            .c-header__nav-list-item{
                .c-header__nav-list-item-link{
                    color: var(--theme-color-neutrals-1000);
                    font-weight: var(--font-weight-bold);
                    padding: calc(var(--theme-global-spacing) * 1.25) calc(var(--theme-global-spacing) * 2.5);
                }
            }
        }
    }
  }