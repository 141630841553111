@use 'sass:math';
$gap-horizontal: 8px;
$gap-vertical: $gap-horizontal;
$gap-horizontal-sm: 12px;
$gap-vertical-sm: $gap-horizontal-sm;
$gap-horizontal-xxl: 16px;
$gap-vertical-xxl: $gap-horizontal-xxl;
.grid{
  display: flex;
  flex-flow: row wrap;
  justify-content: inherit;
  align-items: flex-start;
  width: 100% !important;
  padding: 0rem;
}

[class*='col-xxl-'],[class*='col-xl-'],[class*='col-lg-'], [class*='col-md-'], [class*='col-sm-'], [class*='col-'] {
        padding: $gap-horizontal;
        padding: $gap-vertical;
    @media (min-width: map-get($context--breakpoints, sm)) {
        padding: $gap-horizontal-sm;
        padding: $gap-vertical-sm;
    }
    @media (min-width: map-get($context--breakpoints, xxl)) {
        padding: $gap-horizontal-xxl;
        padding: $gap-vertical-xxl;
    }
}

.no-gutters {
  [class*='col-xxl-'],[class*='col-xl-'],[class*='col-lg-'], [class*='col-md-'], [class*='col-sm-'], [class*='col-'] {
    padding: 0;
  }
}

@for $i from 1 through 12 {

  .col-#{$i}{
    flex-basis: math.div(100% , math.div(12 , $i)) - 0%;
    max-width: math.div(100% , math.div(12 , $i)) - 0%;
  }

  .no-gutters{
    .col-#{$i}{
      flex-basis: math.div(100% ,  math.div(12 , $i)) - 0%;
      max-width: math.div(100% ,  math.div(12 , $i)) - 0%;
    }
  }

}

@mixin viewport-columns($screen-type: col-xxl, $gutter: 0%) {
  @for $i from 1 through 12 {
    .col-#{$screen-type}-#{$i}{
      flex-basis: math.div(100% , math.div(12 , $i)) - $gutter;
      max-width:  math.div(100% , math.div(12 , $i)) - $gutter;
    }
  }
}

@each $name, $size in $context--breakpoints {
  @media (min-width: $size) {
    @include viewport-columns($screen-type: $name, $gutter: 0%);
    .no-gutters{
      @include viewport-columns($screen-type: $name, $gutter: 0%);      
    }
  }
}