.c-progress-card {
    margin-bottom:calc(var(--theme-global-spacing) * 4);
    /*Header*/
    .c-progress-card__header {
        display: flex;
        @media (max-width: map-get($context--breakpoints, sm-max)) {
            display: block;
        }
        .c-progress-card__header-logo {
            width: 48px;
            height: 48px;
            border-radius: var(--radius);
            background-color: var(--theme-color-neutrals-50);
            margin-right: calc(var(--theme-global-spacing) * 4);
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            @media (max-width: map-get($context--breakpoints, sm-max)) {
                float: left;
            }
        }
        .c-progress-card__header-content {
            display: block;
            flex: 1;
            margin-right: calc(var(--theme-global-spacing) * 4);
            .c-progress-card__title {
                @include font-style(var(--text-base-size), var(--text-xs));
                font-weight: var(--font-weight-bold);
                color: var(--theme-color-neutrals-1000);
                word-break: break-word;
                @media (max-width: map-get($context--breakpoints, sm-max)) {
                    min-height: 48px;
                    display: flex;
                    align-items: center;
                }
            }
            .c-progress-card__list {
                padding-top:calc(var(--theme-global-spacing) * 1);
                display: flex;
                @media (max-width: map-get($context--breakpoints, sm-max)) {
                    display: block;
                    clear: both;
                    padding-top:calc(var(--theme-global-spacing) * 4);
                }
                .c-progress-card__list-item {
                    padding-right:calc(var(--theme-global-spacing) * 4);
                    margin-right:calc(var(--theme-global-spacing) * 4);
                    position: relative;
                    @media (max-width: map-get($context--breakpoints, sm-max)) {
                        margin-bottom:calc(var(--theme-global-spacing) * 2);
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        right: 0;
                        top: 2px;
                        width: 1px;
                        height: 16px;
                        background-color:var(--theme-color-neutrals-150); 
                        @media (max-width: map-get($context--breakpoints, sm-max)) {
                            display: none;
                        }
                    }
                    .c-progress-card__list-label {
                        margin-right:calc(var(--theme-global-spacing) * 1);
                        color: var(--theme-color-neutrals-600);
                        @media (max-width: map-get($context--breakpoints, sm-max)) {
                            display: block;
                            clear: both;
                            margin-bottom:calc(var(--theme-global-spacing) * 1);
                        }
                    }
                    &:last-child {
                        padding-right: 0;
                        margin-right: 0;
                        border: none;
                        @media (max-width: map-get($context--breakpoints, sm-max)) {
                            margin-bottom:0;
                        }
                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    /*Body Content*/
    .c-progress-card__body {
        .c-accordion__panel {
            border-bottom-color: var(--theme-transparent-background);
            .c-accordion__header {
                display: none !important; /*To override the component style*/
                @media (max-width: map-get($context--breakpoints, sm-max)) {
                    display: flex !important;/*To override the component style*/
                    padding: calc(var(--theme-global-spacing) * 4) 0 0;
                    .c-accordion__title-text {
                        @include font-style(var(--text-xxxs), var(--text-xxs));
                        font-weight: var(--font-weight-bold);
                        color: var(--theme-color-neutrals-800);
                        margin-left:calc(var(--theme-global-spacing) * 6) ;
                        text-decoration: underline;
                    }
                    .c-accordion__action-icon {
                        color: var(--theme-color-neutrals-800);
                        right: inherit !important;/*To override the component style*/
                        @include font-style(var(--text-base-size), var(--text-xxs));
                    }
                }
            }
            .c-accordion__content {
                padding:calc(var(--theme-global-spacing) * 6) 0 0 0;
                @media (max-width: map-get($context--breakpoints, sm-max)) {
                    padding:calc(var(--theme-global-spacing) * 4) 0 0 0;
                }
            }     
        } 
        .c-progress-card__data-list {
            display: flex;
            align-items:flex-start;
            align-content:flex-start;
            @media (max-width: map-get($context--breakpoints, sm-max)) {
                display: block;
            }
            .c-progress-card__data-list-item {
                margin-right:calc(var(--theme-global-spacing) * 4);
                flex-grow: 1;
                flex-basis: 0;
                @media (max-width: map-get($context--breakpoints, sm-max)) {
                    margin-bottom:calc(var(--theme-global-spacing) * 2);
                    margin-right: 0;
                }
                &:last-child {
                    margin-right: 0;
                    @media (max-width: map-get($context--breakpoints, sm-max)) {
                        margin-bottom:0;
                    }
                } 
                .c-accordion__panel{
                    .c-accordion__header {
                        display: block !important; /*To override the component style*/
                        text-align: center;
                        padding:calc(var(--theme-global-spacing) * 1) 0;
                        .c-accordion__title {
                            .c-accordion__title-text {
                                padding-right: calc(var(--theme-global-spacing) * 2);
                                @include font-style(var(--text-xxxs), var(--text-xxs));
                                font-weight: var(--font-weight-bold);
                                color:var(--theme-color-500);
                                text-decoration: underline;
                                display: flex;
                                justify-content: center;
                                &:hover {
                                    color: var(--theme-color-neutrals-1000);
                                    .c-accordion__collapse-label:before {
                                        color:var(--theme-color-neutrals-1000);
                                    }
                                }
                            }
                        }
                        .c-accordion__action-icon {
                            position: inherit;
                            right: inherit;
                            bottom:4px;
                            font-size: var(--text-base-size);
                            color: var(--theme-color-500);
                        }
                    }
                    .c-accordion__content{
                        padding-top: 0;
                    }
                    .c-progress-card__skil-block-list {
                        .c-progress-card__skil-block-list-item {
                            display: none;
                            align-items: center;
                            justify-content: space-between;
                            padding-bottom:calc(var(--theme-global-spacing) * 1);
                            @include font-style(var(--text-base-size), var(--text-xs));
                            font-weight: var(--font-weight-normal);
                            color: var(--theme-color-neutrals-1000);
                            .c-progress-card__skil-block-list-item-name {
                                text-align: left;
                                width: 100%;
                                color: var(--theme-color-neutrals-1000);
                                @include font-style(var(--text-xxxs), var(--text-xxs));
                                font-weight: var(--font-weight-bold);
                            }
                            .c-progress-card__skil-block-list-item-score {
                                text-align: right;
                                width: 50px;
                                @include font-style(var(--text-xxxs), var(--text-xxs));
                                color:var(--theme-color-neutrals-1000);
                                font-weight: var(--font-weight-normal);
                            }
                            &:nth-child(1), &:nth-child(2), &:nth-child(3){
                                display: flex;
                            }
                        }
                    }
                }
                .c-accordion__panel[open]{
                    .c-progress-card__skil-block-list {
                        .c-progress-card__skil-block-list-item {
                            display: flex;
                        }
                    }
                }
                .c-card .c-card__body {
                    padding:calc(var(--theme-global-spacing) * 4);
                    @media (max-width: map-get($context--breakpoints, sm-max)) {
                        padding:calc(var(--theme-global-spacing) * 2);  
                        .c-accordion__panel .c-accordion__header .c-accordion__title .c-accordion__title-text {
                            margin-left: 0;
                        }
                    }
                    .c-progress-card__data-list-item-tag {
                        padding-bottom: calc(var(--theme-global-spacing) * 2);
                        &:last-child {
                            padding-bottom: 0;
                        }
                        .c-progress-card__data-list-item-tag-name {
                            @include font-style(var(--text-xxxs), var(--text-xxs));
                            font-weight: var(--font-weight-normal);
                            color: var(--theme-color-neutrals-600);
                        }
                    }
                    .c-progress-card__data-list-item-tag-list {
                        .c-chip {
                            margin:calc(var(--theme-global-spacing) * 1) calc(var(--theme-global-spacing) * 1) 0 0 ;
                            padding: calc(var(--theme-global-spacing) * .75) calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * .75) calc(var(--theme-global-spacing) * 2);
                            &:hover {
                                border-color: var(--theme-color-neutrals-500);
                            }
                            .c-chip__input-element {
                                display: none;
                            }
                            
                        }
                    }
                    .c-accordion .c-accordion__panel .c-accordion__collapse-label {
                        display: block;
                        position: relative;
                        &:before {
                            content: "\e929";
                            position: absolute;
                            right: -23px;
                            top: 1px;
                            width: 20px;
                            height: 20px;
                            font-size: var(--text-base-size);
                            color: var(--theme-color-500);
                            font-family: mee-platform,sans-serif;
                            transform: rotate(180deg);
                        }
                    }
                    .c-accordion .c-accordion__panel .c-accordion__expand-label {
                        display: none;
                    }
                    .c-accordion .c-accordion__panel.c-accordion__panel[open] .c-accordion__expand-label {
                        display: block;
                        position: relative;
                        &:before {
                            content: "\e929";
                            position: absolute;
                            right: -23px;
                            top: 1px;
                            width: 20px;
                            height: 20px;
                            font-size: var(--text-base-size);
                            color: var(--theme-color-500);
                            font-family: mee-platform,sans-serif;
                        }
                    }
                    .c-accordion .c-accordion__panel.c-accordion__panel[open] .c-accordion__collapse-label,
                    .c-accordion .c-accordion__panel.c-accordion__panel[open] .c-accordion__header .c-accordion__action-icon,
                    .c-accordion .c-accordion__panel.c-accordion__panel .c-accordion__header .c-accordion__action-icon {
                        display: none;
                    }.c-accordion.c-accordion--open-top .c-accordion__title:hover .c-accordion__expand-label::before {
                        color: var(--theme-color-neutrals-1000);
                    }
                    .c-accordion.c-accordion--open-top .c-accordion__panel.c-accordion__panel {
                        padding-bottom: calc(var(--theme-global-spacing) * 7);
                        min-height: 102px;
                        position: relative;
                        overflow: hidden;
                    }
                    .c-accordion.c-accordion--open-top .c-accordion__panel.c-accordion__panel .c-accordion__header-content{
                        display: block;
                        margin-bottom: calc(var(--theme-global-spacing) * 1);
                    }
                    .c-accordion.c-accordion--open-top .c-accordion__panel.c-accordion__panel[open]  .c-accordion__header-content{
                        display: none;
                    }
                }
                .c-progress-card__data-list-item-title {
                    @include font-style(var(--text-base-size), var(--text-xs));
                    font-weight: var(--font-weight-normal);
                    color: var(--theme-color-neutrals-1000);
                    padding-bottom:calc(var(--theme-global-spacing) * 2);
                    padding-right: calc(var(--theme-global-spacing) * 5);
                    display: block;
                }
                .c-progress-card__data-list-item-score{
                    @include font-style(var(--text-xxxs), var(--text-xxs));
                    font-weight: var(--font-weight-bold);
                    color: var(--theme-color-neutrals-500);
                    .c-progress-card__data-list-item-score-value {
                        @include font-style(var(--text-xs), var(--text-md));
                        font-weight: var(--font-weight-bold);
                        color: var(--theme-color-neutrals-1000);
                        display: block;
                        @media (max-width: map-get($context--breakpoints, sm-max)) {
                            @include font-style(var(--text-xxs), var(--text-sm));
                        }
                    }
                }
                .c-progress-card__data-list-item-info {
                    position: absolute;
                    right: 16px;
                    top: 16px;
                    padding: calc(var(--theme-global-spacing) * 1) 0;
                    width: 16px;
                    height: 24px;
                    @media (max-width: map-get($context--breakpoints, sm-max)) {
                        right: 8px;
                        top:8px;
                    }
                    .c-progress-card__data-list-item-info-icon.icon-info-circle {
                        color: var(--theme-color-500);
                        font-size: var(--text-base-size);
                        vertical-align: top;
                        &::before {
                            content: "\e952";
                        }
                    }
                }
            }
            .c-progress-card__data-list-item--no-padding.c-progress-card__data-list-item .c-card .c-card__body {
                padding-bottom:calc(var(--theme-global-spacing) * 1);
            }
        }
        .c-progress-card__data-list--equal-height {
            align-items: normal;
        }
        .c-progress-card__body-content-outer {
            padding:calc(var(--theme-global-spacing) * 6) 0 0 0;
            @media (max-width: map-get($context--breakpoints, sm-max)) {
                padding:calc(var(--theme-global-spacing) * 4) 0 0 0;
            }
        }     
    }
    /*Footer*/
    .c-progress-card__button-group {
        @media (max-width: map-get($context--breakpoints, sm-max)) {
            margin-top:calc(var(--theme-global-spacing) * 4);
            .u-button {
                width: 100%;
                justify-content: center;
                @media (max-width: map-get($context--breakpoints, sm-max)) {
                    height:calc(var(--theme-global-spacing) * 10);
                    padding:calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 4); ;
                }
            }
        }
    }
}