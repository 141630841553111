.c-time-form {
	color: var(--theme-color-neutrals-500);
	border-color: var(--theme-color-neutrals-500);
	border-radius: var(--radius);
	padding: calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 12);
	height: calc(var(--theme-global-spacing) * 12);
	&:hover {
		border-color: var(--theme-color-neutrals-950);
	}
	&:focus-within {
		outline-color: var(--theme-color-950);
	}
	.c-time-input-group__control {
		padding: calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 0.6);
		color: var(--theme-color-neutrals-500);
		font-size: var(--text-base-size);
		line-height: var(--text-xs);
		font-weight: var(--font-weight-normal);
		font-family: var(--font-primary);
		&:valid {
			color: var(--theme-color-neutrals-1000);
		}
		&:focus {
			background-color: var(--theme-color-200);
			border-radius: var(--radius);
			color: var(--theme-color-neutrals-1000);
		}
		&::-webkit-input-placeholder,
		&:-moz-placeholder,
		&::-moz-placeholder,
		&:-ms-input-placeholder,
		&::-ms-input-placeholder,
		&::placeholder {
			color: var(--theme-color-neutrals-500);
		}
	}
	.u-icon.c-time-form__clock-icon {
		padding: calc(var(--theme-global-spacing) * 3) calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 3) calc(var(--theme-global-spacing) * 4);
		background-color: var(--theme-transparent-background);
		font-size: var(--text-xs);
	    color: var(--theme-color-neutrals-1000);
	}
	.c-time-form__input-button {
		width: calc(var(--theme-global-spacing) * 8);
		height: calc(var(--theme-global-spacing) * 12);
		.c-time-form__up-button {
			background-color: var(--theme-transparent-background);
			height: calc(var(--theme-global-spacing) * 6);
			padding: calc(var(--theme-global-spacing) * 0);
			-webkit-tap-highlight-color: var(--theme-transparent-background);
			&:hover,
			&:active {
				background-color: var(--theme-color-neutrals-1000);
				color: var(--theme-color-neutrals-0);
				border-radius: calc(var(--radius) * 0) calc(var(--radius)) calc(var(--radius) * 0) calc(var(--radius) * 0);

				.c-time-form__up-icon {
					color: var(--theme-color-neutrals-0);
				}
			}
			&:focus {
				outline-color: var(--theme-color-950);
			}
			&:disabled {
				color: var(--theme-color-neutrals-400);
				background-color: var(--theme-color-neutrals-50);
			}
		}
		.c-time-form__down-button {
			background-color: var(--theme-transparent-background);
			height: calc(var(--theme-global-spacing) * 6);
			padding: calc(var(--theme-global-spacing) * 0);
			-webkit-tap-highlight-color: var(--theme-transparent-background);
			&:hover,
			&:active {
				background-color: var(--theme-color-neutrals-1000);
				color: var(--theme-color-neutrals-0);
				border-radius: calc(var(--radius) * 0) calc(var(--radius) * 0) calc(var(--radius)) calc(var(--radius) * 0);
				.c-time-form__down-icon {
					color: var(--theme-color-neutrals-0);
				}
			}
			&:focus {
				outline-color: var(--theme-color-950);
			}
			&:disabled {
				background-color: var(--theme-color-neutrals-50);
				color: var(--theme-color-neutrals-400);
			}
		}
		.c-time-form__up-icon,
		.c-time-form__down-icon {
			@include context--base;
			color: var(--theme-color-neutrals-1000);
			@media (min-width: map-get($context--breakpoints, xxl)) {
				@include context--base-sm;
			}
		}
	}
}
/*For Invalid form*/
.c-time-form--invalid {
	border-color: var(--theme-error);
	&:hover {
		border-color: var(--theme-error);
	}
	+ .c-time-form__helper-text{
		color: var(--theme-error);
	}
}
/*For disable the form*/
.c-time-form--disabled {
	background-color: var(--theme-color-neutrals-50);
	border-color: var(--theme-color-neutrals-200);
	color: var(--theme-color-neutrals-400);
	.c-time-form__down-button,
	.c-time-form__up-button,
	.c-time-form__clock-icon,
	.c-time-form__input-button .c-time-form__up-icon,
	.c-time-form__input-button .c-time-form__down-icon,
	.c-time-input-group__control {
		background-color: var(--theme-transparent-background);
		color: var(--theme-color-neutrals-400);
		-webkit-text-fill-color: var(--theme-color-neutrals-400);
		opacity: 1;
		&:hover {
			background-color: var(--theme-transparent-background);
			color: var(--theme-color-neutrals-400);
		}
	}
	&:hover {
		border-color: var(--theme-color-neutrals-200);
	}
	+ .c-time-form__helper-text{
		color: var(--theme-color-neutrals-400);
	}
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	margin: calc(var(--theme-global-spacing) * 0);
}
input[disabled] {
	-webkit-text-fill-color: var(--theme-color-neutrals-400);
}
.c-time-form__outer-label {
	color: var(--theme-color-neutrals-1000);
	margin-bottom: calc(var(--theme-global-spacing) * 2);
	.c-time-form__label-required {
		color: var(--theme-error);
	}
	&.c-time-form__outer-label--disabled {
		color: var(--theme-color-neutrals-400);
	}
	@media (min-width: map-get($context--breakpoints, xxl)) {
		@include context--base-sm;
	}
}
.c-time-form__helper-text {
	display: block;
	color: var(--theme-color-neutrals-1000);
	margin-top: calc(var(--theme-global-spacing) * 2);
	@media (min-width: map-get($context--breakpoints, xxl)) {
		@include context--base-sm;
	}
}
/*Override all the hover styles only for touch devices*/
@media (hover:none), (hover:on-demand) {
	.c-time-form .c-time-form__input-button .c-time-form__up-button:hover, 
	.c-time-form .c-time-form__input-button .c-time-form__up-button:active,
	.c-time-form .c-time-form__input-button .c-time-form__down-button:hover, 
	.c-time-form .c-time-form__input-button .c-time-form__down-button:active {
		background-color: var(--theme-transparent-background);
		.c-time-form__up-icon,
		.c-time-form__up-icon,
		.c-time-form__down-icon,
		.c-time-form__down-icon {
			color: var(--theme-color-neutrals-1000);
		}
	}
}