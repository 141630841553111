.c-tree {
    .c-tree__header {
        padding: calc(var(--theme-global-spacing) * 1) calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 2);
        .c-tree__header-title {
            @include context--base;
            font-weight: var(--font-weight-bold);
            margin: calc(var(--theme-global-spacing) * 0);
            padding-right: calc(var(--theme-global-spacing) * 4);
        }
        .c-tree__action{
            .u-button{
                padding: calc(var(--theme-global-spacing) * 0.5) calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 0.5) calc(var(--theme-global-spacing) * 3);
                white-space: nowrap;
                .u-icon{
                    font-size: var(--text-base-size);
                    margin-left: calc(var(--theme-global-spacing) * 2.75);
                }
            }
        }
    }
    .c-tree__list {
        padding: calc(var(--theme-global-spacing) * 0);
        .c-tree__list-item {
            padding: calc(var(--theme-global-spacing) * 0);
            .c-tree__expand-label {
                padding: calc(var(--theme-global-spacing) * 1);
                margin: calc(var(--theme-global-spacing) * 1);
                ~ .c-tree__label{
                    padding-left: calc(var(--theme-global-spacing) * 0);
                }
            }
            .c-tree__input-expand:focus+.c-tree__expand-label {
                outline-color: var(--theme-color-950);
                border-radius: var(--radius);
            }
            .c-tree__label {
                padding: calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 1) calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 10);
                .c-tree__label-check-mark {
                    padding-left: calc(var(--theme-global-spacing) * 6);
                    &::before {
                        border-color: var(--theme-color-neutrals-500);
                        border-radius: var(--radius);
                        background-color: var(--theme-color-neutrals-0);
                    }
                }
                .c-tree__label-icon {
                    margin-right: calc(var(--theme-global-spacing) * 2);
                }
                &.c-tree__label--is-active {
                    &::before {
                        background-color: var(--theme-color-200);
                        border-radius: var(--radius);
                    }
                    .c-tree__label-title{
                        font-weight: var(--font-weight-bold);
                    }
                }
            }
            .c-tree__label-title {
                padding-left: calc(var(--theme-global-spacing) * 2);
                flex: 1;
                word-break: break-word;
            }
            .c-tree__label-title--no-checkbox {
                padding-left:0;
            }
            .c-tree__input{
                &:checked{
                    +.c-tree__label {
                        .c-tree__label-check-mark::after {
                            color: var(--theme-color-500);
                        }
                    }
                    ~.c-tree__list {
                        margin: calc(var(--theme-global-spacing) * 0);
                    }
                }
                &:hover{
                    +.c-tree__label {
                        .c-tree__label-check-mark::before {
                            background-color: var(--theme-color-850);
                            border-color: var(--theme-color-850);
                        }
                        .c-tree__label-check-mark::after {
                            color: var(--theme-color-neutrals-0);
                        }
                    }
                }
                &:focus{
                    +.c-tree__label {
                        .c-tree__label-check-mark::before {
                            outline-color: var(--theme-color-950);
                        }
                    }
                }
            }
            &.c-tree__list-item--indeterminate>.c-tree__list-item-block>.c-tree__input{
                +.c-tree__label {
                    .c-tree__label-check-mark::after {
                        background-color: var(--theme-color-500);
                    }
                }
                &:hover{
                    +.c-tree__label {
                        .c-tree__label-check-mark {
                            &::after {
                                background-color: var(--theme-color-neutrals-0);
                            }
                        }
                    }
                }
            }
            .c-tree__list-item {
                padding: calc(var(--theme-global-spacing) * 1) calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 3);
            }
        }
    }
}

@media (hover:none),
(hover:on-demand) {
    .c-tree .c-tree__list .c-tree__list-item .c-tree__input:hover+.c-tree__label .c-tree__label-check-mark {
        &:before {
            background-color: var(--theme-color-neutrals-0);
        }
        &:after {
            color: var(--theme-color-500);
        }
    }
}