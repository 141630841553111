/**
 * Typography
 * Font settings
 */

:root {
    --font-primary: var(--theme-global-font-familly);
    --font-weight-normal: 400;
    --font-weight-bold: 600;
    --font-weight-heavy: 900;

    /* set base values */
    --text-base-size: 1rem; /*16px*/

    /* type scale */
    --text-smallest: calc(0.625 * var(--text-base-size)); // 10px
    --text-avrage: calc(0.75 * var(--text-base-size)); // 12px
    --text-xxxs: calc(0.875 * var(--text-base-size)); // 14px
    --text-xxs: calc(1.25 * var(--text-base-size)); // 20px
    --text-small: calc(1.375 * var(--text-base-size)); // 22px
    --text-xs: calc(1.5 * var(--text-base-size)); // 24px
    --text-sm: calc(1.75 * var(--text-base-size)); // 28px
    --text-medium: calc(1.875 * var(--text-base-size)); // 30px
    --text-md: calc(2 * var(--text-base-size)); // 32px
    --text-lg: calc(2.25 * var(--text-base-size)); // 36px
    --text-xl: calc(2.5 * var(--text-base-size)); // 40px
    --text-xxl: calc(2.75 * var(--text-base-size)); // 44px
    --text-xxxl: calc(3 * var(--text-base-size)); // 48px
}
