// Floating panel pen css start here
.c-pen {
	// Common wrapper for pen style
	.c-pen__style {
		padding: calc(var(--theme-global-spacing) * 3);
		/* pen form control */
		.c-pen__style-form-control {
			margin: 0 calc(var(--theme-global-spacing) * 1);
			/* pen form control radio button hover style */
			input[type="radio"]:hover, 
			input[type="radio"]:disabled:hover,
			input[type="radio"].disabled:hover,
			input[type="radio"].disabled:focus {
				border-color: var(--theme-color-500);
			}
			
			/* pen form control radio button custom checked*/
			input[type="radio"]:checked::before {
				background-color: var(--theme-color-500);
			}
			input[type="radio"].disabled:checked::before {
				background-color: var(--theme-color-neutrals-0);
			}
		}
	}
	.c-pen__divider {
		background-color: var(--theme-color-neutrals-150);
	}
	// Common wrapper for pen color
	.c-pen__color {
		padding: calc(var(--theme-global-spacing) * 4);
		/* pen form control */
		.c-pen__color-form-control {
			margin: calc(var(--theme-global-spacing) * 2);
			/* pen form control radio style */
			input[type="radio"] {
				border-color: var(--theme-color-neutrals-150);
			}
		}
	}
}
// Floating panel IWB css start here
@media (min-width: map-get($context--breakpoints, xxl)) {
	.c-pen .c-pen__style {
		padding: calc(var(--theme-global-spacing) * 5);
	}
	.c-pen .c-pen__color {
		padding-bottom: calc(var(--theme-global-spacing) * 8);
		/* pen form control */
		.c-pen__color-form-control {
			margin: calc(var(--theme-global-spacing) * 3);
		}
	}
}
// Floating panel Mobile css start here
@media (max-width: map-get($context--breakpoints, sm-max)) {
	.c-pen .c-pen__color {
		padding: calc(var(--theme-global-spacing) * 6) calc(var(--theme-global-spacing) * 6);
		.c-pen__color-form-control {
			margin: calc(var(--theme-global-spacing) * 3);
		}
	}
}
