.c-pagination {
  border-color: var(--theme-color-neutrals-200);
  padding: calc(var(--theme-global-spacing) * 4) calc(var(--theme-global-spacing) * 6);
  border-radius: var(--radius-200);
  .c-pagination__length-count {
    margin-left: calc(var(--theme-global-spacing) * 4);
  }
  .c-paggination__length{    
    .c-dropdown{
      .c-dropdown__button{
        border-color: var(--theme-color-neutrals-500);
        margin-bottom: calc(var(--theme-global-spacing) * 0);
        min-width: 150px;
      }
      .c-dropdown__checkbox ~ .c-dropdown__list-outer{
        min-width: 100%;
      }
    }
    @media (max-width: map-get($context--breakpoints, sm-max)) {
      margin-top: calc(var(--theme-global-spacing) * 6);
    }
  }
  .c-pagination__list-outer {
     margin-left: auto; 
     .c-pagination__list {    
      margin: calc(var(--theme-global-spacing) * 0) auto;
      padding: calc(var(--theme-global-spacing) * 0);
      .c-pagination__item {
        padding: calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 0) calc(var(--theme-global-spacing) * 1);
        .c-pagination__link {
          border-radius: var(--radius);
          color: var(--theme-color-neutrals-1000);
          &:hover {
            background-color: var(--theme-color-950);
            color: var(--theme-color-neutrals-0);
          }
          &:active {
            background-color: var(--theme-transparent-background);
            color: var(--theme-color-neutrals-1000);
          }
          &:focus {
            outline-color: var(--theme-color-950);
          }
          &.c-pagination__link--ellipsis{
            background-color: var(--theme-transparent-background);
            color: var(--theme-color-neutrals-1000);
          }
        }
        .c-pagination__link--active {
          background-color: var(--theme-color-200);
          color: var(--theme-color-neutrals-1000);
        }
        .c-pagination__link--prev,
        .c-pagination__link--next {
          padding: calc(var(--theme-global-spacing) * 2);
          border-color: var(--theme-color-500);
          &:hover {
            background-color: var(--theme-color-neutrals-1000);
            color: var(--theme-color-neutrals-0);
            border-color: var(--theme-color-neutrals-1000);
          }
        }
        .c-pagination__link--disabled {
          background-color: var(--theme-color-neutrals-0);
          border-color: var(--theme-color-200);
          color: var(--theme-color-neutrals-400);
          &:hover{
            background-color: var(--theme-color-neutrals-0);
            border-color: var(--theme-color-200);
            color: var(--theme-color-neutrals-400);
          }
        }
        &:last-child {
          padding: 0;
        }
      }
      .c-pagination__dotted-text {
        padding: calc(var(--theme-global-spacing) * 2) calc(var(--theme-global-spacing) * 3) calc(var(--theme-global-spacing) * 0);
      }
      .c-pagination__link--prev {
        margin-right: calc(var(--theme-global-spacing) * 3);
        @media (max-width: map-get($context--breakpoints, sm-max)) {
          margin-right: calc(var(--theme-global-spacing) * 1.5);
        }
      }
      .c-pagination__link--next {
        margin-left: calc(var(--theme-global-spacing) * 4);
        @media (max-width: map-get($context--breakpoints, sm-max)) {
          margin-right: calc(var(--theme-global-spacing) * 2.5);
        }
      }
      @media (max-width: map-get($context--breakpoints, sm-max)) {
        margin: calc(var(--theme-global-spacing) * 0) auto;
        .c-pagination__item{
          padding-left: calc(var(--theme-global-spacing) * 1);
          .c-pagination__link--prev {
            margin-right: calc(var(--theme-global-spacing) * 1.5);
            padding: calc(var(--theme-global-spacing) * 1.5);
          }
          .c-pagination__link--next {
            margin-left: calc(var(--theme-global-spacing) * 2.5);
            padding: calc(var(--theme-global-spacing) * 1.5);
            margin-right: 0;
          }
          &:first-child {
            padding-left: 0;
          }
        }
      }
    }
  }
  .c-paggination__length + .c-pagination__list {
    margin-left: auto;
    margin-right: calc(var(--theme-global-spacing) * 0);
    @media (max-width: map-get($context--breakpoints, sm-max)) {
      margin: calc(var(--theme-global-spacing) * 0);
    }
  }
  @media (max-width: map-get($context--breakpoints, sm-max)) {
    padding: calc(var(--theme-global-spacing) * 2);
  }
  .c-dropdown__button-text{
    padding-right: calc(var(--theme-global-spacing) * 3);
  }
  @media (max-width: map-get($context--breakpoints, xs-max)) {
    padding: calc(var(--theme-global-spacing) * 2);
  }
}
/*Override all the hover styles only for touch devices*/
@media (hover:none),
(hover:on-demand) {
  .c-pagination .c-pagination__list .c-pagination__item .c-pagination__link:hover, .c-pagination .c-pagination__list-outer .c-pagination__list .c-pagination__item .c-pagination__link:hover {
    background-color: var(--theme-color-200);
    color: var(--theme-color-neutrals-1000);
  }
  .c-pagination .c-pagination__list .c-pagination__item .c-pagination__link.c-pagination__link--next:hover,
  .c-pagination .c-pagination__list .c-pagination__item .c-pagination__link.c-pagination__link--prev:hover {
    border-color:var(--theme-color-500);
    background-color: var(--theme-color-neutrals-0);
  }
  .c-pagination .c-pagination__list-outer .c-pagination__list .c-pagination__item .c-pagination__link--disabled:hover,
  .c-pagination .c-pagination__list-outer .c-pagination__list .c-pagination__item .c-pagination__link--disabled:hover:focus,
  .c-pagination .c-pagination__list-outer .c-pagination__list .c-pagination__item .c-pagination__link--disabled:focus:hover {
    background-color: var(--theme-color-neutrals-0);
    border-color: var(--theme-color-200);
    color: var(--theme-color-neutrals-400);
  }
}